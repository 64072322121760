import React, { Component } from "react";

import { Button, DatePicker, Select, message, AutoComplete, Input } from "antd";
import Fetch from "../../utils/fetch";

const { Option } = Select;

class GdsOutboundSearch extends Component {
  state = {
    showDatePicker: false,
    hideDate: false,
    raisedBy: "all"
  };

  async componentDidMount() {
    try {
      const complains = await this.fetchComplainCategory();
      this.setState({ complains });
    } catch (err) {
      message.error(err);
    }
  }

  fetchComplainCategory = async () => {
    try {
      this.setState({ cancelLoader: true });
      const url = `/gds/outbound/callCategory`;

      const options = {
        method: "get"
      };

      const response = await Fetch(url, options);

      if (response) {
        this.setState({ cancelLoader: false });
        return response;
      } else {
        this.setState({ cancelLoader: false });
        return [];
      }
    } catch (err) {
      console.log(err);

      return [];
    }
  };

  fetchComplainOnStatus = key => {
    this.setState({ complainStatus: key });
  };

  fetchComplainOnRaisedBy = key => {
    this.setState({ raisedBy: key });
  };
  fetchComplainCustomTime = key => {
    const newCheck = key.target.value.replace(/[^\w\s]/gi, "");
    let value = parseInt(newCheck);
    if (value) {
      if (value > 12) {
        message.error("max time is 12 hrs");
      } else {
        if (
          value == 1 ||
          value == 2 ||
          value == 3 ||
          value == 4 ||
          value == 5 ||
          value == 6 ||
          value == 7 ||
          value == 8 ||
          value == 9 ||
          value == 10 ||
          value == 11 ||
          value == 12
        ) {
          this.setState({ time: parseInt(value) });
        }
      }
    }
  };

  fetchComplainOnTime = async key => {
    if (key != "") {
      this.setState({
        time: parseInt(key),
        hideDate: true,
        fromDate: null,
        toDate: null
      });
    }
    if (key == "custom") {
      this.setState({
        showDatePicker: true,
        hideDate: true,
        fromDate: null,
        toDate: null
      });
    }
    if (key == "today") {
      this.setState({
        showDatePicker: false,
        hideDate: true,
        fromDate: new Date().setHours(0, 0, 0, 0),
        toDate: new Date().setHours(23, 59, 59, 59)
      });
    }
    if (key == "yesterday") {
      this.setState({
        hideDate: true,
        showDatePicker: false,
        fromDate: new Date().setHours(0, 0, 0, 0) - 86400000,
        toDate: new Date().setHours(23, 59, 59, 59) - 86400000
      });
    }
  };

  showResult = async () => {
    const {
      raisedBy,
      complainStatus,
      fromDate,
      toDate,
      time,
      searchedComplain
    } = this.state;
    let body = {};
    this.props.showLoader();
    if (raisedBy) {
      body.raisedBy = raisedBy;
    }
    if (
      searchedComplain &&
      Array.isArray(searchedComplain) &&
      searchedComplain.length > 0
    ) {
      body._id = searchedComplain[0]._id;
      body.raisedBy = searchedComplain[0].type;
    }
    if (complainStatus) {
      body.complainStatus = complainStatus;
    }
    if (fromDate && toDate) {
      body.fromDate = fromDate.valueOf();
      body.toDate = toDate.valueOf();
    }
    if ((fromDate && !toDate) || (toDate && !fromDate)) {
      message.error("please select both date");
      return;
    }
    if (Number.isInteger(time)) {
      body.time = time;
    }
    const url = `/gds/outbound/searchCustomComplain`;
    const options = {
      method: "post",
      data: {
        body
      }
    };
    const response = await Fetch(url, options);
    if (response && Array.isArray) {
      this.props.onSearch(response);
    } else {
      this.props.onSearch(null);
    }
  };

  showResultPnrSearch = async () => {
    const { bookingSearchValue } = this.state;
    let body = {};
    this.props.showLoader();
    if (bookingSearchValue) {
      body.pnr = bookingSearchValue;
      body.raisedBy = "All";
    } else {
      message.error("Please enter PNR");
    }
    const url = `/gds/outbound/searchCustomComplain`;
    const options = {
      method: "post",
      data: {
        body
      }
    };
    const response = await Fetch(url, options);
    if (response && Array.isArray) {
      this.props.onSearch(response);
    } else {
      this.props.onSearch(null);
    }
  };

  setValue = value => {
    const { complains } = this.state;
    let id = complains.filter(complain => complain.name === value);
    this.setState({ searchedComplain: id });
  };

  handleSpecialCharacters = event => {
    const newCheck = event.target.value.replace(/[^\w\s]/gi, "");
    this.setState({ bookingSearchValue: newCheck });
  };

  render() {
    const {
      fromDate,
      toDate,
      showDatePicker,
      hideDate,
      bookingSearchValue,
      raisedBy,
      complains
    } = this.state;
    return (
      <>
        <div className="callTrigger">
          <div className="pnrSearchOutbound">
            <Input
              type="text"
              maxLength="20"
              placeholder="PNR Search"
              style={{ width: 270, margin: "0 0.2rem" }}
              value={bookingSearchValue}
              onChange={this.handleSpecialCharacters}
            />

            <Button onClick={this.showResultPnrSearch} type="primary">
              Search
            </Button>
          </div>
          <div className="complainSelector">
            <Select
              style={{ margin: "0 0.2rem" }}
              placeholder="Complain Status"
              onChange={this.fetchComplainOnStatus}
            >
              <Option value="REJECTED">REJECTED</Option>
              <Option value="CLOSED">CLOSED</Option>
              <Option value="INPROGRESS">INPROGRESS</Option>
              <Option value="CREATED">CREATED</Option>
            </Select>

            <AutoComplete
              style={{
                width: "100%"
              }}
              dataSource={
                complains && Array.isArray(complains)
                  ? complains.map(complain => complain.name)
                  : null
              }
              onChange={value => this.setValue(value)}
              filterOption={(inputValue, option) =>
                option.props.children
                  .toUpperCase()
                  .indexOf(inputValue.toUpperCase()) !== -1
              }
            />
          </div>
          <div style={{ margin: "0 1%" }}>
            <Select
              style={{ margin: "0 0.2rem", width: "100px" }}
              placeholder="Raised by"
              defaultValue={raisedBy}
              onChange={this.fetchComplainOnRaisedBy}
            >
              <Option value="manual">Manual</Option>
              <Option value="system">System</Option>
              <Option value="all">All</Option>
            </Select>
            <Select
              placeholder="Time Duration"
              onChange={this.fetchComplainOnTime}
            >
              <Option value="1">Last 60mins</Option>
              <Option value="4">Last 4hrs</Option>
              <Option value="today">Today</Option>
              <Option value="yesterday">Yesterday</Option>
              <Option value="custom">custom Select</Option>
            </Select>
          </div>
          {!hideDate ? (
            <div style={{ margin: "0 1%" }}>
              <DatePicker
                value={fromDate}
                onChange={fromDate => this.setState({ fromDate })}
              />
              {" to "}
              <DatePicker
                value={toDate}
                onChange={toDate => this.setState({ toDate })}
              />
            </div>
          ) : showDatePicker ? (
            <div style={{ margin: "0 1%" }}>
              <DatePicker
                showTime
                value={fromDate}
                onChange={fromDate => this.setState({ fromDate })}
              />
              {" to "}
              <DatePicker
                showTime
                value={toDate}
                onChange={toDate => this.setState({ toDate })}
              />
            </div>
          ) : null}

          <Button type="primary" onClick={this.showResult}>
            Search / Refresh
          </Button>
        </div>
        <hr />
      </>
    );
  }
}

export default GdsOutboundSearch;
