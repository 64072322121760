import React, { Component } from "react";
import { message, AutoComplete, Button } from "antd";
import Fetch from "../../utils/fetch";
import Loader from "../loader";
import swap from "../../images/swap.png";
import less from "./style.module.scss";

class assignCrew extends Component {
  state = {
    loading: true,
    selectedBusHost: "",
    selectedBusDrivers: "",
    selectedBusRc: "",
    selectedSecondaryBusHost: "",
    selectedSecondaryBusDrivers: "",
    selectedSecondaryBusRc: ""
  };

  async componentDidMount() {
    try {
      const { details } = this.props;
      const {
        bus,
        busDriver,
        busHost,
        secondaryBusDriver,
        secondaryBusHost,
        secondaryBusRc
      } = details;
      let stateObj = {};
      stateObj.selectedBusHost = busHost
        ? `${busHost.mobileNo}-${busHost.name}`
        : "";
      stateObj.selectedBusDrivers = busDriver
        ? `${busDriver.mobileNo}-${busDriver.name}`
        : "";
      stateObj.selectedBusRc = bus ? `${bus.rc}` : "";
      stateObj.selectedSecondaryBusHost = secondaryBusHost
        ? `${secondaryBusHost.mobileNo}-${secondaryBusHost.name}`
        : "";
      stateObj.selectedSecondaryBusDrivers = secondaryBusDriver
        ? `${secondaryBusDriver.mobileNo}-${secondaryBusDriver.name}`
        : "";
      stateObj.selectedSecondaryBusRc = secondaryBusRc
        ? `${secondaryBusRc.mobileNo}-${secondaryBusRc.name}`
        : "";

      stateObj.RCs = await this.getRc();
      stateObj.busHosts = await this.getBusHost();
      stateObj.busDrivers = await this.getBusDriver();
      stateObj.loading = false;
      this.setState(stateObj);
    } catch (err) {
      message.error(err);
    }
  }

  getRc = async () => {
    try {
      const url = `/bus-tracker/busNumber`;

      const options = {
        method: "get"
      };
      const response = await Fetch(url, options);
      if (response) {
        const filteredResponse = response.filter(obj => obj.status == "ACTIVE");
        return filteredResponse;
      } else {
        return [];
      }
    } catch (err) {
      console.log(err);

      return [];
    }
  };

  getBusHost = async () => {
    try {
      const url = `/bus-tracker/busHostName`;

      const options = {
        method: "get"
      };
      const response = await Fetch(url, options);
      if (response) {
        const data = response
          .filter(obj => obj.userStatus === "ACTIVE")
          .map(obj => {
            return {
              mobileNo: obj.mobileNo.toString(),
              _id: obj._id,
              name: obj.name
            };
          });

        return data;
      } else {
        return [];
      }
    } catch (err) {
      console.log(err);

      return [];
    }
  };

  getBusDriver = async () => {
    try {
      const url = `/bus-tracker/busDriverName`;

      const options = {
        method: "get"
      };
      const response = await Fetch(url, options);
      if (response) {
        const data = response
          .filter(obj => obj.userStatus === "ACTIVE")
          .map(obj => {
            return {
              mobileNo: obj.mobileNo.toString(),
              _id: obj._id,
              name: obj.name
            };
          });

        return data;
      } else {
        return [];
      }
    } catch (err) {
      console.log(err);

      return [];
    }
  };

  update = async () => {
    const {
      busHosts,
      RCs,
      busDrivers,
      selectedBusHost,
      selectedBusDrivers,
      selectedBusRc,
      selectedSecondaryBusHost,
      selectedSecondaryBusDrivers,
      selectedSecondaryBusRc
    } = this.state;

    const { tripId, close } = this.props;
    let selectedBusRcId,
      selectedBusHostId,
      selectedBusDriverId,
      selectedSecondaryBusHostId,
      selectedSecondaryBusDriversId,
      selectedSecondaryBusRcId;
    if (selectedBusRc) {
      let id = RCs.filter(obj => obj.rc === selectedBusRc);
      if (id) {
        selectedBusRcId = id[0]._id;
      } else {
        message.error("Incorrect RC selected");
        return;
      }
    }
    if (selectedBusHost) {
      let id = busHosts.filter(
        obj => `${obj.mobileNo}-${obj.name}` === selectedBusHost
      );
      if (id) {
        selectedBusHostId = id[0]._id;
      } else {
        message.error("Incorrect Bus Host selected");
        return;
      }
    }
    if (selectedBusDrivers) {
      let id = busDrivers.filter(
        obj => `${obj.mobileNo}-${obj.name}` === selectedBusDrivers
      );
      if (id) {
        selectedBusDriverId = id[0]._id;
      } else {
        message.error("Incorrect Driver selected");
        return;
      }
    }
    if (selectedSecondaryBusRc) {
      let id = this.state.RCs.filter(obj => obj.rc === selectedBusRc);
      if (id) {
        selectedSecondaryBusRcId = id[0]._id;
      } else {
        message.error("Incorrect RC selected");
        return;
      }
    }
    if (selectedSecondaryBusHost) {
      let id = this.state.busHosts.filter(
        obj => `${obj.mobileNo}-${obj.name}` === selectedBusHost
      );
      if (id) {
        selectedSecondaryBusHostId = id[0]._id;
      } else {
        message.error("Incorrect Bus Host selected");
        return;
      }
    }
    if (selectedSecondaryBusDrivers) {
      let id = this.state.busDrivers.filter(
        obj => `${obj.mobileNo}-${obj.name}` === selectedBusDrivers
      );
      if (id) {
        selectedSecondaryBusDriversId = id[0]._id;
      } else {
        message.error("Incorrect Driver selected");
        return;
      }
    }

    const url = `/bus-tracker/crewUpdate`;

    const options = {
      method: "post",
      data: {
        tripId,
        busId: selectedBusRcId,
        busHostId: selectedBusHostId,
        busDriverId: selectedBusDriverId,
        secondaryBusDriverId: selectedSecondaryBusDriversId,
        secondaryBusHostId: selectedSecondaryBusHostId,
        secondaryBusId: selectedSecondaryBusRcId
      }
    };

    const response = await Fetch(url, options);

    if (response) {
      message.success("Updated");
      close();
    } else {
      console.log(response);
    }
  };

  remove = async selected => {
    const { tripId, close } = this.props;

    const url = `/bus-tracker/crewRemove`;

    const options = {
      method: "post",
      data: {
        tripId,
        removeItem: selected
      }
    };

    const response = await Fetch(url, options);

    if (response) {
      message.success("Removed");
      close();
    } else {
      console.log(response);
    }
  };

  swapData = () => {
    this.setState(prevState => ({
      selectedSecondaryBusHost: prevState.selectedBusHost,
      selectedSecondaryBusDrivers: prevState.selectedBusDrivers,
      selectedSecondaryBusRc: prevState.selectedBusRc,
      selectedBusHost: prevState.selectedSecondaryBusHost,
      selectedBusDrivers: prevState.selectedSecondaryBusDrivers,
      selectedBusRc: prevState.selectedSecondaryBusRc
    }));
  };

  render() {
    const {
      loading,
      busHosts,
      RCs,
      busDrivers,
      selectedBusHost,
      selectedBusDrivers,
      selectedBusRc,
      selectedSecondaryBusHost,
      selectedSecondaryBusDrivers,
      selectedSecondaryBusRc
    } = this.state;
    if (loading) {
      return <Loader />;
    } else {
      return (
        <div className={less.container}>
          <div className={less.select}>
            <div>
              <span>Bus Host</span>
              <div>
                <AutoComplete
                  style={{
                    width: "100%"
                  }}
                  placeholder="select a host number"
                  dataSource={
                    busHosts
                      ? busHosts.map(obj => `${obj.mobileNo}-${obj.name}`)
                      : null
                  }
                  value={selectedBusHost}
                  onChange={value => this.setState({ selectedBusHost: value })}
                  filterOption={(inputValue, option) =>
                    option.props.children
                      .toUpperCase()
                      .indexOf(inputValue.toUpperCase()) !== -1
                  }
                />
                <Button onClick={() => this.remove("RbusHost")} type="danger">
                  Remove
                </Button>
              </div>
            </div>
            <div>
              <span>Bus Driver</span>
              <div>
                <AutoComplete
                  style={{
                    width: "100%"
                  }}
                  placeholder="select a bus driver number"
                  dataSource={
                    busDrivers
                      ? busDrivers.map(obj => `${obj.mobileNo}-${obj.name}`)
                      : null
                  }
                  value={selectedBusDrivers}
                  onChange={value =>
                    this.setState({ selectedBusDrivers: value })
                  }
                  filterOption={(inputValue, option) =>
                    option.props.children
                      .toUpperCase()
                      .indexOf(inputValue.toUpperCase()) !== -1
                  }
                />
                <Button onClick={() => this.remove("RbusDriver")} type="danger">
                  Remove
                </Button>
              </div>
            </div>
            <div>
              <span>Bus RC</span>
              <div>
                <AutoComplete
                  style={{
                    width: "100%"
                  }}
                  placeholder="select a RC"
                  dataSource={RCs ? RCs.map(obj => obj.rc) : null}
                  value={selectedBusRc}
                  onChange={value => this.setState({ selectedBusRc: value })}
                  filterOption={(inputValue, option) =>
                    option.props.children
                      .toUpperCase()
                      .indexOf(inputValue.toUpperCase()) !== -1
                  }
                />{" "}
                <Button onClick={() => this.remove("RbusRC")} type="danger">
                  Remove
                </Button>
              </div>
            </div>
          </div>
          <img src={swap} alt="SWAP" onClick={this.swapData} />
          <div className={less.select}>
            <div>
              <span>Secondary Bus Host</span>
              <div>
                <AutoComplete
                  style={{
                    width: "100%"
                  }}
                  placeholder="select a host number"
                  dataSource={
                    busHosts
                      ? busHosts.map(obj => `${obj.mobileNo}-${obj.name}`)
                      : null
                  }
                  value={selectedSecondaryBusHost}
                  onChange={value =>
                    this.setState({ selectedSecondaryBusHost: value })
                  }
                  filterOption={(inputValue, option) =>
                    option.props.children
                      .toUpperCase()
                      .indexOf(inputValue.toUpperCase()) !== -1
                  }
                />
                <Button onClick={() => this.remove("RSbusHost")} type="danger">
                  Remove
                </Button>
              </div>
            </div>
            <div>
              <span>Secondary Bus Driver</span>
              <div>
                <AutoComplete
                  style={{
                    width: "100%"
                  }}
                  placeholder="select a bus driver number"
                  dataSource={
                    busDrivers
                      ? busDrivers.map(obj => `${obj.mobileNo}-${obj.name}`)
                      : null
                  }
                  value={selectedSecondaryBusDrivers}
                  onChange={value =>
                    this.setState({ selectedSecondaryBusDrivers: value })
                  }
                  filterOption={(inputValue, option) =>
                    option.props.children
                      .toUpperCase()
                      .indexOf(inputValue.toUpperCase()) !== -1
                  }
                />
                <Button
                  onClick={() => this.remove("RSbusDriver")}
                  type="danger"
                >
                  Remove
                </Button>
              </div>
            </div>
            <div>
              <span>Secondary Bus RC</span>
              <div>
                <AutoComplete
                  style={{
                    width: "100%"
                  }}
                  placeholder="select a RC"
                  dataSource={RCs ? RCs.map(obj => obj.rc) : null}
                  value={selectedSecondaryBusRc}
                  onChange={value =>
                    this.setState({ selectedSecondaryBusRc: value })
                  }
                  filterOption={(inputValue, option) =>
                    option.props.children
                      .toUpperCase()
                      .indexOf(inputValue.toUpperCase()) !== -1
                  }
                />
                <Button onClick={() => this.remove("RSbusRC")} type="danger">
                  Remove
                </Button>
              </div>
            </div>
          </div>
          <div className={less.buttons}>
            <Button type="primary" onClick={this.update}>
              Save
            </Button>
            <Button onClick={this.props.close}>Cancel</Button>
          </div>
        </div>
      );
    }
  }
}

export default assignCrew;
