/* eslint-disable no-undef */
import React, { Component } from "react";
import io from "socket.io-client";
import cx from "classnames";
import moment from "moment";
import mapStyle from "../../../config/mapStyle";
import { socketURL } from "../../../config";
import styles from "./map.module.scss";
import CurrentLocation from "../../../images/current-location.svg";
import fullscreen from "../../../images/fullscreen.svg";

function initFullscreenControl(map) {
  const elementToSendFullscreen = map.getDiv().firstChild;
  const fullscreenControl = document.querySelector(".fullscreen-control");

  map.controls[google.maps.ControlPosition.LEFT_BOTTOM].push(fullscreenControl);
  fullscreenControl.onclick = function() {
    if (isFullscreen(elementToSendFullscreen)) {
      exitFullscreen();
    } else {
      requestFullscreen(elementToSendFullscreen);
    }
  };

  document.onwebkitfullscreenchange = document.onmsfullscreenchange = document.onmozfullscreenchange = document.onfullscreenchange = function() {
    if (isFullscreen(elementToSendFullscreen)) {
      fullscreenControl.classList.add("is-fullscreen");
    } else {
      fullscreenControl.classList.remove("is-fullscreen");
    }
  };
}

function isFullscreen(element) {
  return (
    (document.fullscreenElement ||
      document.webkitFullscreenElement ||
      document.mozFullScreenElement ||
      document.msFullscreenElement) == element
  );
}

function requestFullscreen(element) {
  if (element.requestFullscreen) {
    element.requestFullscreen();
  } else if (element.webkitRequestFullScreen) {
    element.webkitRequestFullScreen();
  } else if (element.mozRequestFullScreen) {
    element.mozRequestFullScreen();
  } else if (element.msRequestFullScreen) {
    element.msRequestFullScreen();
  }
}

function exitFullscreen() {
  if (document.exitFullscreen) {
    document.exitFullscreen();
  } else if (document.webkitExitFullscreen) {
    document.webkitExitFullscreen();
  } else if (document.mozCancelFullScreen) {
    document.mozCancelFullScreen();
  } else if (document.msExitFullscreen) {
    document.msExitFullscreen();
  }
}

class CustomerTrackMap extends Component {
  state = {
    loading: false,
    tripId: this.props.tripId,
    path: [],
    fullScreen: false
  };
  directionsRenderer = [];
  step = 0;

  async componentDidMount() {
    try {
      const socket = io(socketURL, {
        withCredentials: true
      });
      socket.on("BusLatLng", this.handleBusPosition);
      this.initMap();
    } catch (err) {
      console.log(err);
    }
  }

  componentWillUnmount() {
    this.busMarkerEventListener.remove();
  }

  initMap = async () => {
    try {
      if (typeof google !== "undefined") {
        const { busPosition } = this.props;

        this.map = new google.maps.Map(document.getElementById("map1"), {
          zoom: 12,
          disableDefaultUI: true,
          gestureHandling: "greedy"
        });

        const styledMapType = new google.maps.StyledMapType(mapStyle);
        this.map.mapTypes.set("styled_map", styledMapType);
        this.map.setMapTypeId("styled_map");

        const myoverlay = new google.maps.OverlayView();
        myoverlay.draw = function() {
          this.getPanes().markerLayer.id = "busMarkerLayer";
        };
        myoverlay.setMap(this.map);

        initFullscreenControl(this.map);

        if (busPosition) {
          // Adding Bus Marker
          this.createBusMarker(busPosition);
          this.setBounds();
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  setBounds = () => {
    const bounds = new google.maps.LatLngBounds();

    const latPosition = this.busMarker.getPosition().lat();
    const lngPosition = this.busMarker.getPosition().lng();

    const busMarkerPosition = { lat: latPosition, lng: lngPosition };

    bounds.extend(busMarkerPosition);

    this.map.fitBounds(bounds);
  };

  createBusMarker = busPosition => {
    const lastLocationTime = moment().format("LLL");

    const icon = {
      scale: 0.7,
      strokeColor: "white",
      strokeWeight: 0.1,
      // scaledSize: new google.maps.Size(21.44, 23.46),
      fillOpacity: 1,
      fillColor: "#7b2aff",
      anchor: new google.maps.Point(7, 15),
      url: "https://i.imgur.com/9qhx16U.png"
    };

    const busMarker = new google.maps.Marker({
      position: busPosition,
      map: this.map,
      icon,
      animation: google.maps.Animation.DROP,
      title: `${lastLocationTime}`,
      optimized: false,
      zIndex: 100
    });

    const infoWindow = new google.maps.InfoWindow({
      content: `<p><b>Updated at: ${lastLocationTime}</b></p>`
    });

    this.busMarkerEventListener = busMarker.addListener("click", () => {
      infoWindow.open(this.map, busMarker);
    });

    this.busMarker = busMarker;
    this.icon = icon;

    return;
  };

  handleBusPosition = async data => {
    try {
      const { tripId, updateLastLocation } = this.props;

      const { updateBounds = false } = this.state;
      if (data && data.tripId === tripId) {
        this.breakDown = 0;
        this.step = 0;
        const busMarker = this.busMarker;
        if (busMarker.getPosition()) {
          const latPosition = busMarker.getPosition().lat();
          const lngPosition = busMarker.getPosition().lng();
          const startPoint = {
            coordinates: { lat: latPosition, lng: lngPosition }
          };

          const route = await this.getDirections(startPoint, data, null);

          this.path = route;
          if (this.path.length < 100) {
            console.log("Move marker");
            requestAnimationFrame(this.moveMarker);
          } else {
            console.log("Jump marker");
            const { lat, lng } = data.coordinates;
            this.jumpMarker(lat, lng);
          }

          if (updateBounds) {
            this.setBounds();
            this.setState({ updateBounds: false });
          }

          if (updateLastLocation) {
            const lastLocationData = {
              coordinates: data.coordinates,
              lat: data.coordinates.lat,
              lng: data.coordinates.lng,
              latlngDate: data.latlngDate,
              readableTime: moment(data.latlngDate).format("hh:mm A")
            };
            updateLastLocation(lastLocationData);
          }
        } else {
          console.log("BusPosition Not found");
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  moveMarker = () => {
    if (this.step < this.path.length) {
      const busMarker = this.busMarker;
      const map = this.map;
      const icon = this.icon;

      const latPosition = busMarker.getPosition().lat();
      const lngPosition = busMarker.getPosition().lng();

      const nextPosition = this.path[this.step];
      const newLatPosition = nextPosition.lat;
      const newLngPosition = nextPosition.lng;

      const oldPosition = new google.maps.LatLng(latPosition, lngPosition);
      const newPosition = new google.maps.LatLng(
        newLatPosition,
        newLngPosition
      );

      const angle = google.maps.geometry.spherical.computeHeading(
        oldPosition,
        newPosition
      );

      busMarker.setPosition(newPosition);

      if (this.props.isOnboarded) {
        map.panTo(newPosition);
      }

      icon.rotation = angle;
      busMarker.setIcon(icon);

      this.step += 1;
      requestAnimationFrame(this.moveMarker);
    }
  };

  jumpMarker = (latPosition, lngPosition) => {
    const busMarker = this.busMarker;
    const map = this.map;

    const newPosition = new google.maps.LatLng(latPosition, lngPosition);
    busMarker.setPosition(newPosition);

    if (this.props.isOnboarded) {
      map.panTo(newPosition);
    }
  };

  moveToCurrentLocation = () => {
    // If customer is boarded take him to bus location
    // else take him to pickup point

    let panPoint;

    const lat = this.busMarker.getPosition().lat();
    const lng = this.busMarker.getPosition().lng();
    panPoint = { lat, lng };

    panPoint = new google.maps.LatLng(panPoint.lat, panPoint.lng);
    this.map.panTo(panPoint);
  };

  render() {
    const {
      width = "100%",
      height = "600px",
      showFullScreenButton,
      showPinButton
    } = this.props;
    return (
      <div className={styles.mapContainer}>
        <div style={{ width, height }} id="map1" />

        <div
          className={cx(
            "controls fullscreen-control",
            !showFullScreenButton ? styles.hideFullScreenButton : ""
          )}
        >
          <button
            title="Toggle Fullscreen"
            style={{
              border: "none",
              background: "white",
              margin: "0 0 -10% 20%",
              boxShadow: "2px 1px 6px #00000080",
              width: "45px",
              borderRadius: "50%",
              height: " 45px"
            }}
          >
            <div className="fullscreen-control-icon fullscreen-control-left-bottom">
              <img src={fullscreen} />
            </div>
          </button>
        </div>

        {showPinButton ? (
          <button
            onClick={this.moveToCurrentLocation}
            className={cx(styles.mapButton, styles.currentLocation)}
          >
            <img src={CurrentLocation} />
          </button>
        ) : null}
      </div>
    );
  }
}

export default CustomerTrackMap;
