import React, { Component } from "react";
import moment from "moment";
import { TimePicker, Button, Select, DatePicker, Modal, message } from "antd";
import Fetch from "../../utils/fetch";
// import io from "socket.io-client";
import jwt from "jsonwebtoken";
// import { socketURL } from "../../config";
// import ReactPlayer from "react-player";
const format = "HH:mm";
const { Option } = Select;

class VideoStream extends Component {
  state = {
    fetchVideo: {},
    videos: [],
    loading: true,
    preparingVideo: false,
    recording: false,
    mediaRecorder: null,
    recordedChunks: [],
    date: moment(),
    totalVideos: 0,
    currentVideo: 0,
    cameraSelected: null
  };

  async componentDidMount() {
    try {
      let disabled = false;
      const token = localStorage.getItem("idToken");
      const decodedToken = jwt.decode(token);
      const { tripId, serviceName, busRc, cameraCode, date } = this.props;
      if (date) {
        disabled = true;
      }
      // socket.on("trackingVideo", this.updateVideo);

      this.setState({
        cameraCode,
        tripId,
        serviceName,
        busRc,
        userId: decodedToken.id,
        videoSource: new Array(),
        date: moment(date),
        disabled,
        startTime: moment(date).subtract(5, "minutes"),
        endTime: moment(date).add(5, "minutes"),
        loading: false
      });
    } catch (err) {
      console.log(err);
      message.error(err);
    }
  }

  fetchTripVideo = async () => {
    try {
      this.setState({ preparingVideo: true });
      const { startTime, endTime, tripId, date, cameraId } = this.state;
      const searchedDate = moment(date).format("YYYY-MM-DD");
      const from = searchedDate + " " + moment(startTime).format("HH:mm:ss");
      const to = searchedDate + " " + moment(endTime).format("HH:mm:ss");
      // const convertedFrom = moment(from).valueOf();
      // const convertedto = moment(to).valueOf();
      // if (Math.abs(convertedFrom - convertedto) > 0.5 * 60 * 60 * 1000) {
      //   message.error("Please select time range of 30mins");
      //   this.setState({ preparingVideo: false });
      //   return;
      // }

      if (from && to && searchedDate && cameraId) {
        const url = `/bus-tracker/fetchVideo?tripId=${tripId}&from=${from}&to=${to}&date=${searchedDate}&cameraId=${cameraId}`;
        const options = {
          method: "get"
        };
        const response = await Fetch(url, options);
        if (response && Array.isArray(response) && response.length > 0) {
          this.setState({
            videoSource: response,
            totalVideos: response.length,
            preparingVideo: false
          });
          this.updateAllVideo(response);
          message.success("video is available");
        } else {
          this.setState({ videoSource: [], preparingVideo: false });
          message.error("no video found");
        }
      } else {
        message.error("All fields are mandatory");
        this.setState({ preparingVideo: false });
        return;
      }
    } catch (err) {
      this.setState({ preparingVideo: false });
    }
  };

  updateAllVideo = async data => {
    const canvas = document.getElementById("myCanvas");
    const ctx = canvas.getContext("2d");
    const video = document.getElementById("video");
    video.addEventListener("load", () => {
      function step() {
        ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
        requestAnimationFrame(step);
      }
      requestAnimationFrame(step);
    });
    this.setState({ src: data[0], videoSource: data, currentVideo: 0 });
  };

  startRecording = () => {
    let { mediaRecorder, recordedChunks } = this.state;

    const canvas = document.getElementById("myCanvas");
    const stream = canvas.captureStream();
    mediaRecorder = new MediaRecorder(stream, {
      mimeType: "video/webm"
    });
    mediaRecorder.start();

    // Get the blob data when is available

    mediaRecorder.ondataavailable = function(e) {
      recordedChunks.push(e.data);
    };
    this.setState({ mediaRecorder, recordedChunks, recording: true });
  };

  stopRecording = () => {
    const { mediaRecorder, recordedChunks } = this.state;
    mediaRecorder.stop();

    mediaRecorder.onstop = () => {
      const fullBlob = new Blob(recordedChunks, { type: "video/webm" });
      const url = window.URL.createObjectURL(fullBlob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "recording.webm";
      a.click();
      URL.revokeObjectURL(url);
      console.log({ fullBlob });
      console.log({ url });
    };
    this.setState({ recording: false });
  };

  playPreVideo = () => {
    let { currentVideo, videoSource } = this.state;
    let videoCount = videoSource.length;
    currentVideo--;
    let src = videoSource[0];
    if (currentVideo == videoCount || currentVideo < 0) {
      currentVideo = 0;
      src = videoSource[currentVideo];
    } else {
      src = videoSource[currentVideo];
    }
    this.setState({ src, currentVideo });
  };

  playNextVideo = () => {
    let { currentVideo, videoSource } = this.state;
    let videoCount = videoSource.length;
    currentVideo++;
    let src = videoSource[0];
    if (currentVideo == videoCount) {
      currentVideo = 0;
      src = videoSource[currentVideo];
    } else {
      src = videoSource[currentVideo];
    }
    this.setState({ src, currentVideo });
  };

  replayVideo = () => {
    let { src } = this.state;
    src = src + "&rand=" + new Date().getTime();
    this.setState({ src });
  };

  onChange = e => {
    let { currentVideo, videoSource } = this.state;
    e--;
    currentVideo = e;
    let src = videoSource[e];
    src = videoSource[currentVideo];
    this.setState({ src, currentVideo });
  };

  timePickerStart = e => {
    const { endTime } = this.state;
    if (e) {
      const from = moment(e);
      const convertedFrom = moment(from).valueOf();
      const convertedto = moment(endTime).valueOf();
      if (Math.abs(convertedFrom - convertedto) > 0.5 * 60 * 60 * 1000) {
        const to = moment(from).add(30, "m");
        this.setState({ endTime: to, startTime: from });
      }
      this.setState({ startTime: from });
    }
  };

  timePickerEnd = e => {
    const { startTime } = this.state;
    if (e) {
      const from = moment(e);
      const convertedFrom = moment(from).valueOf();
      const convertedto = moment(startTime).valueOf();
      if (Math.abs(convertedFrom - convertedto) > 0.5 * 60 * 60 * 1000) {
        const to = moment(from).subtract(30, "m");
        this.setState({ endTime: from, startTime: to });
      }
      this.setState({ endTime: from });
    }
  };

  selectedCamera = e => {
    const { cameraCode } = this.props;
    if (!cameraCode) {
      message.error("No cameras available for this bus");
      return;
    }
    if (e == "dashCamCode") {
      if (cameraCode.dashCamCode) {
        this.setState({
          cameraSelected: "dashCamCode",
          cameraId: cameraCode.dashCamCode
        });
      } else {
        message.error("Selected Camera is not available");
        return;
      }
    }
    if (e == "lobby") {
      if (cameraCode.galleryCamCode) {
        this.setState({
          cameraSelected: "lobby",
          cameraId: cameraCode.galleryCamCode
        });
      } else {
        message.error("Selected Camera is not available");
        return;
      }
    }
    if (e == "back") {
      if (cameraCode.cabinCamCode) {
        this.setState({
          cameraSelected: "back",
          cameraId: cameraCode.cabinCamCode
        });
      } else {
        message.error("Selected Camera is not available");
        return;
      }
    }

    // this.setState({ cameraSelected: cameraCode });
  };

  render() {
    const {
      tripId,
      loading,
      preparingVideo,
      date,
      videoSource,
      serviceName,
      busRc,
      disabled,
      cameraSelected,
      startTime,
      endTime,
      src,
      cameraCode,
      recording
    } = this.state;
    return (
      <Modal
        width={"50%"}
        title="Show Video"
        visible={true}
        onCancel={this.props.onCancel}
        footer={null}
      >
        {loading ? (
          <h1>loading ...</h1>
        ) : tripId ? (
          <div>
            {!cameraCode ? (
              <div
                style={{
                  fontWeight: "bold",
                  marginBottom: "15px",
                  color: "white",
                  background: "red",
                  textAlign: "center",
                  fontSize: "large"
                }}
              >
                No camera available
              </div>
            ) : null}
            <div
              className="row"
              style={{
                alignItems: "center"
              }}
            >
              <div className="col-3" style={{ fontSize: "large" }}>
                Service Name:
              </div>
              <div className="col-6 text-left" style={{ fontSize: "large" }}>
                {serviceName}{" "}
              </div>
            </div>
            <div
              className="row"
              style={{
                marginTop: "5px",
                alignItems: "center"
              }}
            >
              <div className="col-3" style={{ fontSize: "large" }}>
                Bus Number:
              </div>
              <div className="col-6 text-left" style={{ fontSize: "large" }}>
                {busRc}{" "}
              </div>
            </div>
            <div
              className="row"
              style={{
                marginTop: "5px",
                alignItems: "center"
              }}
            >
              <div className="col-3" style={{ fontSize: "large" }}>
                Select Camera:
              </div>
              <div className="col-6 text-left" style={{ fontSize: "large" }}>
                <Select
                  value={cameraSelected}
                  style={{ width: 200 }}
                  onChange={value => this.selectedCamera(value)}
                >
                  {cameraCode && cameraCode.dashCamCode ? (
                    <Option value="dashCamCode">Front</Option>
                  ) : null}
                  {cameraCode && cameraCode.galleryCamCode ? (
                    <Option value="lobby">Lobby</Option>
                  ) : null}{" "}
                  {cameraCode && cameraCode.cabinCamCode ? (
                    <Option value="back">Back</Option>
                  ) : null}
                </Select>
              </div>
            </div>
            <div
              className="row"
              style={{
                marginTop: "5px",
                alignItems: "center"
              }}
            >
              <div className="col-3" style={{ fontSize: "large" }}>
                Select Date:
              </div>
              <div className="col-6 text-left" style={{ fontSize: "large" }}>
                <DatePicker
                  disabled={disabled}
                  style={{ width: 200 }}
                  value={date}
                  onChange={date => this.setState({ date })}
                />
              </div>
            </div>
            <div
              className="row"
              style={{
                marginTop: "5px",
                alignItems: "center"
              }}
            >
              <div className="col-3" style={{ fontSize: "large" }}>
                Select Time
              </div>
              <div className="col-6 text-left" style={{ fontSize: "large" }}>
                <TimePicker
                  format={format}
                  value={startTime}
                  onChange={e => this.timePickerStart(e)}
                />
                {" to "}
                <TimePicker
                  format={format}
                  value={endTime}
                  onChange={e => this.timePickerEnd(e)}
                />
              </div>
            </div>
            <div style={{ color: "red" }}>*max time selected is 30mis</div>
            {cameraCode ? (
              <div
                className="row"
                style={{
                  marginTop: "5px",
                  alignItems: "center"
                }}
              >
                <div className="col-12 text-right">
                  <Button onClick={this.fetchTripVideo}>
                    {" "}
                    View Camera Feed{" "}
                  </Button>
                </div>
              </div>
            ) : null}

            <br />
            {/* {videoSource && videoSource.length > 0 ? (
              <>
                {" "}
                <p>Total Videos:{totalVideos}</p>
                <p>Current Video:{currentVideo + 1}</p>
              </>
            ) : null} */}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center"
              }}
            >
              {videoSource && videoSource.length > 0 ? (
                <div style={{ display: "flex" }}>
                  {/* <button
                    style={{
                      backgroundColor: "transparent",
                      border: "transparent",
                      fontSize: "2em"
                    }}
                    onClick={this.playPreVideo}
                  >
                    &#8249;
                  </button> */}
                  <img
                    style={{ display: "none" }}
                    id="video"
                    crossOrigin="anonymous"
                    src={src}
                  ></img>
                  <canvas id="myCanvas" width="650" height="300"></canvas>
                  {/* <button
                    style={{
                      backgroundColor: "transparent",
                      border: "transparent",
                      fontSize: "2em"
                    }}
                    onClick={this.playNextVideo}
                  >
                    &#8250;
                  </button> */}
                </div>
              ) : null}{" "}
              {videoSource && videoSource.length > 0 ? (
                <>
                  <button
                    style={{
                      border: "transparent"
                    }}
                    onClick={this.replayVideo}
                  >
                    Replay
                  </button>
                  <br />
                  <button
                    className="btn btn-danger"
                    onClick={
                      recording ? this.stopRecording : this.startRecording
                    }
                  >
                    {recording ? "Stop" : "Record"}
                  </button>
                </>
              ) : null}
            </div>
          </div>
        ) : (
          "No Details found"
        )}
        {preparingVideo ? <p>preparing Video ...</p> : null}
      </Modal>
    );
  }
}

export default VideoStream;
