import React, { Component } from "react";
import {
  Button,
  Input,
  DatePicker,
  Select,
  Modal,
  message,
  AutoComplete
} from "antd";
import moment from "moment";

import Fetch from "../../utils/fetch";

const { TextArea } = Input;
const { Option } = Select;

class InitiateTaskGroup extends Component {
  state = {
    showInitiateTask: false,
    Comment: "",
    etaDate: moment().add(10, "days"),
    date: moment(),
    tripdate: moment(),
    groupObj: {},
    serviceNames: [],
    busNumberSearch: [],
    stationName: [],
    tripServiceNames: [],
    agentName: [],
    busDriverName: [],
    busHost: [],
    loader: false
  };

  handleChange = (id, key, value) => {
    const { groupObj } = this.state;
    const deepCopyFunction = inObject => {
      let outObject, value, key;

      if (typeof inObject !== "object" || inObject === null) {
        return inObject; // Return the value if inObject is not an object
      }
      outObject = Array.isArray(inObject) ? [] : {};

      for (key in inObject) {
        value = inObject[key];

        outObject[key] = deepCopyFunction(value);
      }

      return outObject;
    };

    let newGroupObj = deepCopyFunction(groupObj);
    if ((key == "etaDate" || key == "date") && value) {
      value = value.toString();
    }
    if (!newGroupObj[id]) {
      newGroupObj[id] = { [key]: value };
    } else {
      newGroupObj[id] = { ...newGroupObj[id], [key]: value };
    }
    newGroupObj[id] = { ...newGroupObj[id], taskMasterId: id };
    this.setState({
      groupObj: newGroupObj
    });
  };

  getServiceName = async () => {
    try {
      const url = `/bus-tracker/serviceNameEs`;

      const options = {
        method: "get"
      };
      const response = await Fetch(url, options);
      if (response) {
        return response;
      } else {
        return [];
      }
    } catch (err) {
      console.log(err);

      return [];
    }
  };

  busNumber = async () => {
    try {
      const url = `/bus-tracker/busNumber`;

      const options = {
        method: "get"
      };
      const response = await Fetch(url, options);

      if (response) {
        return response;
      } else {
        return [];
      }
    } catch (err) {
      console.log(err);

      return [];
    }
  };

  getStationName = async () => {
    try {
      const url = `/bus-tracker/stationName`;

      const options = {
        method: "get"
      };
      const response = await Fetch(url, options);

      if (response) {
        return response;
      } else {
        return [];
      }
    } catch (err) {
      console.log(err);

      return [];
    }
  };

  getBusHost = async () => {
    try {
      const url = `/bus-tracker/busHostName`;

      const options = {
        method: "get"
      };
      const response = await Fetch(url, options);

      if (response) {
        return response;
      } else {
        return [];
      }
    } catch (err) {
      console.log(err);

      return [];
    }
  };

  getAgentName = async () => {
    try {
      const url = `/bus-tracker/agentName`;

      const options = {
        method: "get"
      };
      const response = await Fetch(url, options);

      if (response) {
        return response;
      } else {
        return [];
      }
    } catch (err) {
      console.log(err);

      return [];
    }
  };

  getBusDriverName = async () => {
    try {
      const url = `/bus-tracker/busDriverName`;

      const options = {
        method: "get"
      };
      const response = await Fetch(url, options);
      if (response) {
        return response;
      } else {
        return [];
      }
    } catch (err) {
      console.log(err);

      return [];
    }
  };

  getTrips = async date => {
    try {
      const url = `/bus-tracker/trips?date=${moment(date).format(
        "YYYY-MM-DD"
      )}`;

      const options = {
        method: "get"
      };
      const response = await Fetch(url, options);
      if (response) {
        return response;
      } else {
        return [];
      }
    } catch (err) {
      console.log(err);

      return [];
    }
  };

  getBookingId = async searchpnr => {
    try {
      const url = `/bus-tracker/booking?pnr=${searchpnr}`;
      const options = {
        method: "get"
      };
      const response = await Fetch(url, options);
      if (response) {
        return response[0]._id;
      } else {
        message.error("Invalid PNR");
        return [];
      }
    } catch (err) {
      console.log(err);
      return [];
    }
  };

  handleSpecialCharacters = async event => {
    const newCheck = event.target.value.replace(/[^\w\s]/gi, "");
    if (newCheck.length == 13) {
      let bookingId = await this.getBookingId(newCheck);
      this.setState({ bookingId: bookingId });
    }
  };

  getTaskMasterKeys = async id => {
    try {
      const { taskGroup, tripdate } = this.state;

      let taskMasterKeys = taskGroup.filter(task => task._id == id)[0]
        .taskMaster;

      let taskMasterKeysMandateFields = await this.getMandatoryFields(
        taskMasterKeys
      );
      taskMasterKeysMandateFields.forEach(obj => {
        obj.mandatoryField.forEach(async entities => {
          if (entities == "serviceId") {
            const serviceNames = await this.getServiceName();
            this.setState({ serviceNames });
          } else if (entities == "busId") {
            const busNumberSearch = await this.busNumber();
            this.setState({ busNumberSearch });
          } else if (entities == "stationId") {
            const stationName = await this.getStationName();
            this.setState({ stationName });
          } else if (entities == "agentId") {
            let agentName = await this.getAgentName();
            agentName = agentName.filter(agent => agent.user.name);
            this.setState({ agentName });
          } else if (entities == "busDriverId") {
            let busDriverName = await this.getBusDriverName();
            busDriverName = busDriverName.filter(bus => bus.name);
            this.setState({ busDriverName });
          } else if (entities == "busHostId") {
            let busHost = await this.getBusHost();
            busHost = busHost.filter(
              (ele, ind) =>
                ind === busHost.findIndex(elem => elem.name === ele.name)
            );
            this.setState({ busHost });
          } else if (entities == "tripId") {
            const tripServiceNames = await this.getTrips(tripdate);
            this.setState({ tripServiceNames });
          }
        });
      });

      this.setState({
        taskMasterKeysMandate: taskMasterKeysMandateFields,
        taskMasterId: id
      });
    } catch (err) {
      console.log(err);

      return [];
    }
  };

  toggleMessageOption = () => {
    const { taskGroup } = this.props;

    if (!taskGroup) {
      return message.error("Please wait");
    }

    this.setState({
      loading: false,
      taskGroup: taskGroup,
      showInitiateTask: true
    });
  };

  getMandatoryFields = async id => {
    try {
      this.setState({ taskLoader: true });
      const url = `/bus-tracker/createTask/${id}`;

      const options = {
        method: "get"
      };

      const response = await Fetch(url, options);

      if (response) {
        this.setState({ taskLoader: false });
        return response.data;
      } else {
        return [];
      }
    } catch (err) {
      console.log(err);

      return [];
    }
  };

  onDateChange = async tripdate => {
    const tripServiceNames = await this.getTrips(tripdate);
    this.setState({ tripServiceNames, tripdate, tripId: "" });
  };

  handleSendClick = () => {
    const { onClick } = this.props;

    const { taskMasterKeysMandate, groupObj, taskMasterId } = this.state;
    let tempobj = [];

    taskMasterKeysMandate.forEach(task => {
      tempobj.push(groupObj[task._id]);
    });

    onClick(tempobj, taskMasterId);

    this.setState({
      showInitiateTask: false,
      taskMasterKeysMandate: [],
      taskMasterId: ""
    });
  };

  render() {
    const {
      showInitiateTask,
      taskGroup,
      taskMasterKeysMandate,
      tripdate,
      loader,
      serviceNames,
      tripServiceNames,
      busNumberSearch,
      stationName,
      agentName,
      busDriverName,
      busHost
    } = this.state;

    return (
      <div>
        {showInitiateTask ? (
          <Modal
            centered
            width={1500}
            title="Create Task"
            footer={null}
            visible={true}
            onCancel={() =>
              this.setState({
                showInitiateTask: false,
                taskMasterKeysMandate: [],
                taskMasterId: ""
              })
            }
          >
            <div className="row">
              <div className="col-3">Task Group</div>
              <div className="col-6 text-left">
                <Select
                  className="mb-1"
                  style={{ width: "200px" }}
                  defaultValue=" "
                  onChange={key => this.getTaskMasterKeys(key)}
                >
                  {taskGroup.map(task => (
                    <Option value={task._id} key={task._id}>
                      {task.name}
                    </Option>
                  ))}
                </Select>
              </div>
            </div>
            {Array.isArray(taskMasterKeysMandate) &&
            taskMasterKeysMandate.length > 0
              ? taskMasterKeysMandate.map(fields => (
                  <div key={fields._id}>
                    <div>{fields.name}</div>
                    {fields.mandatoryField.map((mandatefield, index) => (
                      <div className="row" key={index}>
                        <div className="col-3">
                          {mandatefield == "serviceId"
                            ? "Service Name"
                            : mandatefield == "busId"
                            ? "Bus Number"
                            : mandatefield == "agentId"
                            ? "Agent Name"
                            : mandatefield == "tripId"
                            ? "Trip Name and Trip Date"
                            : mandatefield == "bookingId"
                            ? "PNR"
                            : mandatefield == "busDriverId"
                            ? "Bus Driver Name"
                            : mandatefield == "stationId"
                            ? "Station Name"
                            : mandatefield == "busHostId"
                            ? "Bus Host Name"
                            : mandatefield == "date"
                            ? "Date"
                            : mandatefield}{" "}
                        </div>
                        <div className="col-6 text-left">
                          {" "}
                          {mandatefield == "serviceId" ? (
                            <AutoComplete
                              style={{
                                width: "500px"
                              }}
                              dataSource={serviceNames.map(
                                service => service.name
                              )}
                              onChange={value => {
                                let id = serviceNames.filter(
                                  service => service.name === value
                                );
                                if (id.length) {
                                  this.handleChange(
                                    fields._id,
                                    mandatefield,
                                    id[0]._id
                                  );
                                } else {
                                  this.handleChange(
                                    fields._id,
                                    mandatefield,
                                    null
                                  );
                                }
                              }}
                              filterOption={(inputValue, option) =>
                                option.props.children
                                  .toUpperCase()
                                  .indexOf(inputValue.toUpperCase()) !== -1
                              }
                            />
                          ) : mandatefield == "busId" ? (
                            <AutoComplete
                              style={{
                                width: "500px"
                              }}
                              onChange={value => {
                                let id = busNumberSearch.filter(
                                  bus => bus.rc === value
                                );
                                if (id.length) {
                                  this.handleChange(
                                    fields._id,
                                    mandatefield,
                                    id[0]._id
                                  );
                                } else {
                                  this.handleChange(
                                    fields._id,
                                    mandatefield,
                                    null
                                  );
                                }
                              }}
                              dataSource={busNumberSearch.map(bus => bus.rc)}
                              filterOption={(inputValue, option) =>
                                option.props.children
                                  .toUpperCase()
                                  .indexOf(inputValue.toUpperCase()) !== -1
                              }
                            />
                          ) : mandatefield == "busDriverId" ? (
                            <AutoComplete
                              style={{
                                width: "500px"
                              }}
                              onChange={value => {
                                let id = busDriverName.filter(
                                  bus => bus.name === value
                                );
                                if (id.length) {
                                  this.handleChange(
                                    fields._id,
                                    mandatefield,
                                    id[0]._id
                                  );
                                } else {
                                  this.handleChange(
                                    fields._id,
                                    mandatefield,
                                    null
                                  );
                                }
                              }}
                              dataSource={busDriverName.map(bus => bus.name)}
                              filterOption={(inputValue, option) =>
                                option.props.children
                                  .toUpperCase()
                                  .indexOf(inputValue.toUpperCase()) !== -1
                              }
                            />
                          ) : mandatefield == "busHostId" ? (
                            <AutoComplete
                              style={{
                                width: "500px"
                              }}
                              onChange={value => {
                                let id = busHost.filter(
                                  bus => bus.name === value
                                );
                                if (id.length) {
                                  this.handleChange(
                                    fields._id,
                                    mandatefield,
                                    id[0]._id
                                  );
                                } else {
                                  this.handleChange(
                                    fields._id,
                                    mandatefield,
                                    null
                                  );
                                }
                              }}
                              dataSource={busHost.map(bus => bus.name)}
                              filterOption={(inputValue, option) =>
                                option.props.children
                                  .toUpperCase()
                                  .indexOf(inputValue.toUpperCase()) !== -1
                              }
                            />
                          ) : mandatefield == "agentId" ? (
                            <AutoComplete
                              style={{
                                width: "500px"
                              }}
                              onChange={value => {
                                let id = agentName.filter(
                                  agent => agent.user.name === value
                                );
                                if (id.length) {
                                  this.handleChange(
                                    fields._id,
                                    mandatefield,
                                    id[0]._id
                                  );
                                } else {
                                  this.handleChange(
                                    fields._id,
                                    mandatefield,
                                    null
                                  );
                                }
                              }}
                              dataSource={agentName.map(
                                agent => agent.user.name
                              )}
                              filterOption={(inputValue, option) =>
                                option.props.children
                                  .toUpperCase()
                                  .indexOf(inputValue.toUpperCase()) !== -1
                              }
                            />
                          ) : mandatefield == "tripId" ? (
                            <div>
                              <DatePicker
                                style={{ width: 150 }}
                                value={tripdate}
                                onChange={this.onDateChange}
                              />{" "}
                              {Array.isArray(tripServiceNames) &&
                              tripServiceNames.length > 0 ? (
                                <AutoComplete
                                  loading={loader}
                                  style={{
                                    width: "500px"
                                  }}
                                  dataSource={tripServiceNames.map(
                                    tripServiceNames =>
                                      tripServiceNames.service.name
                                  )}
                                  onChange={value => {
                                    let id = tripServiceNames.filter(
                                      service => service.service.name === value
                                    );
                                    if (id.length) {
                                      this.handleChange(
                                        fields._id,
                                        mandatefield,
                                        id[0]._id
                                      );
                                    } else {
                                      this.handleChange(
                                        fields._id,
                                        mandatefield,
                                        null
                                      );
                                    }
                                  }}
                                  filterOption={(inputValue, option) =>
                                    option.props.children
                                      .toUpperCase()
                                      .indexOf(inputValue.toUpperCase()) !== -1
                                  }
                                />
                              ) : (
                                <span> No Trip on desired date </span>
                              )}
                            </div>
                          ) : mandatefield == "stationId" ? (
                            <AutoComplete
                              style={{
                                width: "500px"
                              }}
                              onChange={value => {
                                let id = stationName.filter(
                                  station => station.name === value
                                );
                                if (id.length) {
                                  this.handleChange(
                                    fields._id,
                                    mandatefield,
                                    id[0]._id
                                  );
                                } else {
                                  this.handleChange(
                                    fields._id,
                                    mandatefield,
                                    null
                                  );
                                }
                              }}
                              dataSource={stationName.map(
                                station => station.name
                              )}
                              filterOption={(inputValue, option) =>
                                option.props.children
                                  .toUpperCase()
                                  .indexOf(inputValue.toUpperCase()) !== -1
                              }
                            />
                          ) : mandatefield == "bookingId" ? (
                            <TextArea
                              className="mb-1"
                              type="text"
                              maxLength="14"
                              autoSize={{ minRows: 1, maxRows: 2 }}
                              style={{ width: "100%" }}
                              onChange={this.handleSpecialCharacters}
                            />
                          ) : mandatefield == "amount" ? (
                            <TextArea
                              className="mb-1"
                              inputMode="numeric"
                              autoSize={{ minRows: 1, maxRows: 2 }}
                              maxLength={30}
                              style={{ width: "100%" }}
                              onChange={e =>
                                this.handleChange(
                                  fields._id,
                                  mandatefield,
                                  parseInt(e.target.value)
                                )
                              }
                            />
                          ) : mandatefield == "date" ? (
                            <DatePicker
                              style={{ width: 150 }}
                              onChange={e =>
                                this.handleChange(fields._id, `date`, e)
                              }
                            />
                          ) : (
                            <TextArea
                              className="mb-1"
                              autoSize={{ minRows: 1, maxRows: 2 }}
                              maxLength={30}
                              style={{ width: "100%" }}
                              onChange={e =>
                                this.handleChange(
                                  fields._id,
                                  mandatefield,
                                  e.target.value
                                )
                              }
                            />
                          )}{" "}
                        </div>
                      </div>
                    ))}
                    <div className="row">
                      <div className="col-3">ETA</div>
                      <div className="col-6 text-left">
                        {" "}
                        <DatePicker
                          style={{ width: 150 }}
                          disabledDate={current => {
                            let customDate = moment().format("YYYY-MM-DD");
                            return (
                              current &&
                              current < moment(customDate, "YYYY-MM-DD")
                            );
                          }}
                          onChange={e =>
                            this.handleChange(fields._id, `etaDate`, e)
                          }
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-3">Comments</div>
                      <div className="col-6 text-left">
                        <TextArea
                          className="mb-1"
                          autoSize={{ minRows: 1, maxRows: 2 }}
                          maxLength={30}
                          style={{ width: "100%" }}
                          onChange={e =>
                            this.handleChange(
                              fields._id,
                              "Comment",
                              e.target.value
                            )
                          }
                        />
                      </div>
                    </div>
                  </div>
                ))
              : null}
            {Array.isArray(taskMasterKeysMandate) &&
            taskMasterKeysMandate.length > 0 ? (
              <Button
                type="primary"
                onClick={this.handleSendClick}
                style={{ margin: "1% 0" }}
              >
                Confirm Group Task
              </Button>
            ) : null}
          </Modal>
        ) : (
          <Button onClick={this.toggleMessageOption}>
            Initiate Group Task
          </Button>
        )}
      </div>
    );
  }
}

export default InitiateTaskGroup;
