import Fetch from "../utils/fetch";

export const fetchLastLocation = async tripId => {
  try {
    const url = `/bus-tracker/lastlocation/${tripId}`;
    const options = {
      method: "get"
    };

    const response = await Fetch(url, options);

    if (response) {
      return response;
    } else {
      throw new Error(response);
    }
  } catch (err) {
    console.log(err);
    return Promise.reject(err);
  }
};

export const fetchBooking = async (pnr, id = null) => {
  try {
    let url = `/bus-tracker/booking?pnr=${pnr}&customResponse=true`;

    if (id) {
      url = `/bus-tracker/booking?id=${id}&customResponse=true`;
    }

    const response = await Fetch(url);
    if (response) {
      return Promise.resolve(response[0]);
    } else {
      throw new Error(response);
    }
  } catch (err) {
    console.log(err);
    return Promise.reject(err);
  }
};

export const fetchBusRoute = async tripId => {
  try {
    const url = `/bus-tracker/route/${tripId}`;
    const options = {
      method: "get"
    };

    const response = await Fetch(url, options);

    if (response) {
      return Promise.resolve(response);
    } else {
      console.log(response);
      throw new Error("No response");
    }
  } catch (err) {
    console.log(err);
    return Promise.reject(err);
  }
};

export const fetchBusType = async id => {
  try {
    const url = `/bus-tracker/busType/${id}`;
    const options = {
      method: "get"
    };

    const response = await Fetch(url, options);

    if (response) {
      return Promise.resolve(response);
    } else {
      console.log(response);
      throw new Error("No response");
    }
  } catch (err) {
    console.log(err);
    return Promise.reject(err);
  }
};

export const markTripCompleted = async tripId => {
  try {
    const url = `/bus-tracker/markTripCompleted/${tripId}`;
    const options = {
      method: "get"
    };

    const response = await Fetch(url, options);

    if (response) {
      return Promise.resolve(response);
    } else {
      console.log(response);
      throw new Error("No response");
    }
  } catch (err) {
    console.log(err);
    return Promise.reject(err);
  }
};

export const fetchCities = async () => {
  try {
    const url = `/bus-tracker/fetchCities`;

    const options = {
      method: "get"
    };

    const response = await Fetch(url, options);
    return response;
  } catch (err) {
    console.log(err);
    return [];
  }
};

export const postIssue = async data => {
  try {
    const url = `/selfHelp/raise/issue`;
    const options = {
      method: "post",
      data
    };

    const response = await Fetch(url, options);

    if (response) {
      return Promise.resolve(response);
    } else {
      console.log(response);
      throw new Error("No response");
    }
  } catch (err) {
    console.log(err);
    return Promise.reject(err);
  }
};

export const fetchStation = async tripId => {
  try {
    const url = `/bus-tracker/stations/${tripId}`;

    const options = {
      method: "get"
    };

    const response = await Fetch(url, options);
    return Promise.resolve(response);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const markTripCompletedManually = async tripId => {
  try {
    const url = `/bus-tracker/markTripCompletedManually/${tripId}`;

    const options = {
      method: "get"
    };

    const response = await Fetch(url, options);

    if (response) {
      return Promise.resolve(response);
    } else {
      console.log(response);

      throw new Error("No response");
    }
  } catch (err) {
    console.log(err);

    return Promise.reject(err);
  }
};

export const fetchGDSBooking = async query => {
  try {
    let url = `/gds/selfHelp/booking?${query}`;

    const response = await Fetch(url);

    if (response) {
      return Promise.resolve(response);
    } else {
      return [];
    }
  } catch (err) {
    console.log(err);
    return Promise.reject(err);
  }
};

export const fetchMarketingCards = async query => {
  try {
    let url = `${query}`;

    const response = await Fetch(url);

    if (response) {
      return Promise.resolve(response);
    } else {
      return [];
    }
  } catch (err) {
    console.log(err);
    return Promise.reject(err);
  }
};

export const gdsLastLocation = async tripId => {
  try {
    let url = `/gds/selfHelp/lastLocation/${tripId}`;

    const response = await Fetch(url);

    if (response) {
      return Promise.resolve(response);
    } else {
      return {};
    }
  } catch (err) {
    console.log(err);
    return Promise.reject(err);
  }
};

export const gdsSavedGPSData = async tripId => {
  try {
    let url = `/gds/track/savedLocation/${tripId}`;

    const response = await Fetch(url);

    if (response) {
      return Promise.resolve(response);
    } else {
      return [];
    }
  } catch (err) {
    console.log(err);
    return Promise.reject(err);
  }
};
