import React, { Component } from "react";
import { Button, Modal, message } from "antd";
import Fetch from "../../../utils/fetch";
import styles from "./stationChange.module.scss";
import BackIcon from "../../../images/back.png";
import noShow from "../../../images/nothingtoshow.svg";
import mixpanel from "mixpanel-browser";

// import { getHoursAndMinFromMillis } from "../../../utils/common";
import Otpinput from "../otpInput";
import moment from "moment";
import { prodServer, mixPanelToken, isStage } from "../../../config";
import Loader from "../../loader";

import DirectionIcon from "../../../images/direction.svg";
import otpIcon from "../../../images/otp.svg";
import infoIcon from "../../../images/infoSelfHelp.svg";
import closeIcon from "../../../images/closeModal.svg";
import doneIcon from "../../../images/doneSelf.svg";
import oopsIcon from "../../../images/oopsSelf.svg";
import requestIcon from "../../../images/requestSelf.svg";

class StationChangeScreen extends Component {
  state = {
    stationList: [],
    showOtpScreen1: false,
    loading: false,
    showConfirmationModal: false,
    showOtpScreen2: false,
    disableInput: false,
    isSuccessfull: false,
    isFailed: false,
    selectedStationKey: true
  };

  async componentDidMount() {
    try {
      this.setState({ loading: true });
      const { booking, overlayTitle } = this.props;
      const fetchStationList = await this.fetchTripRoute(booking, overlayTitle);
      const stationList = fetchStationList.map(obj => {
        let totalTime =
          booking.trip.tripDate +
          booking.trip.service.startTime +
          obj.millisFromStart;

        return {
          ...obj,
          stationTime: moment(totalTime).format("L")
        };
      });
      this.setState({ stationList, loading: false });
    } catch (err) {
      console.log(err);
      this.setState({ loading: false });
    }
  }

  fetchTripRoute = async (booking, overlayTitle) => {
    try {
      let url = `/bus-tracker/booking/selfhelpUpdate/?tripId=${booking.tripId}`;

      if (overlayTitle == "Boarding Point") {
        url += `&fromCityId=${booking.boardingPoint.cityId}&fromStationId=${booking.boardingPoint.stationId}`;
      } else {
        url += `&toCityId=${booking.dropPoint.cityId}&toStationId=${booking.dropPoint.stationId}`;
      }
      const options = {
        method: "get"
      };
      const response = await Fetch(url, options);
      if (response) {
        return response;
      }
    } catch (err) {
      message.error(err);
    }
  };

  selectedStation = obj => {
    const selectedStation = obj.target.value;
    const selectedStationId = obj.target.value;
    this.setState({ selectedStation, selectedStationId });
  };

  showDoneScreen = () => {
    const { booking } = this.props;
    mixpanel.init(mixPanelToken, { debug: isStage });
    mixpanel.track("Done Button Clicked", {
      mobile_no: booking.mobileNo,
      pnr: booking.bookingCode,
      trip_date: moment(booking.boardingPoint.time).format("YYYY-MM-DD"),
      from_city: booking.boardingPoint.city,
      to_city: booking.dropPoint.city,
      from_station: booking.boardingPoint.address,
      to_station: booking.dropPoint.address,
      booking_source: booking.demandSource
    });
    this.setState({
      disableInput: true,
      showOtpScreen2: false,
      showConfirmationModal: true,
      askForConfirmation: true
    });
  };

  showVerfiyScreen = () => {
    const { booking } = this.props;
    mixpanel.init(mixPanelToken, { debug: isStage });
    mixpanel.track("Proceed Button Clicked", {
      mobile_no: booking.mobileNo,
      pnr: booking.bookingCode,
      trip_date: moment(booking.boardingPoint.time).format("YYYY-MM-DD"),
      from_city: booking.boardingPoint.city,
      to_city: booking.dropPoint.city,
      from_station: booking.boardingPoint.address,
      to_station: booking.dropPoint.address,
      booking_source: booking.demandSource
    });
    const maskedMobileNo = booking.mobileNo
      .toString()
      .replace(/.(?=.{4})/g, "X");
    if (this.state.selectedStationVerify) {
      this.setState({
        showOtpScreen2: false,
        showConfirmationModal: true,
        askForConfirmation: true
      });
    } else {
      if (booking.customerStationChangedAt + 2 * 60 * 1000 > Date.now()) {
        this.setState({
          disableInput: true,
          showConfirmationModal: true,
          selectedStationKey: false,
          selectedStationVerify: false,
          askForConfirmation: true,
          maskedMobileNo
        });
      } else {
        this.setState({
          disableInput: true,
          showOtpScreen1: true,
          selectedStationKey: false,
          selectedStationVerify: false,
          maskedMobileNo
        });
      }
    }
  };

  sendOtp = async () => {
    const { overlayTitle, booking } = this.props;

    mixpanel.init(mixPanelToken, { debug: isStage });
    mixpanel.track("Send OTP Button Clicked", {
      mobile_no: booking.mobileNo,
      pnr: booking.bookingCode,
      trip_date: moment(booking.boardingPoint.time).format("YYYY-MM-DD"),
      from_city: booking.boardingPoint.city,
      to_city: booking.dropPoint.city,
      from_station: booking.boardingPoint.address,
      to_station: booking.dropPoint.address,
      booking_source: booking.demandSource
    });
    const url = `/bus-tracker/sendSelfOtp`;
    const options = {
      method: "post",
      data: {
        mobileNo: booking.mobileNo,
        type: overlayTitle,
        bookingId: booking.id
      }
    };

    const response = await Fetch(url, options);
    if (response) {
      this.setState({
        showOtpScreen1: false,
        showOtpScreen2: true
      });
    }
  };

  mapsSelector = obj => {
    const lat = parseFloat(obj.lat);
    const lng = parseFloat(obj.lng);

    window.open(
      `https://www.google.com/maps/search/?api=1&query=${lat},${lng}`
    );
  };

  showModal = () => {
    this.setState({
      // showOtpScreen2: false,
      // showConfirmationModal: true,
      // askForConfirmation: true
      disableInput: false,
      showOtpScreen1: false,
      selectedStationKey: true,
      selectedStationVerify: true
    });
  };

  confirmationModal = async () => {
    const { booking, overlayTitle } = this.props;
    mixpanel.init(mixPanelToken, { debug: isStage });
    mixpanel.track("Yes Button Clicked", {
      mobile_no: booking.mobileNo,
      pnr: booking.bookingCode,
      trip_date: moment(booking.boardingPoint.time).format("YYYY-MM-DD"),
      from_city: booking.boardingPoint.city,
      to_city: booking.dropPoint.city,
      from_station: booking.boardingPoint.address,
      to_station: booking.dropPoint.address,
      booking_source: booking.demandSource
    });
    const { selectedStationId } = this.state;
    let cityType = "fromCity";
    if (overlayTitle == "Drop Point") {
      cityType = "toCity";
    }
    const url = `/bus-tracker/userStationUpdate`;
    const options = {
      method: "post",
      data: {
        bookingCode: booking.bookingCode,
        cityType,
        pickedStationId: selectedStationId,
        bookingId: booking.id
      }
    };

    const response = await Fetch(url, options);

    if (response) {
      this.setState({
        askForConfirmation: false,
        isSuccessfull: true
      });
    } else {
      this.setState({
        askForConfirmation: false,
        isFailed: true
      });
    }
  };

  closeWindow = () => {
    this.setState({
      selectedStationId: null,
      showOtpScreen1: false,
      disableInput: false,
      showOtpScreen2: false,
      showConfirmationModal: false,
      selectedStationVerify: false,
      selectedStationKey: true
    });
  };

  closeConfirmWindow = () => {
    this.setState({
      showOtpScreen1: false,
      disableInput: false,
      showOtpScreen2: false,
      showConfirmationModal: false,
      selectedStationVerify: true,
      selectedStationKey: true
    });
  };

  reloadPage = () => {
    const { booking } = this.props;
    let url = `https://testtracker.zingmobility.com/customerTracking/${booking.id}`;
    if (prodServer) {
      url = `https://tracer.zingbus.com/customerTracking/${booking.id}`;
    }
    window.location.replace(url);
  };
  render() {
    const { toggleStationScreen, overlayTitle } = this.props;
    const {
      stationList,
      disableInput,
      selectedStationKey,
      selectedStationVerify,
      selectedStationId,
      showOtpScreen1,
      showConfirmationModal,
      maskedMobileNo,
      showOtpScreen2,
      isSuccessfull,
      isFailed,
      loading
    } = this.state;

    const { booking } = this.props;
    if (loading) {
      return <Loader />;
    }
    return (
      <div className={styles.overlayContainer}>
        <div
          style={{
            background: "rgb(240, 239, 244)"
          }}
        >
          <div className={styles.header}>
            <button onClick={() => toggleStationScreen(false, null)}>
              <img src={BackIcon} alt="back" />
            </button>
            <div
              style={{
                textAlign: "center"
              }}
            >
              <span>Choose {overlayTitle}</span>
            </div>
          </div>
          {stationList.length > 0 ? (
            <div
              style={{
                padding: "0 2% 0 2%",
                height: "655px",
                opacity:
                  (showOtpScreen1 || showOtpScreen2) && !selectedStationVerify
                    ? "0.4"
                    : null,
                overflow: selectedStationKey ? "auto" : null,
                "overflow-x": "hidden"
              }}
            >
              {stationList.map(item => (
                <>
                  <div
                    className="row"
                    style={{
                      background: "white",

                      padding: "2%"
                    }}
                  >
                    <div
                      className="col-7"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        fontSize: "13px",
                        color: "#292929",
                        fontFamily: "SFPROTEXT"
                      }}
                    >
                      {item.station.name}
                      <br />
                      {item.stationTime}
                      <div onClick={() => this.mapsSelector(item.station)}>
                        <img style={{ margin: "2%" }} src={DirectionIcon} />
                        <p
                          className="d-inline"
                          style={{
                            fontFamily: "SFPROTEXT",
                            color: "#7b2bff",
                            fontSize: "12px",
                            marginLeft: "1%"
                          }}
                        >
                          Navigate
                        </p>
                      </div>
                    </div>
                    <div
                      className="col-5 text-right"
                      onChange={this.selectedStation}
                    >
                      <input
                        style={{
                          accentColor: "#7B2AFF"
                        }}
                        checked={
                          selectedStationId == item.station._id ? true : false
                        }
                        type="radio"
                        key={item.station._id}
                        disabled={disableInput ? true : false}
                        name="stations"
                        value={item.station._id}
                      />
                    </div>
                  </div>

                  <hr></hr>
                </>
              ))}
            </div>
          ) : (
            <div style={{ textAlign: "center", background: "white" }}>
              <img src={noShow}></img>
              <p
                style={{
                  fontFamily: "SFPROTEXT",
                  color: "#000000",
                  fontSize: "18px",
                  fontWeight: "400"
                }}
              >
                OH,oh !
              </p>
              <p
                style={{
                  fontFamily: "SFPROTEXT",
                  color: "#777777",
                  fontSize: "13px",
                  fontWeight: "500"
                }}
              >
                {overlayTitle == ""}
                No {overlayTitle} is available for the change
              </p>
            </div>
          )}
        </div>
        {stationList.length > 0 && selectedStationKey ? (
          <div
            style={{
              padding: "3%",
              background: "white",
              position: "sticky",
              bottom: "0"
            }}
          >
            <div
              style={{
                textAlign: "center",
                background: "#F0EFF4"
              }}
            >
              <img style={{ margin: "2%" }} src={infoIcon} />
              <p
                className="d-inline"
                style={{
                  fontFamily: "SFPROTEXT",
                  color: "#454545",
                  borderRadius: "10px",
                  fontSize: "13px",
                  marginLeft: "1%"
                }}
              >
                You can change your {overlayTitle} only once
              </p>
            </div>
            {selectedStationId && !selectedStationVerify ? (
              <Button
                onClick={this.showVerfiyScreen}
                style={{
                  fontFamily: "SFPROTEXT",
                  cursor: "pointer",
                  color: "white",
                  height: "45px",
                  background: "#7B2AFF",
                  borderRadius: "10px",
                  marginTop: "1.5%",
                  width: "100%"
                }}
              >
                Proceed
              </Button>
            ) : !selectedStationId && !selectedStationVerify ? (
              <Button
                disabled={true}
                onClick={this.showVerfiyScreen}
                style={{
                  fontFamily: "SFPROTEXT",
                  cursor: "pointer",
                  color: "#796e6e",
                  height: "45px",
                  background: "#D8D8D8",
                  borderRadius: "10px",
                  marginTop: "1.5%",
                  width: "100%"
                }}
              >
                Proceed
              </Button>
            ) : selectedStationVerify ? (
              <Button
                onClick={this.showDoneScreen}
                style={{
                  color: "white",
                  height: "45px",
                  background: "#57A51A",
                  borderRadius: "10px",
                  marginTop: "1.5%",
                  width: "100%"
                }}
              >
                Done
              </Button>
            ) : null}
          </div>
        ) : showOtpScreen1 ? (
          <div
            style={{
              background: "#FFFFFF",
              position: "sticky",
              bottom: "0px",
              borderRadius: "10px 10px 0px 0px"
            }}
          >
            <div
              style={{
                position: "absolute",
                top: "2%",
                right: "3%"
              }}
            >
              <img onClick={this.closeWindow} src={closeIcon}></img>
            </div>

            <div
              style={{
                borderRadius: "10px 10px 0px 0px",
                textAlign: "center",
                background: "#EDCBFE",
                marginBottom: "24px"
              }}
            >
              <img src={otpIcon}></img>
            </div>
            <div
              style={{
                textAlign: "center"
              }}
            >
              <p
                style={{
                  fontFamily: "SFPROTEXT",
                  color: "#000000",
                  fontSize: "18px"
                }}
              >
                Verify with OTP
              </p>
              <p
                style={{
                  fontFamily: "SFPROTEXT",
                  color: "#000000",
                  fontSize: "18px"
                }}
              >
                to change {overlayTitle}
              </p>
              <p
                style={{
                  fontFamily: "SFPROTEXT",
                  color: "#454545",
                  fontSize: "12px"
                }}
              >
                An OTP will be send to {maskedMobileNo}
              </p>
              <Button
                onClick={this.sendOtp}
                style={{
                  fontFamily: "SFPROTEXT",
                  cursor: "pointer",
                  color: "white",
                  height: "45px",
                  background: "#7B2AFF",
                  borderRadius: "10px",
                  marginTop: "1.5%",
                  width: "80%",
                  "margin-bottom": "11%"
                }}
              >
                Send OTP
              </Button>
            </div>
          </div>
        ) : showOtpScreen2 ? (
          <div
            style={{
              background: "#FFFFFF",
              position: "sticky",
              bottom: "0px",
              padding: "3%",
              borderRadius: "10px 10px 0px 0px"
            }}
          >
            <Otpinput
              closeWindow={this.closeWindow}
              maskedNumber={maskedMobileNo}
              type={overlayTitle}
              booking={booking}
              resend={this.sendOtp}
              openModal={this.showModal}
            />
          </div>
        ) : null}
        <Modal
          visible={showConfirmationModal}
          centered
          title=""
          closable={false}
          footer={null}
        >
          <div
            onClick={this.closeConfirmWindow}
            style={{
              cursor: "pointer",
              display: "flex",
              flexDirection: "row-reverse"
            }}
          >
            <img style={{ margin: "2%" }} src={closeIcon} />
          </div>
          <div className="selfHelpModalBPDP">
            <img style={{ margin: "2%" }} src={requestIcon} />
            <p style={{ margin: "2%" }}>
              Are you sure you want to change <br></br>your {overlayTitle} ?
            </p>
            <Button
              onClick={this.confirmationModal}
              style={{
                color: "white",
                height: "45px",
                background: "#7B2AFF",
                borderRadius: "10px",
                marginTop: "1.5%",
                width: "80%"
              }}
            >
              Yes
            </Button>
          </div>
        </Modal>
        <Modal centered visible={isSuccessfull} closable={false} footer={null}>
          <div
            onClick={this.reloadPage}
            style={{
              cursor: "pointer",
              display: "flex",
              flexDirection: "row-reverse"
            }}
          >
            <img style={{ margin: "2%" }} src={closeIcon} />
          </div>
          <div className="selfHelpModalBPDP">
            <img style={{ margin: "2%" }} src={doneIcon} />
            <p style={{ margin: "2%" }}>Congrats</p>

            <p style={{ margin: "2%" }}>
              Your {overlayTitle} has been <br></br> changed successfully
            </p>
            <Button
              onClick={this.reloadPage}
              style={{
                color: "white",
                height: "45px",
                background: "#7B2AFF",
                borderRadius: "10px",
                marginTop: "1.5%",
                width: "80%"
              }}
            >
              Okay
            </Button>
          </div>
        </Modal>{" "}
        <Modal centered closable={false} visible={isFailed} footer={null}>
          <div
            onClick={this.reloadPage}
            style={{
              display: "flex",
              cursor: "pointer",
              flexDirection: "row-reverse"
            }}
          >
            <img style={{ margin: "2%" }} src={closeIcon} />
          </div>
          <div className="selfHelpModalBPDP">
            <img style={{ margin: "2%" }} src={oopsIcon} />
            <p style={{ margin: "2%" }}>Oops !!</p>
            <p style={{ margin: "2%" }}>
              Unable to change the<br></br> {overlayTitle}
            </p>
            <Button
              onClick={this.reloadPage}
              style={{
                color: "white",
                height: "45px",
                background: "#7B2AFF",
                borderRadius: "10px",
                marginTop: "1.5%",
                width: "80%"
              }}
            >
              Okay
            </Button>
          </div>
        </Modal>
      </div>
    );
  }
}

export default StationChangeScreen;
