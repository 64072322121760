import React, { Component } from "react";
import { Select, Switch, Table, Button, Modal, message } from "antd";
import Loader from "../loader";
import Map from "./map";
import Fetch from "../../utils/fetch";
import "./geoFence.css";
import moment from "moment";

const { Option } = Select;

const columns = [
  {
    title: "Station One",
    dataIndex: "stationOne",
    key: "stationOne",
    width: "20%",
    render: stationOne => (stationOne ? stationOne.station.name : "Parking")
  },
  {
    title: "Station Two",
    dataIndex: "stationTwo",
    key: "stationTwo",
    width: "20%",
    render: stationTwo => (stationTwo ? stationTwo.station.name : "Parking")
  },
  {
    title: "Geofence type",
    dataIndex: "geofence",
    key: "geofenceType",
    width: "10%",
    render: geofence => geofence.type
  },
  {
    title: "Coordinates",
    dataIndex: "geofence",
    key: "geofenceCoordinate",
    width: "45%",
    render: geofence => {
      switch (geofence.type) {
        case "circle": {
          return (
            <>
              <div>Center: {JSON.stringify(geofence.center)}</div>
              <div>Radius: {geofence.radius} meter</div>
            </>
          );
        }

        case "rectangle": {
          return <div>{JSON.stringify(geofence.boundingBox)} meter</div>;
        }

        default: {
          return (
            <>
              {geofence.coordinates.map((d, index) => (
                <div key={index}>{JSON.stringify(d)}</div>
              ))}
            </>
          );
        }
      }
    }
  },
  {
    title: "Actions",
    dataIndex: "actions",
    key: "actions",
    width: "20%",
    render: actions => {
      return (
        <>
          <Button onClick={actions.editGeoFence}>Edit</Button>
          {/* <Button>Show on Map</Button> */}
        </>
      );
    }
  }
];

class GeoFenceView extends Component {
  state = {
    loading: true,
    searchLoading: false,
    recalculateGeofenceLoading: false,
    services: [],
    selectedServiceId: null,
    geoFenceData: [],
    formatedGeoFenceData: [],
    editStationGeoFence: false,
    currentGeoFenceDataForStation: null,
    geoFenceDrawData: null,
    showGeoFenceOnMap: true,
    geoFenceRoute: []
  };

  async componentDidMount() {
    try {
      const services = await this.getAllServices();

      this.setState({ loading: false, services });
    } catch (err) {
      console.log(err);
      this.setState({ loading: false });
    }
  }

  getAllServices = async () => {
    try {
      const url = `/bus-tracker/serviceNameEs`;

      const options = {
        method: "get"
      };

      const response = await Fetch(url, options);

      if (response) {
        return response;
      } else {
        return [];
      }
    } catch (err) {
      console.log(err);

      return Promise.reject(err);
    }
  };

  getGeoFenceData = async serviceId => {
    try {
      const url = `/bus-tracker/geofencing/${serviceId}`;

      const options = {
        method: "get"
      };

      const response = await Fetch(url, options);

      if (response) {
        return response;
      } else {
        return [];
      }
    } catch (err) {
      console.log(err);

      return Promise.reject(err);
    }
  };

  updateGeoFenceData = async (serviceId, updateBody) => {
    try {
      const url = `/bus-tracker/geofencing/${serviceId}`;

      const options = {
        method: "patch",
        data: updateBody
      };

      const response = await Fetch(url, options);

      return response;
    } catch (err) {
      console.log(err);

      return Promise.reject(err);
    }
  };

  formatGeofenceData = geoFencingPoints => {
    const formatedGeoFenceData = geoFencingPoints.map((d, index) => ({
      ...d,
      actions: {
        editGeoFence: () => this.onEditGeoFenceClick(d, index)
      }
    }));

    return formatedGeoFenceData;
  };

  handleSearchClick = async () => {
    try {
      const { selectedServiceId } = this.state;

      if (!selectedServiceId) {
        message.error("Select service");
      }

      this.setState({ searchLoading: true });

      let geoFenceData = await this.getGeoFenceData(selectedServiceId);

      if (!geoFenceData.length) {
        console.log("No data found");
        return;
      }
      const url = `/bus-tracker/fetchTripViaService?date=${moment().format(
        "YYYY-MM-DD"
      )}&serviceId=${selectedServiceId}`;

      const options = {
        method: "get"
      };
      const tripId = await Fetch(url, options);
      geoFenceData = geoFenceData[0];

      const { geoFencingPoints } = geoFenceData;

      const formatedGeoFenceData = this.formatGeofenceData(geoFencingPoints);

      this.setState({
        tripId: tripId,
        geoFenceData,
        formatedGeoFenceData,
        searchLoading: false
      });
    } catch (err) {
      console.log(err);
      this.setState({
        geoFenceData: [],
        formatedGeoFenceData: [],
        searchLoading: false
      });
    }
  };

  recalculateGeofence = async serviceId => {
    try {
      const url = `/bus-tracker/recalculate/geofence/${serviceId}`;

      const options = {
        method: "post",
        data: {}
      };

      const response = await Fetch(url, options);

      return response;
    } catch (err) {
      console.log(err);

      return Promise.reject(err);
    }
  };

  handleRecalculateGeofenceClick = async () => {
    try {
      this.setState({
        recalculateGeofenceLoading: true
      });
      const { selectedServiceId } = this.state;

      if (!selectedServiceId) {
        message.error("Select service");
      }

      let geoFenceData = await this.recalculateGeofence(selectedServiceId);
      geoFenceData = geoFenceData[0];

      const { geoFencingPoints } = geoFenceData;

      const formatedGeoFenceData = this.formatGeofenceData(geoFencingPoints);

      this.setState({
        geoFenceData,
        formatedGeoFenceData,
        recalculateGeofenceLoading: false
      });
      message.success("Geofence recalculation success");
    } catch (err) {
      console.log(err);
      message.error("Geofence recalculation failed");
      this.setState({
        recalculateGeofenceLoading: false
      });
    }
  };

  onEditGeoFenceClick = (currentGeofence, position) => {
    const { geoFenceData } = this.state;
    const geoFenceRoute = [];

    for (let i = position - 1; i <= position + 2; ++i) {
      if (geoFenceData.geoFencingPoints[i]) {
        geoFenceRoute.push(geoFenceData.geoFencingPoints[i]);
      }
    }

    this.setState({
      editStationGeoFence: true,
      currentGeoFenceDataForStation: currentGeofence,
      geoFenceRoute
    });
  };

  onSelectChange = serviceId => {
    this.setState({ selectedServiceId: serviceId });
  };

  onSaveEditGeoFence = async () => {
    const { geoFenceDrawData, geoFenceData, selectedServiceId } = this.state;

    if (!geoFenceDrawData) {
      return;
    }

    const newGeoFencingPoints = geoFenceData.geoFencingPoints.map(g => {
      if (
        !g.stationOne &&
        !geoFenceDrawData.stationOne &&
        g.stationTwo &&
        geoFenceDrawData.stationTwo &&
        g.stationTwo.stationId === geoFenceDrawData.stationTwo.stationId
      ) {
        return { ...geoFenceDrawData, customGeofence: true };
      } else if (
        g.stationOne &&
        geoFenceDrawData.stationOne &&
        g.stationOne.stationId === geoFenceDrawData.stationOne.stationId &&
        !g.stationTwo &&
        !geoFenceDrawData.stationTwo
      ) {
        return { ...geoFenceDrawData, customGeofence: true };
      } else if (
        g.stationOne &&
        geoFenceDrawData.stationOne &&
        g.stationTwo &&
        geoFenceDrawData.stationTwo &&
        g.stationOne.stationId === geoFenceDrawData.stationOne.stationId &&
        g.stationTwo.stationId === geoFenceDrawData.stationTwo.stationId
      ) {
        return { ...geoFenceDrawData, customGeofence: true };
      } else {
        return g;
      }
    });

    try {
      let updatedGeofenceData = await this.updateGeoFenceData(
        selectedServiceId,
        {
          geoFenceData: newGeoFencingPoints
        }
      );

      updatedGeofenceData = updatedGeofenceData[0];

      const { geoFencingPoints } = updatedGeofenceData;

      const formatedGeoFenceData = this.formatGeofenceData(geoFencingPoints);

      const stateUpdate = {
        formatedGeoFenceData,
        geoFenceData: updatedGeofenceData
      };

      this.closeEditGeoFence(stateUpdate);
      message.success("Geofence edit success");
    } catch (err) {
      console.log(err);
    }
  };

  closeEditGeoFence = (stateUpdate = {}) => {
    this.setState({
      editStationGeoFence: false,
      currentGeoFenceDataForStation: null,
      geoFenceDrawData: null,
      geoFenceRoute: [],
      ...stateUpdate
    });
  };

  getLatLng = data => ({ lat: data.lat, lng: data.lng });

  onDrawComplete = data => {
    this.setState({ geoFenceDrawData: data });
  };

  onSwitchChange = checked => {
    this.setState({
      showGeoFenceOnMap: checked
    });
  };

  render() {
    const {
      loading,
      services,
      editStationGeoFence,
      currentGeoFenceDataForStation,
      geoFenceDrawData,
      geoFenceData,
      formatedGeoFenceData,
      showGeoFenceOnMap,
      geoFenceRoute,
      searchLoading,
      recalculateGeofenceLoading,
      tripId
    } = this.state;

    if (loading) {
      return <Loader />;
    }

    return (
      <>
        <div className="g-searchContainer">
          <Select
            showSearch
            placeholder="Select a service"
            optionFilterProp="children"
            onChange={this.onSelectChange}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            style={{ width: "400px" }}
          >
            {services.map(s => (
              <Option value={s._id} key={s._id}>
                {s.name}
              </Option>
            ))}
          </Select>
          <div className="g-buttonContainer">
            <Button onClick={this.handleSearchClick} loading={searchLoading}>
              Search
            </Button>
          </div>
          {formatedGeoFenceData.length ? (
            <div className="g-buttonContainer">
              <Button
                onClick={this.handleRecalculateGeofenceClick}
                loading={recalculateGeofenceLoading}
              >
                Recalculate Geofence for Trip
              </Button>
            </div>
          ) : null}
        </div>
        <hr />
        {formatedGeoFenceData.length ? (
          <div className="g-resultContainer">
            <div style={{ width: "60%" }} className="card">
              <Table
                columns={columns}
                dataSource={formatedGeoFenceData}
                pagination={false}
                scroll={{ x: "60%" }}
                size="small"
              />
            </div>
            <div
              style={{
                width: "38%",
                alignSelf: "flex-start",
                position: "sticky",
                top: 0
              }}
              className="card"
            >
              <Map
                id="map3"
                width="100%"
                height="600px"
                center={geoFenceData.geoFencingPoints[0].stationTwoCoordinate}
                tripId={tripId}
                geoFenceData={geoFenceData.geoFencingPoints}
                drawMode={false}
                showGeoFence={true}
              />
            </div>
          </div>
        ) : null}
        {editStationGeoFence ? (
          <Modal
            title="Edit Geofence"
            visible={editStationGeoFence}
            onOk={this.onSaveEditGeoFence}
            onCancel={this.closeEditGeoFence}
            okText="Save"
          >
            <div className="g-editModalContent">
              <div className="g-editModalContentColumn1">
                <div>
                  Station One:
                  {currentGeoFenceDataForStation.stationOne
                    ? currentGeoFenceDataForStation.stationOne.station.name
                    : "Parking"}
                </div>
                <br />
                <div>
                  Station Two:
                  {currentGeoFenceDataForStation.stationTwo
                    ? currentGeoFenceDataForStation.stationTwo.station.name
                    : "Parking"}
                </div>
                <br />
                <div>
                  <Switch
                    defaultChecked
                    checkedChildren="Hide Geofence"
                    unCheckedChildren="Show Geofence"
                    onChange={this.onSwitchChange}
                  />
                </div>
                <br />
                {geoFenceDrawData ? (
                  <div>
                    Geofence Draw Data:
                    {JSON.stringify(geoFenceDrawData.geofence)}
                  </div>
                ) : null}
              </div>
              <div className="g-editModalContentColumn2">
                <Map
                  id="map4"
                  width="100%"
                  height="100%"
                  center={
                    currentGeoFenceDataForStation.stationTwoCoordinate ||
                    currentGeoFenceDataForStation.stationOneCoordinate
                  }
                  tripId={tripId}
                  currentGeofence={currentGeoFenceDataForStation}
                  geoFenceData={geoFenceRoute}
                  onDrawComplete={this.onDrawComplete}
                  drawMode={true}
                  showGeoFence={showGeoFenceOnMap}
                />
              </div>
            </div>
          </Modal>
        ) : null}
      </>
    );
  }
}

export default GeoFenceView;
