import React, { Component } from "react";
import { Select, Table, Button, message } from "antd";
import jwt from "jsonwebtoken";

import Loader from "../loader";
import Fetch from "../../utils/fetch";
import CallTriggerSearchForm from "../../components/cameraFeedSearchForm";
import VideoStream from "../../components/videoStream";
import CrewDetails from "../../components/crewDetails";

import moment from "moment";
const { Option } = Select;

const columns = [
  {
    title: "Trigger",
    dataIndex: "complainComments",
    key: "complainComments",
    width: "10%"
  },
  {
    title: "Bus Rc",
    dataIndex: "busrc",
    key: "busrc",
    width: "10%"
  },
  {
    title: "Service Name",
    dataIndex: "serviceName",
    key: "serviceName",
    width: "10%"
  },
  {
    title: "Raised By",
    dataIndex: "raisedBy",
    key: "raisedBy",
    width: "10%",
    render: ({ name, date }) => {
      return (
        <div>
          {name}
          <br />
          {date}
        </div>
      );
    }
  },
  {
    title: "Camera Feed",
    dataIndex: "videoFeed",
    key: "videoFeed",
    width: "10%",
    render: ({ showVideo, tripId, date, _id }) => {
      return (
        <div>
          <Button onClick={() => showVideo({ tripId, _id, date })}>
            View Feed
          </Button>
        </div>
      );
    }
  },
  {
    title: "Notes",
    dataIndex: "notes",
    key: "notes",
    width: "10%"
  },
  {
    title: "Attachements",
    dataIndex: "attachements",
    key: "attachements",
    width: "10%"
  },
  {
    title: "Crew Details",
    dataIndex: "crewDetails",
    key: "crewDetails",
    width: "10%",
    render: ({ tripId, showDetails, _id }) => {
      return (
        <div>
          <Button onClick={() => showDetails({ tripId, _id })}>
            Show Details
          </Button>
        </div>
      );
    }
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    width: "10%",
    render: ({ status, raisedBy, _id, updateStatus }) => {
      return (
        <Select
          value={status}
          style={{ width: 120, margin: "0 0.2rem", whiteSpace: "pre-line" }}
          onChange={e =>
            updateStatus({
              status: e,
              raisedBy,
              _id
            })
          }
        >
          <Option value="REJECTED">REJECTED</Option>
          <Option value="CLOSED">CLOSED</Option>
          <Option value="INPROGRESS">INPROGRESS</Option>
          <Option value="CREATED">CREATED</Option>
        </Select>
      );
    }
  }
];

class CameraFeed extends Component {
  state = {
    contentLoading: false,
    showCrewDetails: false,
    showVideo: false,
    loading: true,
    complainResponse: [],
    outboundRemarkInput: ""
  };

  async componentDidMount() {
    try {
      const complainResponse = await this.fetchComplain();
      this.setState({ complainResponse, loading: false });
    } catch (err) {
      console.log(err);
      this.setState({ loading: false });
    }
  }

  fetchComplain = async () => {
    try {
      const url = `/bus-tracker/cameraFeedSyatemAlerts`;
      const options = {
        method: "get"
      };
      const response = await Fetch(url, options);
      if (response && Array.isArray(response)) {
        const formattedData = this.formattedData(response);
        return formattedData;
      }
      return null;
    } catch (err) {
      console.log(err);
      message.error(err);
    }
  };

  formattedData = response => {
    try {
      return response.map(complains => {
        const complainFormated = {
          _id: complains._id,
          complainCategory: complains.complaintCategory,
          complainComments: complains.complaintName,
          serviceName: complains.serviceName,
          busrc: complains.busRc,
          pnr: complains.bookingCode,
          tripId: complains.tripId,
          videoFeed: {
            tripId: complains.tripId,
            date: complains.createdOn,
            complain: complains,
            showVideo: this.showVideo,
            _id: complains._id
          },
          raisedBy: {
            name: complains.raisedBy,
            date: moment(complains.createdOn).format("LLL")
          },
          crewDetails: {
            showDetails: this.callMarshall,
            _id: complains._id,
            tripId: complains.tripId
          },
          status: {
            updateStatus: this.updateStatus,
            status: complains.status,
            raisedBy: complains.raisedBy,
            _id: complains._id
          },
          notes: complains.outboundRemark
        };
        return complainFormated;
      });
    } catch (error) {
      console.log(error);
    }
  };

  handleChange = (key, value) => {
    if (key == "showBookingDetails") {
      this.setState({ bookings: [] });
    }
    this.setState({ [key]: value, viewMoreList: [] });
  };

  handleTextChange = (key, value) => {
    this.setState({ [key]: value });
  };

  updateStatus = async obj => {
    const { complainResponse } = this.state;
    const url = `/bus-tracker/complianUpdate/cameraFeed`;
    const options = {
      method: "patch",
      data: {
        _id: obj._id,
        raisedBy: obj.raisedBy,
        status: obj.status
      }
    };

    const response = await Fetch(url, options);
    if (response && Array.isArray(response)) {
      const formattedData = this.formattedData(response);
      let updatedValue = complainResponse.map(
        obj => formattedData.find(o => o._id === obj._id) || obj
      );
      this.setState({
        complainResponse: updatedValue
      });
      message.success("Data is updated");
    }
  };

  updateOutboundRemark = async () => {
    const {
      complainResponse,
      outboundRemarkInput,
      outboundRemarksUpdateObj
    } = this.state;
    if (outboundRemarkInput.trim().length === 0) {
      message.error("please enter something");
      this.setState({
        showOutBoundsEdit: false,
        selectedId: "",
        outboundRemarkInput: ""
      });
      return;
    }
    const url = `/bus-tracker/outBoundUpdate`;
    const token = localStorage.getItem("idToken");
    const decodedToken = jwt.decode(token);
    const toBeUpdateData = complainResponse.filter(
      obj => outboundRemarksUpdateObj._id == obj._id
    );
    let remark = toBeUpdateData[0].outboundRemarks.allOutBoundsRemarks
      ? `${toBeUpdateData[0].outboundRemarks.allOutBoundsRemarks} `
      : "";

    let updateData = `${remark}${decodedToken.name},${moment().format(
      "LLL"
    )}: ${outboundRemarkInput} |`;
    const options = {
      method: "patch",
      data: {
        _id: outboundRemarksUpdateObj._id,
        raisedBy: outboundRemarksUpdateObj.raisedBy,
        outboundRemark: updateData
      }
    };

    const response = await Fetch(url, options);
    if (response && Array.isArray(response)) {
      const formattedData = this.formattedData(response);
      let updatedValue = complainResponse.map(
        obj => formattedData.find(o => o._id === obj._id) || obj
      );
      this.setState({
        showOutBoundsEdit: false,
        selectedId: "",
        outboundRemarkInput: "",
        complainResponse: updatedValue
      });
      message.success("Data is updated");
    } else {
      message.success("Data not updated");
      this.setState({
        showOutBoundsEdit: false,
        selectedId: "",
        outboundRemarkInput: ""
      });
    }
  };

  outboundRemarksUpdate = async obj => {
    let allOutBoundsRemarksArray = obj.allOutBoundsRemarks
      ? obj.allOutBoundsRemarks.split("|")
      : "";
    allOutBoundsRemarksArray = allOutBoundsRemarksArray
      ? allOutBoundsRemarksArray.filter(obj => obj != "")
      : "";
    const outboundRemarksUpdateObj = {
      outboundRemark: obj.value,
      allOutBoundsRemarks: allOutBoundsRemarksArray,
      raisedBy: obj.raisedBy,
      _id: obj._id
    };
    this.setState({ showOutBoundsEdit: true, outboundRemarksUpdateObj });
  };

  callMarshall = async obj => {
    const url = `/bus-tracker/trip/${obj.tripId}/${obj._id}/2`;
    const options = {
      method: "get"
    };
    let response = await Fetch(url, options),
      trip;
    if (response) {
      trip = response;
      this.setState({ trip, showCrewDetails: true });
    } else {
      message.error("something is wrong");
      return;
    }
  };

  showVideo = async obj => {
    const url = `/bus-tracker/trip/${obj.tripId}/${obj._id}/2`;
    const options = {
      method: "get"
    };
    let response = await Fetch(url, options);
    let videoObj = {
      tripId: response[0]._id,
      cameraCode: response[0].bus.cameraCodes,
      serviceName: response[0].service.name,
      busRc: response[0].bus.rc,
      date: obj.date
    };
    this.setState({ videoObj, showVideo: true });
  };

  updateData = response => {
    if (response && Array.isArray(response)) {
      const formattedData = this.formattedData(response);
      this.setState({ complainResponse: formattedData, contentLoading: false });
    } else {
      this.setState({ complainResponse: [], contentLoading: false });
    }
  };

  showLoading = () => {
    this.setState({ contentLoading: true });
  };

  render() {
    const {
      loading,
      complainResponse,
      contentLoading,
      showVideo,
      videoObj,
      showCrewDetails,
      trip
    } = this.state;
    if (loading) {
      return <Loader />;
    } else {
      return (
        <div>
          <div>
            <CallTriggerSearchForm
              showLoader={this.showLoading}
              onSearch={this.updateData}
            />
          </div>
          <div
            style={{
              overflow: "hidden",
              width: "100%",
              height: "100vh"
            }}
          >
            <Table
              scroll={{ y: 700 }}
              columns={columns}
              loading={contentLoading}
              pagination={false}
              dataSource={complainResponse}
            />
          </div>
          {showVideo ? (
            <VideoStream
              tripId={videoObj.tripId}
              date={videoObj.date}
              cameraCode={videoObj.cameraCode}
              serviceName={videoObj.serviceName}
              busRc={videoObj.busRc}
              onCancel={() => this.setState({ showVideo: false })}
            />
          ) : null}
          {showCrewDetails ? (
            <CrewDetails
              details={trip[0].crewDetails}
              invent={trip[0].inventoryDetails}
              onCancel={() => this.setState({ showCrewDetails: false })}
            />
          ) : null}
        </div>
      );
    }
  }
}

export default CameraFeed;
