import React from "react";
import moment from "moment";
import restStationIcon from "../../images/restStation.png";
import styles from "./routeMap.module.scss";

const RouteMap = props => {
  const { route, cities } = props;

  const filterRoute = route.filter(
    stop =>
      stop.status === "ACTIVE" &&
      (stop.station.type === "STATION" || stop.station.type === "RESTSTOP")
  );

  const citiesHash = {};

  for (let i = 0; i < cities.length; ++i) {
    const { _id, name } = cities[i];
    citiesHash[_id] = name;
  }

  return (
    <div id="timeline" className="container py-2">
      {filterRoute.map((stops, index) => {
        const { reachingTime, station } = stops;
        const time = moment(reachingTime).format("hh:mmA");

        return (
          <>
            <div className={styles.timelineTile} key={index}>
              <div className={styles.time}>{time}</div>
              <div className={styles.imageContainer}>
                {station.type === "STATION" ? null : (
                  <img src={restStationIcon} alt="restStation" />
                )}
              </div>
              <div className={styles.stationInfo}>
                <div className={styles.stationName}>
                  {`${station.name}, ${citiesHash[station.cityId]}`}
                </div>
              </div>
            </div>
            <hr className={styles.hr} />
          </>
        );
      })}
    </div>
  );
};

export default RouteMap;
