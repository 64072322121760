import React, { Component } from "react";
import { Button, Modal, Select } from "antd";

import Fetch from "../../utils/fetch";
const { Option } = Select;

class StationView extends Component {
  state = {
    loader: false,
    viewModal: false,
    selectedStation: []
  };

  getStationName = async () => {
    try {
      this.setState({ loader: true });
      const url = `/bus-tracker/stationName`;

      const options = {
        method: "get"
      };
      const response = await Fetch(url, options);
      if (response) {
        this.setState({ loader: false, station: response, viewModal: true });

        return response;
      } else {
        this.setState({ loader: false });

        return [];
      }
    } catch (err) {
      console.log(err);

      return [];
    }
  };
  handleChange = value => {
    const { station, selectedStation } = this.state;
    let id;
    value.forEach(stationName => {
      id = station.filter(obj => obj.name === stationName);
    });
    if (id.length) {
      selectedStation.push(id[0]._id);
    }
    this.setState(selectedStation);
  };

  handleSendClick = () => {
    const { onClick } = this.props;

    let { selectedStation } = this.state;

    onClick(selectedStation);

    this.setState({
      showInitiateComplain: false,
      selectedStation: [],
      viewModal: false,
      station: []
    });
  };

  render() {
    const { station, viewModal } = this.state;
    const { isloading } = this.props;
    return (
      <div>
        {viewModal ? (
          <Modal
            centered
            width={1500}
            title="Station View"
            footer={null}
            visible={true}
            onCancel={() =>
              this.setState({
                station: [],
                viewModal: false
              })
            }
          >
            <div className="row">
              <div className="col-3">Select Station Name</div>
              <div className="col-6 text-left">
                <Select
                  mode="multiple"
                  style={{ width: "100%" }}
                  placeholder="select one station"
                  onChange={this.handleChange}
                  optionLabelProp="label"
                >
                  {station.map(obj => (
                    <Option key={obj._id} value={obj.name} label={obj.name}>
                      <div>{obj.name}</div>
                    </Option>
                  ))}
                </Select>
              </div>
            </div>
            <Button
              type="primary"
              onClick={this.handleSendClick}
              style={{ margin: "1% 0" }}
            >
              Show ETA
            </Button>
          </Modal>
        ) : (
          <Button
            loading={isloading}
            style={{ width: "150px" }}
            onClick={this.getStationName}
          >
            Stations View
          </Button>
        )}
      </div>
    );
  }
}

export default StationView;
