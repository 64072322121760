import React, { Component } from "react";
import { Table, Tooltip, message } from "antd";
import Loader from "../../loader";
import Fetch from "../../../utils/fetch";
import Search from "../Inter_IntraSearch";
import moment from "moment";
import less from "./inter.module.scss";
import { getSeatNumberFromTripChart } from "../../../utils/common";

const columns = [
  {
    title: "Service Name",
    dataIndex: "name",
    key: "name",
    width: "10%",
    render: ({ value }) => (
      <div>
        <span style={{ wordBreak: "break-word" }}>{value}</span>
      </div>
    )
  },
  {
    title: "Start Time",
    dataIndex: "time",
    key: "time",
    width: "10%"
  },
  {
    title: "Change Station",
    dataIndex: "changeStation",
    key: "changeStation",
    width: "10%",
    render: ({ value }) => (
      <div>
        <span style={{ wordBreak: "break-word" }}>{value}</span>
      </div>
    )
  },
  {
    title: "Seats",
    dataIndex: "seats",
    key: "seats",
    width: "10%"
  },
  Table.EXPAND_COLUMN,
  {
    title: "Boarded / Not-Boarded",
    dataIndex: "countBnB",
    key: "countBnB",
    width: "10%"
  },
  {
    title: "Type",
    dataIndex: "type",
    key: "type",
    width: "10%"
  },
  {
    title: "Cutoff Time",
    dataIndex: "cutoffTime",
    key: "cutoffTime",
    width: "10%"
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    width: "10%",
    render: ({ value }) => (
      <div className={less.statusDiv}>
        <div
          className={
            value === "ACTIVE" ? less.statusActive : less.statusInActive
          }
        ></div>
        <span>{value}</span>
      </div>
    )
  },
  {
    title: "Actions",
    dataIndex: "actions",
    key: "actions",
    width: "10%",
    render: ({ markRead, count, _id }) => (
      <div className={less.editTable}>
        <span style={{ visibility: count == 0 ? "hidden" : "visible" }}>
          {count}
        </span>
        <img
          style={{ visibility: count == 0 ? "hidden" : "visible" }}
          src="https://d1flzashw70bti.cloudfront.net/original/images/tracer/notification.svg"
          alt="bell"
        />
        <div className={less.expandDetail} onClick={() => markRead(_id)}>
          <span>Mark as read</span>
        </div>
      </div>
    )
  }
];

class OperatorCabTrip extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      tripData: [],
      expandedRows: [],
      addOpt: {},
      cabGroupId: ""
    };
  }

  async componentDidMount() {
    try {
      let stateObj = { loading: false };
      const tripData = await this.fetchData();
      if (tripData.tableData) {
        stateObj.tripData = tripData.tableData;
        stateObj.allResponse = tripData.response;
      }

      this.setState(stateObj);
    } catch (err) {
      console.log(err);
      this.setState({ loading: false });
    }
  }

  fetchData = async () => {
    try {
      const url = `/cabOperator/lastMileTrips`;
      const options = {
        method: "get"
      };
      const response = await Fetch(url, options);
      let data = [];
      if (response) {
        data = this.formatData(response);
      }
      return { response, tableData: data };
    } catch (err) {
      console.log(err);
      message.error(err);
    }
  };

  sortData = data => {
    const currentTime = Date.now();

    data.sort((a, b) => {
      if (a.status === "ACTIVE" && b.status !== "ACTIVE") {
        return -1;
      }
      if (a.status !== "ACTIVE" && b.status === "ACTIVE") {
        return 1;
      }
      const aCombinedStartTime = a.tripDate + a.service.startTime;
      const bCombinedStartTime = b.tripDate + b.service.startTime;

      const aIsFuture = aCombinedStartTime > currentTime;
      const bIsFuture = bCombinedStartTime > currentTime;

      if (aIsFuture && !bIsFuture) {
        return -1;
      }
      if (!aIsFuture && bIsFuture) {
        return 1;
      }

      return aCombinedStartTime - bCombinedStartTime;
    });

    return data;
  };

  countTotalSeatsInTrip(trip) {
    let totalSeats = 0;

    trip.bookings.forEach(booking => {
      totalSeats += booking.tripChart.length;
    });

    return totalSeats;
  }
  formatData = values => {
    const sortedData = this.sortData(values);
    let tableData = sortedData.map(value => {
      let seatCount = this.countTotalSeatsInTrip(value);
      let newAlerts = 0;
      let markedReadOn = value.markedReadOn ? value.markedReadOn : 0;
      if (value.bookings.length > 0) {
        newAlerts = value.bookings.filter(
          booking => parseInt(booking.createdOn) > parseInt(markedReadOn)
        ).length;
      }
      let data = {
        key: value._id,
        name: { value: value.service.name },
        changeStation: { value: value.changeStationName.name },
        time: `${moment(
          parseInt(value.tripDate) + parseInt(value.service.startTime)
        ).format("h:mm a, DD MMM YY")}`,
        seats: seatCount,
        countBnB: `${value.boarded}/${value.notBoarded}`,
        type:
          value.route[0].station.cityId ==
          value.route[value.route.length - 1].station.cityId
            ? "Intra"
            : "Inter",
        cutoffTime: value.service.cutOffTimeMins
          ? parseInt(value.service.cutOffTimeMins)
          : "NA",

        status: {
          value: value.service.status
        },
        actions: {
          _id: value._id,
          count: newAlerts,
          markRead: this.read
        },
        markedReadOn: value.markedReadOn,
        bookings: value.bookings
      };
      return data;
    });
    return tableData;
  };

  handleExpandedRowRender = record => {
    const isRowExpanded =
      this.state.expandedRows && this.state.expandedRows.includes(record.key);

    if (isRowExpanded) {
      const data = this.listData(record);
      return data;
    }

    return null;
  };

  handleRowExpand = (expanded, record) => {
    const { expandedRows } = this.state;

    let newExpandedRows;
    if (expanded) {
      newExpandedRows = [...expandedRows, record.key];
    } else {
      newExpandedRows = expandedRows.filter(key => key !== record.key);
    }

    this.setState({
      expandedRows: newExpandedRows
    });

    if (Object.keys(this.state.addOpt).length > 0 && !expanded) {
      this.setState({ addOpt: {} });
    }
  };

  listData = data => {
    const bookings = data.bookings;
    const markedReadOn = data.markedReadOn;
    let x = 1;
    if (!bookings) {
      return <span>No Booking found</span>;
    } else
      return bookings.map((booking, index) => (
        <div key={index} className={less.listData}>
          <div>
            <span>{x++}</span>{" "}
            <img
              style={{
                visibility:
                  parseInt(markedReadOn) > parseInt(booking.createdOn)
                    ? "hidden"
                    : "visible"
              }}
              src="https://d1flzashw70bti.cloudfront.net/original/images/tracer/notification.svg"
              alt="bell"
            />
          </div>

          <div className={less.stationDetail}>
            <div>
              <span>PNR : </span>
              <span>{booking.connectingBookingCode}</span>
            </div>
            <div>
              <span>Leg PNR : </span>
              <span>{booking.bookingCode}</span>
            </div>
          </div>
          <div className={less.stationDetail}>
            <div>
              <span>Pickup Station : </span>
              <span>{booking.fromStation.name}</span>
            </div>
            <div>
              <span>Drop Station : </span>
              <span>{booking.toStation.name}</span>
            </div>
          </div>
          <div className={less.stationDetail}>
            <div>
              <span>Pickup Time : </span>
              <span>
                {moment(parseInt(booking.fromStationDate)).format("h:mm a")}
              </span>
            </div>
            <div>
              <span>Drop Time : </span>
              <span>
                {" "}
                {moment(parseInt(booking.toStationDate)).format("h:mm a")}
              </span>
            </div>
          </div>
          <div>
            <span>Seats : </span>
            <span>{getSeatNumberFromTripChart(booking.tripChart) || "NA"}</span>
          </div>

          <div className={less.status}>
            <span
              style={{
                color: booking.status == "CANCELLED" ? "#ED1E21" : "#69AC39"
              }}
            >
              {booking.status}{" "}
            </span>
          </div>

          <div>
            <span>{this.assignTrip(booking._id, booking)}</span>
          </div>
        </div>
      ));
  };

  assignTrip = (_id, booking) => {
    const { addOpt } = this.state;
    const groupCode =
      booking && booking.cabBookingGroup
        ? booking.cabBookingGroup.groupCode
        : "";
    const fromStationDate = booking.fromStationDate;

    if (booking.cabBookingGroupId) {
      return (
        <div className={less.isAssignedDiv}>
          <div>
            <img
              src="https://d1flzashw70bti.cloudfront.net/original/test/tracer/accept.svg"
              alt="accept"
            />
            <span>Assigned</span>
          </div>
          <img
            onClick={() => this.deleteBooking(_id, booking.cabBookingGroupId)}
            src="https://d1flzashw70bti.cloudfront.net/original/test/tracer/trash.svg"
            alt="trash"
          />
          <Tooltip
            title={this.operatorDetails(groupCode)}
            color={"#1b8ef8"}
            placement="left"
            trigger={"click"}
          >
            <img
              src="https://d1flzashw70bti.cloudfront.net/original/images/tracer/tooltip.svg"
              alt="tooltip"
            />
          </Tooltip>
        </div>
      );
    } else {
      if (!addOpt[_id]) {
        return (
          <div
            className={less.addOptBtn}
            onClick={() =>
              this.setState({ addOpt: { ...addOpt, [_id]: true } })
            }
          >
            <span>Assign Cab Trip</span>
          </div>
        );
      } else {
        return (
          <div className={less.assignTrip}>
            <input
              value={addOpt[_id].cabGroupId || ""}
              placeholder="Enter Trip ID here"
              onChange={e =>
                this.setState({
                  addOpt: { ...addOpt, [_id]: { cabGroupId: e.target.value } }
                })
              }
            />
            <button onClick={() => this.assignCabTrip(_id, fromStationDate)}>
              <span>Assign</span>
            </button>
          </div>
        );
      }
    }
  };

  deleteBooking = async (bookingId, cabGroupId) => {
    const { allResponse } = this.state;
    let body = {
      cabBookingGroupId: cabGroupId,
      bookingIdsToRemove: [bookingId]
    };
    const url = `/cabOperator/removeTripToBooking`;

    const options = {
      method: "post",
      data: { details: body }
    };
    const BookingResponse = await Fetch(url, options);
    if (Array.isArray(BookingResponse)) {
      allResponse.forEach(response => {
        response.bookings.forEach(booking => {
          if (booking._id === bookingId) {
            delete booking.cabBookingGroupId;
          }
        });
      });

      const data = this.formatData(allResponse);

      this.setState({
        tripData: data,
        allResponse
      });
      message.success("Booking is Removed");
    } else {
      message.error(BookingResponse);
    }
  };

  assignCabTrip = async (bookingId, time) => {
    const { addOpt, allResponse } = this.state;
    let body = {
      cabBookingGroupId: addOpt[bookingId].cabGroupId,
      coreBookingIds: [bookingId],
      time
    };
    const url = `/cabOperator/assignTripToBooking`;

    const options = {
      method: "post",
      data: { details: body }
    };
    const bookingResponse = await Fetch(url, options);
    if (Array.isArray(bookingResponse)) {
      allResponse.forEach(response => {
        response.bookings.forEach(booking => {
          if (booking._id === bookingId) {
            booking.cabBookingGroupId = bookingResponse[0]._id;
          }
        });
      });
      const data = this.formatData(allResponse);

      this.setState({
        addOpt: { ...addOpt, [bookingId]: { cabGroupId: "" } },
        tripData: data,
        allResponse
      });
      message.success("Cab is Attached");
    }
  };

  operatorDetails = groupCode => {
    return (
      <div className={less.Tooltip}>
        <p>ID</p>
        {/* <p>{cabBookingGroupId}</p> */}
        <p>{groupCode}</p>
        <img
          onClick={() => this.copyData(groupCode)}
          src="https://d1flzashw70bti.cloudfront.net/original/test/tracer/copywhite.svg"
          alt="copywhite"
        />
      </div>
    );
  };

  copyData = value => {
    navigator.clipboard
      .writeText(value)
      .then(() => {
        message.success("Value copied: " + value);
      })
      .catch(err => {
        console.error("Error copying text: ", err);
      });
  };

  read = async id => {
    const { allResponse } = this.state;
    const url = `/cabOperator/updateMileTrip`;
    const markedReadOn = Date.now();
    const options = {
      method: "patch",
      data: { id, markedReadOn }
    };

    const tripResp = await Fetch(url, options);

    if (Array.isArray(tripResp) && tripResp.length > 0) {
      tripResp.forEach(trip => {
        const matchingResponse = allResponse.find(
          response => response._id === trip._id
        );

        if (matchingResponse) {
          matchingResponse.markedReadOn = markedReadOn;
        }
      });

      const data = this.formatData(allResponse);

      this.setState({
        tripData: data,
        allResponse
      });
    }
  };

  searchData = response => {
    const data = this.formatData(response);
    this.setState({
      tripData: data,
      allResponse: response
    });
  };

  render() {
    const { loading, contentLoading, tripData } = this.state;
    const { cities } = this.props;
    if (loading) {
      return <Loader />;
    } else {
      return (
        <div>
          <div>
            <Search
              showLoader={this.showLoading}
              cities={cities}
              onSearch={this.searchData}
            />
          </div>
          <hr />

          <Table
            scroll={{ y: "70vh" }}
            loading={contentLoading}
            columns={columns}
            pagination={false}
            dataSource={tripData}
            expandable={{
              expandedRowRender: record => this.handleExpandedRowRender(record),
              expandIcon: ({ expanded, onExpand, record }) => (
                <div
                  className={less.expandDetail}
                  onClick={() => onExpand(record, !expanded)}
                >
                  <img
                    src={
                      expanded
                        ? "https://d1flzashw70bti.cloudfront.net/original/images/tracer/caretdown.svg"
                        : "https://d1flzashw70bti.cloudfront.net/original/images/bookingdown.svg"
                    }
                    alt="show/hide"
                    style={{
                      width: 20,
                      height: 20
                    }}
                  />
                </div>
              ),
              onExpand: this.handleRowExpand,
              columnWidth: "5%"
            }}
          />
        </div>
      );
    }
  }
}

export default OperatorCabTrip;
