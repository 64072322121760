export const getHoursAndMinFromMillis = mills => {
  if (mills) {
    let hours = mills / (60 * 60 * 1000);
    hours = Math.floor(hours);

    let mins = mills % (60 * 60 * 1000);
    mins = Math.floor(mins / (60 * 1000));

    if (!hours) {
      return `${mins}mins`;
    }

    if (!mins) {
      return `${hours}hr`;
    }

    return `${hours}hr ${mins}mins `;
  } else {
    return "- -";
  }
};

export const getAllStationBeforeUpcomingStation = (
  upcomingStationId,
  busRoute = []
) => {
  const stationIdArr = [];

  for (let i = 0; i < busRoute.length; ++i) {
    const stationId = busRoute[i].stationId;

    if (stationId === upcomingStationId) {
      return stationIdArr;
    }

    stationIdArr.push(stationId);
  }

  return [];
};

export const isDeviceAndroid = () =>
  navigator.userAgent.toLowerCase().indexOf("android") !== -1;

export const getWayPoints = (busRoute, station1, station2) => {
  let station1Index, station2Index;

  for (let i = 0; i < busRoute.length; ++i) {
    const b = busRoute[i];

    if (b.stationId === station1) {
      station1Index = i;
    }

    if (b.stationId === station2) {
      station2Index = i;
    }
  }
  const wayPoints = busRoute.slice(station1Index, station2Index + 1);

  return wayPoints.map(w =>
    w.locationType === "OPPOSITE"
      ? {
          ...w,
          ...w.station,
          coordinates: { lat: w.station.oppLat, lng: w.station.oppLng }
        }
      : {
          ...w,
          ...w.station,
          coordinates: { lat: w.station.lat, lng: w.station.lng }
        }
  );
};

export const getStationFromStationId = (stationId, busRoute) => {
  for (let i = 0; i < busRoute.length; ++i) {
    const station = busRoute[i];

    if (station.stationId === stationId) {
      return {
        ...station,
        coordinates: { lat: station.station.lat, lng: station.station.lng }
      };
    }
  }
};

export const getSeatNumberFromTripChart = tripChart => {
  let seatsLabel = "";
  if (tripChart && tripChart.length > 0) {
    for (let i = 0; i < tripChart.length; ++i) {
      const seat = tripChart[i];
      if (seat.status === "CONFIRMED") {
        const seatNumber = seat.seat ? seat.seat.seatLabel : seat.seatLabel;
        const seatGender = seat.gender ? seat.gender : "";

        if (seatsLabel.length === 0) {
          seatsLabel += `${seatNumber}(${seatGender})`;
        } else {
          seatsLabel += `,${seatNumber}(${seatGender})`;
        }
      }
    }
  }
  return seatsLabel;
};

export const getBrowserVisibilityProp = () => {
  if (typeof document.hidden !== "undefined") {
    return "hidden";
  } else if (typeof document.msHidden !== "undefined") {
    return "msHidden";
  } else if (typeof document.webkitHidden !== "undefined") {
    return "webkitHidden";
  }
};

export const getBrowserVisibilityEvent = () => {
  if (typeof document.hidden !== "undefined") {
    return "visibilitychange";
  } else if (typeof document.msHidden !== "undefined") {
    return "msvisibilitychange";
  } else if (typeof document.webkitHidden !== "undefined") {
    return "webkitvisibilitychange";
  }
};

export const debounce = (fun, delay) => {
  // global arguments

  let timeoutId;

  function delayedFunction() {
    const context = this;
    const args = arguments;

    clearTimeout(timeoutId);

    const later = function() {
      return fun.apply(context, args);
    };

    timeoutId = setTimeout(later, delay);
  }

  return delayedFunction;
};
