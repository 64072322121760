import React, { Component } from "react";
import { Modal, Button, message, Input, AutoComplete } from "antd";
import Fetch from "../../utils/fetch";
import jwt from "jsonwebtoken";

class EditBooking extends Component {
  state = {
    loading: true,
    station: [],
    selectedStationId: "",
    input: ""
  };

  async componentDidMount() {
    try {
      const { editField } = this.props;

      if (editField === "fromStation" || editField === "toStation") {
        // const stationMap = {};

        const tripRoute = await this.fetchTripRoute();
        // for (let i = 0; i < tripRoute.length; ++i) {
        //   const route = tripRoute[i];
        //   stationMap[route[editField].name] = route[editField];
        // }
        // this.setState({ stations: Object.values(stationMap), loading: false });
        this.setState({ stations: tripRoute, loading: false });
      } else {
        this.setState({ loading: false });
      }
    } catch (err) {
      message.error(err);
    }
  }

  fetchTripRoute = async () => {
    try {
      const { booking } = this.props;

      let url = `/bus-tracker/booking/stations/?tripId=${booking.tripId}&fromCityId=${booking.fromCityId}&toCityId=${booking.toCityId}&fromStationDate=${booking.fromStationDate}&toStationDate=${booking.toStationDate}`;

      const options = {
        method: "get"
      };

      const response = await Fetch(url, options);

      if (response) {
        return response;
      }
    } catch (err) {
      message.error(err);
    }
  };

  updateStation = async () => {
    try {
      const { booking, editField, editType } = this.props;
      const { selectedStationId } = this.state;
      if (!selectedStationId) {
        message.error("Please select a station");
        return;
      }
      let cityType, response;

      if (editField === "fromStation") {
        cityType = "fromCity";
      } else if (editField === "toStation") {
        cityType = "toCity";
      }
      let notificationbody = {
        tripId: booking.tripId,
        name: booking.name,
        tripStatus: booking.trip.status,
        fromStationTime: booking.fromStationDate
      };
      if (editType == "cargo") {
        const url = `/bus-tracker/cargoBooking/stations`;
        const options = {
          method: "patch",
          data: {
            selectedCargo: booking,
            cityType,
            pickedStationId: selectedStationId,
            notificationbody
          }
        };

        response = await Fetch(url, options);
      } else {
        const url = `/bus-tracker/booking/stations`;

        const options = {
          method: "patch",
          data: {
            demandSource: booking.demandSource,
            bookingCode: booking.bookingCode,
            cityType,
            pickedStationId: selectedStationId
          }
        };

        response = await Fetch(url, options);
      }

      if (response) {
        message.success("Station Updated");
        this.afterUpdate();
      }
    } catch (err) {
      console.log(err);
      message.error(err);
    }
  };

  handleChange = (key, value) => {
    this.setState({ [key]: value });
  };

  isInputValid = () => {
    const { editField } = this.props;
    const { input } = this.state;

    switch (editField) {
      case "mobileNo": {
        if (input.length !== 10) {
          message.error("Mobile number is not valid");

          return false;
        }

        return true;
      }

      default: {
        return true;
      }
    }
  };

  updateBooking = async () => {
    try {
      let { editField, booking, editType } = this.props;
      const { input } = this.state;
      if (editType == "cargo") {
        if (this.isInputValid()) {
          const url = `/bus-tracker/cargoBooking`;
          editField =
            editField == "Sender mobileNo"
              ? "sender_MobileNo"
              : editField == "Receiver mobileNo"
              ? "reciever_MobileNo"
              : editField;

          const options = {
            method: "patch",
            data: {
              cargoSelected: booking,
              [editField]: input
            }
          };

          if (editField === "remark") {
            const token = localStorage.getItem("idToken");
            const decodedToken = jwt.decode(token);
            let remark = booking.remark ? `${booking.remark} | ` : "";

            options.data[editField] = `${remark}${decodedToken.name}: ${input}`;
          }

          const response = await Fetch(url, options);

          if (response) {
            message.success("Booking Updated");
            this.afterUpdate();
          }
        }
      } else {
        let notificationbody = {
          tripId: booking.tripId,
          name: booking.name,
          tripStatus: booking.trip.status,
          isOnboarded: booking.isOnboarded,
          fromStationTime: booking.fromStationDate,
          hasCalled: booking.callDetails.length > 0
        };
        if (this.isInputValid()) {
          const url = `/bus-tracker/booking`;

          const options = {
            method: "patch",
            data: {
              demandSource: booking.demandSource,
              bookingId: booking._id,
              [editField]: input,
              notificationbody
            }
          };

          if (editField === "remark") {
            const token = localStorage.getItem("idToken");
            const decodedToken = jwt.decode(token);
            let remark = booking.remark ? `${booking.remark} | ` : "";

            options.data[editField] = `${remark}${decodedToken.name}: ${input}`;
          }

          const response = await Fetch(url, options);

          if (response) {
            message.success("Booking Updated");
            this.afterUpdate();
          }
        }
      }
    } catch (err) {
      console.log(err);
      message.error(err);
    }
  };

  afterUpdate = () => {
    const { onChange, closeParentModal } = this.props;

    onChange({
      editField: "",
      bookingToEdit: {},
      editBooking: false
    });

    closeParentModal();
  };

  render() {
    const { stations, loading, input } = this.state;
    const { onChange, editField, editType } = this.props;
    const name = `EDIT ${editField}`;

    return (
      <Modal
        title={name}
        visible={true}
        onCancel={() =>
          onChange({ editBooking: false, bookingToEdit: {}, editField: "" })
        }
        footer={null}
      >
        {loading ? (
          "...Loading"
        ) : editType == "cargo" ? (
          editField === "fromStation" || editField === "toStation" ? (
            <>
              <AutoComplete
                style={{
                  width: 200
                }}
                dataSource={stations.map(station => station.station.name)}
                onChange={value => {
                  let id = stations.filter(
                    station => station.station.name === value
                  );
                  if (id.length) {
                    this.setState({
                      selectedStationId: id[0].station._id
                    });
                  } else {
                    this.setState({
                      selectedStationId: null
                    });
                  }
                }}
                filterOption={(inputValue, option) =>
                  option.props.children
                    .toUpperCase()
                    .indexOf(inputValue.toUpperCase()) !== -1
                }
              />
              <Button type="primary" onClick={this.updateStation}>
                Update
              </Button>
            </>
          ) : (
            <>
              <Input
                type={editField === "Sender mobileNo" ? "number" : "text"}
                value={input}
                style={{ width: 200 }}
                onChange={e => this.handleChange("input", e.target.value)}
              />
              <Button type="primary" onClick={this.updateBooking}>
                Update
              </Button>
            </>
          )
        ) : editField === "fromStation" || editField === "toStation" ? (
          <>
            <AutoComplete
              style={{
                width: 200
              }}
              dataSource={stations.map(station => station.station.name)}
              onChange={value => {
                let id = stations.filter(
                  station => station.station.name === value
                );

                if (id.length) {
                  this.setState({
                    selectedStationId: id[0].station._id
                  });
                } else {
                  this.setState({
                    selectedStationId: null
                  });
                }
              }}
              filterOption={(inputValue, option) =>
                option.props.children
                  .toUpperCase()
                  .indexOf(inputValue.toUpperCase()) !== -1
              }
            />

            <Button type="primary" onClick={this.updateStation}>
              Update
            </Button>
          </>
        ) : (
          <>
            <Input
              type={editField === "mobileNo" ? "number" : "text"}
              value={input}
              style={{ width: 200 }}
              onChange={e => this.handleChange("input", e.target.value)}
            />
            <Button type="primary" onClick={this.updateBooking}>
              Update
            </Button>
          </>
        )}
      </Modal>
    );
  }
}

export default EditBooking;
