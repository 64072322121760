import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Modal, Tabs, Table, Button, message, Tag, Select } from "antd";
import { EditOutlined, PlayCircleOutlined } from "@ant-design/icons";
import moment from "moment";
import Fetch from "../../utils/fetch";
import SendMessage from "../sendMessage";
import SendRefund from "../sendRefund";
import EditBooking from "../editBooking";

const wordBreakStyle = { wordBreak: "breakAll" };
const { Option } = Select;

const { TabPane } = Tabs;

const columns = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    width: "8%",
    render: data => <div style={wordBreakStyle}>{data}</div>
  },
  {
    title: "Mobile Number",
    dataIndex: "mobileNo",
    key: "mobileNo",
    width: "8%",
    render: ({ value, onChange }) => (
      <>
        <EditOutlined onClick={onChange} />
        <span style={wordBreakStyle}>{value}</span>
      </>
    )
  },
  {
    title: "PNR",
    dataIndex: "pnr",
    key: "pnr",
    width: "8%"
  },
  {
    title: "Seats",
    dataIndex: "seats",
    key: "seats",
    width: "8%",
    render: data => <div style={wordBreakStyle}>{data}</div>
  },
  {
    title: "From Station",
    dataIndex: "fromStation",
    key: "fromStation",
    width: "8%",
    render: ({ name, selectedLeg, onChange }) => (
      <>
        {selectedLeg == "firstLeg" ? <EditOutlined onClick={onChange} /> : null}
        <span style={wordBreakStyle}>{name}</span>
      </>
    )
  },
  {
    title: "To Station",
    dataIndex: "toStation",
    key: "toStation",
    width: "8%",
    render: ({ name, selectedLeg, onChange }) => (
      <>
        {selectedLeg == "secondLeg" ? (
          <EditOutlined onClick={onChange} />
        ) : null}
        <span style={wordBreakStyle}>{name}</span>
      </>
    )
  },
  {
    title: "Cash Collection",
    dataIndex: "amountToBeCollected",
    key: "amountToBeCollected",
    width: "8%",
    render: ({ value, agentId, onChange }) =>
      agentId ? (
        <>
          <EditOutlined onClick={onChange} />
          <span style={wordBreakStyle}>{value}</span>
        </>
      ) : (
        <span style={wordBreakStyle}>{value}</span>
      )
  },

  {
    title: "Call attempted",
    dataIndex: "callAttempted",
    key: "callAttempted",
    width: "8%"
  },
  {
    title: "Outgoing Call records",
    dataIndex: "callRecords",
    key: "callRecords",
    width: "8%",
    render: (data = []) => {
      if (data.length) {
        return data.map((call, index) => (
          <div key={index} className="mb-1">
            {call.recording ? (
              <>
                <Link to={call.recording} target="_blank">
                  <PlayCircleOutlined />
                </Link>
                <br></br>
                {call.date}
              </>
            ) : (
              <div>
                <Tag>{call.status}</Tag>
                <br></br>
                <Tag>{call.date}</Tag>
              </div>
            )}
          </div>
        ));
      } else {
        return <Tag color="red">No Call Records</Tag>;
      }
    }
  },
  {
    title: "Incoming CallRecords",
    dataIndex: "incomingCallDetails",
    key: "incomingCallDetails",
    width: "8%",
    render: (data = []) => {
      if (data.length) {
        return data.map((call, index) => (
          <div key={index} className="mb-1">
            {call.recording ? (
              <>
                <Link to={call.recording} target="_blank">
                  <PlayCircleOutlined />
                </Link>
                <br></br>
                {call.date}
              </>
            ) : (
              <div>
                <Tag>{call.status}</Tag>
                <br></br>
                <Tag>{call.date}</Tag>
              </div>
            )}
          </div>
        ));
      } else {
        return <Tag color="red">No Call Records</Tag>;
      }
    }
  },
  {
    title: "Remark",
    dataIndex: "remark",
    key: "remark",
    width: "8%",
    render: ({ value = "", onChange }) => {
      if (!value) {
        value = "";
      }
      value = value.split("|");

      let remarkStr = "";

      for (let i = 0; i < value.length; ++i) {
        remarkStr += value[i];
      }

      return (
        <>
          <EditOutlined onClick={onChange} />
          {remarkStr.length ? (
            <span style={wordBreakStyle}>{remarkStr}</span>
          ) : (
            <span>No Remark</span>
          )}
        </>
      );
    }
  },
  {
    title: "Action History",
    dataIndex: "actionHistory",
    key: "actionHistory",
    width: "8%"
  },
  {
    title: "Action",
    dataIndex: "action",
    key: "action",
    width: "8%",
    render: ({ tripId, customerTrackingLink, copyCustomerTrackingLink }) => {
      return (
        <>
          <Link to={`/track/${tripId}`} target="_blank">
            <Button className="mb-1">Track Bus</Button>
          </Link>
          <a href={customerTrackingLink} rel="noreferrer" target="_blank">
            <Button className="mb-1">Customer Link</Button>
          </a>
          <Button onClick={copyCustomerTrackingLink}>
            Share Customer Link
          </Button>
        </>
      );
    }
  }
];

class bookingConnectingList extends Component {
  state = {
    loading: true,
    bookings: [],
    boardingList: [],
    notBoardedList: [],
    selectedBookings: [],
    selectedRowKeys: [],
    upcomingList: [],
    activeKey: "1",
    editBooking: false,
    bookingToEdit: {},
    bookingEdit: false,
    field: "",
    updatedField: "",
    closeBookingList: false,
    trackingWorking: true,
    selectedLeg: "firstLeg"
  };

  inputRef = React.createRef();

  async componentDidMount() {
    try {
      const { selectedLeg } = this.state;
      const { tripId } = this.props;
      let url = `/bus-tracker/connectingTripBookings/${tripId}`;

      const options = {
        method: "get"
      };

      let response = await Fetch(url, options);

      if (response) {
        await this.setBookingData(response, selectedLeg);
      } else {
        this.setState({ loading: false });
      }
    } catch (err) {
      this.setState({ loading: false });
      console.log(err);
    }
  }

  setBookingData = (response, selectedLeg) => {
    try {
      const boardingList = [],
        notBoardedList = [],
        upcomingList = [];
      const markedStationMap = {};
      response = response.sort((a, b) => a.fromStationDate - b.fromStationDate);

      for (let i = 0; i < response.length; ++i) {
        const booking =
          selectedLeg == "secondLeg" ? response[i].booking : response[i];

        if (booking.status === "CONFIRMED") {
          let seatsLabel = "";

          for (let i = 0; i < booking.tripChart.length; ++i) {
            const seat = booking.tripChart[i];
            if (seat.status === "CONFIRMED") {
              const seatNumber = seat.seat.seatLabel;
              const seatGender = seat.gender ? seat.gender : "";

              if (seatsLabel.length === 0) {
                seatsLabel += `${seatNumber}(${seatGender})`;
              } else {
                seatsLabel += `,${seatNumber}(${seatGender})`;
              }
            }
          }

          const bookingObj = {
            key: booking._id,
            name: booking.name,
            mobileNo: {
              value: booking.mobileNo,
              onChange: () =>
                this.setState({
                  editField: "mobileNo",
                  editBooking: true,
                  bookingToEdit: booking
                })
            },
            refunds: {
              data: booking.refund ? booking.refund : null
            },
            pnr: booking.bookingCode,
            status: booking.status,
            seats: seatsLabel,
            fromStation: {
              name: `${booking.fromStation.name}, ${booking.fromCity.name}`,
              selectedLeg: selectedLeg,
              onChange: () =>
                this.setState({
                  editField: "fromStation",
                  editBooking: true,
                  bookingToEdit: booking
                })
            },
            toStation: {
              name: `${booking.toStation.name}, ${booking.toCity.name}`,
              selectedLeg: selectedLeg,
              onChange: () =>
                this.setState({
                  editField: "toStation",
                  editBooking: true,
                  bookingToEdit: booking
                })
            },
            amountToBeCollected: {
              value: booking.amountToBeCollected,
              agentId: booking.agentId ? booking.agentId : null,
              onChange: () =>
                this.setState({
                  editField: "amountToBeCollected",
                  editBooking: true,
                  bookingToEdit: booking
                })
            },
            _id: booking._id,
            callAttempted:
              selectedLeg == "secondLeg" ? 0 : booking.callDetails.length,
            callRecords: selectedLeg == "secondLeg" ? [] : booking.callDetails,
            incomingCallDetails:
              selectedLeg == "secondLeg" ? [] : booking.incomingCallDetails,
            remark: {
              value: booking.remark,
              onChange: () =>
                this.setState({
                  editField: "remark",
                  editBooking: true,
                  bookingToEdit: booking
                })
            },
            action: {
              tripId: booking.tripId,
              customerTrackingLink: `http://${booking.trackingUrl}`,
              copyCustomerTrackingLink: () =>
                this.copyCustomerTrackingLink(booking.trackingUrl)
            },
            trackingUrl: booking.trackingUrl,
            booking,
            actionHistory: booking.actionHistory || ""
          };
          if (booking.trip.status === "COMPLETED") {
            if (booking.isOnboarded) {
              bookingObj.time = moment(booking.boardingDate).format(
                "Do MMMM YYYY, h:mm:ss a"
              );
              boardingList.push(bookingObj);
            } else {
              notBoardedList.push(bookingObj);
            }
          } else if (booking.isOnboarded) {
            bookingObj.time = moment(booking.boardingDate).format(
              "Do MMMM YYYY, h:mm:ss a"
            );
            boardingList.push(bookingObj);
          }
          // cx will be not boarded only after its boarding time is passed
          else if (
            markedStationMap[booking.fromStationId] &&
            booking.fromStationDate <= Date.now()
          ) {
            notBoardedList.push(bookingObj);
          } else {
            upcomingList.push(bookingObj);
          }
        }
      }

      this.setState({
        loading: false,
        bookings: response,
        boardingList,
        notBoardedList,
        upcomingList
      });
    } catch (error) {
      console.log(error);
    }
  };
  copyCustomerTrackingLink = customerTrackingLink => {
    this.inputRef.current.value = customerTrackingLink;
    this.inputRef.current.select();

    document.execCommand("copy");
    message.success("Customer Link copied to clipboard");
  };

  handleChange = async (key, value) => {
    const { bookings } = this.state;
    this.setState({ [key]: value });
    this.setBookingData(bookings, value);
  };

  showBooking = bookings => {
    return bookings.map(booking => (
      <div key={booking._id}>
        <span>{`${booking.name}(${booking.bookingCode})`}</span>
      </div>
    ));
  };

  sendMessage = async (
    templateType,
    vars,
    sendSMSMessage,
    sendWhatsAppMessage
  ) => {
    try {
      const { selectedBookings } = this.state;

      if (selectedBookings.length) {
        const url = `/bus-tracker/sendmessageandwhatsapp`;

        const options = {
          method: "post",
          data: {
            bookings: selectedBookings.map(b => b.booking),
            templateType,
            vars,
            sendSMSMessage,
            sendWhatsAppMessage
          }
        };

        const response = await Fetch(url, options);

        if (response) {
          message.success("Message sent successfully");
          this.setState({ selectedBookings: [], selectedRowKeys: [] });
        }
      } else {
        message.error("No Booking Selected");
      }
    } catch (err) {
      message.error(err);
    }
  };

  sendRefund = async (
    selectedCategory,
    subIssueId,
    issueId,
    categorys,
    issueComment
  ) => {
    try {
      const { selectedBookings } = this.state;
      let refundID = categorys[0]._id;
      let refundClassification = selectedCategory[0].refundClassification;

      if (selectedBookings.length) {
        const url = `/bus-tracker/sendRefund`;

        const options = {
          method: "post",
          data: {
            subIssueId: subIssueId,
            issueId: issueId,
            refundRuleId: refundID,
            refundClassification: refundClassification
              ? refundClassification
              : "NORMAL",
            bookingType: "ZINGBUS",
            comment: issueComment,
            bookings: selectedBookings
          }
        };
        const response = await Fetch(url, options);

        if (response) {
          message.success("Refund Initiated");
          this.setState({ selectedBookings: [], selectedRowKeys: [] });
        }
      } else {
        message.error("No Booking Selected");
      }
    } catch (err) {
      message.error(err);
    }
  };

  updateBoardingStatus = async () => {
    try {
      const {
        selectedBookings,
        activeKey,
        boardingList,
        notBoardedList,
        upcomingList
      } = this.state;

      const { upcomingStation } = this.props;

      const url = `/bus-tracker/booking/boarding`;

      const options = {
        method: "patch",
        data: {
          bookings: selectedBookings,
          type: activeKey === "1" ? "MARKUNBOARDED" : "MARKONBOARDED"
        }
      };

      const response = await Fetch(url, options);

      if (response) {
        const bookingMap = {};
        const updateStateObj = { selectedBookings: [], selectedRowKeys: [] };

        for (let i = 0; i < response.length; ++i) {
          const booking = response[i];
          bookingMap[booking._id] = booking;
        }

        if (activeKey === "1") {
          updateStateObj.notBoardedList = [...notBoardedList];
          updateStateObj.upcomingList = [...upcomingList];

          updateStateObj.boardingList = boardingList.filter(b => {
            if (
              bookingMap[b._id] &&
              bookingMap[b._id].fromStationId === upcomingStation._id
            ) {
              updateStateObj.upcomingList.push(b);
            } else {
              updateStateObj.notBoardedList.push(b);
            }

            return !bookingMap[b._id];
          });
        } else if (activeKey === "2") {
          updateStateObj.boardingList = [...boardingList];
          updateStateObj.notBoardedList = [...notBoardedList];

          updateStateObj.upcomingList = upcomingList.filter(b => {
            if (bookingMap[b._id]) {
              updateStateObj.boardingList.push(b);
            }

            return !bookingMap[b._id];
          });
        } else {
          updateStateObj.boardingList = [...boardingList];
          updateStateObj.upcomingList = [...upcomingList];

          updateStateObj.notBoardedList = notBoardedList.filter(b => {
            if (bookingMap[b._id]) {
              updateStateObj.boardingList.push(b);
            }

            return !bookingMap[b._id];
          });
        }

        message.success("Booking boarding status updated");
        this.setState(updateStateObj);
      }
    } catch (err) {
      message.error(err);
    }
  };

  render() {
    const {
      loading,
      boardingList,
      customerIssue,
      notBoardedList,
      activeKey,
      editBooking,
      editField,
      bookingToEdit,
      upcomingList,
      selectedBookings,
      selectedRowKeys = [],
      closeBookingList = false
    } = this.state;

    if (closeBookingList) {
      this.props.onCancel();
    }

    return (
      <Modal
        title="Booking List"
        visible={true}
        className="modalClass"
        onCancel={this.props.onCancel}
        footer={null}
        width={"100%"}
      >
        {loading ? (
          <h1>loading ...</h1>
        ) : (
          <>
            {/* input tag is for copying customer link */}
            <input
              ref={this.inputRef}
              readOnly={true}
              style={{ position: "absolute", left: "-9999px" }}
            />
            {editBooking ? (
              <EditBooking
                onChange={obj => this.setState(obj)}
                editField={editField}
                booking={bookingToEdit}
                closeParentModal={this.props.onCancel}
              />
            ) : null}
            <div className="bookingListFooter">
              <div className="bookingListFooterItemOne">
                <Select
                  defaultValue="firstLeg"
                  style={{ width: 200 }}
                  onChange={e => this.handleChange("selectedLeg", e)}
                >
                  <Option value="firstLeg">Leading Trip</Option>
                  <Option value="secondLeg">Trailing Trip</Option>
                </Select>
              </div>
              {selectedBookings.length > 0 ? (
                <div className="bookingListFooterItemOne">
                  <SendRefund
                    issues={customerIssue}
                    selectedBookings={selectedBookings}
                    onClick={this.sendRefund}
                    selectedCount={selectedBookings.length}
                  />
                </div>
              ) : null}
              <div className="bookingListFooterItemOne">
                <Button onClick={this.updateBoardingStatus} type="danger">
                  {activeKey === "1" ? "Mark Not Boarded" : "Mark Boarded"}
                </Button>
              </div>
              <div className="bookingListFooterItem2">
                <SendMessage
                  selectedBookings={selectedBookings}
                  onClick={this.sendMessage}
                  selectedCount={selectedBookings.length}
                />
              </div>
            </div>
            <br />
            <Tabs
              activeKey={activeKey}
              onChange={key =>
                this.setState({ activeKey: key, selectedBookings: [] })
              }
            >
              <TabPane
                tab={"Not Boarded List (" + notBoardedList.length + ")"}
                key="3"
              >
                <Table
                  columns={columns}
                  dataSource={notBoardedList}
                  pagination={false}
                  rowSelection={{
                    columnWidth: "4%",
                    selectedRowKeys,
                    onChange: (keys, booking) => {
                      this.setState({
                        selectedBookings: booking,
                        selectedRowKeys: keys
                      });
                    }
                  }}
                  scroll={{ y: 300, x: "200%" }}
                />
              </TabPane>
              <TabPane
                tab={"Boarded List (" + boardingList.length + ")"}
                key="1"
              >
                <Table
                  columns={columns}
                  dataSource={boardingList}
                  pagination={false}
                  rowSelection={{
                    columnWidth: "2%",
                    selectedRowKeys,
                    onChange: (keys, booking) => {
                      this.setState({
                        selectedBookings: booking,
                        selectedRowKeys: keys
                      });
                    }
                  }}
                  scroll={{ y: 300, x: "200%" }}
                />
              </TabPane>
              <TabPane
                tab={"Upcoming Boarding List (" + upcomingList.length + ")"}
                key="2"
              >
                <Table
                  columns={columns}
                  dataSource={upcomingList}
                  pagination={false}
                  rowSelection={{
                    columnWidth: "2%",
                    selectedRowKeys,
                    onChange: (keys, booking) => {
                      this.setState({
                        selectedBookings: booking,
                        selectedRowKeys: keys
                      });
                    }
                  }}
                  scroll={{ y: 300, x: "200%" }}
                />
              </TabPane>
            </Tabs>
          </>
        )}
      </Modal>
    );
  }
}

export default bookingConnectingList;
