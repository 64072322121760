import React, { Component } from "react";
import Fetch from "../../utils/fetch";

import stationPoints from "../../images/station_icon-01.png";
import reststopIcon from "../../images/reststop-01.png";
import virtualStopIcon from "../../images/virtual_stop-01.png";
import markedStationIcon from "../../images/marked_station_icon-01.png";

const google = window.google;

class Map extends Component {
  state = {
    loading: true
  };

  undoButtonRef = React.createRef();

  componentDidMount() {
    this.initMap();
  }

  componentDidUpdate(prevProps) {
    const update = { type: "", isRefreshRequired: false };

    const isGeofenceDataChanged =
      JSON.stringify(this.props.geoFenceData) !==
      JSON.stringify(prevProps.geoFenceData);

    if (isGeofenceDataChanged) {
      update.type = "geoFenceData";
      update.isRefreshRequired = true;
    }

    const isShowGeoFenceChanged =
      this.props.showGeoFence !== prevProps.showGeoFence;

    if (isShowGeoFenceChanged) {
      update.type = "showGeoFence";
      update.isRefreshRequired = true;
    }

    console.log("isRefreshRequired", update.isRefreshRequired);

    if (update.isRefreshRequired) {
      this.refreshMap(update);
    }
  }

  stationsMarker = [];
  geofenceShape = [];

  initMap = async () => {
    try {
      if (typeof google !== "undefined") {
        const {
          id,
          geoFenceData,
          drawMode,
          showGeoFence,
          center,
          currentGeofence
        } = this.props;

        // Initialize google map
        this.map = new google.maps.Map(document.getElementById(id), {
          center,
          zoom: 16,
          disableDefaultUI: true,
          zoomControl: true,
          fullscreenControl: true,
          gestureHandling: "greedy"
        });
        const busRoute = await this.getRoute();
        const routeWay = await this.getRouteWay(null, null, busRoute.route);

        const newPath = new google.maps.Polyline({
          path: routeWay,
          preserveViewport: true,
          suppressMarkers: true,
          strokeColor: "#7b2bff"
        });

        newPath.setMap(this.map);

        this.directionsService = new google.maps.DirectionsService();

        if (geoFenceData) {
          await this.plotGeofenceData(
            geoFenceData,
            currentGeofence,
            showGeoFence
          );
        }

        if (currentGeofence) {
          this.plotCurrentGeofence(currentGeofence);
        }

        if (drawMode) {
          const undoButtonElement = this.undoButtonRef.current;
          this.map.controls[google.maps.ControlPosition.TOP_RIGHT].push(
            undoButtonElement
          );

          const drawingManager = new google.maps.drawing.DrawingManager({
            drawingControl: true,
            drawingControlOptions: {
              position: google.maps.ControlPosition.TOP_RIGHT,
              drawingModes: [
                google.maps.drawing.OverlayType.CIRCLE,
                google.maps.drawing.OverlayType.POLYGON,
                google.maps.drawing.OverlayType.RECTANGLE
              ]
            },
            circleOptions: {
              editable: true
            },
            polygonOptions: {
              editable: true,
              draggable: true
            },
            rectangleOptions: {
              editable: true,
              draggable: true
            }
          });

          drawingManager.setMap(this.map);

          google.maps.event.addListener(
            drawingManager,
            "overlaycomplete",
            this.handleOverlayComplete
          );
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  getRoute = async () => {
    try {
      const { tripId } = this.props;
      const url = `/bus-tracker/route/${tripId}`;
      const options = {
        method: "get"
      };
      const response = await Fetch(url, options);
      if (response) {
        let { busRoute = [] } = response;

        const route = busRoute.map(stopStation => {
          return stopStation.locationType === "DEFAULT"
            ? {
                coordinates: {
                  lat: stopStation.station.lat,
                  lng: stopStation.station.lng
                }
              }
            : {
                coordinates: {
                  lat: stopStation.station.oppLat,
                  lng: stopStation.station.oppLng
                }
              };
        });

        return {
          route
        };
      }
    } catch (err) {
      console.log(err);
      return Promise.reject(err);
    }
  };

  getRouteWay = async (startPoint, endPoint, path = []) => {
    let route = await this.getDirections(startPoint, endPoint, path);
    return route;
  };

  refreshMap = async () => {
    try {
      const { geoFenceData, currentGeofence, showGeoFence } = this.props;

      this.deleteStationMarker();
      this.deleteGeofenceShape();
      this.deleteDirectionRenderer();

      await this.plotGeofenceData(geoFenceData, currentGeofence, showGeoFence);

      if (currentGeofence) {
        this.plotCurrentGeofence(currentGeofence);
      }
    } catch (err) {
      console.log(err);
    }
  };

  plotGeofenceData = async (geoFenceData, currentGeofence, showGeoFence) => {
    try {
      const route = this.getRouteFromGeofenceData(geoFenceData);

      const highlightStationMap = {};
      if (currentGeofence) {
        const { stationOne, stationTwo } = currentGeofence;

        if (stationOne) {
          highlightStationMap[stationOne.station._id] = 1;
        }

        if (stationTwo) {
          highlightStationMap[stationTwo.station._id] = 1;
        }
      }

      const { latlngBounds, stationsMarker } = this.createStationMarker(
        route,
        highlightStationMap
      );

      this.stationsMarker = stationsMarker;

      if (!currentGeofence) {
        this.map.fitBounds(latlngBounds);
      }

      // const directions = await this.getRouteDirections(route);
      // directions.forEach(d => this.plotRoute(d));

      if (showGeoFence) {
        this.geofenceShape = this.createGeofenceShape(geoFenceData);
      }
    } catch (err) {
      console.log(err);
    }
  };

  plotCurrentGeofence = currentGeofence => {
    const { stationOneCoordinate, stationTwoCoordinate } = currentGeofence;

    const latlngBounds = new google.maps.LatLngBounds();

    if (stationOneCoordinate) {
      latlngBounds.extend(stationOneCoordinate);
    }

    if (stationTwoCoordinate) {
      latlngBounds.extend(stationTwoCoordinate);
    }

    this.map.fitBounds(latlngBounds);
  };

  deleteStationMarker = () => {
    this.stationsMarker.forEach(station => station.marker.setMap(null));
    this.stationsMarker = [];
  };

  deleteDirectionRenderer = () => {
    this.directionsRenderer.setMap(null);
    this.directionsRenderer = null;
  };

  deleteGeofenceShape = () => {
    this.geofenceShape.forEach(g => g.setMap(null));
    this.geofenceShape = [];
  };

  getRouteFromGeofenceData = geofenceData => {
    const route = [];

    for (let i = 0; i < geofenceData.length; ++i) {
      const { stationOne } = geofenceData[i];

      if (stationOne) {
        route.push(stationOne);
      }
    }

    return route;
  };

  getLatLng = data => {
    if (data.lat && data.lng) {
      return { lat: data.lat, lng: data.lng };
    }

    if (
      data.locationType == "OPPOSITE" &&
      data.station &&
      data.station.oppLat &&
      data.station.oppLng
    ) {
      return { lat: data.station.oppLat, lng: data.station.oppLng };
    }
    if (
      data.locationType == "DEFAULT" &&
      data.station &&
      data.station.lat &&
      data.station.lng
    ) {
      return { lat: data.station.lat, lng: data.station.lng };
    }
  };

  createGeofenceShape = geofenceData => {
    return geofenceData.map(g => {
      switch (g.geofence.type) {
        case "circle": {
          const circleMarker = this.createCircle(g.geofence);

          return circleMarker;
        }

        case "rectangle": {
          const rectangleMarker = this.createReactangle(g.geofence.boundingBox);

          return rectangleMarker;
        }

        case "polygon": {
          const polygonMarker = this.createPolygon(g.geofence.coordinates);

          return polygonMarker;
        }
        default: {
          return null;
        }
      }
    });
  };

  createCircle = circle => {
    return new google.maps.Circle({
      strokeColor: "#FF0000",
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: "#FF0000",
      fillOpacity: 0.35,
      map: this.map,
      center: circle.center,
      radius: circle.radius
    });
  };

  createPolygon = coords => {
    const polygon = new google.maps.Polygon({
      paths: coords,
      strokeColor: "#00FF00",
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: "#00FF00",
      fillOpacity: 0.35
    });

    polygon.setMap(this.map);

    return polygon;
  };

  createReactangle = boundingBox => {
    const { topRight, bottomLeft } = boundingBox;

    const north = topRight.lat;
    const south = bottomLeft.lat;
    const east = topRight.lng;
    const west = bottomLeft.lng;

    const rectangle = new google.maps.Rectangle({
      strokeColor: "#FF0000",
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: "#FF0000",
      fillOpacity: 0.35,
      map: this.map,
      bounds: {
        north,
        south,
        east,
        west
      }
    });

    return rectangle;
  };

  plotRoute = route => {
    this.directionsRenderer = new google.maps.DirectionsRenderer({
      preserveViewport: true,
      suppressMarkers: true
    });

    this.directionsRenderer.setMap(this.map);
    this.directionsRenderer.setDirections(route);
  };

  getDirections = async (startPoint, endPoint, path = []) => {
    const url = `/bus-tracker/routeOSRM`;
    const options = {
      method: "post",
      data: {
        startPoint,
        endPoint,
        path
      }
    };
    const response = await Fetch(url, options);
    return response;
  };

  getRouteDirections = async path => {
    const completePairRoute = Math.floor(path.length / 25);
    const remainingRoutePoints = path.length % 25;
    const directionsArray = [];

    for (let i = 0; i < completePairRoute; ++i) {
      const start = i * 25;
      const end = (i + 1) * 25 - 1;

      let waypoints = path.slice(start + 1, end);

      waypoints = waypoints.map(points => ({
        location: this.getLatLng(points),
        stopover: true
      }));

      const startPoint = this.getLatLng(path[start]);
      const endPoint = this.getLatLng(path[end]);

      directionsArray.push(this.getDirections(startPoint, endPoint, waypoints));
    }

    if (remainingRoutePoints) {
      // add remainingRoutePoints to waypoints and make request
      let start = completePairRoute * 25 - 1;
      const end = start + remainingRoutePoints;

      if (start < 0) {
        start = 0;
      }

      let waypoints = path.slice(start + 1, end);

      waypoints = waypoints.map(points => ({
        location: this.getLatLng(points),
        stopover: true
      }));

      const startPoint = this.getLatLng(path[start]);
      const endPoint = this.getLatLng(path[end]);

      directionsArray.push(this.getDirections(startPoint, endPoint, waypoints));
    }

    return Promise.all(directionsArray);
  };

  createStationMarker = (busRoute, highlightStationMap = {}) => {
    try {
      const latlngBounds = new google.maps.LatLngBounds();
      const stationsMarker = busRoute.map(s => {
        const { stationType, _id } = s.station;
        let icon = stationPoints;

        if (stationType === "RESTSTOP") {
          icon = reststopIcon;
        }

        if (stationType === "VIRTUALSTOP") {
          icon = virtualStopIcon;
        }

        if (highlightStationMap[_id]) {
          icon = markedStationIcon;
        }

        const position = this.getLatLng(s);

        const stationMarker = new google.maps.Marker({
          position,
          title: `${s.station.name}`,
          icon: icon,
          map: this.map
        });

        latlngBounds.extend(position);

        const infoWindow = new google.maps.InfoWindow({
          content: `<p><b>${s.station.name}</b></p>`
        });

        // eslint-disable-next-line no-loop-func
        stationMarker.addListener("click", () => {
          infoWindow.open(this.map, stationMarker);
        });

        return {
          stationId: s.station._id,
          marker: stationMarker,
          infoWindow,
          station: s.station
        };
      });

      return { latlngBounds, stationsMarker };
    } catch (err) {
      console.log(err);
    }
  };

  getCircleCoordinates = circle => {
    const radius = Math.round(circle.getRadius());
    const center = {
      lat: circle.getCenter().lat(),
      lng: circle.getCenter().lng()
    };

    const data = { type: "circle", radius, center };

    return data;
  };

  handleCircleEdit = () => {
    const { currentGeofence } = this.props;

    const circle = this.overlay;
    const circleData = this.getCircleCoordinates(circle);

    const data = {
      ...currentGeofence,
      geofence: circleData
    };

    return this.props.onDrawComplete(data);
  };

  getPolygonCoordinates = polygon => {
    const coordinates = [];

    polygon.getPaths().forEach(element => {
      element.forEach(e => {
        coordinates.push({ lat: e.lat(), lng: e.lng() });
      });
    });

    const data = { type: "polygon", coordinates };

    return data;
  };

  handlePolygonEdit = () => {
    const { currentGeofence } = this.props;

    const polygon = this.overlay;
    const polygonData = this.getPolygonCoordinates(polygon);

    const data = {
      ...currentGeofence,
      geofence: polygonData
    };

    return this.props.onDrawComplete(data);
  };

  getRectangleCoordinates = rectangle => {
    const boundingBox = {
      topLeft: {
        lat: rectangle
          .getBounds()
          .getSouthWest()
          .lat(),
        lng: rectangle
          .getBounds()
          .getNorthEast()
          .lng()
      },
      bottomLeft: {
        lat: rectangle
          .getBounds()
          .getSouthWest()
          .lat(),
        lng: rectangle
          .getBounds()
          .getSouthWest()
          .lng()
      },
      topRight: {
        lat: rectangle
          .getBounds()
          .getNorthEast()
          .lat(),
        lng: rectangle
          .getBounds()
          .getNorthEast()
          .lng()
      },
      bottomRight: {
        lat: rectangle
          .getBounds()
          .getNorthEast()
          .lat(),
        lng: rectangle
          .getBounds()
          .getSouthWest()
          .lng()
      }
    };

    const data = {
      type: "rectangle",
      boundingBox
    };

    return data;
  };

  handleRectangleEdit = () => {
    const { currentGeofence } = this.props;

    const rectangle = this.overlay;
    const rectangleData = this.getRectangleCoordinates(rectangle);

    const data = {
      ...currentGeofence,
      geofence: rectangleData
    };

    return this.props.onDrawComplete(data);
  };

  handleOverlayComplete = event => {
    const { currentGeofence } = this.props;

    if (this.overlay) {
      this.overlay.setMap(null);
    }

    this.overlay = event.overlay;

    if (event.type === "circle") {
      const circle = event.overlay;

      const circleData = this.getCircleCoordinates(circle);

      const data = {
        ...currentGeofence,
        geofence: circleData
      };

      circle.addListener("radius_changed", this.handleCircleEdit);

      circle.addListener("center_changed", this.handleCircleEdit);

      return this.props.onDrawComplete(data);
    }

    if (event.type === "polygon") {
      const polygon = event.overlay;

      const polygonData = this.getPolygonCoordinates(polygon);

      const data = {
        ...currentGeofence,
        geofence: polygonData
      };

      const polygonPath = polygon.getPath();

      polygonPath.addListener("set_at", this.handlePolygonEdit);

      polygonPath.addListener("dragend", this.handlePolygonEdit);

      return this.props.onDrawComplete(data);
    }

    if (event.type === "rectangle") {
      const rectangle = event.overlay;

      const rectangleData = this.getRectangleCoordinates(rectangle);

      const data = {
        ...currentGeofence,
        geofence: rectangleData
      };

      rectangle.addListener("bounds_changed", this.handleRectangleEdit);

      rectangle.addListener("dragend", this.handleRectangleEdit);

      return this.props.onDrawComplete(data);
    }
  };

  handleUndo = () => {
    if (this.overlay) {
      this.overlay.setMap(null);
      this.props.onDrawComplete(null);
    }
  };

  render() {
    const { width = "100%", height = "100%", id, drawMode } = this.props;

    return (
      <>
        {drawMode ? (
          <div ref={this.undoButtonRef}>
            <button
              onClick={this.handleUndo}
              style={{
                background: "white",
                border: 0,
                boxShadow: "rgb(0 0 0 / 30%) 0px 1px 4px -1px",
                margin: "5px",
                padding: "5px"
              }}
            >
              Undo
            </button>
          </div>
        ) : null}
        <div style={{ width, height }} id={id} />
      </>
    );
  }
}

export default Map;
