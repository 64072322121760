import React, { Component } from "react";
import { Modal, Table, Button, message, Tag, Tabs, Select } from "antd";
import { PlayCircleOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import moment from "moment";

import Fetch from "../../utils/fetch";
import Timer from "../timer";
import {
  getSeatNumberFromTripChart,
  getHoursAndMinFromMillis
} from "../../utils/common";
import { issueExpireTime } from "../../config";

const { Option } = Select;
const { TabPane } = Tabs;

const priorityOrder = { OPEN: 1, REJECTED: 2, CLOSED: 3 };

function msToTime(duration) {
  let milliseconds = Math.floor((duration % 1000) / 100),
    seconds = Math.floor((duration / 1000) % 60),
    minutes = Math.floor((duration / (1000 * 60)) % 60),
    hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

  if (!hours) {
    if (!minutes) {
      if (!seconds) {
        return ` ${milliseconds}ms`;
      }
      return ` ${seconds}sec ${milliseconds}ms`;
    }
    return `${minutes}mins ${seconds}sec ${milliseconds}ms`;
  }
  return `${hours}hrs ${minutes}mins ${seconds}sec ${milliseconds}ms`;
}

const taskcolumns = [
  {
    title: "Trip Name",
    dataIndex: "tripName",
    key: "tripName",
    width: "20%",
    render: data => <div className="tableContent">{data}</div>
  },
  {
    title: "Type",
    dataIndex: "type",
    key: "type"
  },
  {
    title: "Amount",
    dataIndex: "amount",
    key: "amount"
  },
  {
    title: "Description",
    dataIndex: "description",
    key: "description",
    width: "20%",
    render: data => <div className="tableContent">{data}</div>
  },
  {
    title: "Station Name",
    dataIndex: "station",
    key: "station",
    width: "20%",
    render: data => <div className="tableContent">{data}</div>
  },
  {
    title: "Created By",
    dataIndex: "createdBy",
    key: "createdBy"
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    render: status => {
      let tagColor = "";

      switch (status) {
        case "OPEN": {
          tagColor = "blue";
          break;
        }

        case "REJECTED": {
          tagColor = "red";
          break;
        }

        case "CLOSED": {
          tagColor = "green";
        }
      }

      return <Tag color={tagColor}>{status}</Tag>;
    }
  }
];

const columns = [
  {
    title: "Service",
    dataIndex: "serviceName",
    key: "serviceName",
    width: "10%"
  },
  {
    title: "Bus Number",
    dataIndex: "busNumber",
    key: "busNumber",
    width: "10%"
  },
  {
    title: "Marshal",
    dataIndex: "marshal",
    key: "marshal",
    width: "10%"
  },
  {
    title: "Issue Type",
    dataIndex: "type",
    key: "type",
    width: "10%",
    render: data => <Tag color="orange">{data}</Tag>
  },
  {
    title: "Description",
    dataIndex: "description",
    key: "description",
    width: "10%"
  },
  {
    title: "Resolve In",
    dataIndex: "resolvedBy",
    key: "resolvedBy",
    width: "10%",
    render: date => <Timer expireAt={date + issueExpireTime} />
  },
  {
    title: "Action",
    dataIndex: "action",
    key: "action",
    width: "10%",
    render: ({ issueId, onClick, status }) => {
      return status === "OPEN" ? (
        <>
          <Button onClick={() => onClick(issueId, "RESOLVED")}>Resolve</Button>
          <Button onClick={() => onClick(issueId, "REJECTED")}>Ignore</Button>
        </>
      ) : (
        <Tag color={status === "RESOLVED" ? "green" : "red"}>{status}</Tag>
      );
    }
  },
  {
    title: "Marhsal Number",
    dataIndex: "marshalNumber",
    key: "marshalNumber",
    width: "10%"
  },
  {
    title: "Action History",
    dataIndex: "actionHistory",
    key: "actionHistory",
    width: "10%"
  }
];

const bookingColumn = [
  {
    title: "Cx Name",
    dataIndex: "name",
    key: "name",
    width: "10%"
  },
  {
    title: "Cx Mobile Number",
    dataIndex: "mobileNo",
    key: "mobileNo",
    width: "10%"
  },
  {
    title: "PNR",
    dataIndex: "pnr",
    key: "pnr",
    width: "10%"
  },
  {
    title: "Seats",
    dataIndex: "seats",
    key: "seats",
    width: "10%"
  },
  {
    title: "From",
    dataIndex: "from",
    key: "from",
    width: "10%"
  },
  {
    title: "To",
    dataIndex: "to",
    key: "to",
    width: "10%"
  },
  {
    title: "Collection Amount",
    dataIndex: "collectionAmount",
    key: "collectionAmount",
    width: "10%"
  },
  {
    title: "Agent",
    dataIndex: "agent",
    key: "agent",
    width: "10%"
  },
  {
    title: "POC",
    dataIndex: "poc",
    key: "poc",
    width: "10%"
  },
  {
    title: "Call Attempted",
    dataIndex: "callAttempted",
    key: "callAttempted",
    width: "10%"
  },
  {
    title: "Call logs",
    dataIndex: "callDetails",
    key: "callDetails",
    width: "10%",
    render: (data = []) => {
      if (data.length) {
        return data.map((call, index) => (
          <div key={index}>
            {call.recording ? (
              <Link to={call.recording} target="_blank">
                <PlayCircleOutlined />
              </Link>
            ) : (
              <Tag>{call.date}</Tag>
            )}
          </div>
        ));
      } else {
        return <Tag color="red">No Call Records</Tag>;
      }
    }
  }
];

const faultColumn = [
  {
    title: "Bus Rc",
    dataIndex: "rc",
    key: "rc",
    width: "20%",
    render: data => <div className="tableContent">{data}</div>
  },
  {
    title: "Bus Health",
    dataIndex: "type",
    key: "type"
  },
  {
    title: "Fault Code",
    dataIndex: "code",
    key: "code"
  },
  {
    title: "marshal",
    dataIndex: "busHost",
    key: "type"
  },
  {
    title: "marshal Number",
    dataIndex: "busHostNumber",
    key: "type"
  },
  {
    title: "Driver",
    dataIndex: "busDriver",
    key: "type"
  },
  {
    title: "Driver Number",
    dataIndex: "busDriverNumber",
    key: "type"
  }
];

const alertColumn = [
  {
    title: "Bus Rc",
    dataIndex: "rc",
    key: "rc",
    render: data => <div className="tableContent">{data}</div>
  },
  {
    title: "Fault Type",
    dataIndex: "type",
    key: "type"
  },
  {
    title: "Time",
    dataIndex: "time",
    key: "time"
  },
  {
    title: "Address",
    dataIndex: "address",
    key: "address"
  },
  {
    title: "marshal",
    dataIndex: "busHost",
    key: "type"
  },
  {
    title: "marshal Number",
    dataIndex: "busHostNumber",
    key: "type"
  },
  {
    title: "Driver",
    dataIndex: "busDriver",
    key: "type"
  },
  {
    title: "Driver Number",
    dataIndex: "busDriverNumber",
    key: "type"
  }
];

const alertStoppageColumn = [
  {
    title: "Bus Rc",
    dataIndex: "rc",
    key: "rc",
    render: data => <div className="tableContent">{data}</div>
  },
  {
    title: "Fault Type",
    dataIndex: "type",
    key: "type"
  },
  {
    title: "From Date",
    dataIndex: "stoppage_start_time",
    key: "stoppage_start_time"
  },
  {
    title: "To Date",
    dataIndex: "stoppage_end_time",
    key: "stoppage_end_time"
  },
  {
    title: "Stoppage Time",
    dataIndex: "stoppage_time",
    key: "stoppage_time"
  },
  {
    title: "Time",
    dataIndex: "time",
    key: "time"
  },
  {
    title: "Address",
    dataIndex: "address",
    key: "address"
  },
  {
    title: "marshal",
    dataIndex: "busHost",
    key: "type"
  },
  {
    title: "marshal Number",
    dataIndex: "busHostNumber",
    key: "type"
  },
  {
    title: "Driver",
    dataIndex: "busDriver",
    key: "type"
  },
  {
    title: "Driver Number",
    dataIndex: "busDriverNumber",
    key: "type"
  }
];

const alertOverSpeedColumn = [
  {
    title: "Bus Rc",
    dataIndex: "rc",
    key: "rc",
    render: data => <div className="tableContent">{data}</div>
  },
  {
    title: "Fault Type",
    dataIndex: "type",
    key: "type"
  },
  {
    title: "Stoppage Start Time",
    dataIndex: "stoppage_start_time",
    key: "stoppage_start_time"
  },
  {
    title: "Stoppage Going On",
    dataIndex: "stoppage_going_on",
    key: "stoppage_going_on"
  },
  {
    title: "Stoppage End Time",
    dataIndex: "stoppage_end_time",
    key: "stoppage_end_time"
  },
  {
    title: "Stoppage Time",
    dataIndex: "stoppage_time",
    key: "stoppage_time"
  },
  {
    title: "Max Type",
    dataIndex: "max_time",
    key: "max_time"
  },

  {
    title: "Time",
    dataIndex: "time",
    key: "time"
  },
  {
    title: "Address",
    dataIndex: "address",
    key: "address"
  },
  {
    title: "marshal",
    dataIndex: "busHost",
    key: "type"
  },
  {
    title: "marshal Number",
    dataIndex: "busHostNumber",
    key: "type"
  },
  {
    title: "Driver",
    dataIndex: "busDriver",
    key: "type"
  },
  {
    title: "Driver Number",
    dataIndex: "busDriverNumber",
    key: "type"
  }
];

const alertIdlingColumn = [
  {
    title: "Bus Rc",
    dataIndex: "rc",
    key: "rc",
    render: data => <div className="tableContent">{data}</div>
  },
  {
    title: "Fault Type",
    dataIndex: "type",
    key: "type"
  },
  {
    title: "Idling Time",
    dataIndex: "idle_time",
    key: "idle_time"
  },
  {
    title: "Fuel Consumed",
    dataIndex: "fuel_consumed",
    key: "fuel_consumed"
  },
  {
    title: "Time",
    dataIndex: "time",
    key: "time"
  },
  {
    title: "Address",
    dataIndex: "address",
    key: "address"
  },
  {
    title: "marshal",
    dataIndex: "busHost",
    key: "type"
  },
  {
    title: "marshal Number",
    dataIndex: "busHostNumber",
    key: "type"
  },
  {
    title: "Driver",
    dataIndex: "busDriver",
    key: "type"
  },
  {
    title: "Driver Number",
    dataIndex: "busDriverNumber",
    key: "type"
  }
];

class IssuesList extends Component {
  state = {
    activeKey: "1",
    issues: [],
    tasks: []
  };

  async componentDidMount() {
    try {
      const tasks = await this.fetchTask();
      const alerts = await this.fetchAlerts();
      const faults = await this.fetchFaults();
      const { crewDetails } = this.props;
      let fault;

      if (faults && faults.length > 0) {
        fault = await this.formatFaults(faults, crewDetails);
      }
      const issues = await this.fetchIssues();
      this.setState({ issues, tasks, alerts, fault, alertFilterValue: "ALL" });
      if (alerts && alerts.length > 0) {
        await this.formatAlert();
      }
    } catch (err) {
      console.log(err);
      message.error(err);
    }
  }

  fetchAlerts = async () => {
    try {
      const { tripId } = this.props;
      const url = `/bus-tracker/tripAlertsAndFaults/?tripId=${tripId}&type=Alert`;

      const options = {
        method: "get"
      };

      const response = await Fetch(url, options);

      if (response) {
        return response;
      }
    } catch (err) {
      return Promise.reject(err);
    }
  };

  fetchFaults = async () => {
    try {
      const { tripId } = this.props;
      const url = `/bus-tracker/tripAlertsAndFaults/?tripId=${tripId}&type=Fault`;

      const options = {
        method: "get"
      };

      const response = await Fetch(url, options);

      if (response) {
        return response;
      }
    } catch (err) {
      return Promise.reject(err);
    }
  };

  formatAlert = async alertsValueSelected => {
    const { crewDetails } = this.props;
    const { alerts } = this.state;

    const { bus, busDriver, busHost } = crewDetails;
    let formattedData = alerts.map(obj => {
      const formattedAlert = {
        rc: bus.rc,
        type: obj.alertType,
        time: moment(obj.timestamp).format("LLL"),
        address: obj.address,
        busHost: busHost.name,
        busHostNumber: busHost.mobileNo,
        busDriver: busDriver.name,
        busDriverNumber: busDriver.mobileNo
      };
      return formattedAlert;
    });

    if (alertsValueSelected == "OverSpeed") {
      let overSpeedAlert = alerts.filter(obj => obj.alertType == "over_speed");
      formattedData = overSpeedAlert.map(obj => {
        const formattedAlert = {
          stoppage_start_time: obj.alertValues
            ? obj.alertValues.stoppage_start_time
            : null, //Stoppage
          stoppage_going_on: obj.alertValues
            ? obj.alertValues.stoppage_going_on
            : null, //Stoppage
          stoppage_end_time: obj.alertValues
            ? obj.alertValues.stoppage_end_time
            : null, //Stoppage
          stoppage_time: obj.alertValues
            ? getHoursAndMinFromMillis(obj.alertValues.stoppage_time)
            : null, //Stoppage
          alertValues: obj.alertValues ? obj.alertValues : null,
          max_time: obj.alertValues ? obj.alertValues.max_time : null,
          rc: bus.rc,
          type: obj.alertType,
          time: moment(obj.timestamp).format("LLL"),
          address: obj.address,
          busHost: busHost.name,
          busHostNumber: busHost.mobileNo,
          busDriver: busDriver.name,
          busDriverNumber: busDriver.mobileNo
        };
        return formattedAlert;
      });
    }

    if (alertsValueSelected == "HarshAcceleration") {
      let overSpeedAlert = alerts.filter(
        obj => obj.alertType == "harsh_acceleration"
      );
      formattedData = overSpeedAlert.map(obj => {
        const formattedAlert = {
          stoppage_start_time: obj.alertValues
            ? obj.alertValues.stoppage_start_time
            : null, //Stoppage
          stoppage_going_on: obj.alertValues
            ? obj.alertValues.stoppage_going_on
            : null, //Stoppage
          stoppage_end_time: obj.alertValues
            ? obj.alertValues.stoppage_end_time
            : null, //Stoppage
          stoppage_time: obj.alertValues
            ? getHoursAndMinFromMillis(obj.alertValues.stoppage_time)
            : null, //Stoppage
          alertValues: obj.alertValues ? obj.alertValues : null,
          max_time: obj.alertValues ? obj.alertValues.max_time : null,
          rc: bus.rc,
          type: obj.alertType,
          time: moment(obj.timestamp).format("LLL"),
          address: obj.address,
          busHost: busHost.name,
          busHostNumber: busHost.mobileNo,
          busDriver: busDriver.name,
          busDriverNumber: busDriver.mobileNo
        };
        return formattedAlert;
      });
    }

    if (alertsValueSelected == "Idling") {
      let idlingAlert = alerts.filter(obj => obj.alertType == "idling");
      formattedData = idlingAlert.map(obj => {
        const formattedAlert = {
          idle_time: obj.alertValues
            ? msToTime(obj.alertValues.idle_time)
            : null, //ideal
          fuel_consumed: obj.alertValues ? obj.alertValues.fuel_consumed : null, //ideal
          distance: obj.alertValues ? obj.alertValues.distance : null, // need to be checked  //distance
          alertValues: obj.alertValues ? obj.alertValues : null,
          max_time: obj.alertValues ? obj.alertValues.max_time : null,
          rc: bus.rc,
          type: obj.alertType,
          time: moment(obj.timestamp).format("LLL"),
          address: obj.address,
          busHost: busHost.name,
          busHostNumber: busHost.mobileNo,
          busDriver: busDriver.name,
          busDriverNumber: busDriver.mobileNo
        };
        return formattedAlert;
      });
    }

    if (alertsValueSelected == "HardBrake") {
      let hardBrakeAlert = alerts.filter(obj => obj.alertType == "hard_brake");
      formattedData = hardBrakeAlert.map(obj => {
        const formattedAlert = {
          alertValues: obj.alertValues ? obj.alertValues : null,
          rc: bus.rc,
          type: obj.alertType,
          time: moment(obj.timestamp).format("LLL"),
          address: obj.address,
          busHost: busHost.name,
          busHostNumber: busHost.mobileNo,
          busDriver: busDriver.name,
          busDriverNumber: busDriver.mobileNo
        };
        return formattedAlert;
      });
    }

    if (alertsValueSelected == "Stoppage") {
      let StoppageAlert = alerts.filter(obj => obj.alertType == "stoppage");

      formattedData = StoppageAlert.map(obj => {
        const formattedAlert = {
          stoppage_start_time: obj.alertValues
            ? moment(obj.alertValues.stoppage_start_time).format("LLL")
            : null,
          stoppage_going_on: obj.alertValues
            ? obj.alertValues.stoppage_going_on
            : null,
          stoppage_end_time: obj.alertValues
            ? moment(obj.alertValues.stoppage_end_time).format("LLL")
            : null,
          stoppage_time: obj.alertValues
            ? getHoursAndMinFromMillis(obj.alertValues.stoppage_time)
            : null, //Stoppage
          alertValues: obj.alertValues ? obj.alertValues : null,
          max_time: obj.alertValues ? obj.alertValues.max_time : null,
          rc: bus.rc,
          type: obj.alertType,
          time: moment(obj.timestamp).format("LLL"),
          address: obj.address,
          busHost: busHost.name,
          busHostNumber: busHost.mobileNo,
          busDriver: busDriver.name,
          busDriverNumber: busDriver.mobileNo
        };
        return formattedAlert;
      });
    }

    if (alertsValueSelected == "ALL") {
      // return alerts.map(obj => {
      //   const formattedAlert = {
      //     speeding_start_time: obj.alertValues
      //       ? obj.alertValues.speeding_start_time
      //       : null, //overspeeding
      //     speeding_start_time: obj.alertValues
      //       ? obj.alertValues.speeding_start_time
      //       : null, //overspeeding
      //     idle_time: obj.alertValues ? obj.alertValues.idle_time : null, //ideal
      //     fuel_consumed: obj.alertValues ? obj.alertValues.fuel_consumed : null, //ideal
      //     distance: obj.alertValues ? obj.alertValues.distance : null, // need to be checked  //distance
      //     time_threshold: obj.alertValues
      //       ? obj.alertValues.time_threshold
      //       : null, //time_threshold
      //     max_speed: obj.alertValues ? obj.alertValues.max_speed : null, //time_threshold  //freerun
      //     stoppage_start_time: obj.alertValues
      //       ? obj.alertValues.stoppage_start_time
      //       : null, //Stoppage
      //     stoppage_going_on: obj.alertValues
      //       ? obj.alertValues.stoppage_going_on
      //       : null, //Stoppage
      //     stoppage_end_time: obj.alertValues
      //       ? obj.alertValues.stoppage_end_time
      //       : null, //Stoppage
      //     stoppage_time: obj.alertValues ? obj.alertValues.stoppage_time : null, //Stoppage
      //     alertValues: obj.alertValues ? obj.alertValues : null,
      //     max_time: obj.alertValues ? obj.alertValues.max_time : null,
      //     rc: bus.rc,
      //     type: obj.alertType,
      //     time: moment(obj.timestamp).format("LLL"),
      //     address: obj.address,
      //     busHost: busHost.name,
      //     busHostNumber: busHost.mobileNo,
      //     busDriver: busDriver.name,
      //     busDriverNumber: busDriver.mobileNo
      //   };
      //   return formattedAlert;
      // });
      formattedData = alerts.map(obj => {
        const formattedAlert = {
          rc: bus.rc,
          type: obj.alertType,
          time: moment(obj.timestamp).format("LLL"),
          address: obj.address,
          busHost: busHost.name,
          busHostNumber: busHost.mobileNo,
          busDriver: busDriver.name,
          busDriverNumber: busDriver.mobileNo
        };
        return formattedAlert;
      });
    }

    this.setState({
      alert: formattedData,
      alertFilterValue: alertsValueSelected
    });
  };

  formatFaults = async (faults, crewDetails) => {
    const { bus, busDriver, busHost } = crewDetails;
    return faults.map(obj => {
      const formattedAlert = {
        rc: bus.rc,
        type: obj.busHealth,
        code: obj.faultCode,
        busHost: busHost.name,
        busHostNumber: busHost.mobileNo,
        busDriver: busDriver.name,
        busDriverNumber: busDriver.mobileNo
      };
      return formattedAlert;
    });
  };

  fetchTask = async () => {
    try {
      const { tripId } = this.props;
      const url = `/bus-tracker/task/${tripId}`;

      const options = {
        method: "get"
      };

      const response = await Fetch(url, options);

      if (response) {
        const tasks = response.map(task => {
          return {
            tripName: task.trip.service.name,
            type: task.type,
            amount: task.amount,
            description: task.description,
            station: task.station ? task.station.name : "",
            createdBy: `${task.createdByUser.name}, ${task.createdByUser.mobileNo}`,
            status: task.status,
            priority: priorityOrder[task.status]
          };
        });

        return tasks.sort((a, b) => a.priority - b.priority);
      }
    } catch (err) {
      return Promise.reject(err);
    }
  };

  fetchIssues = async () => {
    try {
      const { tripId } = this.props;
      const url = `/bus-tracker/issues/${tripId}`;

      const options = {
        method: "get"
      };

      const response = await Fetch(url, options);

      if (response) {
        const issues = response.map(issue => {
          let actionHistory = "";

          if (issue.raisedByUserId) {
            const user = issue.raisedBy;

            actionHistory += `Raised by `;

            if (user.name) {
              actionHistory += user.name;
            }

            actionHistory += `, mobileNo: ${user.mobileNo}`;
          }

          if (issue.resolvedByUserId) {
            const user = issue.resolvedBy;

            actionHistory += ` | UpdatedBy by`;

            if (user.name) {
              actionHistory += user.name;
            }

            actionHistory += `, mobileNo: ${user.mobileNo}`;
          }

          return {
            ...issue,
            marshal: issue.marshalName,
            description: issue.name,
            resolvedBy: issue.createdOn,
            action: {
              issueId: issue._id,
              onClick: this.updateIssueStatus,
              status: issue.status
            },
            actionHistory,
            key: issue._id,
            _id: issue._id
          };
        });
        return Promise.resolve(issues);
      }
    } catch (err) {
      return Promise.reject(err);
    }
  };

  getSeatsNumber = tripChart => {
    let seatsNumber = "";

    for (let i = 0; i < tripChart.length; ++i) {
      const { status, seatChart } = tripChart[i];

      if (status === "CONFIRMED") {
        seatsNumber += `${seatChart.tripSeat.serviceSeat.seat.seatLabel},`;
      }
    }

    seatsNumber = seatsNumber.slice(0, seatsNumber.length - 1);

    return seatsNumber;
  };

  updateIssueStatus = async (issueId, status) => {
    try {
      const url = `/bus-tracker/issues`;

      const options = {
        method: "patch",
        data: { status, issueId }
      };

      const response = await Fetch(url, options, true);

      if (response) {
        const { issues } = this.state;
        const updatedIssue = issues.map(issue => {
          if (issue._id === response[0]._id) {
            return {
              ...issue,
              action: { ...issue.action, status: response[0].status }
            };
          }
          return issue;
        });

        this.setState({ issues: updatedIssue });
        message.success(`Issue ${status} success`);
      }
    } catch (err) {
      console.log(err);
      message.error(err);
    }
  };

  handleExpandableRow = record => {
    const booking = record.booking;

    let dataSource = [];

    if (booking) {
      dataSource.push({
        name: booking.name,
        mobileNo: booking.mobileNo,
        pnr: booking.bookingCode,
        seats: getSeatNumberFromTripChart(booking.tripChart),
        from: `${booking.fromStation.name}, ${booking.fromCity.name}`,
        to: `${booking.toStation.name}, ${booking.toCity.name}`,
        collectionAmount: booking.amountToBeCollected || 0,
        agent: booking.agent
          ? booking.agent.agencyName
          : booking.otaPartner
          ? booking.otaPartner
          : booking.demandSource,
        poc: booking.agent ? booking.agent.pocEmail : "",
        callAttempted: booking.callDetails.length,
        callDetails: booking.callDetails
      });

      return (
        <Table
          columns={bookingColumn}
          dataSource={dataSource}
          pagination={false}
        />
      );
    }

    return <div />;
  };

  render() {
    const {
      issues,
      activeKey,
      tasks,
      alert,
      fault,
      alertFilterValue
    } = this.state;
    return (
      <Modal
        title="Issue List"
        visible={true}
        onCancel={this.props.onCancel}
        footer={null}
        width="100"
      >
        <Tabs
          activeKey={activeKey}
          onChange={key => this.setState({ activeKey: key })}
        >
          <TabPane tab=" Issues " key="1">
            <Table
              columns={columns}
              expandedRowRender={this.handleExpandableRow}
              rowExpandable={record => {
                return record.bookingId;
              }}
              dataSource={issues}
              pagination={false}
              scroll={{ y: 500, x: "150%" }}
            />
          </TabPane>
          <TabPane tab="Tasks" key="4">
            <Table
              columns={taskcolumns}
              dataSource={tasks}
              pagination={false}
            />
          </TabPane>
          <TabPane
            tab={
              fault && fault.length > 0
                ? "Fault (" + fault.length + ")"
                : "Faults"
            }
            key="2"
          >
            <Table
              columns={faultColumn}
              dataSource={fault}
              pagination={false}
            />
          </TabPane>
          <TabPane
            tab={
              alert && alert.length > 0
                ? "Alerts (" + alert.length + ")"
                : "Alerts"
            }
            key="3"
          >
            <div className="alertSearch">
              <Select
                defaultValue="ALL"
                style={{ width: "fit-content", minWidth: "200px" }}
                value={alertFilterValue}
                onChange={value => this.formatAlert(value)}
              >
                <Option value="OverSpeed">Over Speed</Option>
                <Option value="HarshAcceleration">Harsh Acceleration</Option>
                <Option value="Idling">Idling</Option>
                <Option value="HardBrake">Hard Brake</Option>
                <Option value="Stoppage">Stoppage</Option>
                <Option value="ALL">All</Option>
              </Select>
            </div>
            <Table
              columns={
                alertFilterValue == "Stoppage"
                  ? alertStoppageColumn
                  : alertFilterValue == "OverSpeed"
                  ? alertOverSpeedColumn
                  : alertFilterValue == "HarshAcceleration"
                  ? alertColumn
                  : alertFilterValue == "Idling"
                  ? alertIdlingColumn
                  : alertFilterValue == "HardBrake"
                  ? alertColumn
                  : alertColumn
              }
              scroll={{ y: 300, x: "200%" }}
              dataSource={alert}
              pagination={false}
            />
          </TabPane>
        </Tabs>
      </Modal>
    );
  }
}

export default IssuesList;
