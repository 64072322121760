import React, { Component } from "react";
import moment from "moment";
import mapStyle from "../../../config/mapStyle";

import stationPoints from "../../../images/station_icon-01.svg";
import endpointsicon from "../../../images/endpoints.svg";
import reststopIcon from "../../../images/reststop-01.svg";
import CurrentLocation from "../../../images/current-location.svg";

const google = window.google;

class Map extends Component {
  state = {
    loading: true
  };

  componentDidMount() {
    this.initMap();
  }

  directionsService = null;
  directionsRenderer = [];

  initMap = async () => {
    try {
      if (typeof google !== "undefined") {
        const { wayPoints } = this.props;

        // Initialize google map
        this.map = new google.maps.Map(document.getElementById("map2"), {
          zoom: 16,
          center: wayPoints[0].coordinates,
          disableDefaultUI: true,
          gestureHandling: "greedy"
        });

        // Direction Service initialization

        // Add styles to Map
        const styledMapType = new google.maps.StyledMapType(mapStyle);
        this.map.mapTypes.set("styled_map", styledMapType);
        this.map.setMapTypeId("styled_map");

        // Adding Station Marker
        await this.createStationMarker();
      }
    } catch (err) {
      console.log(err);
    }
  };

  plotRoute = (route, color = "#7b2bff") => {
    const directionsRenderer = new google.maps.DirectionsRenderer({
      preserveViewport: true,
      suppressMarkers: true,
      polylineOptions: {
        strokeColor: color
      }
    });

    directionsRenderer.setMap(this.map);
    directionsRenderer.setDirections(route);

    this.directionsRenderer.push(directionsRenderer);
  };

  createStationMarker = async () => {
    try {
      const { wayPoints, fromstation, dropPoint } = this.props;

      const bounds = new google.maps.LatLngBounds();

      for (let i = 0; i < wayPoints.length; ++i) {
        const station = wayPoints[i];
        const { type, address } = station;
        let icon = stationPoints;

        if (address === fromstation || address === dropPoint) {
          icon = endpointsicon;
        }

        if (type === "RESTSTOP") {
          icon = reststopIcon;
        }

        const stationMarker = new google.maps.Marker({
          position: station.coordinates,
          title: `${station.name}`,
          icon: icon,
          map: this.map
        });

        let prev_infowindow = null;

        const infowindow = new google.maps.InfoWindow({
          content: `<b>${station.name}</b><p / >
          <p>Station Reaching Time: ${moment(station.reachingTime).format(
            "LLL"
          )}</p>`
        });
        const reststop_infowindow = new google.maps.InfoWindow({
          content: `<p><b>${type}</b></p><b>${station.name}</b>
          <p>Station Reaching Time: ${moment(station.reachingTime).format(
            "LLL"
          )}</p>`
        });

        stationMarker.addListener("click", () => {
          if (prev_infowindow) {
            prev_infowindow.close();
          }
          if (type === "RESTSTOP") {
            prev_infowindow = reststop_infowindow;
            reststop_infowindow.open(this.map, stationMarker);
          } else {
            prev_infowindow = infowindow;
            infowindow.open(this.map, stationMarker);
          }
        });

        bounds.extend(station.coordinates);
      }

      this.map.fitBounds(bounds);
    } catch (err) {
      console.log(err);
    }
  };

  moveToCurrentLocation = () => {
    const { wayPoints } = this.props;

    this.map.panTo(wayPoints[0].coordinates);
  };

  render() {
    const { width = "100%", height = "100%", isTrackingWorking } = this.props;

    return (
      <>
        <div style={{ width, height }} id="map2" />
        {isTrackingWorking ? null : (
          <button
            onClick={this.moveToCurrentLocation}
            className="oldcurrentLocation"
          >
            <img src={CurrentLocation} />
          </button>
        )}
      </>
    );
  }
}

export default Map;
