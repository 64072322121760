import React, { Component } from "react";
import { Button, Input, Checkbox, Select, Modal, message } from "antd";
import Fetch from "../../utils/fetch";
import { whatsAppTemplates, SMSTemplates } from "../../config";

const { TextArea } = Input;
const { Option } = Select;

class SendMessage extends Component {
  state = {
    showMessageOption: false,
    customMessage: "",
    sendSMSMessage: false,
    sendWhatsAppMessage: false,
    templateType: "----Select Message----",
    trackingLink: false,
    zingcashCredit: false
  };

  handleChange = (key, value) => {
    if (value == "") {
      value = null;
    }
    this.setState({ [key]: value });
  };

  toggleMessageOption = () => {
    let { selectedCount, selectedBookings } = this.props;
    if (!selectedCount) {
      selectedCount = selectedBookings.length;
    }

    const { showMessageOption } = this.state;

    if (!showMessageOption && !selectedCount) {
      return message.error("Please select booking or station");
    }

    this.setState({ showMessageOption: !showMessageOption });
  };

  zingcashCreditValue = () => {
    return (
      <input
        type="number"
        min="10"
        max="5000"
        style={{ width: "120px" }}
        onChange={e =>
          this.handleChange("zingcashCreditValue", parseInt(e.target.value))
        }
      ></input>
    );
  };

  customWhatsAppMessage = templateType => {
    return (
      <>
        <Select
          className="mb-1"
          style={{ width: "500px" }}
          defaultValue="----Select Message----"
          onChange={value => this.setState({ templateType: value })}
        >
          <Option value="select">----Select Message----</Option>

          <Option value="delay_in_pickup_v1">Delay in Pickup</Option>
          <Option value="delay_in_drop_v1"> Delay in Drop</Option>
          <Option value="drop_point_update_v1"> Drop Point Update</Option>
          <Option value="share_marshal_number_v1"> Share Marshal Number</Option>
          <Option value="crew_misbehaviour_v1"> Crew Misbehaviour</Option>
          <Option value="breakdown_v2">Breakdown </Option>
          <Option value="ac_not_working_v1">AC </Option>
          <Option value="charging_point_wifi_v1">Charging Point/WiFi</Option>
          <Option value="route_change_v1">Route Change</Option>
          <Option value="rest_stop_change_v1">Rest Stop Change/Skip</Option>
          <Option value="tech_related_issues_v1">
            Any Tech Related Issues
          </Option>
          <Option value="not_boarded_v1">Not Boarded</Option>
          <Option value="pickup_update_lt_24_hours_v1">
            Pickup Update_Less than 24 hours
          </Option>
          {/* <Option value="booking_confirmation_v8_1">
            Booking Confirmation
          </Option> */}
          <Option value="trip_assignment_v7">Trip Assignment</Option>
          <Option value="before_24_hours_cancellation_v3">
            Cancellation Greater than 24 hours
          </Option>
          <Option value="booking_modify_preponed_v1">
            Booking Modification_Preponed
          </Option>
          <Option value="booking_modify_postponed_v1">
            Booking Modification_Postponed
          </Option>
          <Option value="booking_modify_seat_change_v1">
            Booking Modification_Seat Change
          </Option>
          <Option value="after_24_hours_cancellation_v3">
            Less than 24 hours
          </Option>
          <Option value="booking_confirm_connecting_v1">
            {" "}
            Booking Confirmation_Connecting
          </Option>
          <Option value="trip_assignment_1st_leg_v1">
            Trip Assignment (First Leg){" "}
          </Option>
          <Option value="trip_assignment_2nd_leg_v1">
            Trip Assignment (Second Leg){" "}
          </Option>
          <Option value="connecting_booking_modify_v1">
            Booking Modification
          </Option>
        </Select>
        <br />
        {templateType === "booking_confirmation_v8_1" ? (
          <div>
            Your zingbus ticket is confirmed. Pickup Date and Time:{" "}
            <TextArea
              placeholder="maxLength is 30"
              maxLength={30}
              className="mb-1"
              autoSize={{ minRows: 1, maxRows: 2 }}
              style={{ width: "25%" }}
              onChange={e => this.handleChange("var1", e.target.value)}
            />{" "}
            <br />
            Pickup Point:{" "}
            <TextArea
              placeholder="maxLength is 60"
              maxLength={60}
              className="mb-1"
              autoSize={{ minRows: 1, maxRows: 2 }}
              style={{ width: "25%" }}
              onChange={e => this.handleChange("var2", e.target.value)}
            />{" "}
            <br />
            Route:{" "}
            <TextArea
              placeholder="maxLength is 30"
              maxLength={30}
              className="mb-1"
              autoSize={{ minRows: 1, maxRows: 2 }}
              style={{ width: "25%" }}
              onChange={e => this.handleChange("var3", e.target.value)}
            />{" "}
            to{" "}
            <TextArea
              placeholder="maxLength is 30"
              maxLength={30}
              className="mb-1"
              autoSize={{ minRows: 1, maxRows: 2 }}
              style={{ width: "25%" }}
              onChange={e => this.handleChange("var4", e.target.value)}
            />{" "}
            <br />
            PNR:{" "}
            <TextArea
              placeholder="maxLength is 30"
              maxLength={30}
              className="mb-1"
              autoSize={{ minRows: 1, maxRows: 2 }}
              style={{ width: "25%" }}
              onChange={e => this.handleChange("var5", e.target.value)}
            />{" "}
            <br />
            Seat:{" "}
            <TextArea
              placeholder="maxLength is 30"
              maxLength={30}
              className="mb-1"
              autoSize={{ minRows: 1, maxRows: 2 }}
              style={{ width: "25%" }}
              onChange={e => this.handleChange("var6", e.target.value)}
            />{" "}
            <br />
            Track your bus:{" "}
            <TextArea
              placeholder="maxLength is 30"
              maxLength={30}
              className="mb-1"
              autoSize={{ minRows: 1, maxRows: 2 }}
              style={{ width: "25%" }}
              onChange={e => this.handleChange("var7", e.target.value)}
            />{" "}
            <br />
            Marshal Number:
            <br />
            For any queries please Whatsapp @ 8287009889
          </div>
        ) : null}
        {templateType === "trip_assignment_v7" ? (
          <div>
            Please find your journey details. Pickup OTP:{" "}
            <TextArea
              placeholder="maxLength is 30"
              maxLength={30}
              className="mb-1"
              autoSize={{ minRows: 1, maxRows: 2 }}
              style={{ width: "25%" }}
              onChange={e => this.handleChange("var1", e.target.value)}
            />{" "}
            <br />
            Bus Number:{" "}
            <TextArea
              placeholder="maxLength is 30"
              maxLength={30}
              className="mb-1"
              autoSize={{ minRows: 1, maxRows: 2 }}
              style={{ width: "25%" }}
              onChange={e => this.handleChange("var2", e.target.value)}
            />{" "}
            <br />
            Pickup Date and Time:{" "}
            <TextArea
              placeholder="maxLength is 30"
              maxLength={30}
              className="mb-1"
              autoSize={{ minRows: 1, maxRows: 2 }}
              style={{ width: "25%" }}
              onChange={e => this.handleChange("var3", e.target.value)}
            />{" "}
            <br />
            Pickup Point:{" "}
            <TextArea
              placeholder="maxLength is 60"
              maxLength={60}
              className="mb-1"
              autoSize={{ minRows: 1, maxRows: 2 }}
              style={{ width: "25%" }}
              onChange={e => this.handleChange("var4", e.target.value)}
            />{" "}
            <br />
            Route:{" "}
            <TextArea
              placeholder="maxLength is 30"
              maxLength={30}
              className="mb-1"
              autoSize={{ minRows: 1, maxRows: 2 }}
              style={{ width: "25%" }}
              onChange={e => this.handleChange("var5", e.target.value)}
            />{" "}
            to{" "}
            <TextArea
              placeholder="maxLength is 30"
              maxLength={30}
              className="mb-1"
              autoSize={{ minRows: 1, maxRows: 2 }}
              style={{ width: "25%" }}
              onChange={e => this.handleChange("var6", e.target.value)}
            />{" "}
            <br />
            PNR:{" "}
            <TextArea
              placeholder="maxLength is 30"
              maxLength={30}
              className="mb-1"
              autoSize={{ minRows: 1, maxRows: 2 }}
              style={{ width: "25%" }}
              onChange={e => this.handleChange("var7", e.target.value)}
            />{" "}
            <br />
            Seat:{" "}
            <TextArea
              placeholder="maxLength is 30"
              maxLength={30}
              className="mb-1"
              autoSize={{ minRows: 1, maxRows: 2 }}
              style={{ width: "25%" }}
              onChange={e => this.handleChange("var8", e.target.value)}
            />{" "}
            <br />
            Track your bus:{" "}
            <TextArea
              placeholder="maxLength is 30"
              maxLength={30}
              className="mb-1"
              autoSize={{ minRows: 1, maxRows: 2 }}
              style={{ width: "25%" }}
              onChange={e => this.handleChange("var9", e.target.value)}
            />{" "}
            <br />
            Marshal Number:{" "}
            <TextArea
              placeholder="maxLength is 30"
              maxLength={30}
              className="mb-1"
              autoSize={{ minRows: 1, maxRows: 2 }}
              style={{ width: "25%" }}
              onChange={e => this.handleChange("var10", e.target.value)}
            />{" "}
            <br />
            For any queries please Whatsapp @ 8287009889
          </div>
        ) : null}
        {templateType === "before_24_hours_cancellation_v3" ? (
          <div>
            Your zingbus ticket is cancelled Amount (if applicable) to be
            refunded to source account within 5-7 days. For any queries please
            Whatsapp @ 8287009889{" "}
          </div>
        ) : null}
        {templateType === "booking_modify_preponed_v1" ? (
          <div>
            Your Journey from{" "}
            <TextArea
              placeholder="maxLength is 30"
              maxLength={30}
              className="mb-1"
              autoSize={{ minRows: 1, maxRows: 2 }}
              style={{ width: "25%" }}
              onChange={e => this.handleChange("var1", e.target.value)}
            />{" "}
            to{" "}
            <TextArea
              placeholder="maxLength is 30"
              maxLength={30}
              className="mb-1"
              autoSize={{ minRows: 1, maxRows: 2 }}
              style={{ width: "25%" }}
              onChange={e => this.handleChange("var2", e.target.value)}
            />{" "}
            has been preponed.
            <br />
            PNR:{" "}
            <TextArea
              placeholder="maxLength is 30"
              maxLength={30}
              className="mb-1"
              autoSize={{ minRows: 1, maxRows: 2 }}
              style={{ width: "25%" }}
              onChange={e => this.handleChange("var3", e.target.value)}
            />{" "}
            <br />
            New Pickup Time:{" "}
            <TextArea
              placeholder="maxLength is 30"
              maxLength={30}
              className="mb-1"
              autoSize={{ minRows: 1, maxRows: 2 }}
              style={{ width: "25%" }}
              onChange={e => this.handleChange("var4", e.target.value)}
            />{" "}
          </div>
        ) : null}
        {templateType === "booking_modify_postponed_v1" ? (
          <div>
            Your Journey from{" "}
            <TextArea
              placeholder="maxLength is 30"
              maxLength={30}
              className="mb-1"
              autoSize={{ minRows: 1, maxRows: 2 }}
              style={{ width: "25%" }}
              onChange={e => this.handleChange("var1", e.target.value)}
            />{" "}
            to{" "}
            <TextArea
              placeholder="maxLength is 30"
              maxLength={30}
              className="mb-1"
              autoSize={{ minRows: 1, maxRows: 2 }}
              style={{ width: "25%" }}
              onChange={e => this.handleChange("var2", e.target.value)}
            />{" "}
            has been postponed.
            <br />
            PNR:{" "}
            <TextArea
              placeholder="maxLength is 30"
              maxLength={30}
              className="mb-1"
              autoSize={{ minRows: 1, maxRows: 2 }}
              style={{ width: "25%" }}
              onChange={e => this.handleChange("var3", e.target.value)}
            />{" "}
            <br />
            New Pickup Time:{" "}
            <TextArea
              placeholder="maxLength is 30"
              maxLength={30}
              className="mb-1"
              autoSize={{ minRows: 1, maxRows: 2 }}
              style={{ width: "25%" }}
              onChange={e => this.handleChange("var4", e.target.value)}
            />{" "}
          </div>
        ) : null}
        {templateType === "booking_modify_seat_change_v1" ? (
          <div>
            Your Journey from{" "}
            <TextArea
              placeholder="maxLength is 30"
              maxLength={30}
              className="mb-1"
              autoSize={{ minRows: 1, maxRows: 2 }}
              style={{ width: "25%" }}
              onChange={e => this.handleChange("var1", e.target.value)}
            />{" "}
            to{" "}
            <TextArea
              placeholder="maxLength is 30"
              maxLength={30}
              className="mb-1"
              autoSize={{ minRows: 1, maxRows: 2 }}
              style={{ width: "25%" }}
              onChange={e => this.handleChange("var2", e.target.value)}
            />{" "}
            has been preponed.
            <br />
            PNR:{" "}
            <TextArea
              placeholder="maxLength is 30"
              maxLength={30}
              className="mb-1"
              autoSize={{ minRows: 1, maxRows: 2 }}
              style={{ width: "25%" }}
              onChange={e => this.handleChange("var3", e.target.value)}
            />{" "}
            <br />
            New Pickup Time:{" "}
            <TextArea
              placeholder="maxLength is 30"
              maxLength={30}
              className="mb-1"
              autoSize={{ minRows: 1, maxRows: 2 }}
              style={{ width: "25%" }}
              onChange={e => this.handleChange("var4", e.target.value)}
            />{" "}
            Seat:{" "}
            <TextArea
              placeholder="maxLength is 30"
              maxLength={30}
              className="mb-1"
              autoSize={{ minRows: 1, maxRows: 2 }}
              style={{ width: "25%" }}
              onChange={e => this.handleChange("var5", e.target.value)}
            />{" "}
            ,{" "}
            <TextArea
              placeholder="maxLength is 30"
              maxLength={30}
              className="mb-1"
              autoSize={{ minRows: 1, maxRows: 2 }}
              style={{ width: "25%" }}
              onChange={e => this.handleChange("var6", e.target.value)}
            />{" "}
          </div>
        ) : null}
        {templateType === "after_24_hours_cancellation_v3" ? (
          <div>
            Sorry, we couldn&apos;t serve you this time. Your ticket has been
            cancelled. For any queries please WhatsApp @ 8287009889
          </div>
        ) : null}
        {templateType === "booking_confirm_connecting_v1" ? (
          <div>
            zingbus ticket is confirmed PNR:{" "}
            <TextArea
              placeholder="maxLength is 30"
              maxLength={30}
              className="mb-1"
              autoSize={{ minRows: 1, maxRows: 2 }}
              style={{ width: "25%" }}
              onChange={e => this.handleChange("var1", e.target.value)}
            />{" "}
            <br />
            Seat:{" "}
            <TextArea
              placeholder="maxLength is 30"
              maxLength={30}
              className="mb-1"
              autoSize={{ minRows: 1, maxRows: 2 }}
              style={{ width: "25%" }}
              onChange={e => this.handleChange("var2", e.target.value)}
            />{" "}
            <br />
            Please note your journey will be completed in two parts
            <TextArea
              placeholder="maxLength is 30"
              maxLength={30}
              className="mb-1"
              autoSize={{ minRows: 1, maxRows: 2 }}
              style={{ width: "25%" }}
              onChange={e => this.handleChange("var3", e.target.value)}
            />{" "}
            ,{" "}
            <TextArea
              placeholder="maxLength is 30"
              maxLength={30}
              className="mb-1"
              autoSize={{ minRows: 1, maxRows: 2 }}
              style={{ width: "25%" }}
              onChange={e => this.handleChange("var4", e.target.value)}
            />{" "}
            to{" "}
            <TextArea
              placeholder="maxLength is 30"
              maxLength={30}
              className="mb-1"
              autoSize={{ minRows: 1, maxRows: 2 }}
              style={{ width: "25%" }}
              onChange={e => this.handleChange("var5", e.target.value)}
            />{" "}
            ,{" "}
            <TextArea
              placeholder="maxLength is 30"
              maxLength={30}
              className="mb-1"
              autoSize={{ minRows: 1, maxRows: 2 }}
              style={{ width: "25%" }}
              onChange={e => this.handleChange("var6", e.target.value)}
            />{" "}
            <br />
            Layover:
            <TextArea
              placeholder="maxLength is 30"
              maxLength={30}
              className="mb-1"
              autoSize={{ minRows: 1, maxRows: 2 }}
              style={{ width: "25%" }}
              onChange={e => this.handleChange("var7", e.target.value)}
            />{" "}
            ,
            <TextArea
              placeholder="maxLength is 30"
              maxLength={30}
              className="mb-1"
              autoSize={{ minRows: 1, maxRows: 2 }}
              style={{ width: "25%" }}
              onChange={e => this.handleChange("var8", e.target.value)}
            />{" "}
            (Time and Location)
            <br />
            <TextArea
              placeholder="maxLength is 30"
              maxLength={30}
              className="mb-1"
              autoSize={{ minRows: 1, maxRows: 2 }}
              style={{ width: "25%" }}
              onChange={e => this.handleChange("var9", e.target.value)}
            />{" "}
            ,
            <TextArea
              placeholder="maxLength is 30"
              maxLength={30}
              className="mb-1"
              autoSize={{ minRows: 1, maxRows: 2 }}
              style={{ width: "25%" }}
              onChange={e => this.handleChange("var10", e.target.value)}
            />{" "}
            to{" "}
            <TextArea
              placeholder="maxLength is 30"
              maxLength={30}
              className="mb-1"
              autoSize={{ minRows: 1, maxRows: 2 }}
              style={{ width: "25%" }}
              onChange={e => this.handleChange("var11", e.target.value)}
            />{" "}
            ,
            <TextArea
              placeholder="maxLength is 30"
              maxLength={30}
              className="mb-1"
              autoSize={{ minRows: 1, maxRows: 2 }}
              style={{ width: "25%" }}
              onChange={e => this.handleChange("var12", e.target.value)}
            />{" "}
            <br />
            Pickup will be done from{" "}
            <TextArea
              placeholder="maxLength is 30"
              maxLength={30}
              className="mb-1"
              autoSize={{ minRows: 1, maxRows: 2 }}
              style={{ width: "25%" }}
              onChange={e => this.handleChange("var13", e.target.value)}
            />{" "}
            at{" "}
            <TextArea
              placeholder="maxLength is 30"
              maxLength={30}
              className="mb-1"
              autoSize={{ minRows: 1, maxRows: 2 }}
              style={{ width: "25%" }}
              onChange={e => this.handleChange("var14", e.target.value)}
            />{" "}
            <br />
            Track your bus:{" "}
            <TextArea
              placeholder="maxLength is 30"
              maxLength={30}
              className="mb-1"
              autoSize={{ minRows: 1, maxRows: 2 }}
              style={{ width: "25%" }}
              onChange={e => this.handleChange("var15", e.target.value)}
            />{" "}
          </div>
        ) : null}
        {templateType === "trip_assignment_1st_leg_v1" ? (
          <div>
            Please find your journey details for{" "}
            <TextArea
              placeholder="maxLength is 30"
              maxLength={30}
              className="mb-1"
              autoSize={{ minRows: 1, maxRows: 2 }}
              style={{ width: "25%" }}
              onChange={e => this.handleChange("var1", e.target.value)}
            />{" "}
            to{" "}
            <TextArea
              placeholder="maxLength is 30"
              maxLength={30}
              className="mb-1"
              autoSize={{ minRows: 1, maxRows: 2 }}
              style={{ width: "25%" }}
              onChange={e => this.handleChange("var2", e.target.value)}
            />{" "}
            <br />
            Boarding OTP:{" "}
            <TextArea
              placeholder="maxLength is 30"
              maxLength={30}
              className="mb-1"
              autoSize={{ minRows: 1, maxRows: 2 }}
              style={{ width: "25%" }}
              onChange={e => this.handleChange("var3", e.target.value)}
            />{" "}
            <br />
            Pickup Time:{" "}
            <TextArea
              placeholder="maxLength is 30"
              maxLength={30}
              className="mb-1"
              autoSize={{ minRows: 1, maxRows: 2 }}
              style={{ width: "25%" }}
              onChange={e => this.handleChange("var4", e.target.value)}
            />{" "}
            <br />
            Pick up point:{" "}
            <TextArea
              placeholder="maxLength is 60"
              maxLength={60}
              className="mb-1"
              autoSize={{ minRows: 1, maxRows: 2 }}
              style={{ width: "25%" }}
              onChange={e => this.handleChange("var5", e.target.value)}
            />{" "}
            <br />
            Marshal No:{" "}
            <TextArea
              placeholder="maxLength is 30"
              maxLength={30}
              className="mb-1"
              autoSize={{ minRows: 1, maxRows: 2 }}
              style={{ width: "25%" }}
              onChange={e => this.handleChange("var6", e.target.value)}
            />{" "}
            <br />
            Track your bus:{" "}
            <TextArea
              placeholder="maxLength is 30"
              maxLength={30}
              className="mb-1"
              autoSize={{ minRows: 1, maxRows: 2 }}
              style={{ width: "25%" }}
              onChange={e => this.handleChange("var7", e.target.value)}
            />{" "}
            <br />
            Please Note: Details of the 2nd trip will be shared in a short while
            <br />
            For any queries please Whatsapp @ 8287009889
          </div>
        ) : null}
        {templateType === "trip_assignment_2nd_leg_v1" ? (
          <div>
            Please find your journey details for{" "}
            <TextArea
              placeholder="maxLength is 30"
              maxLength={30}
              className="mb-1"
              autoSize={{ minRows: 1, maxRows: 2 }}
              style={{ width: "25%" }}
              onChange={e => this.handleChange("var1", e.target.value)}
            />{" "}
            to{" "}
            <TextArea
              placeholder="maxLength is 30"
              maxLength={30}
              className="mb-1"
              autoSize={{ minRows: 1, maxRows: 2 }}
              style={{ width: "25%" }}
              onChange={e => this.handleChange("var2", e.target.value)}
            />{" "}
            <br />
            Boarding OTP:{" "}
            <TextArea
              placeholder="maxLength is 30"
              maxLength={30}
              className="mb-1"
              autoSize={{ minRows: 1, maxRows: 2 }}
              style={{ width: "25%" }}
              onChange={e => this.handleChange("var3", e.target.value)}
            />{" "}
            <br />
            Pickup Time:{" "}
            <TextArea
              placeholder="maxLength is 30"
              maxLength={30}
              className="mb-1"
              autoSize={{ minRows: 1, maxRows: 2 }}
              style={{ width: "25%" }}
              onChange={e => this.handleChange("var4", e.target.value)}
            />{" "}
            <br />
            Pick up point:{" "}
            <TextArea
              placeholder="maxLength is 60"
              maxLength={60}
              className="mb-1"
              autoSize={{ minRows: 1, maxRows: 2 }}
              style={{ width: "25%" }}
              onChange={e => this.handleChange("var5", e.target.value)}
            />{" "}
            <br />
            Marshal No:{" "}
            <TextArea
              placeholder="maxLength is 30"
              maxLength={30}
              className="mb-1"
              autoSize={{ minRows: 1, maxRows: 2 }}
              style={{ width: "25%" }}
              onChange={e => this.handleChange("var6", e.target.value)}
            />{" "}
            <br />
            Track your bus:{" "}
            <TextArea
              placeholder="maxLength is 30"
              maxLength={30}
              className="mb-1"
              autoSize={{ minRows: 1, maxRows: 2 }}
              style={{ width: "25%" }}
              onChange={e => this.handleChange("var7", e.target.value)}
            />{" "}
            <br />
            For any queries please Whatsapp @ 8287009889
          </div>
        ) : null}
        {templateType === "connecting_booking_modify_v1" ? (
          <div>
            Your booking has been modified.
            <br />
            PNR:{" "}
            <TextArea
              placeholder="maxLength is 30"
              maxLength={30}
              className="mb-1"
              autoSize={{ minRows: 1, maxRows: 2 }}
              style={{ width: "25%" }}
              onChange={e => this.handleChange("var1", e.target.value)}
            />{" "}
            <br />
            Seat:{" "}
            <TextArea
              placeholder="maxLength is 30"
              maxLength={30}
              className="mb-1"
              autoSize={{ minRows: 1, maxRows: 2 }}
              style={{ width: "25%" }}
              onChange={e => this.handleChange("var2", e.target.value)}
            />{" "}
            ,{" "}
            <TextArea
              placeholder="maxLength is 30"
              maxLength={30}
              className="mb-1"
              autoSize={{ minRows: 1, maxRows: 2 }}
              style={{ width: "25%" }}
              onChange={e => this.handleChange("var3", e.target.value)}
            />{" "}
            <br />
            Pickup Point:{" "}
            <TextArea
              placeholder="maxLength is 30"
              maxLength={30}
              className="mb-1"
              autoSize={{ minRows: 1, maxRows: 2 }}
              style={{ width: "25%" }}
              onChange={e => this.handleChange("var4", e.target.value)}
            />{" "}
            ,
            <TextArea
              placeholder="maxLength is 30"
              maxLength={30}
              className="mb-1"
              autoSize={{ minRows: 1, maxRows: 2 }}
              style={{ width: "25%" }}
              onChange={e => this.handleChange("var5", e.target.value)}
            />{" "}
            <br />
            Layover:{" "}
            <TextArea
              placeholder="maxLength is 30"
              maxLength={30}
              className="mb-1"
              autoSize={{ minRows: 1, maxRows: 2 }}
              style={{ width: "25%" }}
              onChange={e => this.handleChange("var6", e.target.value)}
            />{" "}
            ,{" "}
            <TextArea
              placeholder="maxLength is 30"
              maxLength={30}
              className="mb-1"
              autoSize={{ minRows: 1, maxRows: 2 }}
              style={{ width: "25%" }}
              onChange={e => this.handleChange("var7", e.target.value)}
            />{" "}
          </div>
        ) : null}
        {templateType === "delay_in_pickup_v1" ? (
          <div>
            Due to{" "}
            <TextArea
              placeholder="maxLength is 30"
              maxLength={30}
              className="mb-1"
              autoSize={{ minRows: 1, maxRows: 2 }}
              style={{ width: "25%" }}
              onChange={e => this.handleChange("var1", e.target.value)}
            />{" "}
            your bus will be delayed by{" "}
            <TextArea
              placeholder="maxLength is 30"
              maxLength={30}
              className="mb-1"
              autoSize={{ minRows: 1, maxRows: 2 }}
              style={{ width: "25%" }}
              onChange={e => this.handleChange("var2", e.target.value)}
            />{" "}
            to reach your pickup point. We apologize for the discomfort. For any
            queries please Whatsapp @ 8287009889
          </div>
        ) : null}
        {templateType === "delay_in_drop_v1" ? (
          <div>
            Due to{" "}
            <TextArea
              placeholder="maxLength is 30"
              maxLength={30}
              className="mb-1"
              autoSize={{ minRows: 1, maxRows: 2 }}
              style={{ width: "25%" }}
              onChange={e => this.handleChange("var1", e.target.value)}
            />{" "}
            your bus will be delayed by{" "}
            <TextArea
              placeholder="maxLength is 30"
              maxLength={30}
              className="mb-1"
              autoSize={{ minRows: 1, maxRows: 2 }}
              style={{ width: "25%" }}
              onChange={e => this.handleChange("var2", e.target.value)}
            />{" "}
            to reach your drop point. We apologize for the discomfort. For any
            queries please Whatsapp @ 8287009889
          </div>
        ) : null}
        {templateType === "drop_point_update_v1" ? (
          <div>
            Due to{" "}
            <TextArea
              placeholder="maxLength is 30"
              maxLength={30}
              className="mb-1"
              autoSize={{ minRows: 1, maxRows: 2 }}
              style={{ width: "25%" }}
              onChange={e => this.handleChange("var1", e.target.value)}
            />{" "}
            your de-boarding point and time is changed. Now your pickup will be
            done from
            <TextArea
              placeholder="maxLength is 30"
              maxLength={30}
              className="mb-1"
              autoSize={{ minRows: 1, maxRows: 2 }}
              style={{ width: "25%" }}
              onChange={e => this.handleChange("var2", e.target.value)}
            />{" "}
            . For any queries please Whatsapp @ 8287009889
          </div>
        ) : null}
        {templateType === "share_marshal_number_v1" ? (
          <div>
            Due to some{" "}
            <TextArea
              placeholder="maxLength is 30"
              maxLength={30}
              className="mb-1"
              autoSize={{ minRows: 1, maxRows: 2 }}
              style={{ width: "25%" }}
              onChange={e => this.handleChange("var1", e.target.value)}
            />{" "}
            You can contact our marshal directly at{" "}
            <TextArea
              placeholder="maxLength is 30"
              maxLength={30}
              className="mb-1"
              autoSize={{ minRows: 1, maxRows: 2 }}
              style={{ width: "25%" }}
              onChange={e => this.handleChange("var2", e.target.value)}
            />{" "}
            .For any queries please Whatsapp @ 8287009889
          </div>
        ) : null}
        {templateType === "crew_misbehaviour_v1" ? (
          <div>
            We apologize for the discomfort in Journey. The incident has been
            bought into our notice and we&apos;ll ensure that this is not
            repeated. For any queries please Whatsapp @ 8287009889
          </div>
        ) : null}
        {templateType === "breakdown_v2" ? (
          <div>
            Your zingbus is cancelled due to{" "}
            <TextArea
              placeholder="maxLength is 30"
              maxLength={30}
              className="mb-1"
              autoSize={{ minRows: 1, maxRows: 2 }}
              style={{ width: "25%" }}
              onChange={e => this.handleChange("var1", e.target.value)}
            />{" "}
            .{" "}
            <TextArea
              placeholder="maxLength is 30"
              maxLength={30}
              className="mb-1"
              autoSize={{ minRows: 1, maxRows: 2 }}
              style={{ width: "25%" }}
              onChange={e => this.handleChange("var2", e.target.value)}
            />{" "}
            refund of your booking amount is processed from our side. For any
            queries please Whatsapp @ 8287009889
          </div>
        ) : null}
        {templateType === "ac_not_working_v1" ? (
          <div>
            <TextArea
              placeholder="maxLength is 30"
              maxLength={30}
              className="mb-1"
              autoSize={{ minRows: 1, maxRows: 2 }}
              style={{ width: "25%" }}
              onChange={e => this.handleChange("var1", e.target.value)}
            />{" "}
            refund of your booking amount is processed from our side as the AC
            was not working. We apologize for the discomfort. For any queries
            please Whatsapp @ 8287009889
          </div>
        ) : null}
        {templateType === "charging_point_wifi_v1" ? (
          <div>
            Due to{" "}
            <TextArea
              placeholder="maxLength is 30"
              maxLength={30}
              className="mb-1"
              autoSize={{ minRows: 1, maxRows: 2 }}
              style={{ width: "25%" }}
              onChange={e => this.handleChange("var1", e.target.value)}
            />{" "}
            failure,{" "}
            <TextArea
              placeholder="maxLength is 30"
              maxLength={30}
              className="mb-1"
              autoSize={{ minRows: 1, maxRows: 2 }}
              style={{ width: "25%" }}
              onChange={e => this.handleChange("var2", e.target.value)}
            />{" "}
            was not working. We have added{" "}
            <TextArea
              placeholder="maxLength is 30"
              maxLength={30}
              className="mb-1"
              autoSize={{ minRows: 1, maxRows: 2 }}
              style={{ width: "25%" }}
              onChange={e => this.handleChange("var3", e.target.value)}
            />{" "}
            zingcash in your account as the token of apology. For any queries
            please WhatsApp @ 8287009889
          </div>
        ) : null}
        {templateType === "route_change_v1" ? (
          <div>
            Due to{" "}
            <TextArea
              placeholder="maxLength is 30"
              maxLength={30}
              className="mb-1"
              autoSize={{ minRows: 1, maxRows: 2 }}
              style={{ width: "25%" }}
              onChange={e => this.handleChange("var1", e.target.value)}
            />{" "}
            , the route was changed. We apologize for any discomfort it may have
            caused you. For any queries please WhatsApp @ 8287009889
          </div>
        ) : null}
        {templateType === "rest_stop_change_v1" ? (
          <div>
            Due to{" "}
            <TextArea
              placeholder="maxLength is 30"
              maxLength={30}
              className="mb-1"
              autoSize={{ minRows: 1, maxRows: 2 }}
              style={{ width: "25%" }}
              onChange={e => this.handleChange("var1", e.target.value)}
            />{" "}
            , the rest stop was{" "}
            <TextArea
              placeholder="maxLength is 30"
              maxLength={30}
              className="mb-1"
              autoSize={{ minRows: 1, maxRows: 2 }}
              style={{ width: "25%" }}
              onChange={e => this.handleChange("var2", e.target.value)}
            />{" "}
            . We apologize for any discomfort it may have caused you. For any
            queries please WhatsApp @ 8287009889
          </div>
        ) : null}
        {templateType === "tech_related_issues_v1" ? (
          <div>
            Due to tech issues , you might have received{" "}
            <TextArea
              placeholder="maxLength is 30"
              maxLength={30}
              className="mb-1"
              autoSize={{ minRows: 1, maxRows: 2 }}
              style={{ width: "25%" }}
              onChange={e => this.handleChange("var1", e.target.value)}
            />{" "}
            . Correct details have been sent to you. Please refer to the latest
            communication received on WhatsApp/SMS. For any queries please
            WhatsApp @ 8287009889
          </div>
        ) : null}
        {templateType === "not_boarded_v1" ? (
          <div>
            We have observed that you have not boarded the bus, please confirm
            if you want us to reschedule the bus.
          </div>
        ) : null}
        {templateType === "pickup_update_lt_24_hours_v1" ? (
          <div>
            Due to{" "}
            <TextArea
              placeholder="maxLength is 30"
              maxLength={30}
              className="mb-1"
              autoSize={{ minRows: 1, maxRows: 2 }}
              style={{ width: "25%" }}
              onChange={e => this.handleChange("var1", e.target.value)}
            />{" "}
            your boarding point and time is changed. Now your pickup will be
            done from
            <TextArea
              placeholder="maxLength is 30"
              maxLength={30}
              className="mb-1"
              autoSize={{ minRows: 1, maxRows: 2 }}
              style={{ width: "25%" }}
              onChange={e => this.handleChange("var2", e.target.value)}
            />{" "}
            . We will reimburse the cab charges (if applicable). TC applied. For
            any queries please WhatsApp @ 8287009889
          </div>
        ) : null}
      </>
    );
  };

  handleSendClickTemp = () => {
    const {
      var1,
      var2,
      var3,
      var4,
      var5,
      var6,
      var7,
      var8,
      var9,
      var10,
      var11,
      var12,
      var13,
      var14,
      var15,
      templateType,
      sendSMSMessage,
      sendWhatsAppMessage
    } = this.state;
    const { onClick } = this.props;
    let vars = [];

    if (!sendSMSMessage && !sendWhatsAppMessage) {
      message.error("please select the platform for message delivery");
      return;
    }

    if (templateType === "booking_confirmation_v8_1") {
      vars = [var1, var2, var3, var4, var5, var6, var7];
    } else if (templateType === "trip_assignment_v7") {
      vars = [var1, var2, var3, var4, var5, var6, var7, var8, var9, var10];
    } else if (templateType === "before_24_hours_cancellation_v3") {
      vars = [];
    } else if (templateType === "booking_modify_preponed_v1") {
      vars = [var1, var2, var3, var4];
    } else if (templateType === "booking_modify_postponed_v1") {
      vars = [var1, var2, var3, var4];
    } else if (templateType === "booking_modify_seat_change_v1") {
      vars = [var1, var2, var3, var4, var5, var6];
    } else if (templateType === "after_24_hours_cancellation_v3") {
      vars = [];
    } else if (templateType === "booking_confirm_connecting_v1") {
      vars = [
        var1,
        var2,
        var3,
        var4,
        var5,
        var6,
        var7,
        var8,
        var9,
        var10,
        var11,
        var12,
        var13,
        var14,
        var15
      ];
    } else if (templateType === "trip_assignment_1st_leg_v1") {
      vars = [var1, var2, var3, var4, var5, var6, var7];
    } else if (templateType === "trip_assignment_2nd_leg_v1") {
      vars = [var1, var2, var3, var4, var5, var6, var7];
    } else if (templateType === "connecting_booking_modify_v1") {
      vars = [var1, var2, var3, var4, var5, var6, var7];
    } else if (templateType === "delay_in_pickup_v1") {
      vars = [var1, var2];
    } else if (templateType === "delay_in_drop_v1") {
      vars = [var1, var2];
    } else if (templateType === "drop_point_update_v1") {
      vars = [var1, var2];
    } else if (templateType === "share_marshal_number_v1") {
      vars = [var1, var2];
    } else if (templateType === "crew_misbehaviour_v1") {
      vars = [];
    } else if (templateType === "breakdown_v2") {
      vars = [var1, var2];
    } else if (templateType === "ac_not_working_v1") {
      vars = [var1];
    } else if (templateType === "charging_point_wifi_v1") {
      vars = [var1, var2, var3];
    } else if (templateType === "route_change_v1") {
      vars = [var1];
    } else if (templateType === "rest_stop_change_v1") {
      vars = [var1, var2];
    } else if (templateType === "tech_related_issues_v1") {
      vars = [var1];
    } else if (templateType === "not_boarded_v1") {
      vars = [];
    } else if (templateType === "pickup_update_lt_24_hours_v1") {
      vars = [var1, var2];
    }
    onClick(templateType, vars, sendSMSMessage, sendWhatsAppMessage);

    this.setState({
      vars: [],
      var1: null,
      var2: null,
      var3: null,
      var4: null,
      var5: null,
      var6: null,
      var7: null,
      var8: null,
      var9: null,
      var10: null,
      var11: null,
      var12: null,
      var13: null,
      var14: null,
      var15: null,
      showMessageOption: false,
      templateType: "",
      sendSMSMessage: false,
      sendWhatsAppMessage: false,
      trackingLink: false
    });
  };

  handleSendClick = () => {
    const {
      var1,
      var2,
      var3,
      var4,
      var5,
      trackingLink,
      templateType
    } = this.state;
    const { onClick } = this.props;
    let vars = [];
    let parts = null;
    let parts1 = null;
    let parts2 = null;
    let parts3 = null;
    let parts4 = null;
    let parts5 = null;
    let subpart = null;
    if (templateType === "trip_delayed_v2") {
      if (
        var1 !== undefined ||
        var2 !== undefined ||
        var3 !== undefined ||
        var4 !== undefined ||
        var5 !== undefined
      ) {
        parts1 = var1.match(/[\s\S]{1,30}/g) || [];
        parts2 = var2.match(/[\s\S]{1,30}/g) || [];
        parts3 = var3.match(/[\s\S]{1,30}/g) || [];
        parts4 = var4.match(/[\s\S]{1,30}/g) || [];
        parts5 = var5.match(/[\s\S]{1,30}/g) || [];
        if (parts2[1] === undefined) {
          parts2[1] = "";
          parts2[2] = "";
        }
        if (parts5[1] === undefined) {
          parts5[1] = "";
          parts5[2] = "";
        }

        vars = [
          parts1[0],
          parts2[0],
          parts2[1],
          parts2[2],
          parts3[0],
          parts4[0],
          parts5[0],
          parts5[1],
          parts5[2]
        ];
      }
    } else if (templateType === "bus_cancel_refund_sms") {
      if (var1 !== undefined || var2 !== undefined || var3 !== undefined) {
        parts = var1.match(/[\s\S]{1,30}/g) || [];
        parts2 = var2.match(/[\s\S]{1,30}/g) || [];
        parts3 = var3.match(/[\s\S]{1,30}/g) || [];
        parts4 = ["8287009889"];
        if (parts[1] === undefined) {
          parts[1] = "";
        }
        vars = [parts[0], parts[1], parts2[0], parts3[0], parts4[0]];
      }
    } else if (templateType === "zingcash_apology_msg_updated") {
      if (var1 !== undefined) {
        parts = var1.match(/[\s\S]{1,30}/g) || [];
        parts2 = ["8287009889"];
        vars = [parts[0], parts2[0]];
      }
    } else if (templateType === "pickup_updated_sms") {
      if (var1 !== undefined || var2 !== undefined) {
        {
          parts = var1.match(/[\s\S]{1,30}/g) || [];
          subpart = var2.match(/[\s\S]{1,30}/g) || [];
          parts2 = ["8287009889"];
        }
        // console.log("======parts", parts[1], parts[0]);
        if (parts[1] === undefined) {
          parts[1] = "";
        }
        if (subpart[1] === undefined) {
          subpart[1] = "";
          subpart[2] = "";
          subpart[3] = "";
        } else if (subpart[2] === undefined) {
          subpart[2] = "";
          subpart[3] = "";
        } else if (subpart[3] === undefined) {
          subpart[3] = "";
        }
        vars = [
          parts[0],
          parts[1],
          subpart[0],
          subpart[1],
          subpart[2],
          subpart[3],
          parts2[0]
        ];
      }
    } else if (templateType === "amenities_apology_sms") {
      if (var1 !== undefined || var2 !== undefined) {
        {
          parts = var1.match(/[\s\S]{1,30}/g) || [];
          parts2 = var2.match(/[\s\S]{1,30}/g) || [];
          parts3 = ["8287009889"];
        }
        if (parts[1] === undefined) {
          parts[1] = "";
          parts[2] = "";
          parts[3] = "";
        } else if (parts[2] === undefined) {
          parts[2] = "";
          parts[3] = "";
        } else if (parts[3] === undefined) {
          parts[3] = "";
        }
        vars = [parts[0], parts[1], parts[2], parts[3], parts2[0], parts3[0]];
      }
    } else if (templateType === "share_marshal_number_sms") {
      if (var1 !== undefined || var2 !== undefined) {
        parts = var1.match(/[\s\S]{1,30}/g) || [];
        parts2 = var2.match(/[\s\S]{1,30}/g) || [];
        parts3 = ["8287009889"];
        if (parts[1] === undefined) {
          parts[1] = "";
          parts[2] = "";
          parts[3] = "";
        } else if (parts[2] === undefined) {
          parts[2] = "";
          parts[3] = "";
        } else if (parts[3] === undefined) {
          parts[3] = "";
        }
        if (parts2[1] === undefined) {
          parts2[1] = "";
        }
        vars = [
          parts[0],
          parts[1],
          parts[2],
          parts[3],
          parts2[0],
          parts2[1],
          parts3[0]
        ];
      }
    } else if (templateType === "issue_refund_v1") {
      if (var1 !== undefined || var2 !== undefined || var3 !== undefined) {
        parts = var1.match(/[\s\S]{1,30}/g) || [];
        parts2 = var2.match(/[\s\S]{1,30}/g) || [];
        parts3 = var3.match(/[\s\S]{1,30}/g) || [];
        parts4 = var4.match(/[\s\S]{1,30}/g) || [];
        parts5 = var5.match(/[\s\S]{1,30}/g) || [];
        if (parts4[1] === undefined) {
          parts4[1] = "";
          parts4[2] = "";
        } else if (parts4[2] === undefined) {
          parts4[2] = "";
        }
        if (parts2[1] === undefined) {
          parts2[1] = "";
        }
        vars = [
          parts[0],
          parts2[0],
          parts3[0],
          parts4[0],
          parts4[1],
          parts4[2],
          parts5[0]
        ];
      }
    }
    onClick(templateType, vars, trackingLink);

    this.setState({
      showMessageOption: false,
      templateType: "",
      trackingLink: false
    });
  };

  handleCreditClick = async () => {
    const { tabSelected, zingcashCreditValue, zingcashCredit } = this.state;
    if (tabSelected === "1" && zingcashCredit && zingcashCreditValue) {
      if (this.props.selectedStation) {
        const url = "/bus-tracker/sendzingcash";

        const options = {
          method: "POST",
          data: {
            stationType: this.props.stationType === 1 ? "PICKUP" : "DROP",
            tripId: this.props.tripId,
            zingcashCreditValue,
            zingcashCredit,
            bookings: this.props.selectedBookings,
            stations: this.props.selectedStation
          }
        };
        const response = await Fetch(url, options);
        if (response.length) {
          message.success("Zingcash Credited ");
        } else {
          message.error("Please select Pickup or Drop button Correctly");
        }
      } else if (this.props.selectedBookings) {
        const url = "/bus-tracker/sendzingcash";

        const options = {
          method: "POST",
          data: {
            zingcashCreditValue,
            zingcashCredit,
            bookings: this.props.selectedBookings
          }
        };
        const response = await Fetch(url, options);
        // console.log("response", response);

        if (response.length) {
          message.success("Zingcash Credited ");
        } else {
          message.error("Zingcash Cannot be Credited ");
        }
      } else {
        message.error("Please select Pickup or Drop button");
      }
    } else {
      message.error("Zingcash Cannot be Credited ");
    }
    this.setState({
      showMessageOption: false,
      zingcashCreditValue: "",
      zingcashCredit: false
    });
  };

  render() {
    const {
      showMessageOption,
      templateType,
      sendSMSMessage,
      sendWhatsAppMessage
    } = this.state;

    return (
      <div>
        {showMessageOption ? (
          <Modal
            title="Send Message"
            footer={null}
            visible={true}
            centered
            width={"80%"}
            onCancel={() =>
              this.setState({
                showMessageOption: false,
                templateType: "",
                zingcashCreditValue: "",
                zingcashCredit: false
              })
            }
          >
            <div>{this.customWhatsAppMessage(templateType)}</div>
            <br />
            <Checkbox
              disabled={!whatsAppTemplates.includes(templateType)}
              checked={sendWhatsAppMessage}
              onChange={e =>
                this.setState({
                  sendWhatsAppMessage: e.target.checked
                })
              }
            >
              WhatsApp
            </Checkbox>
            <Checkbox
              disabled={!SMSTemplates.includes(templateType)}
              checked={sendSMSMessage}
              onChange={e =>
                this.setState({
                  sendSMSMessage: e.target.checked
                })
              }
            >
              SMS
            </Checkbox>

            <Button
              type="primary"
              onClick={this.handleSendClickTemp}
              style={{ margin: "1% 0" }}
            >
              Send
            </Button>
          </Modal>
        ) : (
          <Button type="primary" onClick={this.toggleMessageOption}>
            Send Message
          </Button>
        )}
      </div>
    );
  }
}

export default SendMessage;
