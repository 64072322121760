import React, { Component } from "react";
import moment from "moment";
import styles from "./waitingLounge.module.scss";
import Logo from "../../images/logo-zingbus.svg";

import { getHoursAndMinFromMillis } from "../../utils/common";
import { Link } from "react-router-dom";
import { Table, Carousel } from "antd";
import Fetch from "../../utils/fetch";
const wordBreakStyle = { wordBreak: "keep-all" };

const columns = [
  {
    title: "BUS NUMBER",
    width: "10%",
    dataIndex: "bus",
    render: ({ number }) => <span style={wordBreakStyle}>{number}&ensp;</span>
  },
  {
    title: "SCHEDULED TIME",
    width: "10%",
    dataIndex: "time"
  },
  {
    title: "STATUS",
    dataIndex: "status",
    width: "10%",
    render: data => (
      <div
        style={{
          color: data.color,
          fontFamily: "SF-Pro-Bold",
          wordBreak: "keep-all"
        }}
      >
        {data.value}
      </div>
    )
  },
  {
    title: "EXPECTED TIME",
    width: "10%",
    dataIndex: "eta"
  },
  {
    title: "DESTINATION",
    width: "10%",
    dataIndex: "destination",
    render: ({ name }) => <span style={wordBreakStyle}>{name}&ensp;</span>
  }
];

class WaitingLounge extends Component {
  state = {
    showAds: false,
    loading: true,
    trips: [],
    currentPage: 1
  };

  inputRef = React.createRef();

  async componentDidMount() {
    try {
      const { stationId } = this.props.match.params;
      const tripDetailsPromise = await this.getTrips(stationId);
      const marketingCardsPromise = this.getMarketingCards();

      let [tripDetails, marketingCardsData] = await Promise.all([
        tripDetailsPromise,
        marketingCardsPromise
      ]);

      if (tripDetails != "No data found" && tripDetails.length > 0) {
        this.formattrip(tripDetails);
      }

      this.interval = setInterval(() => {
        this.newPage();
      }, 30000);

      this.setState({
        marketingCardsData,
        stationId,
        loading: false
      });
    } catch (err) {
      console.log(err);
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  onAdChange = value => {
    console.log("value", value);
    if (value == 0) {
      this.setState({ showAds: false });
    }
  };

  formattrip = trip => {
    let formattedStation = [];
    trip.forEach(obj => {
      const status = { value: "ON TIME", color: "#00D709" };
      const timeDiff = obj.stationDetails.expectedReachingTime
        ? obj.stationDetails.expectedReachingTime -
          obj.stationDetails.scheduleReachingTime
        : null;
      if (!timeDiff) {
        status.value = `NA`;
        status.color = "red";
      }
      if (timeDiff !== null && timeDiff > 0) {
        status.value = `DELAYED`;
        status.color = "white";
      }
      if (obj.stationDetails.stationMarked) {
        status.value = "REACHED";
      }
      let formattedObj = {
        key: obj.stationDetails._id,
        time: moment(obj.stationDetails.scheduleReachingTime).format("LT"),
        bus: {
          number: `${obj.stationDetails.busRc}`
        },
        status: status,
        destination: {
          name: `${obj.lastCityName}`
        },
        eta:
          status.value !== `NA` || !obj.stationDetails.stationMarked
            ? status.value == `ON TIME`
              ? getHoursAndMinFromMillis(
                  obj.stationDetails.scheduleReachingTime - Date.now()
                )
              : obj.stationDetails.expectedReachingTime > 0
              ? getHoursAndMinFromMillis(
                  obj.stationDetails.expectedReachingTime - Date.now()
                )
              : "- -"
            : "- -"
      };
      formattedStation.push(formattedObj);
    });
    this.setState({ trips: formattedStation });
    return;
  };

  getTrips = async stationId => {
    try {
      const url = `/bus-tracker/FetchStation?stationId=${stationId}`;

      const options = {
        method: "get"
      };

      const response = await Fetch(url, options);

      if (response) {
        return response;
      } else {
        return [];
      }
    } catch (err) {
      console.log(err);

      return [];
    }
  };

  getMarketingCards = async () => {
    try {
      const path = "/marketing/selfHelpPage?platform=WAITINGLOUNGE";
      const options = {
        method: "get"
      };

      const marketingCards = await Fetch(path, options);

      return marketingCards;
    } catch (err) {
      console.log(err);
    }
  };

  newPage = async () => {
    const {
      currentPage,
      trips,
      stationId,
      marketingCardsData,
      showAds
    } = this.state;
    const totalPages = Math.ceil(trips.length / 5);
    let newCurrentPage = currentPage + 1;
    if (newCurrentPage > totalPages) {
      if (marketingCardsData) {
        this.setState({ showAds: true });
      }
      if (!showAds) {
        newCurrentPage = 1;

        const tripDetails = await this.getTrips(stationId);

        if (tripDetails != "No data found" && tripDetails.length > 0) {
          this.formattrip(tripDetails);
        }
      }
    }

    if (newCurrentPage !== currentPage) {
      this.setState({ currentPage: newCurrentPage });
    }
  };

  handleChange = page => {
    this.setState({
      currentPage: page
    });
  };

  render() {
    const {
      loading,
      trips,
      currentPage,
      showAds,
      marketingCardsData
    } = this.state;
    if (loading) {
      return "Loading";
    } else if (trips.length > 0) {
      return !showAds ? (
        <div>
          <div id="webHeader" style={{ padding: "10px" }}>
            <div className={styles.webHeaderImageContainer}>
              <Link to="/">
                <img src={Logo} alt="zingbus logo" />
              </Link>
            </div>
            <div className={styles.webHeadertvContainer}>
              <span style={{ fontfamily: "SF-Pro-Bold" }}>
                {moment(Date.now()).format("LLL")}
              </span>
            </div>
          </div>

          <div style={{ height: "90vh" }}>
            <Table
              rowClassName={trips =>
                trips.status.value === "ON TIME"
                  ? styles.table_row_dark
                  : styles.table_row_light
              }
              columns={columns}
              dataSource={trips}
              pagination={{
                current: currentPage, //newly added
                onChange: this.handleChange, //newly added
                defaultPageSize: 6
              }}
              className={styles.booking_information_table}
              onChange={this.onChange}
            />
          </div>
        </div>
      ) : (
        <div>
          <Carousel
            autoplay
            afterChange={this.onAdChange}
            autoplaySpeed={10000}
          >
            {marketingCardsData.PLATINUM.map((obj, index) => (
              <div key={index}>
                <img
                  style={{ width: " -webkit-fill-available" }}
                  src={obj.link}
                />
              </div>
            ))}
          </Carousel>
        </div>
      );
    } else {
      return (
        <div>
          <h1>No trips found</h1>
        </div>
      );
    }
  }
}

export default WaitingLounge;
