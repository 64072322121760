import React, { Component } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import {
  EditOutlined,
  PlayCircleOutlined,
  CopyOutlined
} from "@ant-design/icons";
import SendMessage from "../sendMessage";
import SendRefund from "../sendRefund";
import SendSelfHelpMessage from "../SendSelfHelpMessage";
import SendCoupon from "../sendCoupon";

import {
  Input,
  Button,
  DatePicker,
  Select,
  AutoComplete,
  Modal,
  message,
  Table,
  Tag
} from "antd";
import Fetch from "../../utils/fetch";
import EditBooking from "../editBooking";
import swapIcon from "../../images/swap-icon.svg";
import { getSeatNumberFromTripChart } from "../../utils/common";
import InitiateComplain from "../InitiateComplain";
import StationView from "../stationView";
import CrewDetails from "../../components/crewDetails";
import ZingPrime from "../../components/zingPrime";

const { Option } = Select;
const wordBreakStyle = { wordBreak: "breakAll" };
function msToTime(duration) {
  let seconds = Math.floor((duration / 1000) % 60);
  let minutes = Math.floor((duration / (1000 * 60)) % 60);
  let hours = Math.floor((duration / (1000 * 60 * 60)) % 24);
  hours = hours < 10 ? "0" + hours : hours;
  minutes = minutes < 10 ? "0" + minutes : minutes;
  seconds = seconds < 10 ? "0" + seconds : seconds;

  return hours + " hrs :" + minutes + " min :" + seconds + " sec";
}

const columns = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    width: "8%",
    render: ({ value, copyCustomerDetails }) => (
      <>
        <span style={wordBreakStyle}>{value}&ensp;</span>
        <CopyOutlined onClick={copyCustomerDetails} />
      </>
    )
  },
  {
    title: "Mobile Number",
    dataIndex: "mobileNo",
    key: "mobileNo",
    width: "8%",
    render: ({ value, onChange }) => (
      <>
        <EditOutlined onClick={onChange} />
        <span style={wordBreakStyle}>{value}</span>
      </>
    )
  },
  {
    title: "PNR",
    dataIndex: "bookingCode",
    width: "8%",
    key: "bookingCode"
  },
  {
    title: "Email ID",
    dataIndex: "email",
    key: "email",
    width: "8%",
    render: ({ value, onChange }) => (
      <>
        <span style={wordBreakStyle}>{value}</span>
        <EditOutlined onClick={onChange} /> <br />
      </>
    )
  },
  {
    title: "Seats",
    dataIndex: "seatChart",
    width: "8%",
    key: "seatChart"
  },
  {
    title: "From Station",
    dataIndex: "fromStation",
    width: "8%",
    key: "fromStation",
    render: ({ name, onChange, date }) => (
      <>
        <EditOutlined onClick={onChange} />
        <span style={wordBreakStyle}>{name}</span>
        <br></br>
        <span>{date}</span>
      </>
    )
  },
  {
    title: "To Station",
    dataIndex: "toStation",
    width: "8%",
    key: "toStation",
    render: ({ name, onChange, date }) => (
      <>
        <EditOutlined onClick={onChange} />
        <span style={wordBreakStyle}>{name}</span>
        <br></br>
        <span>{date}</span>
      </>
    )
  },
  {
    title: "Demand Source",
    dataIndex: "demandSource",
    key: "demandSource",
    width: "8%"
  },
  {
    title: "Action",
    dataIndex: "action",
    key: "action",
    width: "10%",
    render: ({
      tripId,
      customerTrackingLink,
      copyCustomerTrackingLink,
      showModal,
      showMessagesInfo
    }) => {
      return (
        <>
          <Link to={`/track/${tripId}`} target="_blank">
            <Button className="mb-1">Track Bus</Button>
          </Link>
          <a href={customerTrackingLink} target="_blank" rel="noreferrer">
            <Button className="mb-1">Customer Link</Button>
          </a>
          <Button onClick={copyCustomerTrackingLink}>
            Share Customer Link
          </Button>
          <Button onClick={showMessagesInfo}>Messages Info</Button>
          <Button onClick={showModal}>Show more Details</Button>
        </>
      );
    }
  }
];

const moreDetailsColumns = [
  {
    title: "Status",
    dataIndex: "status",
    width: "8%",
    key: "status"
  },
  {
    title: "Bus Number",
    dataIndex: "busNumber",
    width: "8%",
    key: "busNumber"
  },
  {
    title: "Service Name",
    dataIndex: "service",
    width: "8%",
    key: "service"
  },
  {
    title: "ZingPrime Details",
    dataIndex: "isZingPrimeUser",
    key: "isZingPrimeUser",
    width: "10%",
    render: ({ isZingPrimeUser, showModal }) => {
      if (isZingPrimeUser) {
        return <Button onClick={showModal}>View</Button>;
      } else {
        return;
      }
    }
  },
  {
    title: "Cash Collection",
    dataIndex: "amountToBeCollected",
    width: "8%",
    key: "amountToBeCollected",
    render: ({ value, agentId, onChange }) =>
      agentId ? (
        <>
          <EditOutlined onClick={onChange} />
          <span style={wordBreakStyle}>{value}</span>
        </>
      ) : (
        <span style={wordBreakStyle}>{value}</span>
      )
  },
  {
    title: "Call attempted",
    dataIndex: "callAttempted",
    key: "callAttempted",
    width: "8%"
  },
  {
    title: "Call Records",
    dataIndex: "callRecords",
    key: "callRecords",
    width: "8%",
    render: ({ data = [], from }) => {
      if (data.length) {
        return data.map((call, index) => (
          <div key={index} className="mb-1">
            {call.recording ? (
              <>
                <Link to={call.recording} target="_blank">
                  <PlayCircleOutlined />
                </Link>
                <br></br>
                {call.callfrom == from ? (
                  <div>
                    {"I-"}
                    {moment(call.date).format("lll")}
                    <br />
                    {call.callDuration}
                  </div>
                ) : (
                  <div>
                    {"O-"}
                    {moment(call.date).format("lll")}
                    <br />
                    {call.callDuration}
                  </div>
                )}
              </>
            ) : (
              <div>
                <Tag>{call.status}</Tag>
                <br></br>
                <Tag>{call.date}</Tag>
              </div>
            )}
          </div>
        ));
      } else {
        return <Tag color="red">No Call Records</Tag>;
      }
    }
  },
  {
    title: "Refunds",
    dataIndex: "refunds",
    width: "8%",
    key: "refunds",
    render: ({ data, sendRefundMessage }) =>
      data.length > 0 && data[0].status ? (
        <div>
          {data[0].status == "INPROGRESS" ? (
            <>
              {data[0].status}
              <Button onClick={sendRefundMessage}>Resend Message</Button>
            </>
          ) : (
            data[0].status
          )}
        </div>
      ) : (
        <Tag color="red">No Records</Tag>
      )
  },
  {
    title: "Not Boarded Reason",
    dataIndex: "notBoarededReason",
    key: "notBoarededReason",
    width: "8%"
  },
  {
    title: "Remark",
    dataIndex: "remark",
    width: "8%",
    key: "remark",
    render: ({ value = "", onChange }) => {
      if (!value) {
        value = "";
      }
      value = value.split("|");

      let remarkStr = "";

      for (let i = 0; i < value.length; ++i) {
        remarkStr += value[i];
      }

      return (
        <>
          <EditOutlined onClick={onChange} />
          {remarkStr.length ? (
            <span style={wordBreakStyle}>{remarkStr}</span>
          ) : (
            <span>No Remark</span>
          )}
        </>
      );
    }
  },
  {
    title: "Invoice Action",
    dataIndex: "invoice",
    key: "invoice",
    width: "10%",
    render: ({
      date,
      todaysdate,
      tripStatus,
      downloadEmail,
      sendEmail,
      status,
      isCoreBooking,
      isToStationTimePassed,
      sendWhatsApp
    }) =>
      tripStatus === "COMPLETED" ||
      date <= todaysdate ||
      status != "CANCELLED" ? (
        <div>
          <Button
            disabled={isCoreBooking || !isToStationTimePassed}
            className="ant-btn mb-1"
            onClick={sendEmail}
          >
            Mail Invoice
          </Button>
          <Button
            disabled={!isCoreBooking || !isToStationTimePassed}
            className="ant-btn mb-1"
            onClick={sendWhatsApp}
          >
            Whatspp Invoice
          </Button>
          <Button
            disabled={!isToStationTimePassed}
            className="ant-btn mb-1"
            onClick={downloadEmail}
          >
            Download Invoice
          </Button>
        </div>
      ) : (
        <Tag color="red">Trip Not Completed</Tag>
      )
  },

  {
    title: "Action History",
    dataIndex: "actionHistory",
    width: "8%",
    key: "actionHistory"
  }
];

class SearchForm extends Component {
  state = {
    showResult: false,
    result: [],
    fromCity: "",
    SearchValue: "pnr",
    toCity: "",
    date: moment(),
    tripFilter: "ALL",
    editBooking: false,
    editField: "",
    bookingToEdit: {},
    bookingEdit: false,
    updatedField: "",
    selectKey: "2",
    bookingSearchValue: "",
    tripLoader: false,
    cancelLoader: false,
    bookingLoader: false,
    viewMoreList: [],
    messageSentReasons: [],
    showMessageSentDetails: false,
    showZingPrimeDetails: false,
    showMoreDetails: false,
    invoiceLoader: false,
    showCrewDetails: false,
    cabSelect: "ALL",
    showCrewDetailsViaNumber: false
  };

  inputRef = React.createRef();

  async componentDidMount() {
    try {
      const complain = await this.fetchComplainCategory();
      const issueResponse = await this.fetchIssue();
      this.setState({
        customerIssue: issueResponse,
        complainCategory: complain
      });
    } catch (err) {
      message.error(err);
    }
  }

  fetchIssue = async () => {
    try {
      const url = `/bus-tracker/customerIssue`;

      const options = {
        method: "get"
      };

      const response = await Fetch(url, options);
      return response;
    } catch (err) {
      message.error(err);
    }
  };

  fetchComplainCategory = async () => {
    try {
      const url = `/bus-tracker/complaintCategory`;

      const options = {
        method: "get"
      };
      const response = await Fetch(url, options);

      if (response) {
        return response;
      } else {
        return [];
      }
    } catch (err) {
      console.log(err);

      return [];
    }
  };

  fetchBooking = async () => {
    try {
      this.setState({ bookingLoader: true });
      let { selectKey = "2", bookingSearchValue } = this.state;
      if (
        bookingSearchValue.slice(0, 2) == "zm" ||
        bookingSearchValue.slice(0, 2) == "ZM"
      ) {
        const url = `/bus-tracker/marketPlaceBooking?pnr=${bookingSearchValue}`;
        const options = {
          method: "get"
        };
        let response = await Fetch(url, options);
        if (response) {
          const bookingR = [];
          let callDuration = "";

          for (let i = 0; i < response.length; ++i) {
            const booking = response[i];

            for (let i = 0; i < booking.callDetails.length; ++i) {
              let calls = booking.callDetails[i];
              if (calls) {
                if (calls.recording) {
                  let startTime = new Date(calls.start_time).getTime();
                  let endTime = new Date(calls.end_time).getTime();
                  const diff = endTime - startTime;
                  callDuration = msToTime(diff);
                } else {
                  callDuration = null;
                }
                booking.callDetails[i]["callDuration"] = callDuration;
              }
            }
            let seatsLabel = "";
            if (booking.tripChart && booking.tripChart.length > 0) {
              for (let i = 0; i < booking.tripChart.length; ++i) {
                for (let i = 0; i < booking.tripChart.length; ++i) {
                  const seat = booking.tripChart[i];

                  if (seat.status === "CONFIRMED") {
                    const seatNumber = "";
                    const seatGender = seat.gender ? seat.gender : "";

                    if (seatsLabel.length === 0) {
                      seatsLabel += `${seatNumber}(${seatGender})`;
                    } else {
                      seatsLabel += `,${seatNumber}(${seatGender})`;
                    }
                  }
                }
                break;
              }
            }
            const fromStation = booking.fromStation
                ? booking.fromStation.name
                : undefined,
              fromStationAddress = booking.fromStation
                ? booking.fromStation.address
                : undefined,
              toStation = booking.toStation
                ? booking.toStation.name
                : undefined,
              toStationAddress = booking.toStation
                ? booking.toStation.address
                : undefined;
            {
              const bookingObj = {
                key: booking._id,
                name: {
                  value: booking.name,
                  copyCustomerDetails: () =>
                    this.copyCustomerDetails(
                      booking.marketplaceBookingCode,
                      booking.mobileNo,
                      seatsLabel,
                      fromStation,
                      fromStationAddress,
                      booking.fromStationDate,
                      toStation,
                      toStationAddress,
                      booking.trackingUrl
                    )
                },
                email: {
                  value: booking.email,
                  tripStatus: booking.status,
                  onChange: () => message.error("Not Avilable for MarketPlace")
                },
                invoice: {
                  status: booking.status,
                  value: booking.email,
                  bookingcode: booking.marketplaceBookingCode,
                  tripStatus: booking.status,
                  date: booking.toStationDate,
                  todaysdate: Date.now(),
                  sendEmail: () =>
                    this.sendEmail(
                      booking.marketplaceBookingCode,
                      booking.email,
                      booking.mobileNo,
                      booking._id
                    ),
                  downloadEmail: () =>
                    this.downloadEmail(
                      booking.marketplaceBookingCode,
                      booking._id
                    ),
                  onChange: () => message.error("Not Avilable for MarketPlace")
                },
                mobileNo: {
                  value: booking.mobileNo,
                  onChange: () => message.error("Not Avilable for MarketPlace")
                },
                demandSource: ` ${booking.demandSource}(${
                  booking.otaPartner ? booking.otaPartner : ""
                })`,
                bookingCode: booking.marketplaceBookingCode,
                seatChart: seatsLabel,
                fromStation: {
                  name: `${
                    booking.fromStation ? booking.fromStation.name : undefined
                  }, ${
                    booking.fromStation
                      ? booking.fromStation.address
                      : undefined
                  }`,
                  date: booking.fromStation
                    ? moment(booking.fromStationDate).format("lll")
                    : undefined,
                  onChange: () => message.error("Not Avilable for MarketPlace")
                },
                seats: "",
                toStation: {
                  name: booking.toStation
                    ? `${booking.toStation.name}, ${booking.toStation.address}`
                    : undefined,
                  date: booking.toStation
                    ? moment(booking.toStationDate).format("lll")
                    : undefined,
                  onChange: () => message.error("Not Avilable for MarketPlace")
                },
                busNumber:
                  booking.trip && booking.trip.bus ? (
                    <span>{booking.trip.bus.rc}</span>
                  ) : (
                    <span>No Bus Assigned</span>
                  ),
                crewDetails: {
                  bus: "",
                  busHost: "",
                  busDriver: ""
                },
                amountToBeCollected: {
                  value: booking.amountToBeCollected,
                  agentId: booking.agentId ? booking.agentId : null,
                  onChange: () => message.error("Not Avilable for MarketPlace")
                },
                call: booking.callDetails.length,
                // remark: booking.remark,
                service:
                  booking.connectionTrip &&
                  booking.connectionTrip.connectionService &&
                  booking.connectionTrip.connectionService.areaCode
                    ? booking.connectionTrip.connectionService.areaCode
                    : null,
                status: booking.status,
                refunds: {
                  data: booking.refund ? booking.refund : null,
                  sendRefundMessage: () => this.sendRefundMessage(booking)
                },
                callAttempted: booking.callDetails.length,
                callRecords: {
                  data: booking.callDetails,
                  from: booking.mobileNo
                },
                isCreditCouponSent: booking.isCreditCouponSent,
                creditCouponCode: booking.creditCouponCode,
                IncomingcallRecords: booking.incomingCallDetails,
                action: {
                  tripId: booking.tripId,
                  customerTrackingLink: `http://${booking.trackingUrl}`,
                  copyCustomerTrackingLink: () =>
                    this.copyCustomerTrackingLink(booking.trackingUrl),
                  showMessagesInfo: () => this.showMessageSent(booking),
                  showModal: () => this.showMarketPlaceModal(booking)
                },
                trackingUrl: booking.trackingUrl,
                booking,
                actionHistory: booking.actionHistory || "",
                notBoarededReason: booking.notBoardedReason
                  ? booking.notBoardedReason
                  : null,
                remark: {
                  value: booking.remark,
                  onChange: () => message.error("Not Avilable for MarketPlace")
                },
                _id: booking._id
              };

              bookingR.push(bookingObj);
            }
          }
          this.setState({
            showResult: true,
            showBooking: true,
            result: response,
            bookings: bookingR,
            bookingLoader: false
          });
        }
      } else if (bookingSearchValue) {
        let url = `/bus-tracker/booking`;
        if (
          bookingSearchValue.slice(0, 2) == "zb" ||
          bookingSearchValue.slice(0, 2) == "ZB"
        ) {
          url += `?pnr=${bookingSearchValue}`;
        } else {
          bookingSearchValue = bookingSearchValue.replace(" ", "");
          if (
            bookingSearchValue.length > 10 &&
            bookingSearchValue.slice(0, 2) == "91"
          ) {
            let mobile = bookingSearchValue.substring(2);
            url += `?mobileNo=${mobile}`;
          } else {
            url += `?mobileNo=${bookingSearchValue}`;
          }
        }

        const options = {
          method: "get"
        };
        let response = await Fetch(url, options);
        response = response.sort(
          (a, b) => b.fromStationDate - a.fromStationDate
        );
        if (response) {
          const bookingR = [];
          const stationMap = {};
          let station;
          let booking;
          let stationArray = [];
          let callDuration = "";

          for (let i = 0; i < response.length; ++i) {
            booking = response[i];
            stationArray.push(booking.tripId);
          }

          const urlEta = `/bus-tracker/bulkStations/${stationArray}`;
          let EtaResponse = await Fetch(urlEta, options);

          for (let i = 0; i < EtaResponse.length; ++i) {
            station = EtaResponse[i];
            stationMap[station.stationId] = station;
          }

          for (let i = 0; i < response.length; ++i) {
            const booking = response[i];
            station = stationMap[booking.fromStation._id];
            for (let i = 0; i < booking.callDetails.length; ++i) {
              let calls = booking.callDetails[i];
              if (calls) {
                if (calls.recording) {
                  let startTime = new Date(calls.start_time).getTime();
                  let endTime = new Date(calls.end_time).getTime();
                  const diff = endTime - startTime;
                  callDuration = msToTime(diff);
                } else {
                  callDuration = null;
                }
                booking.callDetails[i]["callDuration"] = callDuration;
              }
            }

            let seatsLabel = "";
            if (booking.tripChart && booking.tripChart.length > 0) {
              for (let i = 0; i < booking.tripChart.length; ++i) {
                for (let i = 0; i < booking.tripChart.length; ++i) {
                  const seat = booking.tripChart[i];

                  if (seat.status === "CONFIRMED") {
                    const seatNumber = seat.seat.seatLabel;
                    const seatGender = seat.gender ? seat.gender : "";

                    if (seatsLabel.length === 0) {
                      seatsLabel += `${seatNumber}(${seatGender})`;
                    } else {
                      seatsLabel += `,${seatNumber}(${seatGender})`;
                    }
                  }
                }
                break;
              }
            }
            const isCoreBooking =
              booking.demandSource == "B2CIOS" ||
              booking.demandSource == "B2CWEB" ||
              booking.demandSource == "B2CANDROID";
            const isToStationTimePassed = booking.fromStationDate < Date.now();

            if (
              booking.status === "CONFIRMED" ||
              booking.status === "ASSINGEDTOMARKETPLACE" ||
              booking.status === "FAILED" ||
              booking.status === "NOT REPORTED" ||
              booking.status === "CANCELLED"
            ) {
              const bookingObj = {
                key: booking._id,
                name: {
                  value: booking.name,
                  copyCustomerDetails: () =>
                    this.copyCustomerDetails(
                      booking.bookingCode,
                      booking.mobileNo,
                      seatsLabel,
                      booking.fromStation.name,
                      booking.fromStation.city.name,
                      booking.fromStationDate,
                      booking.toStation.name,
                      booking.toStation.city.name,
                      booking.trackingUrl,
                      booking.trip.bus
                    )
                },
                email: {
                  value: booking.email,
                  tripStatus: booking.trip.status,
                  onChange: () =>
                    this.setState({
                      editField: "email",
                      editBooking: true,
                      bookingToEdit: booking
                    })
                },
                invoice: {
                  isCoreBooking,
                  isToStationTimePassed,
                  status: booking.status,
                  value: booking.email,
                  bookingcode: booking.bookingCode,
                  tripStatus: booking.trip.status,
                  date: booking.toStationDate,
                  todaysdate: Date.now(),
                  sendEmail: () =>
                    this.sendEmail(
                      booking.bookingCode,
                      booking.email,
                      booking.mobileNo
                    ),
                  downloadEmail: () =>
                    this.downloadEmail(
                      booking.bookingCode,
                      booking._id,
                      isCoreBooking
                    ),
                  onChange: () =>
                    this.setState({
                      editField: "email",
                      editBooking: true,
                      bookingToEdit: booking
                    })
                },
                isOnboarded: booking.isOnboarded ? booking.isOnboarded : false,
                mobileNo: {
                  value: booking.mobileNo,
                  onChange: () =>
                    this.setState({
                      editField: "mobileNo",
                      editBooking: true,
                      bookingToEdit: booking
                    })
                },
                demandSource: ` ${booking.demandSource}(${
                  booking.otaPartner ? booking.otaPartner : ""
                })`,
                bookingCode: booking.bookingCode,
                seatChart: seatsLabel,
                fromStation: {
                  name: `${booking.fromStation.name}, ${booking.fromStation.city.name}`,
                  date: moment(booking.fromStationDate).format("lll"),
                  onChange: () =>
                    this.setState({
                      editField: "fromStation",
                      editBooking: true,
                      bookingToEdit: booking
                    })
                },
                isCreditCouponSent: booking.isCreditCouponSent,
                creditCouponCode: booking.creditCouponCode,
                zingStorePassRewardId: booking.zingStorePassRewardId,
                isZingPrimeUser: {
                  isZingPrimeUser: booking.isZingPrimeUser,
                  showModal: () =>
                    this.setState({
                      showZingPrimeDetails: true,
                      selectedPrimeBooking: booking
                    })
                },
                seats: getSeatNumberFromTripChart(booking.tripChart),
                toStation: {
                  name: `${booking.toStation.name}, ${booking.toStation.city.name}`,
                  date: moment(booking.toStationDate).format("lll"),
                  onChange: () =>
                    this.setState({
                      editField: "toStation",
                      editBooking: true,
                      bookingToEdit: booking
                    })
                },
                busNumber: booking.trip.bus ? (
                  <span>{booking.trip.bus.rc}</span>
                ) : (
                  <span>No Bus Assigned</span>
                ),
                crewDetails: {
                  bus: booking.trip.bus,
                  busHost: booking.trip.busHost,
                  busDriver: booking.trip.busDriver
                },
                amountToBeCollected: {
                  value: booking.amountToBeCollected,
                  agentId: booking.agentId ? booking.agentId : null,
                  onChange: () =>
                    this.setState({
                      editField: "amountToBeCollected",
                      editBooking: true,
                      bookingToEdit: booking
                    })
                },
                call: booking.callDetails.length,
                // remark: booking.remark,
                service: booking.trip.service.name,
                status: booking.status,
                refunds: {
                  data: booking.refund ? booking.refund : null,
                  sendRefundMessage: () => this.sendRefundMessage(booking)
                },
                callAttempted: booking.callDetails.length,
                callRecords: {
                  data: booking.callDetails,
                  from: booking.mobileNo
                },

                IncomingcallRecords: booking.incomingCallDetails,
                action: {
                  tripId: booking.tripId,
                  customerTrackingLink: `http://${booking.trackingUrl}`,
                  copyCustomerTrackingLink: () =>
                    this.copyCustomerTrackingLink(booking.trackingUrl),
                  showMessagesInfo: () => this.showMessageSent(booking),
                  showModal: () => this.showModal(booking)
                },
                trackingUrl: booking.trackingUrl,
                booking,
                actionHistory: booking.actionHistory || "",
                notBoarededReason: booking.notBoardedReason
                  ? booking.notBoardedReason
                  : null,
                remark: {
                  value: booking.remark,
                  onChange: () =>
                    this.setState({
                      editField: "remark",
                      editBooking: true,
                      bookingToEdit: booking
                    })
                },
                _id: booking._id
              };

              bookingR.push(bookingObj);
            }
          }
          this.setState({
            showResult: true,
            showBooking: true,
            result: response,
            bookings: bookingR,
            bookingLoader: false
          });
        }
      } else {
        this.setState({ bookingLoader: false });
        const errMsg =
          selectKey === "1"
            ? `PNR field is blank`
            : `Mobile Number field is blank`;
        message.error(errMsg);
        this.setState({ bookingLoader: false });
      }
    } catch (err) {
      console.log(err);
      this.setState({ bookingLoader: false });
    }
  };

  fetchTrip = async () => {
    try {
      this.setState({ tripLoader: true });
      const {
        fromCity,
        toCity,
        date,
        tripFilter,
        selectedBusRc,
        selectedBusDrivers,
        selectedBusHost,
        selectServiceName,
        cabSelect
      } = this.state;
      if (tripFilter === "onTime") {
        let tripOT = this.props.trips.filter(
          obj => obj.status.name === "On Time"
        );
        if (tripOT.length > 0) {
          this.setState({ tripLoader: false }, () =>
            this.props.onTripSearch(tripOT, tripFilter)
          );
          return message.success("Trip found");
        } else {
          this.setState({ tripLoader: false });
          return message.error("No Trip found");
        }
      } else if (tripFilter === "willStart") {
        let tripWS = this.props.trips.filter(
          obj => obj.status.color === "cyan"
        );
        if (tripWS.length > 0) {
          this.setState({ tripLoader: false }, () =>
            this.props.onTripSearch(tripWS, tripFilter)
          );
          return message.success("Trip found");
        } else {
          this.setState({ tripLoader: false });
          return message.error("No Trip found");
        }
      } else if (tripFilter === "trackingNW") {
        let tripNW = this.props.trips.filter(
          obj => obj.status.name === "Tracking Not Working"
        );
        if (tripNW.length > 0) {
          this.setState({ tripLoader: false }, () =>
            this.props.onTripSearch(tripNW, tripFilter)
          );
          return message.success("Trip found");
        } else {
          this.setState({ tripLoader: false });
          return message.error("No Trip found");
        }
      } else if (tripFilter === "tripNAY") {
        let tripNAY = this.props.trips.filter(
          obj => obj.status.name === "Trip Not Assigned Yet"
        );
        if (tripNAY.length > 0) {
          this.setState({ tripLoader: false }, () =>
            this.props.onTripSearch(tripNAY, tripFilter)
          );
          return message.success("Trip found");
        } else {
          this.setState({ tripLoader: false });
          return message.error("No Trip found");
        }
      } else if (tripFilter === "Delay") {
        let tripD = this.props.trips.filter(obj => obj.status.color === "#f50");
        tripD = tripD.sort((a, b) => b.status.value - a.status.value);
        if (tripD.length > 0) {
          this.setState({ tripLoader: false }, () =>
            this.props.onTripSearch(tripD, tripFilter)
          );
          return message.success("Trip found");
        } else {
          this.setState({ tripLoader: false });
          return message.error("No Trip found");
        }
      } else if (tripFilter === "MarshalNLI") {
        let tripMarshalNLI = this.props.trips.filter(
          obj => obj.status.isPseudoOnTrip === "true"
        );
        if (tripMarshalNLI.length > 0) {
          this.setState({ tripLoader: false }, () =>
            this.props.onTripSearch(tripMarshalNLI, tripFilter)
          );
          return message.success("Trip found");
        } else {
          this.setState({ tripLoader: false });
          return message.error("No Trip found");
        }
      }
      let selectedBusRcId,
        selectedBusHostId,
        selectedBusDriverId,
        selectServiceNameId;
      if (selectedBusRc) {
        let id = this.state.RCs.filter(obj => obj.rc === selectedBusRc);
        if (id) {
          selectedBusRcId = id[0]._id;
        } else {
          message.error("Incorrect RC selected");
          return;
        }
      } else if (selectedBusHost) {
        let id = this.state.busHosts.filter(
          obj => obj.mobileNo === selectedBusHost
        );
        if (id) {
          selectedBusHostId = id[0]._id;
        } else {
          message.error("Incorrect Bus Host selected");
          return;
        }
      } else if (selectedBusDrivers) {
        let id = this.state.busDrivers.filter(
          obj => obj.mobileNo === selectedBusDrivers
        );
        if (id) {
          selectedBusDriverId = id[0]._id;
        } else {
          message.error("Incorrect Driver selected");
          return;
        }
      } else if (selectServiceName) {
        let id = this.state.serviceNames.filter(
          obj => obj.name === selectServiceName
        );
        if (id) {
          selectServiceNameId = id[0]._id;
        } else {
          message.error("Incorrect Service name selected");
          return;
        }
      }
      let response;
      if (
        selectedBusRcId ||
        selectedBusHostId ||
        selectedBusDriverId ||
        selectServiceNameId
      ) {
        const url = `/bus-tracker/allQLTrips`;

        const options = {
          method: "post",
          data: {
            date: date ? moment(date).format("YYYY-MM-DD") : undefined,
            rc: selectedBusRcId,
            busHost: selectedBusHostId,
            busDriver: selectedBusDriverId,
            serviceName: selectServiceNameId,
            tripFilter
          }
        };

        response = await Fetch(url, options);
      } else if (fromCity && toCity && date) {
        const url = `/bus-tracker/searchRoute?fromCity=${fromCity}&toCity=${toCity}&date=${moment(
          date
        ).format("YYYY-MM-DD")}&tripFilter=${tripFilter}`;

        const options = {
          method: "get"
        };

        response = await Fetch(url, options);
      } else if (date) {
        const url = `/bus-tracker/allQLTrips`;

        const options = {
          method: "post",
          data: {
            date: moment(date).format("YYYY-MM-DD"),
            tripFilter
          }
        };

        response = await Fetch(url, options);
      }
      if (!response) {
        this.setState({ tripLoader: false });
      } else {
        if (response !== "No data found") {
          if (cabSelect === "CAB") {
            let cabSelectedTrips = response.filter(
              obj =>
                obj.service.cabServiceType === "FIRSTMILE" ||
                obj.service.cabServiceType === "LASTMILE"
            );
            if (cabSelectedTrips.length > 0) {
              this.setState({ tripLoader: false }, () =>
                this.props.onTripSearch(cabSelectedTrips)
              );
              return message.success(
                "Trip found with Only FIRSTMILE - LASTMILE"
              );
            } else {
              this.setState({ tripLoader: false });
              return message.error(
                "No Trip found with Only FIRSTMILE - LASTMILE"
              );
            }
          } else if (cabSelect === "CORE") {
            let coreSelectedTrips = response.filter(
              obj =>
                obj.service.cabServiceType !== "FIRSTMILE" &&
                obj.service.cabServiceType !== "LASTMILE"
            );
            if (coreSelectedTrips.length > 0) {
              this.setState({ tripLoader: false }, () =>
                this.props.onTripSearch(coreSelectedTrips)
              );
              return message.success(" Trip found with Only Core");
            } else {
              this.setState({ tripLoader: false });
              return message.error("No Trip found with Only Core");
            }
          } else {
            this.setState({ tripLoader: false }, () =>
              this.props.onTripSearch(response)
            );
          }
        } else {
          message.error("No trip found");
          this.setState({ tripLoader: false });
        }
      }
    } catch (err) {
      message.error(err);
      this.setState({ tripLoader: false });
    }
  };

  updateBookingStatus = async (booking, type) => {
    try {
      const url = `/bus-tracker/booking/boarding`;

      const options = {
        method: "patch",
        data: {
          bookings: [booking],
          type
        }
      };

      const response = await Fetch(url, options);

      const formatedBooking = this.formatBooking(response);

      this.setState({ result: formatedBooking });
    } catch (err) {
      message.error(err);
    }
  };

  updateBoardingStatus = async () => {
    try {
      const { bookings } = this.state;
      const url = `/bus-tracker/booking/boarding`;
      const options = {
        method: "patch",
        data: {
          bookings: bookings,
          type: bookings[0].isOnboarded ? "MARKUNBOARDED" : "MARKONBOARDED"
        }
      };
      const response = await Fetch(url, options);
      if (response) {
        message.success(
          `Person marked ${
            bookings[0].isOnboarded ? "MARKUNBOARDED" : "MARKONBOARDED"
          }`
        );
        this.setState({ showResult: false });
      }
    } catch (err) {
      message.error(err);
    }
  };

  updateParticularBoardingStatus = async () => {
    try {
      const { selectedBookings } = this.state;
      const url = `/bus-tracker/booking/boarding`;
      const updateType = selectedBookings[0].isOnboarded
        ? "MARKUNBOARDED"
        : "MARKONBOARDED";
      const options = {
        method: "patch",
        data: {
          bookings: selectedBookings,
          type: updateType
        }
      };
      const response = await Fetch(url, options);
      if (response) {
        message.success(
          `Person marked ${
            selectedBookings[0].isOnboarded ? "MARKUNBOARDED" : "MARKONBOARDED"
          }`
        );
        selectedBookings[0].isOnboarded =
          updateType == "MARKONBOARDED" ? true : false;
        this.setState({
          showResult: false,
          selectedBookings: selectedBookings
        });
      }
    } catch (err) {
      message.error(err);
    }
  };

  showBooking = bookings => {
    return bookings.map(booking => {
      let popConfirmText = "";

      if (booking.isOnboarded) {
        popConfirmText = "Are you sure you want to mark customer not boarded?";
      } else {
        popConfirmText = "Are you sure you want to mark customer boarded?";

        if (booking.amountToBeCollected) {
          popConfirmText += ` Customer has a cash collection of ${booking.amountToBeCollected}.`;
        }
      }
      return popConfirmText;
    });
  };

  sendRefundMessage = async bookingBody => {
    try {
      let refundlink = await this.fetchRefundLink(bookingBody.refund[0]);

      if (refundlink) {
        let parts2 =
          bookingBody.refund[0].refundRule.categoryName.match(
            /[\s\S]{1,30}/g
          ) || [];
        if (parts2[1] === undefined) {
          parts2[1] = "";
          parts2[2] = "";
        }
        let vars = [
          bookingBody.name,
          bookingBody.refund[0].amount.toString(),
          moment(bookingBody.fromStationDate).format("lll"),
          parts2[0],
          parts2[1],
          parts2[2],
          refundlink
        ];
        const url = `/bus-tracker/sendRefundmessage`;

        const options = {
          method: "post",
          data: {
            bookingBody,
            vars
          }
        };

        const response = await Fetch(url, options);

        if (response) {
          message.success("Message sent successfully");
        } else {
          message.error("Message not sent");
        }
      }
    } catch (err) {
      message.error(err);
    }
  };
  fetchRefundLink = async refundBody => {
    const url = `/bus-tracker/fetchRefundLink?razorPayTransactionId=${refundBody.razorPayTransactionId}`;

    const options = {
      method: "get"
    };

    const response = await Fetch(url, options);

    if (response) {
      return response.short_url;
    }
    return false;
  };
  sendRefund = async (
    selectedCategory,
    subIssueId,
    issueId,
    categorys,
    issueComment,
    userInput
  ) => {
    try {
      const { selectedBookings, selectedCargos } = this.state;
      let refundID = selectedCategory[0]._id;
      let refundClassification = selectedCategory[0].refundClassification;
      if (selectedBookings.length || selectedCargos.length) {
        const url = `/bus-tracker/sendRefund`;
        let booking = [];
        if (selectedBookings.length) {
          booking = selectedBookings.map(obj => {
            return {
              agentMobileNo: obj.booking.agent
                ? obj.booking.agent.mobileNo
                : null,
              mobileNo: obj.mobileNo.value,
              userId: obj.booking.userId,
              bookingCode: obj.bookingCode
            };
          });
        } else {
          booking = selectedCargos.map(obj => {
            return {
              agentMobileNo: obj.cargoBooking.agent
                ? obj.cargoBooking.agent.mobileNo
                : null,
              sender_mobileNo: obj.sender_mobileNo.value,
              receiver_mobileNo: obj.receiver_mobileNo.value,
              bookingCode: obj.bookingCode ? obj.bookingCode : obj.pnr
            };
          });
        }
        const options = {
          method: "post",
          data: {
            subIssueId: subIssueId,
            issueId: issueId,
            type: selectedBookings.length > 0 ? "booking" : "cargoBooking",
            refundRuleId: refundID,
            refundClassification: refundClassification
              ? refundClassification
              : "NORMAL",
            bookingType: "ZINGBUS",
            amount: userInput,
            comment: issueComment,
            bookings: booking
          }
        };
        const response = await Fetch(url, options);

        if (response) {
          message.success("Refund Initiated");
          this.setState({ selectedBookings: [], selectedRowKeys: [] });
        }
      } else {
        message.error("No Booking Selected");
      }
    } catch (err) {
      message.error(err);
    }
  };

  sendCoupon = async _id => {
    try {
      const { selectedBookings, bookings } = this.state;
      const sbooking =
        selectedBookings && selectedBookings.length > 0
          ? selectedBookings
          : bookings;
      if (sbooking.length) {
        const url = `/booking/sendCoupon`;
        let booking = [];
        if (sbooking.length) {
          booking = sbooking.map(obj => {
            return obj.bookingCode;
          });
        }
        const options = {
          method: "post",
          data: {
            ruleId: _id,
            pnrArr: booking
          }
        };
        const response = await Fetch(url, options);

        if (response == "Credit Coupon sent Successfully") {
          message.success("Credit Coupon sent Successfully");
          this.setState({ selectedRowKeys: [] });
        } else {
          message.error("Coupon not sent");
          this.setState({ selectedRowKeys: [] });
        }
      } else {
        message.error("No Booking Selected");
      }
    } catch (err) {
      message.error(err);
    }
  };

  publishNote = async (comment, time) => {
    try {
      const { selectedBookings, selectedCargos } = this.state;
      if (selectedBookings.length || selectedCargos.length) {
        const url = `/bus-tracker/publishSelfHelpNote`;
        let booking = [];
        booking = selectedBookings.map(obj => {
          return {
            bookingId: obj._id ? obj._id : obj.pnr,
            actionHistory: obj.actionHistory
          };
        });
        const options = {
          method: "post",
          data: {
            selfHelpComment: comment,
            selfHelpCommentRelayTime: time,
            bookings: booking
          }
        };
        const response = await Fetch(url, options);

        if (response) {
          message.success("Note Published");
        }
      } else {
        message.error("No Booking Selected");
      }
    } catch (err) {
      message.error(err);
    }
  };

  publishNoteviaPnr = async (comment, time) => {
    try {
      const { bookings } = this.state;
      const url = `/bus-tracker/publishSelfHelpNote`;
      let booking = [];
      booking = bookings.map(obj => {
        return {
          bookingId: obj._id ? obj._id : obj.pnr,
          actionHistory: obj.actionHistory
        };
      });
      const options = {
        method: "post",
        data: {
          selfHelpComment: comment,
          selfHelpCommentRelayTime: time,
          bookings: booking
        }
      };
      const response = await Fetch(url, options);

      if (response) {
        message.success("Note Published");
      }
    } catch (err) {
      message.error(err);
    }
  };

  sendRefundviaPnr = async (
    selectedCategory,
    subIssueId,
    issueId,
    categorys,
    issueComment,
    userInput
  ) => {
    try {
      const { bookings } = this.state;
      let booking = [];
      booking = bookings.map(obj => {
        return {
          agentMobileNo: obj.booking.agent ? obj.booking.agent.mobileNo : null,
          mobileNo: obj.mobileNo.value,
          bookingCode: obj.bookingCode ? obj.bookingCode : obj.pnr,
          userId: obj.booking.userId
        };
      });

      let refundID = selectedCategory[0]._id;
      let refundClassification = selectedCategory[0].refundClassification;

      if (bookings) {
        const url = `/bus-tracker/sendRefund`;
        const options = {
          method: "post",
          data: {
            subIssueId: subIssueId,
            issueId: issueId,
            bookingType: "ZINGBUS",
            refundRuleId: refundID,
            refundClassification: refundClassification
              ? refundClassification
              : "NORMAL",
            comment: issueComment,
            amount: userInput,
            type: "booking",
            bookings: booking
          }
        };
        const response = await Fetch(url, options);

        if (response) {
          message.success("Refund Initiated");
          // this.setState({ selectedBookings: [], selectedRowKeys: [] });
        }
      } else {
        message.error("No Booking Selected");
      }
    } catch (err) {
      message.error(err);
    }
  };
  sendMessage = async (
    templateType,
    vars,
    sendSMSMessage,
    sendWhatsAppMessage
  ) => {
    try {
      const { bookings, result } = this.state;
      if (bookings.length) {
        // console.log("bookings.length", bookings.length);
        const url = `/bus-tracker/sendmessageandwhatsapp`;

        const options = {
          method: "post",
          data: {
            bookings: bookings.map(b => b.booking),
            templateType,
            vars,
            sendSMSMessage,
            result,
            sendWhatsAppMessage
          }
        };

        const response = await Fetch(url, options);

        if (response) {
          message.success("Message sent successfully");
          this.setState({ showResult: false });
        }
      } else {
        message.error("No Booking Selected");
      }
    } catch (err) {
      message.error(err);
    }
  };
  sendMessageNumber = async (
    templateType,
    vars,
    sendSMSMessage,
    sendWhatsAppMessage
  ) => {
    try {
      const { selectedBookings, result } = this.state;

      if (selectedBookings.length) {
        // console.log("bookings.length", selectedBookings.length);
        const url = `/bus-tracker/sendmessageandwhatsapp`;

        const options = {
          method: "post",
          data: {
            bookings: selectedBookings.map(b => b.booking),
            templateType,
            vars,
            sendSMSMessage,
            sendWhatsAppMessage,
            result
          }
        };

        const response = await Fetch(url, options);

        if (response) {
          message.success("Message sent successfully");
          this.setState({ selectedRowKeys: [], showResult: false });
        } else {
          message.error("No Booking Selected");
        }
      }
    } catch (err) {
      message.error(err);
    }
  };

  copyCustomerDetails = (
    bookingCode,
    mobileNo,
    seatsLabel,
    fromStation,
    fromCity,
    fromStationDate,
    toStation,
    toCity,
    trackingUrl,
    rc
  ) => {
    let busNumber = "";
    if (rc) {
      busNumber = rc.rc;
    } else {
      busNumber = "No Bus Assigned";
    }
    let time = moment(fromStationDate).format("lll");

    let text = `PNR :${bookingCode} \n Mobile Number: ${mobileNo} \n Seat Number: ${seatsLabel} \n From Station: ${fromStation}, ${fromCity}, ${time} \n To Station: ${toStation}, ${toCity} \n Tracking URL: ${trackingUrl} \n Bus Number: ${busNumber} `;
    // console.log("text", text);

    this.inputRef.current.value = text;
    this.inputRef.current.select();
    document.execCommand("copy");
    message.success("Customer Details copied to clipboard");
  };

  copyCustomerTrackingLink = customerTrackingLink => {
    this.inputRef.current.value = customerTrackingLink;
    this.inputRef.current.select();

    document.execCommand("copy");
    message.success("Customer Link copied to clipboard");
  };
  showModal = booking => {
    const { viewMoreList } = this.state;
    const isCoreBooking =
      booking.demandSource == "B2CIOS" ||
      booking.demandSource == "B2CWEB" ||
      booking.demandSource == "B2CANDROID";
    const isToStationTimePassed = booking.fromStationDate < Date.now();

    const bookingObj = {
      busNumber: booking.trip.bus ? (
        <span>{booking.trip.bus.rc}</span>
      ) : (
        <span>No Bus Assigned</span>
      ),
      service: booking.trip.service.name,
      status: booking.status,
      refund: booking.refund,
      zingStorePassRewardId: booking.zingStorePassRewardId,
      demandSource: booking.demandSource,
      isZingPrimeUser: {
        isZingPrimeUser: booking.isZingPrimeUser,
        showModal: () =>
          this.setState({
            showZingPrimeDetails: true,
            selectedPrimeBooking: booking
          })
      },
      amountToBeCollected: {
        value: booking.amountToBeCollected,
        agentId: booking.agentId ? booking.agentId : null,
        onChange: () =>
          this.setState({
            editField: "amountToBeCollected",
            editBooking: true,
            bookingToEdit: booking
          })
      },
      notBoarededReason: booking.notBoardedReason
        ? booking.notBoardedReason
        : null,
      refunds: {
        data: booking.refund ? booking.refund : null,
        sendRefundMessage: () => this.sendRefundMessage(booking)
      },
      callAttempted: booking.callDetails.length,
      callRecords: {
        data: booking.callDetails,
        from: booking.mobileNo
      },
      IncomingcallRecords: booking.incomingCallDetails,
      remark: {
        value: booking.remark,
        onChange: () =>
          this.setState({
            editField: "remark",
            editBooking: true,
            bookingToEdit: booking
          })
      },
      invoice: {
        isCoreBooking,
        isToStationTimePassed,
        status: booking.status,
        value: booking.email,
        bookingcode: booking.bookingCode,
        tripStatus: booking.trip.status,
        date: booking.toStationDate,
        todaysdate: Date.now(),
        sendEmail: () =>
          this.sendEmail(
            booking.bookingCode,
            booking.email,
            booking.mobileNo,
            booking.fromStationDate
          ),
        downloadEmail: () =>
          this.downloadEmail(
            booking.bookingCode,
            booking._id,
            isCoreBooking,
            booking.fromStationDate
          ),
        sendWhatsApp: () =>
          this.sendWhatsApp(
            booking.bookingCode,
            booking._id,
            isCoreBooking,
            booking.fromStationDate
          ),

        onChange: () =>
          this.setState({
            editField: "email",
            editBooking: true,
            bookingToEdit: booking
          })
      },
      mobileNo: {
        value: booking.mobileNo,
        onChange: () =>
          this.setState({
            editField: "mobileNo",
            editBooking: true,
            bookingToEdit: booking
          })
      },
      actionHistory: booking.actionHistory || ""
    };
    viewMoreList.push(bookingObj);
    this.setState({ showMoreDetails: true, viewMoreList });
  };
  showMessageSent = booking => {
    const { messageSentReasons } = this.state;
    const { messageSent } = booking;
    if (!messageSent) {
      message.error("No messages");
      return;
    }
    for (let i = 0; i < messageSent.length; i++) {
      let data = messageSent[i];
      let templateType =
        data.templateType == "delay_in_pickup_v1"
          ? "Delay in Pickup"
          : data.templateType == "delay_in_drop_v1"
          ? "Delay in Drop"
          : data.templateType;
      let reasons =
        data.reasons.length > 0
          ? `${data.reasons[0]} & time ${data.reasons[1]} `
          : null;
      let message = `${templateType} - Reasons: ${reasons} at ${moment(
        data.time
      ).format("lll")}`;
      if (
        templateType == "Delay in Pickup" ||
        templateType == "Delay in Drop"
      ) {
        messageSentReasons.push(message);
      }
    }
    this.setState({ showMessageSentDetails: true, messageSentReasons });
  };

  showMarketPlaceModal = booking => {
    const { viewMoreList } = this.state;
    const bookingObj = {
      busNumber:
        booking.trip && booking.trip.bus ? (
          <span>{booking.trip.bus.rc}</span>
        ) : (
          <span>No Bus Assigned</span>
        ),
      service:
        booking.connectionTrip &&
        booking.connectionTrip.connectionService &&
        booking.connectionTrip.connectionService.areaCode
          ? booking.connectionTrip.connectionService.areaCode
          : null,

      status: booking.status,
      refund: booking.refund,
      demandSource: null,
      amountToBeCollected: {
        value: booking.amountToBeCollected,
        agentId: booking.agentId ? booking.agentId : null,
        onChange: () => message.error("Not Avilable for MarketPlace")
      },
      notBoarededReason: booking.notBoardedReason
        ? booking.notBoardedReason
        : null,
      refunds: {
        data: booking.refund ? booking.refund : null,
        sendRefundMessage: () => this.sendRefundMessage(booking)
      },
      isZingPrimeUser: {
        isZingPrimeUser: booking.isZingPrimeUser,
        showModal: () =>
          this.setState({
            showZingPrimeDetails: true,
            selectedPrimeBooking: booking
          })
      },
      callAttempted: booking.callDetails.length,
      callRecords: {
        data: booking.callDetails,
        from: booking.mobileNo
      },
      IncomingcallRecords: booking.incomingCallDetails,
      remark: {
        value: booking.remark,
        onChange: () => message.error("Not Avilable for MarketPlace")
      },
      invoice: {
        status: booking.status,
        value: booking.email,
        bookingcode: booking.marketplaceBookingCode,
        tripStatus: booking.status,
        date: booking.toStationDate,
        todaysdate: Date.now(),
        sendEmail: () =>
          this.sendEmail(
            booking.marketplaceBookingCode,
            booking.email,
            booking.mobileNo,
            booking._id
          ),
        downloadEmail: () =>
          this.downloadEmail(booking.marketplaceBookingCode, booking._id),
        onChange: () => message.error("Not Avilable for MarketPlace")
      },
      mobileNo: {
        value: booking.mobileNo,
        onChange: () => message.error("Not Avilable for MarketPlace")
      },
      actionHistory: booking.actionHistory || ""
    };
    viewMoreList.push(bookingObj);
    this.setState({ showMoreDetails: true, viewMoreList });
  };
  showCrewDetails = () => {
    this.setState({ showCrewDetails: true });
  };
  showCrewDetailsViaNumber = () => {
    this.setState({ showCrewDetailsViaNumber: true });
  };
  sendEmail = async (bookingCode, email, mobileNo, _id, date) => {
    if (date < 1694716200000) {
      message.error("Booking should be of after 14th Sept");
      return;
    }
    let url = `/bus-tracker/emailSent`;
    if (bookingCode.slice(0, 2) == "ZM") {
      url = `/bus-tracker/whatsAppInvoice`;
    }

    const options = {
      method: "post",
      data: {
        pnr: bookingCode,
        _id,
        email: email,
        mobileNo
      }
    };
    const response = await Fetch(url, options);

    if (response && bookingCode.slice(0, 2) == "ZM") {
      message.success("Whatspp initiated");
    } else {
      message.success("Email send");
    }
  };

  sendWhatsApp = async (customerpnr, _id, isCoreBooking, date) => {
    if (date < 1694716200000) {
      message.error("Booking should be of after 14th Sept");
      return;
    }
    this.setState({ invoiceLoader: true });
    let url = `/bus-tracker/coreWhatsAppInvoice?pnr=${customerpnr}&_id=${_id}`;

    const options = {
      method: "get"
    };
    const response = await Fetch(url, options);

    if (response) {
      message.success("WhatsApp Sent Successfully");
    }
    this.setState({ invoiceLoader: false });
  };

  downloadEmail = async (customerpnr, _id, isCoreBooking, date) => {
    this.setState({ invoiceLoader: true });
    let url = `/bus-tracker/downloadEmail?pnr=${customerpnr}&_id=${_id}&isCoreBooking=${isCoreBooking}`;
    if (customerpnr.slice(0, 2) == "ZM") {
      if (date < 1694716200000) {
        message.error("Booking should be of after 14th Sept");
        return;
      }
      url = `/bus-tracker/marketPlaceInvoiceDownload?cnr=${customerpnr}&_id=${_id}`;
    }

    const options = {
      method: "get"
    };

    const response = await Fetch(url, options);

    if (response) {
      message.success("Invoice downloaded");
    }
    this.setState({ invoiceLoader: false });

    const linkSource = response;
    const downloadLink = document.createElement("a");
    const fileName = `invoice-${customerpnr}.pdf`;
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  handleChange = (key, value) => {
    this.setState({ [key]: value, viewMoreList: [], messageSentReasons: [] });
  };

  handleSpecialCharacters = event => {
    const newCheck = event.target.value.replace(/[^\w\s]/gi, "");
    this.setState({ bookingSearchValue: newCheck });
  };

  handleChangeInput = event => {
    const { value, maxLength } = event.target;
    const message = value.slice(0, maxLength);
    this.setState({ bookingSearchValue: message });
  };

  handleSwap = () => {
    const { fromCity, toCity } = this.state;

    this.setState({ fromCity: toCity, toCity: fromCity });
  };

  sendComplain = async Bodyobj => {
    try {
      const url = `/bus-tracker/sendComplain`;
      const options = {
        method: "post",
        data: { obj: Bodyobj }
      };

      const response = await Fetch(url, options);
      if (response) {
        message.success("Complain Will be created in some Time");
      }
    } catch (err) {
      message.error(err);
    }
  };
  openWindow = selectedStation => {
    window.open(`/track/station/${selectedStation}`, "_blank");
  };
  openReportWindow = tripId => {
    window.open(`/report/?tripId=${tripId}`, "_blank");
  };

  getServiceName = async () => {
    try {
      this.setState({ loader: true });
      const url = `/bus-tracker/serviceNameEs`;

      const options = {
        method: "get"
      };
      const response = await Fetch(url, options);
      if (response) {
        this.setState({
          serviceNames: response
        });

        return response;
      } else {
        this.setState({ loader: false });

        return [];
      }
    } catch (err) {
      console.log(err);

      return [];
    }
  };

  getRc = async () => {
    try {
      this.setState({ loader: true });
      const url = `/bus-tracker/busNumber`;

      const options = {
        method: "get"
      };
      const response = await Fetch(url, options);
      if (response) {
        this.setState({
          RCs: response
        });

        return response;
      } else {
        this.setState({ loader: false });

        return [];
      }
    } catch (err) {
      console.log(err);

      return [];
    }
  };

  getBusHost = async () => {
    try {
      this.setState({ loader: true });
      const url = `/bus-tracker/busHostName`;

      const options = {
        method: "get"
      };
      const response = await Fetch(url, options);
      if (response) {
        const data = response.map(obj => {
          return { mobileNo: obj.mobileNo.toString(), _id: obj._id };
        });
        this.setState({
          busHosts: data
        });

        return data;
      } else {
        this.setState({ loader: false });

        return [];
      }
    } catch (err) {
      console.log(err);

      return [];
    }
  };

  getBusDriver = async () => {
    try {
      this.setState({ loader: true });
      const url = `/bus-tracker/busDriverName`;

      const options = {
        method: "get"
      };
      const response = await Fetch(url, options);
      if (response) {
        const data = response.map(obj => {
          return { mobileNo: obj.mobileNo.toString(), _id: obj._id };
        });

        this.setState({
          busDrivers: data
        });

        return response;
      } else {
        this.setState({ loader: false });

        return [];
      }
    } catch (err) {
      console.log(err);

      return [];
    }
  };

  setSearchSelect = (key, value) => {
    let stateObj = {};
    if (value == "sevice") {
      this.getServiceName();
      stateObj = {
        [key]: value,
        selectedBusHostId: null,
        selectedBusRcId: null,
        selectedBusDriverId: null
      };
    } else if (value == "rc") {
      this.getRc();
      stateObj = {
        [key]: value,
        selectedBusHostId: null,
        selectServiceNameId: null,
        selectedBusDriverId: null
      };
    } else if (value == "host") {
      this.getBusHost();
      stateObj = {
        [key]: value,
        selectedBusRcId: null,
        selectServiceNameId: null,
        selectedBusDriverId: null
      };
    } else if (value == "driver") {
      this.getBusDriver();
      stateObj = {
        [key]: value,
        selectedBusRcId: null,
        selectServiceNameId: null,
        selectedBusHostId: null
      };
    } else {
      stateObj = {
        [key]: value,
        selectedBusHostId: null,
        selectedBusRcId: null,
        selectServiceNameId: null,
        selectedBusDriverId: null
      };
    }
    this.setState(stateObj);
  };

  render() {
    const {
      showResult,
      editBooking,
      bookingSearchValue,
      editField,
      customerIssue,
      bookingToEdit,
      fromCity,
      SearchValue,
      serviceNames,
      selectServiceName,
      RCs,
      selectedBusRc,
      busHosts,
      selectedBusHost,
      busDrivers,
      selectedBusDrivers,
      toCity,
      bookingLoader,
      tripLoader,
      cancelLoader,
      tripFilter,
      selectedBookings,
      date,
      bookings,
      complainCategory,
      showMoreDetails,
      showMessageSentDetails,
      messageSentReasons,
      showCrewDetails,
      viewMoreList,
      showZingPrimeDetails,
      selectedPrimeBooking,
      invoiceLoader,
      showCrewDetailsViaNumber,
      cabSelect
    } = this.state;

    const { cities } = this.props;
    return (
      <>
        <div className="searchForm">
          {/* input tag is for copying customer link */}
          <input
            ref={this.inputRef}
            readOnly={true}
            style={{
              position: "absolute",
              left: "-9999px"
            }}
          />
          <div className="homeSearchForm">
            <div className="tripSearch">
              <div className="citySelect">
                <Select
                  showSearch
                  style={{ width: 150 }}
                  value={fromCity}
                  onChange={value => this.setState({ fromCity: value })}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {cities.map(city => (
                    <Option value={city.name} key={city._id}>
                      {city.name}
                    </Option>
                  ))}
                </Select>
                <button className="swapButton" onClick={this.handleSwap}>
                  <img src={swapIcon} alt="swapicon" />
                </button>
                <Select
                  showSearch
                  style={{ width: 150 }}
                  value={toCity}
                  onChange={value => this.setState({ toCity: value })}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {cities.map(city => (
                    <Option value={city.name} key={city._id}>
                      {city.name}
                    </Option>
                  ))}
                </Select>
              </div>
              <div className="optionSelect">
                <Select
                  style={{ width: "170px" }}
                  value={tripFilter}
                  onChange={value => this.setState({ tripFilter: value })}
                >
                  <Option value="ONGOING">Ongoing</Option>
                  <Option value="COMPLETED">Completed</Option>
                  <Option value="ALL">All</Option>
                  <Option value="onTime">On Time</Option>
                  <Option value="Delay">Delay</Option>
                  <Option value="willStart">Will Start</Option>
                  <Option value="trackingNW">Tracking Not Working</Option>
                  <Option value="MarshalNLI">Marshal Not Logged In</Option>
                  <Option value="tripNAY">Trip Not Assigned Yet</Option>
                </Select>
              </div>
              {tripFilter === "ALL" ||
              tripFilter === "COMPLETED" ||
              tripFilter === "ONGOING" ? (
                <div>
                  <DatePicker
                    style={{ width: 150 }}
                    value={date}
                    onChange={date => this.setState({ date })}
                  />
                </div>
              ) : null}
              <div className="optionSelect">
                <Select
                  style={{ width: "170px" }}
                  value={cabSelect}
                  onChange={value => this.setState({ cabSelect: value })}
                >
                  <Option value="ALL">ALL</Option>
                  <Option value="CORE">CORE</Option>
                </Select>
              </div>
              <div>
                <Button
                  loading={tripLoader}
                  onClick={this.fetchTrip}
                  style={{ width: 100 }}
                  type="primary"
                >
                  Search
                </Button>
              </div>
            </div>
            <div className="pnrSearch">
              <Select
                className="pnr_select"
                // style={{ width: "20%", margin: "0 0.2rem" }}
                showSearch
                value={SearchValue}
                onChange={value => this.setSearchSelect("SearchValue", value)}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                <Option value={"pnr"} key={1}>
                  PNR/MOBILE Number
                </Option>
                <Option value={"sevice"} key={2}>
                  Service Name
                </Option>
                <Option value={"rc"} key={3}>
                  Bus RC
                </Option>{" "}
                <Option value={"host"} key={4}>
                  Bus Host
                </Option>{" "}
                <Option value={"driver"} key={5}>
                  Bus Driver
                </Option>
              </Select>
              {SearchValue == "pnr" ? (
                <Input
                  className="search_input"
                  type="text"
                  maxLength="20"
                  placeholder="PNR / Mobile Number Search"
                  value={bookingSearchValue}
                  onChange={this.handleSpecialCharacters}
                />
              ) : SearchValue == "sevice" ? (
                <AutoComplete
                  className="search_input_trip"
                  placeholder="select a service"
                  dataSource={
                    serviceNames
                      ? serviceNames.map(service => service.name)
                      : null
                  }
                  value={selectServiceName ? selectServiceName : null}
                  onChange={value =>
                    this.setState({ selectServiceName: value })
                  }
                  filterOption={(inputValue, option) =>
                    option.props.children
                      .toUpperCase()
                      .indexOf(inputValue.toUpperCase()) !== -1
                  }
                />
              ) : SearchValue == "rc" ? (
                <AutoComplete
                  className="search_input_trip"
                  placeholder="select a RC"
                  dataSource={RCs ? RCs.map(obj => obj.rc) : null}
                  value={selectedBusRc ? selectedBusRc : null}
                  onChange={value => this.setState({ selectedBusRc: value })}
                  filterOption={(inputValue, option) =>
                    option.props.children
                      .toUpperCase()
                      .indexOf(inputValue.toUpperCase()) !== -1
                  }
                />
              ) : SearchValue == "host" ? (
                <AutoComplete
                  className="search_input_trip"
                  placeholder="select a host number"
                  dataSource={
                    busHosts ? busHosts.map(obj => obj.mobileNo) : null
                  }
                  value={selectedBusHost ? selectedBusHost : null}
                  onChange={value => this.setState({ selectedBusHost: value })}
                  filterOption={(inputValue, option) =>
                    option.props.children
                      .toUpperCase()
                      .indexOf(inputValue.toUpperCase()) !== -1
                  }
                />
              ) : SearchValue == "driver" ? (
                <AutoComplete
                  className="search_input_trip"
                  placeholder="select a bus driver number"
                  dataSource={
                    busDrivers ? busDrivers.map(obj => obj.mobileNo) : null
                  }
                  value={selectedBusDrivers ? selectedBusDrivers : null}
                  onChange={value =>
                    this.setState({ selectedBusDrivers: value })
                  }
                  filterOption={(inputValue, option) =>
                    option.props.children
                      .toUpperCase()
                      .indexOf(inputValue.toUpperCase()) !== -1
                  }
                />
              ) : null}
              {SearchValue == "pnr" ? (
                <Button
                  onClick={this.fetchBooking}
                  loading={bookingLoader}
                  type="primary"
                >
                  Search
                </Button>
              ) : null}
            </div>
          </div>
          <div className="waiting_lounge">
            <InitiateComplain
              isloading={cancelLoader}
              complains={complainCategory}
              onClick={this.sendComplain}
            />
            <StationView onClick={this.openWindow} />
          </div>
        </div>
        <hr />
        <Modal
          centered
          width={"100%"}
          title="More Details"
          visible={showMoreDetails}
          cancelButtonProps={{ style: { display: "none" } }}
          onCancel={() => this.handleChange("showMoreDetails", false)}
          footer={null}
        >
          <Table
            loading={invoiceLoader}
            columns={moreDetailsColumns}
            dataSource={viewMoreList}
            pagination={false}
            scroll={{ y: 300 }}
          />
        </Modal>

        <Modal
          centered
          width={"100%"}
          title="Messages Details"
          visible={showMessageSentDetails}
          cancelButtonProps={{ style: { display: "none" } }}
          onCancel={() => this.handleChange("showMessageSentDetails", false)}
          footer={null}
        >
          <ul>
            {messageSentReasons.map(function(data, index) {
              return <li key={index}>{data}</li>;
            })}
          </ul>
        </Modal>
        <Modal
          title="Search Result"
          className="modalClass"
          visible={showResult}
          onCancel={() => this.setState({ showResult: false })}
          footer={null}
          width="80"
        >
          {bookingSearchValue.slice(0, 2) == "zb" ||
          bookingSearchValue.slice(0, 2) == "ZB" ||
          bookingSearchValue.slice(0, 2) == "ZM" ||
          bookingSearchValue.slice(0, 2) == "zm" ? (
            <div className="bookingListFooter">
              <div className="bookingListFooterItemOne">
                <SendMessage
                  selectedBookings={bookings}
                  onClick={this.sendMessage}
                  // selectedCount={selectedBookings.length}
                />
              </div>
              <div className="bookingListFooterItemOne">
                <InitiateComplain
                  selectedBookings={bookings}
                  complains={complainCategory}
                  onClick={this.sendComplain}
                />
              </div>
              <div className="bookingListFooterItemOne">
                {" "}
                <SendRefund
                  issues={customerIssue}
                  isSearchForm="true"
                  selectedBookings={bookings}
                  onClick={this.sendRefundviaPnr}
                />
              </div>
              <div className="bookingListFooterItemOne">
                {" "}
                <SendCoupon
                  selectedBookings={bookings}
                  onClick={this.sendCoupon}
                  isSearchForm={true}
                />
              </div>
              <div className="bookingListFooterItemOne">
                <SendSelfHelpMessage
                  selectedBookings={bookings}
                  onClick={this.publishNoteviaPnr}
                />
              </div>
              <div className="bookingListFooterItemOne">
                <Button type="primary" onClick={this.showCrewDetails}>
                  Crew Details
                </Button>
              </div>
              <div onClick={this.updateBoardingStatus}>
                <Button type="danger">
                  {Array.isArray(bookings) &&
                  bookings.length > 0 &&
                  bookings[0].isOnboarded
                    ? "Mark UnBoarded"
                    : "Mark Boarded"}
                </Button>
              </div>
              {showCrewDetails ? (
                <CrewDetails
                  details={
                    Array.isArray(bookings) &&
                    bookings.length > 0 &&
                    bookings[0].crewDetails
                      ? bookings[0].crewDetails
                      : null
                  }
                  onCancel={() => this.setState({ showCrewDetails: false })}
                />
              ) : null}
            </div>
          ) : (
            <div className="bookingListFooter">
              <div className="bookingListFooterItemOne">
                <SendMessage
                  selectedBookings={selectedBookings}
                  onClick={this.sendMessageNumber}
                  // selectedCount={selectedBookings.length}
                />
              </div>
              <div className="bookingListFooterItemOne">
                <SendRefund
                  issues={customerIssue}
                  isSearchForm="true"
                  selectedBookings={selectedBookings}
                  onClick={this.sendRefund}
                />
              </div>
              <div className="bookingListFooterItemOne">
                {" "}
                <SendCoupon
                  selectedBookings={selectedBookings}
                  onClick={this.sendCoupon}
                  isSearchForm={true}
                />
              </div>
              <div className="bookingListFooterItemOne">
                <SendSelfHelpMessage
                  selectedBookings={selectedBookings}
                  onClick={this.publishNote}
                />
              </div>
              {selectedBookings && selectedBookings.length == 1 ? (
                <>
                  <div className="bookingListFooterItemOne">
                    <Button
                      type="primary"
                      onClick={this.showCrewDetailsViaNumber}
                    >
                      Crew Details
                    </Button>
                  </div>
                  <div onClick={this.updateParticularBoardingStatus}>
                    <Button type="danger">
                      {Array.isArray(selectedBookings) &&
                      selectedBookings.length > 0 &&
                      selectedBookings[0].isOnboarded
                        ? "Mark UnBoarded"
                        : "Mark Boarded"}
                    </Button>
                  </div>
                </>
              ) : null}
              {showCrewDetailsViaNumber ? (
                <CrewDetails
                  details={
                    Array.isArray(selectedBookings) &&
                    selectedBookings.length > 0 &&
                    selectedBookings[0].crewDetails
                      ? selectedBookings[0].crewDetails
                      : null
                  }
                  onCancel={() =>
                    this.setState({ showCrewDetailsViaNumber: false })
                  }
                />
              ) : null}
              {/* <div className="bookingListFooterItemOne">
                <InitiateComplain
                  selectedBookings={selectedBookings}
                  onClick={this.publishNote}
                  complains={complainCategory}
                />
              </div> */}
            </div>
          )}
          {bookingSearchValue.slice(0, 2) == "zb" ||
          bookingSearchValue.slice(0, 2) == "ZB" ||
          bookingSearchValue.slice(0, 2) == "ZM" ||
          bookingSearchValue.slice(0, 2) == "zm" ? (
            <Table
              columns={columns}
              dataSource={bookings}
              pagination={false}
              scroll={{ y: 300 }}
            />
          ) : (
            <Table
              columns={columns}
              dataSource={bookings}
              pagination={false}
              rowSelection={{
                type: "radio",
                columnWidth: "4%",
                onChange: (keys, booking) => {
                  this.setState({
                    selectedBookings: booking
                  });
                }
              }}
              scroll={{ y: 300 }}
            />
          )}
          {editBooking ? (
            <EditBooking
              onChange={obj => this.setState(obj)}
              editField={editField}
              booking={bookingToEdit}
              closeParentModal={() => this.setState({ showResult: false })}
            />
          ) : null}
          {showZingPrimeDetails ? (
            <ZingPrime
              selectedBooking={selectedPrimeBooking}
              onCancel={() =>
                this.setState({
                  showZingPrimeDetails: false,
                  selectedPrimeBooking: undefined
                })
              }
            />
          ) : null}
        </Modal>
      </>
    );
  }
}

export default SearchForm;
