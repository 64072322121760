import React, { Component } from "react";
import moment from "moment";
import less from "./search.module.scss";
import { DatePicker, Select, message, Switch } from "antd";
import Fetch from "../../../utils/fetch";
const { RangePicker } = DatePicker;

const { Option } = Select;

class OperatorSearch extends Component {
  state = {
    showNonZeroBookingTrips: true,
    tripType: "Both",
    buttonLoading: false
  };

  async componentDidMount() {
    try {
      const stations = await this.fetchStations();
      const services = await this.fetchServices();
      this.setState({ services, stations });
    } catch (err) {
      message.error(err);
    }
  }

  fetchStations = async () => {
    try {
      const url = `/bus-tracker/getStationsCache`;

      const options = {
        method: "get"
      };
      const response = await Fetch(url, options);
      if (response) {
        return response;
      } else {
        return [];
      }
    } catch (err) {
      console.log(err);
      return [];
    }
  };

  fetchServices = async () => {
    try {
      const url = `/bus-tracker/getServicesCache`;

      const options = {
        method: "get"
      };
      const response = await Fetch(url, options);
      if (response) {
        return response;
      } else {
        return [];
      }
    } catch (err) {
      console.log(err);
      return [];
    }
  };
  search = async body => {
    try {
      const {
        serviceNameId,
        changeStationSelectedId,
        fromDate,
        toDate,
        showNonZeroBookingTrips,
        tripType
      } = this.state;
      const fromDateMillis = fromDate;

      const toDateMillis = toDate;
      const bodyData = {
        showNonZeroBookingTrips:
          body.showNonZeroBookingTrips === true ||
          body.showNonZeroBookingTrips === false
            ? body.showNonZeroBookingTrips
            : showNonZeroBookingTrips,
        serviceNameId,
        changeStationSelectedId,
        fromDateMillis,
        toDateMillis,
        tripType
      };
      const { isFirstMile } = this.props;
      const url = `/cabOperator/serviceSearch`;
      let details = {
        ...bodyData,
        serviceType: isFirstMile ? "FIRSTMILE" : "LASTMILE"
      };
      const options = {
        method: "post",
        data: { details }
      };
      let response = await Fetch(url, options);
      this.setState({ buttonLoading: false });
      if (response) {
        if (body.tripType == "inter") {
          response = response.filter(value => {
            if (value.route && value.route.length > 1) {
              const firstCityId =
                value.route[0].station && value.route[0].station.cityId;
              const lastCityId =
                value.route[value.route.length - 1].station &&
                value.route[value.route.length - 1].station.cityId;
              return firstCityId && lastCityId && firstCityId !== lastCityId;
            }

            return false;
          });
        } else if (body.tripType == "intra") {
          response = response.filter(value => {
            if (value.route && value.route.length > 1) {
              const firstCityId =
                value.route[0].station && value.route[0].station.cityId;
              const lastCityId =
                value.route[value.route.length - 1].station &&
                value.route[value.route.length - 1].station.cityId;
              return firstCityId && lastCityId && firstCityId === lastCityId;
            }

            return false;
          });
        }
        this.props.onSearch(response);
      } else {
        this.props.onSearch([]);
      }
    } catch (err) {
      console.log(err);
      this.setState({ buttonLoading: false });
      this.props.onSearch([]);
    }
  };
  serviceSearch = () => {
    this.setState({ buttonLoading: true });
    const { serviceNameId } = this.state;
    if (!serviceNameId) {
      message.error("Please select service first");
      return;
    }
    const body = {
      serviceNameId
    };
    this.search(body);
  };

  stationSearch = () => {
    this.setState({ buttonLoading: true });
    const { changeStationSelectedId } = this.state;
    if (!changeStationSelectedId) {
      message.error("Please select station first");
      return;
    }
    const body = {
      changeStationSelectedId
    };
    this.search(body);
  };

  onTimeRangeSelect = value => {
    const [startTime, endTime] = value;
    const fromDate = moment(startTime)
      .startOf("day")
      .valueOf();
    const toDate = moment(endTime)
      .endOf("day")
      .valueOf();
    this.setState({ fromDate, toDate });
  };

  dateSearch = () => {
    this.setState({ buttonLoading: true });

    const { fromDate, toDate, tripType } = this.state;
    const fromDateMillis = fromDate;

    const toDateMillis = toDate;

    const body = {
      fromDateMillis,
      tripType,
      toDateMillis
    };
    this.search(body);
  };

  handleChange = async (key, value, data) => {
    if (key === "serviceSelected") {
      this.setState({
        serviceName: value,
        serviceNameId: data.key
      });
    } else if (key === "changeStationSelected") {
      this.setState({
        changeStationSelected: value,
        changeStationSelectedId: data.key
      });
    } else this.setState({ [key]: value });
  };

  onChange = checked => {
    this.setState({ showNonZeroBookingTrips: checked });
    const body = {
      showNonZeroBookingTrips: checked
    };
    this.search(body);
  };

  render() {
    const {
      buttonLoading,
      stations = [],
      changeStationSelected,
      serviceName,
      tripType,
      services = [],
      showNonZeroBookingTrips
    } = this.state;
    return (
      <div className={less.OperatorSearch}>
        <div className={less.selectables}>
          <Select
            placeholder="Service Name"
            showSearch
            style={{ width: 350 }}
            value={serviceName}
            onChange={(value, key) =>
              this.handleChange("serviceSelected", value, key)
            }
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            {services.map(s => (
              <Option value={s.name} key={s._id}>
                {s.name}
              </Option>
            ))}
          </Select>
          <button
            disabled={buttonLoading}
            className={less.searchBtn}
            onClick={this.serviceSearch}
          >
            <span>Search</span>
          </button>
        </div>
        <div className={less.selectables}>
          <Select
            showSearch
            style={{ width: 350 }}
            value={changeStationSelected}
            onChange={(value, key) =>
              this.handleChange("changeStationSelected", value, key)
            }
            placeholder="Change Station"
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            {stations.map(s => (
              <Option value={s.name} key={s._id}>
                {s.name}
              </Option>
            ))}
          </Select>
          <button
            disabled={buttonLoading}
            className={less.searchBtn}
            onClick={this.stationSearch}
          >
            <span>Search</span>
          </button>
        </div>
        <div className={less.dateSelect}>
          <RangePicker format="YYYY-MM-DD" onChange={this.onTimeRangeSelect} />
        </div>
        <div className={less.selectables}>
          <Select
            style={{ width: 150 }}
            defaultValue={tripType}
            placeholder="Select"
            onChange={value => this.setState({ tripType: value })}
          >
            <Option value="Both">Inter/Intra</Option>
            <Option value="inter">Inter</Option>
            <Option value="intra">Intra</Option>
          </Select>
        </div>
        <Switch
          checked={showNonZeroBookingTrips}
          style={{
            backgroundColor: showNonZeroBookingTrips ? "#7b2bff" : "#d9d9d9",
            borderColor: "#7b2bff"
          }}
          checkedChildren="Trips with booking"
          unCheckedChildren="All Trips"
          onChange={this.onChange}
        />
        <button
          disabled={buttonLoading}
          className={less.searchBtn}
          onClick={this.dateSearch}
        >
          <span>Search / Refresh</span>
        </button>
      </div>
    );
  }
}

export default OperatorSearch;
