import React, { Component } from "react";
import {
  Button,
  Input,
  DatePicker,
  Select,
  Modal,
  AutoComplete,
  message
} from "antd";
import moment from "moment";

import Fetch from "../../utils/fetch";

const { TextArea } = Input;
const { Option } = Select;

class InitiateTask extends Component {
  state = {
    showInitiateTask: false,
    Comment: "",
    etaDate: moment().add(10, "days"),
    tripdate: moment(),
    date: moment(),
    serviceNames: [],
    busNumberSearch: [],
    stationName: [],
    tripServiceNames: [],
    agentName: [],
    busDriverName: [],
    busHost: [],
    loader: false
  };

  async componentDidMount() {
    try {
      const createTaskResp = await this.createTask();
      this.setState({
        tasks: createTaskResp
      });
    } catch (err) {
      console.error(err);
    }
  }

  handleChange = (key, value) => {
    this.setState({ [key]: value });
  };

  createTask = async () => {
    try {
      this.setState({ taskLoader: true });
      const url = `/bus-tracker/createTask/all`;

      const options = {
        method: "get"
      };
      const response = await Fetch(url, options);

      if (response) {
        this.setState({ taskLoader: false });
        return response.data;
      } else {
        return [];
      }
    } catch (err) {
      console.log(err);

      return [];
    }
  };

  getServiceName = async () => {
    try {
      const url = `/bus-tracker/serviceNameEs`;

      const options = {
        method: "get"
      };
      const response = await Fetch(url, options);
      if (response) {
        return response;
      } else {
        return [];
      }
    } catch (err) {
      console.log(err);

      return [];
    }
  };

  busNumber = async () => {
    try {
      const url = `/bus-tracker/busNumber`;

      const options = {
        method: "get"
      };
      const response = await Fetch(url, options);

      if (response) {
        return response;
      } else {
        return [];
      }
    } catch (err) {
      console.log(err);

      return [];
    }
  };

  getStationName = async () => {
    try {
      const url = `/bus-tracker/stationName`;

      const options = {
        method: "get"
      };
      const response = await Fetch(url, options);

      if (response) {
        return response;
      } else {
        return [];
      }
    } catch (err) {
      console.log(err);

      return [];
    }
  };

  getBusHost = async () => {
    try {
      const url = `/bus-tracker/busHostName`;

      const options = {
        method: "get"
      };
      const response = await Fetch(url, options);

      if (response) {
        return response;
      } else {
        return [];
      }
    } catch (err) {
      console.log(err);

      return [];
    }
  };

  getAgentName = async () => {
    try {
      const url = `/bus-tracker/agentName`;

      const options = {
        method: "get"
      };
      const response = await Fetch(url, options);

      if (response) {
        return response;
      } else {
        return [];
      }
    } catch (err) {
      console.log(err);

      return [];
    }
  };

  getBusDriverName = async () => {
    try {
      const url = `/bus-tracker/busDriverName`;

      const options = {
        method: "get"
      };
      const response = await Fetch(url, options);
      if (response) {
        return response;
      } else {
        return [];
      }
    } catch (err) {
      console.log(err);

      return [];
    }
  };

  getTrips = async date => {
    try {
      const url = `/bus-tracker/trips?date=${moment(date).format(
        "YYYY-MM-DD"
      )}&taskSearch=${true}`;

      const options = {
        method: "get"
      };
      const response = await Fetch(url, options);

      if (response) {
        return response;
      } else {
        return [];
      }
    } catch (err) {
      console.log(err);

      return [];
    }
  };

  getBookingId = async searchpnr => {
    try {
      const url = `/bus-tracker/booking?pnr=${searchpnr}`;
      const options = {
        method: "get"
      };
      const response = await Fetch(url, options);
      if (response) {
        return response[0]._id;
      } else {
        message.error("Invalid PNR");
        return [];
      }
    } catch (err) {
      console.log(err);

      return [];
    }
  };

  handleSpecialCharacters = async event => {
    const newCheck = event.target.value.replace(/[^\w\s]/gi, "");
    if (newCheck.length == 13) {
      let bookingId = await this.getBookingId(newCheck);
      this.setState({ bookingId: bookingId });
    }
  };

  getMandatoryFields = async id => {
    this.setState({ loader: true });
    const { tasks, tripdate } = this.state;
    let getMandatoryFields = tasks.filter(task => task._id == id)[0]
      .mandatoryField;

    getMandatoryFields.forEach(async obj => {
      if (obj == "serviceId") {
        const serviceNames = await this.getServiceName();
        this.setState({ serviceNames });
      } else if (obj == "busId") {
        const busNumberSearch = await this.busNumber();
        this.setState({ busNumberSearch });
      } else if (obj == "stationId") {
        const stationName = await this.getStationName();
        this.setState({ stationName });
      } else if (obj == "agentId") {
        let agentName = await this.getAgentName();
        agentName = agentName.filter(agent => agent.user.name);
        this.setState({ agentName });
      } else if (obj == "busDriverId") {
        let busDriverName = await this.getBusDriverName();
        busDriverName = busDriverName.filter(bus => bus.name);
        this.setState({ busDriverName });
      } else if (obj == "busHostId") {
        let busHost = await this.getBusHost();
        busHost = busHost.filter(
          (ele, ind) =>
            ind === busHost.findIndex(elem => elem.name === ele.name)
        );
        this.setState({ busHost });
      } else if (obj == "tripId") {
        const tripServiceNames = await this.getTrips(tripdate);
        this.setState({ tripServiceNames });
      }
    });
    this.setState({
      loader: false,
      mandatoryFields: getMandatoryFields,
      taskMasterId: id
    });
  };

  toggleMessageOption = () => {
    // const { tasks } = this.props;

    // if (!tasks) {
    //   return message.error("Please select booking or station");
    // }

    this.setState({
      loading: false,
      // tasks: tasks,
      showInitiateTask: true
    });
  };

  onDateChange = async tripdate => {
    const tripServiceNames = await this.getTrips(tripdate);
    this.setState({ tripServiceNames, tripdate, tripId: "" });
  };

  handleSendClick = () => {
    const { onClick } = this.props;
    let Bodyobj = {
      taskMasterId: this.state.taskMasterId,
      etaDate: this.state.etaDate ? this.state.etaDate : undefined,
      Comment: this.state.Comment < 1 ? undefined : this.state.Comment
    };
    let { mandatoryFields } = this.state;
    mandatoryFields.forEach(task => {
      if (this.state[task]) {
        Bodyobj = {
          ...Bodyobj,
          [task]: this.state[task]
        };
      }
    });

    onClick(Bodyobj);

    mandatoryFields.forEach(task => {
      if (this.state.task) {
        this.setState({
          [task]: ""
        });
      }
    });

    this.setState({
      showInitiateTask: false,
      mandatoryFields: "",
      taskMasterId: ""
    });
  };

  render() {
    const {
      showInitiateTask,
      mandatoryFields,
      etaDate,
      date,
      tasks,
      tripdate,
      loader,
      serviceNames,
      tripServiceNames,
      busNumberSearch,
      stationName,
      agentName,
      busDriverName,
      busHost
    } = this.state;
    const { isloading } = this.props;
    return (
      <div>
        {showInitiateTask ? (
          <Modal
            centered
            width={1500}
            title="Create Task"
            footer={null}
            visible={true}
            onCancel={() =>
              this.setState({
                showInitiateTask: false,
                mandatoryFields: "",
                taskMasterId: ""
              })
            }
          >
            <div className="row">
              <div className="col-3">Select Task Name</div>
              <div className="col-6 text-left">
                <Select
                  className="mb-1"
                  style={{ width: "200px" }}
                  defaultValue=" "
                  onChange={key => this.getMandatoryFields(key)}
                >
                  {tasks.map(task => (
                    <Option value={task._id} key={task._id}>
                      {task.name}
                    </Option>
                  ))}
                </Select>
              </div>
            </div>
            {Array.isArray(mandatoryFields)
              ? mandatoryFields.map((fields, index) => (
                  <div key={index} loading={loader} className="row">
                    <div className="col-3">
                      {fields == "serviceId"
                        ? "Service Name"
                        : fields == "busId"
                        ? "Bus Number"
                        : fields == "agentId"
                        ? "Agent Name"
                        : fields == "tripId"
                        ? "Trip Name and Trip Date"
                        : fields == "bookingId"
                        ? "PNR"
                        : fields == "busDriverId"
                        ? "Bus Driver Name"
                        : fields == "stationId"
                        ? "Station Name"
                        : fields == "busHostId"
                        ? "Bus Host Name"
                        : fields == "date"
                        ? "Date"
                        : fields}{" "}
                    </div>
                    <div className="col-6 text-left">
                      {" "}
                      {fields == "serviceId" ? (
                        <AutoComplete
                          style={{
                            width: "500px"
                          }}
                          dataSource={serviceNames.map(service => service.name)}
                          onChange={value => {
                            let id = serviceNames.filter(
                              service => service.name === value
                            );
                            if (id.length) {
                              this.setState({
                                [fields]: id[0]._id
                              });
                            } else {
                              this.setState({
                                [fields]: null
                              });
                            }
                          }}
                          filterOption={(inputValue, option) =>
                            option.props.children
                              .toUpperCase()
                              .indexOf(inputValue.toUpperCase()) !== -1
                          }
                        />
                      ) : fields == "busId" ? (
                        <AutoComplete
                          style={{
                            width: "500px"
                          }}
                          onChange={value => {
                            let id = busNumberSearch.filter(
                              bus => bus.rc === value
                            );
                            if (id.length) {
                              this.setState({
                                [fields]: id[0]._id
                              });
                            } else {
                              this.setState({
                                [fields]: null
                              });
                            }
                          }}
                          dataSource={busNumberSearch.map(bus => bus.rc)}
                          filterOption={(inputValue, option) =>
                            option.props.children
                              .toUpperCase()
                              .indexOf(inputValue.toUpperCase()) !== -1
                          }
                        />
                      ) : fields == "busDriverId" ? (
                        <AutoComplete
                          style={{
                            width: "500px"
                          }}
                          onChange={value => {
                            let id = busDriverName.filter(
                              bus => bus.name === value
                            );
                            if (id.length) {
                              this.setState({
                                [fields]: id[0]._id
                              });
                            } else {
                              this.setState({
                                [fields]: null
                              });
                            }
                          }}
                          dataSource={busDriverName.map(bus => bus.name)}
                          filterOption={(inputValue, option) =>
                            option.props.children
                              .toUpperCase()
                              .indexOf(inputValue.toUpperCase()) !== -1
                          }
                        />
                      ) : fields == "busHostId" ? (
                        <AutoComplete
                          style={{
                            width: "500px"
                          }}
                          onChange={value => {
                            let id = busHost.filter(bus => bus.name === value);
                            if (id.length) {
                              this.setState({
                                [fields]: id[0]._id
                              });
                            } else {
                              this.setState({
                                [fields]: null
                              });
                            }
                          }}
                          dataSource={busHost.map(bus => bus.name)}
                          filterOption={(inputValue, option) =>
                            option.props.children
                              .toUpperCase()
                              .indexOf(inputValue.toUpperCase()) !== -1
                          }
                        />
                      ) : fields == "agentId" ? (
                        <AutoComplete
                          style={{
                            width: "500px"
                          }}
                          onChange={value => {
                            let id = agentName.filter(
                              agent => agent.user.name === value
                            );
                            if (id.length) {
                              this.setState({
                                [fields]: id[0]._id
                              });
                            } else {
                              this.setState({
                                [fields]: null
                              });
                            }
                          }}
                          dataSource={agentName.map(agent => agent.user.name)}
                          filterOption={(inputValue, option) =>
                            option.props.children
                              .toUpperCase()
                              .indexOf(inputValue.toUpperCase()) !== -1
                          }
                        />
                      ) : fields == "tripId" ? (
                        <div>
                          <DatePicker
                            style={{ width: 150 }}
                            value={tripdate}
                            onChange={this.onDateChange}
                          />
                          {Array.isArray(tripServiceNames) &&
                          tripServiceNames.length > 0 ? (
                            <AutoComplete
                              loading={loader}
                              style={{
                                width: "500px"
                              }}
                              dataSource={tripServiceNames.map(
                                tripServiceNames =>
                                  tripServiceNames.service.name
                              )}
                              onChange={value => {
                                let id = tripServiceNames.filter(
                                  service => service.service.name === value
                                );
                                if (id.length) {
                                  this.setState({
                                    [fields]: id[0]._id
                                  });
                                } else {
                                  this.setState({
                                    [fields]: null
                                  });
                                }
                              }}
                              filterOption={(inputValue, option) =>
                                option.props.children
                                  .toUpperCase()
                                  .indexOf(inputValue.toUpperCase()) !== -1
                              }
                            />
                          ) : (
                            <span> No Trip on desired date </span>
                          )}
                        </div>
                      ) : fields == "stationId" ? (
                        <AutoComplete
                          style={{
                            width: "500px"
                          }}
                          onChange={value => {
                            let id = stationName.filter(
                              station => station.name === value
                            );
                            if (id.length) {
                              this.setState({
                                [fields]: id[0]._id
                              });
                            } else {
                              this.setState({
                                [fields]: null
                              });
                            }
                          }}
                          dataSource={stationName.map(station => station.name)}
                          filterOption={(inputValue, option) =>
                            option.props.children
                              .toUpperCase()
                              .indexOf(inputValue.toUpperCase()) !== -1
                          }
                        />
                      ) : fields == "bookingId" ? (
                        <TextArea
                          className="mb-1"
                          type="text"
                          maxLength="14"
                          autoSize={{ minRows: 1, maxRows: 2 }}
                          style={{ width: "100%" }}
                          onChange={this.handleSpecialCharacters}
                        />
                      ) : fields == "amount" ? (
                        <Input
                          className="mb-1"
                          type="number"
                          style={{ width: "100%" }}
                          onChange={e =>
                            this.handleChange(
                              `${fields}`,
                              parseInt(e.target.value)
                            )
                          }
                        />
                      ) : fields == "date" ? (
                        <DatePicker
                          style={{ width: 150 }}
                          value={date}
                          onChange={date => this.setState({ date })}
                        />
                      ) : (
                        <TextArea
                          className="mb-1"
                          autoSize={{ minRows: 1, maxRows: 2 }}
                          maxLength={30}
                          style={{ width: "100%" }}
                          onChange={e =>
                            this.handleChange(`${fields}`, e.target.value)
                          }
                        />
                      )}{" "}
                    </div>
                  </div>
                ))
              : null}
            {Array.isArray(mandatoryFields) ? (
              <>
                <div className="row">
                  <div className="col-3">ETA</div>
                  <div className="col-6 text-left">
                    {" "}
                    <DatePicker
                      disabledDate={current => {
                        let customDate = moment().format("YYYY-MM-DD");
                        return (
                          current && current < moment(customDate, "YYYY-MM-DD")
                        );
                      }}
                      style={{ width: 150 }}
                      value={etaDate}
                      onChange={etaDate => this.setState({ etaDate })}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-3">Comments</div>
                  <div className="col-6 text-left">
                    <TextArea
                      className="mb-1"
                      autoSize={{ minRows: 1, maxRows: 2 }}
                      maxLength={30}
                      style={{ width: "100%" }}
                      onChange={e =>
                        this.handleChange("Comment", e.target.value)
                      }
                    />
                  </div>
                </div>
                <div>
                  <Button
                    type="primary"
                    onClick={this.handleSendClick}
                    style={{ margin: "1% 0" }}
                  >
                    Confirm Task
                  </Button>
                </div>
              </>
            ) : null}
          </Modal>
        ) : (
          <Button
            loading={isloading}
            onClick={this.toggleMessageOption}
            style={{ width: 100 }}
          >
            Initiate Task
          </Button>
        )}
      </div>
    );
  }
}

export default InitiateTask;
