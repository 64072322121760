import React from "react";

const KycPolicyHindi = () => {
  return (
    <div>
      <div>
        <h1>क्रू केवाईसी सत्यापन के लिए गोपनीयता नीति</h1>
        <div>
          <h2>परिचय</h2>
          <p>
            यह गोपनीयता नीति &quot;आगे &quot;नीति&quot; के रूप में
            संदर्भित&quot; लागू डेटा संरक्षण कानूनों और सर्कुलर
            11020/205/2017-AUTH-1 में उल्लिखित दिशानिर्देशों के अनुपालन में बनाई
            गई है। Zingbus अपने आंतरिक उपयोगकर्ताओं, जिसमें मार्शल &quot;बस
            होस्ट&quot; और ड्राइवर &quot;बस ड्राइवर&quot; शामिल हैं, द्वारा
            प्रदान किए गए व्यक्तिगत डेटा की गोपनीयता, अखंडता और सुरक्षा
            सुनिश्चित करने के लिए प्रतिबद्ध है। Zingbus प्लेटफ़ॉर्म पर पंजीकरण
            करके, आप स्वीकार करते हैं कि आपने इस नीति को पढ़ा, समझा और इसकी
            शर्तों और प्रावधानों से सहमति दी है।
          </p>
        </div>
        <div>
          <h2>आवेदन का दायरा</h2>
          <p>
            यह नीति निम्नलिखित के व्यक्तिगत डेटा के संग्रह, उपयोग, प्रसंस्करण,
            संग्रहण और प्रकटीकरण को नियंत्रित करती है:
            <ul>
              <li>Zingbus के साथ पंजीकृत मार्शल।</li>
              <li>Zingbus के साथ पंजीकृत ड्राइवर।</li>
            </ul>
          </p>
        </div>
        <div>
          <h2>संग्रहित व्यक्तिगत डेटा की श्रेणियाँ</h2>
          <p>
            सत्यापन उद्देश्यों के लिए Zingbus निम्नलिखित प्रकार के व्यक्तिगत
            डेटा को एकत्र करता और संसाधित करता है।
            <h3>मार्शल के लिए:</h3>
            <ul>
              <li>आधार कार्ड: पहचान और पते के सत्यापन के लिए।</li>
              <li>पैन कार्ड: वित्तीय सत्यापन और अनुपालन के लिए।</li>
            </ul>
            <h3>ड्राइवर के लिए:</h3>
            <ul>
              <li>आधार कार्ड: पहचान और पते के सत्यापन के लिए।</li>
              <li>
                ड्राइविंग लाइसेंस: पहचान सत्यापन और ड्राइविंग क्रेडेंशियल्स की
                वैधता के लिए।
              </li>
            </ul>
          </p>
        </div>
        <div>
          <h2>डेटा प्रसंस्करण का उद्देश्य</h2>
          <p>
            संग्रहित व्यक्तिगत डेटा को निम्नलिखित उद्देश्यों के लिए सख्ती से
            संसाधित किया जाएगा:
            <ul>
              <li>
                लागू कानूनी और नियामक आवश्यकताओं के अनुसार पहचान और पते का
                सत्यापन।
              </li>
              <li>
                यात्री सुरक्षा और परिचालन मानकों के अनुपालन को सुनिश्चित करने के
                लिए क्रेडेंशियल्स का सत्यापन।
              </li>
            </ul>
          </p>
        </div>
        <div>
          <h2>उपयोगकर्ता की सहमति और प्राधिकरण</h2>
          <p>
            व्यक्तिगत डेटा के संग्रह और प्रसंस्करण से पहले Zingbus डेटा विषयों
            से स्पष्ट सहमति प्राप्त करेगा। प्लेटफ़ॉर्म पर पंजीकरण करके, आप अपनी
            सूचित और स्पष्ट सहमति प्रदान करते हैं:
            <ul>
              <li>
                इस नीति में उल्लिखित आपके व्यक्तिगत डेटा के संग्रह, प्रसंस्करण
                और संग्रहण के लिए।
              </li>
              <li>
                अधिकृत तृतीय-पक्ष एजेंसियों के माध्यम से आपकी पहचान और
                क्रेडेंशियल्स के सत्यापन के लिए।
              </li>
              <li>
                लागू कानूनों और विनियमों के अनुसार आपके व्यक्तिगत डेटा के
                संग्रहण के लिए।
              </li>
            </ul>
          </p>
        </div>
      </div>
      <div>
        <h2>डेटा संरक्षण और सुरक्षा उपाय</h2>
        <p>
          Zingbus अपने आंतरिक उपयोगकर्ताओं के व्यक्तिगत डेटा की सुरक्षा के लिए
          कठोर उपाय लागू करता है, जिसमें शामिल हैं:
          <ul>
            <li>डेटा के प्रसारण और संग्रहण के दौरान एन्क्रिप्शन प्रोटोकॉल।</li>
            <li>
              भूमिका-आधारित एक्सेस नियंत्रण, जिससे केवल अधिकृत कर्मचारी ही
              व्यक्तिगत डेटा तक पहुँच प्राप्त कर सकें।
            </li>
            <li>
              सर्कुलर 11020/205/2017-AUTH-1 और प्रासंगिक डेटा संरक्षण कानूनों के
              अनुपालन सुनिश्चित करने के लिए समय-समय पर ऑडिट। Zingbus गारंटी देता
              है कि आपका व्यक्तिगत डेटा एक सुरक्षित वातावरण में रखा जाएगा और इसे
              इस नीति में निर्दिष्ट उद्देश्यों के अलावा किसी अन्य उद्देश्य के
              लिए उपयोग नहीं किया जाएगा।
            </li>
          </ul>
        </p>
        <div>
          <h2>डेटा साझा करना और प्रकटीकरण</h2>
          <p>
            व्यक्तिगत डेटा केवल निम्नलिखित परिस्थितियों में साझा किया जा सकता
            है:
            <ul>
              <li>
                पहचान और क्रेडेंशियल्स के सत्यापन के लिए अधिकृत एजेंसियों के
                साथ।
              </li>
              <li>
                कानून द्वारा अनिवार्य नियामक प्राधिकरणों के साथ। Zingbus तृतीय
                पक्षों के साथ व्यक्तिगत डेटा को विपणन या किसी असंबंधित उद्देश्य
                के लिए साझा करने पर स्पष्ट रूप से रोक लगाता है।
              </li>
            </ul>
          </p>
        </div>
        <div>
          <h2>डेटा संग्रहण नीति</h2>
          <p>
            आपके Zingbus के साथ जुड़ाव की अवधि और किसी भी अतिरिक्त अवधि के लिए
            व्यक्तिगत डेटा को संग्रहित किया जाएगा, जैसा कि कानूनी या नियामक
            दायित्वों के अनुपालन के लिए आवश्यक है।
          </p>
        </div>
        <div>
          <h2>डेटा विषयों के अधिकार</h2>
          <p>
            एक डेटा विषय के रूप में, आपके व्यक्तिगत डेटा के संबंध में आपके पास
            निम्नलिखित अधिकार हैं:
            <ul>
              <li>
                Zingbus द्वारा रखे गए आपके व्यक्तिगत डेटा तक पहुँच प्राप्त करने
                का अधिकार।
              </li>
              <li>
                गलत या अधूरे डेटा को संशोधित करने के लिए अनुरोध करने का अधिकार।
              </li>
              <li>
                प्रसंस्करण के लिए सहमति वापस लेने का अधिकार, लागू कानूनी सीमाओं
                के अधीन। आपके अधिकारों का उपयोग करने के लिए अनुरोध
                care@zingbus.com पर प्रस्तुत किए जा सकते हैं।
              </li>
            </ul>
          </p>
        </div>
        <div>
          <h2>नीति में संशोधन</h2>
          <p>
            Zingbus कानूनी आवश्यकताओं या आंतरिक प्रक्रियाओं में बदलाव को दर्शाने
            के लिए इस नीति को संशोधित या अपडेट करने का अधिकार रखता है। सभी अपडेट
            पंजीकृत उपयोगकर्ताओं को ईमेल या Zingbus प्लेटफ़ॉर्म के माध्यम से
            सूचित किए जाएंगे।
          </p>
        </div>
        <div>
          <h2>संपर्क जानकारी</h2>
          <p>
            इस नीति से संबंधित पूछताछ या चिंताओं के लिए कृपया संपर्क करें:
            Zingbus गोपनीयता अनुपालन अधिकारी
          </p>
          <p>ईमेल: care@zingbus.com</p>
          <p>
            पता: गुड अर्थ बिजनेस बे, 1, गोल्फ कोर्स रोड एक्सटेंशन, सेक्टर 58,
            गुरुग्राम, हरियाणा 122003
          </p>
          <p>
            Zingbus प्लेटफ़ॉर्म पर पंजीकरण करके, आप पुष्टि करते हैं कि आपने इस
            नीति को पढ़ा और समझा है और इसमें उल्लिखित एवं सर्कुलर
            11020/205/2017-AUTH-1 के अनुसार आपके व्यक्तिगत डेटा के प्रसंस्करण के
            लिए सहमति प्रदान की है।
          </p>
        </div>
      </div>
    </div>
  );
};

export default KycPolicyHindi;
