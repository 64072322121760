import React, { Component } from "react";

import { Button, DatePicker, Select, message, AutoComplete, Input } from "antd";
import Fetch from "../../utils/fetch";

const { Option } = Select;

class CameraFeedSearchForm extends Component {
  state = {
    showDatePicker: false,
    hideDate: false,
    serviceNames: [],
    raisedBy: "all"
  };

  async componentDidMount() {
    try {
      const serviceNames = await this.getServiceName();

      this.setState({ serviceNames });
    } catch (err) {
      message.error(err);
    }
  }
  getServiceName = async () => {
    try {
      const url = `/bus-tracker/serviceNameEs`;

      const options = {
        method: "get"
      };
      const response = await Fetch(url, options);
      if (response) {
        return response;
      } else {
        return [];
      }
    } catch (err) {
      console.log(err);

      return [];
    }
  };

  fetchComplainOnTime = async key => {
    if (key != "") {
      this.setState({
        time: parseInt(key),
        hideDate: true,
        fromDate: null,
        toDate: null
      });
    }
    if (key == "custom") {
      this.setState({
        showDatePicker: true,
        hideDate: true,
        fromDate: null,
        toDate: null
      });
    }
    if (key == "today") {
      this.setState({
        hideDate: true,
        showDatePicker: false,
        fromDate: new Date().setHours(0, 0, 0, 0),
        toDate: new Date().setHours(23, 59, 59, 59)
      });
    }
    if (key == "yesterday") {
      this.setState({
        hideDate: true,
        showDatePicker: false,
        fromDate: new Date().setHours(0, 0, 0, 0) - 86400000,
        toDate: new Date().setHours(23, 59, 59, 59) - 86400000
      });
    }
  };

  showResult = async () => {
    const {
      raisedBy,
      fromDate,
      toDate,
      time,
      busNumber,
      searchedServiceNames
    } = this.state;
    let body = {};
    this.props.showLoader();
    if (raisedBy) {
      body.raisedBy = raisedBy;
    }
    if (busNumber) {
      body.busNo = busNumber;
    }
    if (searchedServiceNames) {
      body.serviceId = searchedServiceNames;
    }
    if (fromDate && toDate) {
      body.fromDate = fromDate.valueOf();
      body.toDate = toDate.valueOf();
    }
    if ((fromDate && !toDate) || (toDate && !fromDate)) {
      message.error("please select both date");
      return;
    }
    if (Number.isInteger(time)) {
      body.time = time;
    }
    const url = `/bus-tracker/searchCustomComplain/CameraFeed`;
    const options = {
      method: "post",
      data: {
        body
      }
    };
    const response = await Fetch(url, options);
    if (response && Array.isArray) {
      this.props.onSearch(response);
    } else {
      this.props.onSearch(null);
    }
  };

  setValue = value => {
    const { complains } = this.state;
    let id = complains.filter(complain => complain.name === value);
    this.setState({ searchedComplain: id });
  };

  render() {
    const {
      fromDate,
      toDate,
      showDatePicker,
      hideDate,
      busNumber,
      serviceNames
    } = this.state;
    return (
      <>
        <div className="callTrigger">
          <div className="feedSelector">
            <AutoComplete
              placeholder="Service Search"
              style={{
                width: "100%"
              }}
              dataSource={serviceNames.map(service => service.name)}
              onChange={value => {
                let id = serviceNames.filter(service => service.name === value);
                if (id.length) {
                  this.setState({
                    searchedServiceNames: id[0]._id
                  });
                } else {
                  this.setState({
                    searchedServiceNames: null
                  });
                }
              }}
              filterOption={(inputValue, option) =>
                option.props.children
                  .toUpperCase()
                  .indexOf(inputValue.toUpperCase()) !== -1
              }
            />
            <Input
              style={{ width: "50%" }}
              type="text"
              maxLength="10"
              placeholder="Bus Number Search"
              value={busNumber}
              onChange={e => this.setState({ busNumber: e.target.value })}
            />
          </div>
          <div style={{ margin: "0 1%" }}>
            <Select
              placeholder="Time Duration"
              onChange={this.fetchComplainOnTime}
            >
              <Option value="1">Last 60mins</Option>
              <Option value="4">Last 4hrs</Option>
              <Option value="today">Today</Option>
              <Option value="yesterday">Yesterday</Option>
              <Option value="custom">custom Select</Option>
            </Select>
          </div>
          {!hideDate ? (
            <div style={{ margin: "0 1%" }}>
              <DatePicker
                value={fromDate}
                onChange={fromDate => this.setState({ fromDate })}
              />
              {" to "}
              <DatePicker
                value={toDate}
                onChange={toDate => this.setState({ toDate })}
              />
            </div>
          ) : showDatePicker ? (
            <div style={{ margin: "0 1%" }}>
              <DatePicker
                showTime
                value={fromDate}
                onChange={fromDate => this.setState({ fromDate })}
              />
              {" to "}
              <DatePicker
                showTime
                value={toDate}
                onChange={toDate => this.setState({ toDate })}
              />
            </div>
          ) : null}

          <Button type="primary" onClick={this.showResult}>
            Search
          </Button>
        </div>
        <hr />
      </>
    );
  }
}

export default CameraFeedSearchForm;
