import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import React from "react";
import ReactPlayer from "react-player";
import { Carousel } from "react-responsive-carousel";

const AudioSlide = ({ url, isSelected }) => (
  <ReactPlayer
    width="100%"
    url={url}
    playing={isSelected}
    controls
    style={{ padding: "100px" }}
  />
);

const CarouselAudio = ({ activeKey, data }) => {
  const customRenderItem = (item, props) => (
    <item.type {...item.props} {...props} />
  );
  // console.log("activeKey---------", activeKey, data);
  if (activeKey === "2") {
    return (
      <Carousel renderItem={customRenderItem}>
        {data.map(v => (
          <AudioSlide
            url={"https://d370edwo3kkakj.cloudfront.net/" + v.path}
            key="youtube-1"
          />
        ))}
      </Carousel>
    );
  }
};

export default CarouselAudio;
