import React, { Component } from "react";
import moment from "moment";
import swapIcon from "../../../images/swap-icon.svg";
import less from "./search.module.scss";
import { Switch, Select, message, DatePicker } from "antd";
import Fetch from "../../../utils/fetch";
const { RangePicker } = DatePicker;
const { Option } = Select;

class ContractSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fromCity: undefined,
      toCity: undefined,
      cities: [],
      isExpired: true,
      fromDate: moment()
        .startOf("day")
        .valueOf(),
      toDate: moment()
        .endOf("day")
        .valueOf()
    };
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.clearFilters &&
      this.props.clearFilters !== prevProps.clearFilters
    ) {
      this.resetFilters();
    }
  }

  // Reset state to default values
  resetFilters = () => {
    this.setState({
      fromCity: undefined,
      toCity: undefined,
      assetType: undefined,
      cabOperator: undefined,
      isExpired: true,
      fromCityId: undefined,
      toCityId: undefined,
      cabOperatorId: undefined,
      fromDate: moment()
        .startOf("day")
        .valueOf(),
      toDate: moment()
        .endOf("day")
        .valueOf()
    });
  };
  async componentDidMount() {
    try {
      const { cities, cabOperators, assetTypeValues } = this.props;
      this.setState({ cities, cabOperators, assetTypeValues });
    } catch (err) {
      message.error(err);
    }
  }

  handleSwap = () => {
    const { fromCity, toCity, cities } = this.state;

    let fromCityId = cities.filter(city => city.name === toCity)[0]._id;

    let toCityId = cities.filter(city => city.name === fromCity)[0]._id;

    this.setState({
      fromCity: toCity,
      fromCityId,
      toCity: fromCity,
      toCityId
    });
  };

  handleChange = async (key, value, data) => {
    if (key === "fromCity") {
      this.setState({
        fromCity: value,
        fromCityId: data ? data.key : undefined
      });
    } else if (key === "toCity") {
      this.setState({
        toCity: value,
        toCityId: data ? data.key : undefined
      });
    } else if (key === "cabOperatorId") {
      this.setState({
        cabOperator: value,
        cabOperatorId: data ? data.key : undefined
      });
    } else this.setState({ [key]: value });
  };

  search = async () => {
    try {
      const {
        fromCityId,
        toCityId,
        assetType,
        cabOperatorId,
        isExpired,
        fromDate,
        toDate
      } = this.state;

      const body = {
        fromCityId,
        toCityId,
        assetType,
        cabOperatorId,
        showExpired: isExpired
      };
      if (fromDate && toDate) {
        body.fromDate = fromDate.valueOf();
        body.toDate = toDate.valueOf();
      }
      if ((fromDate && !toDate) || (toDate && !fromDate)) {
        message.error("please select both date");
        return;
      }
      const url = `/cabOperator/searchOperatorContract`;
      const options = {
        method: "post",
        data: { details: body }
      };
      const response = await Fetch(url, options);
      if (response) {
        this.props.onSearch(response);
      } else {
        this.props.onSearch([]);
      }
    } catch (err) {
      console.log(err);
      this.props.onSearch([]);
    }
  };
  citySearch = () => {
    const { fromCityId, toCityId } = this.state;
    const body = {
      fromCityId,
      toCityId
    };
    this.search(body);
  };

  showExpired = async checked => {
    try {
      const { fromCityId, toCityId, assetType, cabOperatorId } = this.state;
      this.setState({ isExpired: checked });
      const body = {
        fromCityId,
        toCityId,
        assetType,
        cabOperatorId,
        showExpired: checked
      };
      const url = `/cabOperator/searchOperatorContract`;
      const options = {
        method: "post",
        data: { details: body }
      };
      const response = await Fetch(url, options);
      if (response) {
        this.props.onSearch(response);
      } else {
        this.props.onSearch([]);
      }
    } catch (err) {
      console.log(err);
      this.props.onSearch([]);
    }
  };
  onTimeRangeSelect = value => {
    if (!value) {
      const fromDate = moment()
        .startOf("day")
        .valueOf();
      const toDate = moment()
        .endOf("day")
        .valueOf();
      this.setState({ fromDate, toDate });
    } else {
      const [startTime, endTime] = value;
      const fromDate = moment(startTime)
        .startOf("day")
        .valueOf();
      const toDate = moment(endTime)
        .endOf("day")
        .valueOf();
      this.setState({ fromDate, toDate });
    }
  };
  render() {
    const {
      cities,
      fromDate,
      toDate,
      fromCity,
      toCity,
      cabOperators = [],
      assetTypeValues = [],
      assetType,
      cabOperator,
      isExpired = false
    } = this.state;
    return (
      <div className={less.wrapper}>
        <div className={less.search}>
          <div className={less.citySearch}>
            <Select
              allowClear
              showSearch
              placeholder="From City"
              style={{ width: 150 }}
              onChange={(value, key) =>
                this.handleChange("fromCity", value, key)
              }
              value={fromCity}
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              {cities.map(city => (
                <Option value={city.name} key={city._id}>
                  {city.name}
                </Option>
              ))}
            </Select>
            <button className="swapButton" onClick={this.handleSwap}>
              <img src={swapIcon} alt="swapicon" />
            </button>
            <Select
              allowClear
              placeholder="To City"
              showSearch
              style={{ width: 150 }}
              onChange={(value, key) => this.handleChange("toCity", value, key)}
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              value={toCity}
            >
              {cities.map(city => (
                <Option value={city.name} key={city._id}>
                  {city.name}
                </Option>
              ))}
            </Select>
          </div>

          <Select
            allowClear
            showSearch
            placeholder="Asset Type"
            style={{ width: 200 }}
            value={assetType}
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
            onChange={value => this.handleChange("assetType", value)}
          >
            {assetTypeValues.map(asset => (
              <Option value={asset.name} key={asset.id}>
                {asset.name}
              </Option>
            ))}
          </Select>

          <div className={less.operatorSearch}>
            <Select
              allowClear
              showSearch
              style={{ width: 200 }}
              onChange={(value, data) =>
                this.handleChange("cabOperatorId", value, data)
              }
              placeholder="Select Operator"
              value={cabOperator}
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              {cabOperators.map(operator => (
                <Option value={operator.value} key={operator._id}>
                  {operator.value}
                </Option>
              ))}
            </Select>
            <div className={less.dateSelect}>
              <RangePicker
                allowClear
                value={[moment(fromDate), moment(toDate)]}
                format="YYYY-MM-DD"
                onChange={this.onTimeRangeSelect}
              />
            </div>

            <button className={less.Btn} onClick={this.search}>
              <span>Search / Refresh</span>
            </button>
          </div>
        </div>
        <div className={less.expiredToggle}>
          <span> Show expired items:</span>
          <Switch
            defaultChecked={isExpired}
            style={{
              backgroundColor: isExpired ? "#7b2bff" : "#d9d9d9",
              borderColor: "#7b2bff"
            }}
            onChange={this.showExpired}
          />
        </div>
      </div>
    );
  }
}

export default ContractSearch;
