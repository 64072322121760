import React, { useState } from "react";
import moment from "moment";
import cx from "classnames";

import { getWayPoints } from "../../../../utils/common";
import RouteMap from "../../../routeMap";
import DirectionIcon from "../../../../images/GetDirection1.gif";
import viewIcon from "../../../../images/viwImg.gif";
import mixpanel from "mixpanel-browser";
import { useHistory } from "react-router-dom";
import { Modal, Button } from "antd";
import styles from "./tripDetails.module.scss";
import { mixPanelToken, isStage } from "../../../../config";

import { analytics } from "../../../../utils/analytics";
import { googleLayerAnalytics } from "../../../../utils/googleDataLayer";
const dateFormat = "hh:mm A";
const dayFormat = "MMM DD";
const _gaq = window._gaq || [];

const TripDetails = props => {
  const {
    booking,
    customerLatLng,
    busRoute,
    toggleOverlayScreen,
    changeBooking,
    connectingFlag,
    cities,
    bus,
    isConnectingBooking,
    connectingFirstLegCompleted,
    showStationChangeScreen
  } = props;

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isDropModalVisible, setIsDropModalVisible] = useState(false);
  const isAgentBooking =
    booking.demandSource == "AGENT" || booking.demandSource == "AGENTPORTAL"
      ? true
      : false;
  const isOnboarded = !!booking.isOnboarded;
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const showStationDetails = () => {
    mixpanel.init(mixPanelToken, { debug: isStage });
    mixpanel.track("View Photo Clicked ", {
      mobile_no: booking.mobileNo,
      pnr: booking.bookingCode,
      from_city: booking.boardingPoint.city,
      to_city: booking.dropPoint.city,
      from_station: booking.boardingPoint.address,
      to_station: booking.dropPoint.address,
      trip_date: moment(booking.boardingPoint.time).format("YYYY-MM-DD"),
      booking_source: booking.demandSource
    });
    googleLayerAnalytics("view_boarding_point_photo", {
      mobile_no: booking.mobileNo,
      pnr: booking.bookingCode,
      from_city: booking.boardingPoint.city,
      to_city: booking.dropPoint.city,
      from_station: booking.boardingPoint.address,
      to_station: booking.dropPoint.address,
      trip_date: moment(booking.boardingPoint.time).format("YYYY-MM-DD"),
      booking_source: booking.demandSource
    });
    setIsModalVisible(true);
  };

  const handleDropCancel = () => {
    setIsDropModalVisible(false);
  };

  const showDropStationDetails = () => {
    mixpanel.init(mixPanelToken, { debug: isStage });
    mixpanel.track("View Photo Clicked ", {
      mobile_no: booking.mobileNo,
      pnr: booking.bookingCode,
      from_city: booking.boardingPoint.city,
      to_city: booking.dropPoint.city,
      from_station: booking.boardingPoint.address,
      to_station: booking.dropPoint.address,
      trip_date: moment(booking.boardingPoint.time).format("YYYY-MM-DD"),
      booking_source: booking.demandSource
    });
    googleLayerAnalytics("view_drop_point_photo", {
      mobile_no: booking.mobileNo,
      pnr: booking.bookingCode,
      from_city: booking.boardingPoint.city,
      to_city: booking.dropPoint.city,
      from_station: booking.boardingPoint.address,
      to_station: booking.dropPoint.address,
      trip_date: moment(booking.boardingPoint.time).format("YYYY-MM-DD"),
      booking_source: booking.demandSource
    });
    setIsDropModalVisible(true);
  };
  // let etaTime;
  // eta check
  // etaTime = eta.early
  //   ? Math.abs(eta.earlyTimeSec)
  //   : eta.delay
  //   ? Math.abs(eta.delayTimeSec)
  //   : Math.abs(booking.boardingPoint.time - Date.now());

  const history = useHistory();

  const route = getWayPoints(
    busRoute,
    booking.boardingPoint.stationId,
    booking.dropPoint.stationId
  );

  const showRoute = () => <RouteMap route={route} cities={cities} />;

  const handleOnClick = () => {
    toggleOverlayScreen(true, showRoute, "Bus Route");
    history.push("Itinerary");
    googleLayerAnalytics("view_itinerary", {
      mobile_no: booking.mobileNo,
      pnr: booking.bookingCode,
      from_city: booking.boardingPoint.city,
      to_city: booking.dropPoint.city,
      from_station: booking.boardingPoint.address,
      to_station: booking.dropPoint.address,
      trip_date: moment(booking.boardingPoint.time).format("YYYY-MM-DD"),
      booking_source: booking.demandSource
    });
    mixpanel.init(mixPanelToken, { debug: isStage });
    mixpanel.track("View Itinerary Clicked", {
      mobile_no: booking.mobileNo,
      pnr: booking.bookingCode,
      trip_date: moment(booking.boardingPoint.time).format("YYYY-MM-DD"),
      from_city: booking.boardingPoint.city,
      to_city: booking.dropPoint.city,
      from_station: booking.boardingPoint.address,
      to_station: booking.dropPoint.address,
      booking_source: booking.demandSource
    });
  };

  const handleBoardingChangeClick = () => {
    showStationChangeScreen(true, "Boarding Point");
    history.push("boardingChange");
    googleLayerAnalytics("boardingChange", {
      mobile_no: booking.mobileNo,
      pnr: booking.bookingCode,
      from_city: booking.boardingPoint.city,
      to_city: booking.dropPoint.city,
      from_station: booking.boardingPoint.address,
      to_station: booking.dropPoint.address,
      trip_date: moment(booking.boardingPoint.time).format("YYYY-MM-DD"),
      booking_source: booking.demandSource
    });
    mixpanel.init(mixPanelToken, { debug: isStage });
    mixpanel.track("Change Button Clicked", {
      mobile_no: booking.mobileNo,
      pnr: booking.bookingCode,
      trip_date: moment(booking.boardingPoint.time).format("YYYY-MM-DD"),
      from_city: booking.boardingPoint.city,
      to_city: booking.dropPoint.city,
      from_station: booking.boardingPoint.address,
      to_station: booking.dropPoint.address,
      booking_source: booking.demandSource
    });
  };

  const handleDropChangeClick = () => {
    showStationChangeScreen(true, "Drop Point");
    history.push("DropPointChange");
    googleLayerAnalytics("DropPointChange", {
      mobile_no: booking.mobileNo,
      pnr: booking.bookingCode,
      from_city: booking.boardingPoint.city,
      to_city: booking.dropPoint.city,
      from_station: booking.boardingPoint.address,
      to_station: booking.dropPoint.address,
      trip_date: moment(booking.boardingPoint.time).format("YYYY-MM-DD"),
      booking_source: booking.demandSource
    });
    mixpanel.init(mixPanelToken, { debug: isStage });
    mixpanel.track("Change Button Clicked", {
      mobile_no: booking.mobileNo,
      pnr: booking.bookingCode,
      trip_date: moment(booking.boardingPoint.time).format("YYYY-MM-DD"),
      from_city: booking.boardingPoint.city,
      to_city: booking.dropPoint.city,
      from_station: booking.boardingPoint.address,
      to_station: booking.dropPoint.address,
      booking_source: booking.demandSource
    });
  };

  function mapsSelector() {
    const { lat, lng } = booking.boardingPoint;
    _gaq.push([
      "_trackEvent",
      "Get Directions",
      "Button Clicked",
      "click to see",
      1,
      true
    ]);
    googleLayerAnalytics("navigate_pickup", {
      mobile_no: booking.mobileNo,
      pnr: booking.bookingCode,
      from_city: booking.boardingPoint.city,
      to_city: booking.dropPoint.city,
      from_station: booking.boardingPoint.address,
      trip_date: moment(booking.boardingPoint.time).format("YYYY-MM-DD"),
      to_station: booking.dropPoint.address,
      booking_source: booking.demandSource
    });
    if (Array.isArray(customerLatLng) && customerLatLng.length > 0) {
      analytics("Navigate", {
        mobile: booking.mobileNo,
        latitude: customerLatLng[0],
        longitude: customerLatLng[1]
      });
    }
    mixpanel.init(mixPanelToken, { debug: isStage });
    mixpanel.track("Navigate Clicked", {
      mobile_no: booking.mobileNo,
      pnr: booking.bookingCode,
      trip_date: moment(booking.boardingPoint.time).format("YYYY-MM-DD"),
      from_city: booking.boardingPoint.city,
      to_city: booking.dropPoint.city,
      from_station: booking.boardingPoint.address,
      to_station: booking.dropPoint.address,
      booking_source: booking.demandSource
    });
    window.open(
      `https://www.google.com/maps/search/?api=1&query=${lat},${lng}`
    );
    // const ua = navigator.userAgent.toLowerCase();
    // if (ua.indexOf("android") > -1) {
    //   window.open(
    //     `https://www.google.com/maps/search/?api=1&query=${lat},${lng}`
    //   );
    // } else if (ua.match(/iPhone|iPad|iPod/i)) {
    //   window.location = `http://maps.apple.com/?q=sll=${lat},${lng}&z=10&t=s`;
    // } else {
    //   window.open(
    //     `https://www.google.com/maps/search/?api=1&query=${lat},${lng}`
    //   );
    // }
  }

  let enablePnrView = {};

  if (props.tourValue === 1) {
    enablePnrView = { zIndex: 101, background: "white", paddingBottom: "10px" };
  }
  return (
    <div className={cx(styles.tripDetails)}>
      <ul>
        {Object.keys(bus).length ||
        (isConnectingBooking && !connectingFirstLegCompleted) ? (
          <div className="row pt-3" style={enablePnrView}>
            {Object.keys(bus).length &&
            isConnectingBooking &&
            !connectingFirstLegCompleted ? (
              <>
                <div className="col-6 text-left">
                  <font className={styles.textBusNumber}>{bus.rc}</font>
                  <font
                    className="light-black"
                    style={{
                      fontSize: "12px",
                      fontWeight: "lighter",
                      fontFamily: "SFPROTEXT"
                    }}
                  >
                    {" "}
                    {bus.busType == "SUV" || bus.busType == "TEMPOTRAVELLER"
                      ? "(Vehicle Number)"
                      : "(Bus Number)"}{" "}
                  </font>
                </div>
                <div className="col-6 text-right">
                  <Button
                    type="primary"
                    onClick={changeBooking}
                    style={{
                      paddingBottom: "15px",
                      background: "#7b2bff",
                      fontSize: "15px",
                      borderRadius: "8px",
                      border: "2px solid #7b2bff"
                    }}
                  >
                    {connectingFlag ? "Track previous Trip" : "Track next Trip"}
                  </Button>
                </div>
              </>
            ) : isConnectingBooking && !connectingFirstLegCompleted ? (
              <div className="col-12 text-right">
                <Button
                  type="primary"
                  onClick={changeBooking}
                  style={{
                    paddingBottom: "15px",
                    background: "#7b2bff",
                    fontSize: "15px",
                    borderRadius: "8px",
                    border: "2px solid #7b2bff"
                  }}
                >
                  {connectingFlag ? "Track previous Trip" : "Track next Trip"}
                </Button>
              </div>
            ) : Object.keys(bus).length ? (
              <div className="col-12 text-left">
                <font className={styles.textBusNumber}>{bus.rc}</font>
                <font
                  className="light-black"
                  style={{
                    fontSize: "12px",
                    fontWeight: "lighter",
                    fontFamily: "SFPROTEXT"
                  }}
                >
                  {" "}
                  {bus.busType == "SUV" || bus.busType == "TEMPOTRAVELLER"
                    ? "(Vehicle Number)"
                    : "(Bus Number)"}{" "}
                </font>
              </div>
            ) : null}

            <div className="col-12" style={{ paddingTop: "8px" }}>
              <hr />
            </div>
          </div>
        ) : null}

        <li className={styles.noImagePickup}>
          <div className="row">
            <div className="col-12">
              <div className="row">
                <div className="col-12">
                  <div className={styles.modalTextFormat}>
                    <p
                      className="d-inline"
                      style={{
                        fontFamily: "SFPROTEXT",
                        color: "#000000",
                        fontSize: "16px",
                        fontWeight: "500"
                      }}
                    >
                      {" "}
                      Boarding Point
                    </p>
                    {!booking.fromStationUpdatedOnce &&
                    isConnectingBooking &&
                    !connectingFlag &&
                    booking.boardingPoint.time > Date.now() &&
                    !isAgentBooking &&
                    !isOnboarded ? (
                      <p
                        onClick={handleBoardingChangeClick}
                        className="d-inline"
                        style={{
                          cursor: "pointer",
                          padding: "3px 8px",
                          border: "1px solid #D4D4D4",
                          borderRadius: "20px",
                          fontFamily: "SFPROTEXT",
                          color: "#7b2bff",
                          fontSize: "12px",
                          marginLeft: "2%"
                        }}
                      >
                        Change
                      </p>
                    ) : !booking.fromStationUpdatedOnce &&
                      !isConnectingBooking &&
                      booking.boardingPoint.time > Date.now() &&
                      !isAgentBooking &&
                      !isOnboarded ? (
                      <p
                        onClick={handleBoardingChangeClick}
                        className="d-inline"
                        style={{
                          cursor: "pointer",
                          padding: "3px 8px",
                          border: "1px solid #D4D4D4",
                          borderRadius: "20px",
                          fontFamily: "SFPROTEXT",
                          color: "#7b2bff",
                          fontSize: "12px",
                          marginLeft: "2%"
                        }}
                      >
                        Change
                      </p>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-7" style={{ marginTop: "5px" }}>
              <div className={styles.textFormat}>
                {booking.boardingPoint.name}
              </div>
            </div>
            <div
              className="col-5 text-right"
              style={{ paddingRight: "0px", paddingLeft: "5px" }}
            >
              <div
                onClick={mapsSelector}
                style={{
                  width: "88px",
                  marginLeft: "auto",
                  marginRight: "10px",
                  border: "1px solid #7B2AFF",
                  borderRadius: "5px"
                }}
              >
                <p
                  className="d-inline"
                  style={{
                    fontFamily: "SFPROTEXT",
                    color: "#7b2bff",
                    fontSize: "12px",
                    marginLeft: "5%"
                  }}
                >
                  Navigate
                </p>
                <img
                  style={{ margin: "2%", width: "20%" }}
                  width="27px"
                  src={DirectionIcon}
                />
              </div>
            </div>
          </div>
          {booking.boardingPoint.stationImagemain ? (
            <div className="row" style={{ justifyContent: "flex-start" }}>
              <div
                style={{
                  width: "fit-content",
                  marginRight: "17px",
                  marginLeft: "14px"
                }}
              >
                <p className={styles.underlyingtextFormat}>
                  {moment(booking.boardingPoint.time).format(dateFormat)} |{" "}
                  {moment(booking.boardingPoint.time).format(dayFormat)}
                </p>
              </div>
              <div onClick={showStationDetails}>
                <div
                  className="row"
                  style={{
                    margin: "0px"
                  }}
                >
                  <div className="col-3.5">
                    <p className={styles.underlyingtextBlue}>View Photo</p>
                  </div>
                  <div className="col-8.5 text-left">
                    <img
                      style={{
                        margin: "-10% 0 0 24%",
                        transform: "rotate(270deg)"
                      }}
                      width="20px"
                      src={viewIcon}
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="row">
              <div className="col-10">
                <p className={styles.underlyingtextFormat}>
                  {moment(booking.boardingPoint.time).format(dateFormat)} |{" "}
                  {moment(booking.boardingPoint.time).format(dayFormat)}
                </p>
              </div>
            </div>
          )}
        </li>

        <li className={styles.noImageDrop}>
          <div className="row">
            <div className="col-12">
              <div className="row">
                <div className="col-7">
                  <div className={styles.modalTextFormat}>
                    <p
                      className="d-inline"
                      style={{
                        fontFamily: "SFPROTEXT",
                        color: "#000000",
                        fontSize: "16px",
                        fontWeight: "500"
                      }}
                    >
                      {" "}
                      Drop Point
                    </p>
                    {!booking.toStationUpdatedOnce &&
                    isConnectingBooking &&
                    connectingFlag &&
                    booking.dropPoint.time > Date.now() &&
                    !isAgentBooking ? (
                      <p
                        onClick={handleDropChangeClick}
                        className="d-inline"
                        style={{
                          padding: "3px 8px",
                          border: "1px solid #D4D4D4",
                          cursor: "pointer",
                          borderRadius: "20px",
                          fontFamily: "SFPROTEXT",
                          color: "#7b2bff",
                          fontSize: "12px",
                          marginLeft: "5%"
                        }}
                      >
                        Change
                      </p>
                    ) : !booking.toStationUpdatedOnce &&
                      !isConnectingBooking &&
                      booking.dropPoint.time > Date.now() &&
                      !isAgentBooking ? (
                      <p
                        onClick={handleDropChangeClick}
                        className="d-inline"
                        style={{
                          padding: "3px 8px",
                          border: "1px solid #D4D4D4",
                          cursor: "pointer",
                          borderRadius: "20px",
                          fontFamily: "SFPROTEXT",
                          color: "#7b2bff",
                          fontSize: "12px",
                          marginLeft: "5%"
                        }}
                      >
                        Change
                      </p>
                    ) : null}
                  </div>
                </div>
                <div className="col-5 text-right">
                  <div onClick={handleOnClick}>
                    <p
                      className="d-inline"
                      style={{
                        fontFamily: "SFPROTEXT",
                        color: "#7b2bff",
                        fontSize: "12px",
                        marginLeft: "5%",
                        textDecoration: "underline"
                      }}
                    >
                      View Itinerary
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-7">
              <div className={styles.textFormat}>{booking.dropPoint.name}</div>
            </div>
          </div>
          {booking.dropPoint.stationImagemain ? (
            <div className="row" style={{ justifyContent: "flex-start" }}>
              <div
                style={{
                  width: "fit-content",
                  marginRight: "17px",
                  marginLeft: "14px"
                }}
              >
                {/* <p className="dark-gray mb-1">Landmark</p> */}
                <div className={styles.underlyingtextFormat}>
                  {moment(booking.dropPoint.time).format(dateFormat)} |{" "}
                  {moment(booking.dropPoint.time).format(dayFormat)}
                </div>
              </div>
              <div onClick={showDropStationDetails}>
                <div
                  className="row"
                  style={{
                    margin: "0px"
                  }}
                >
                  <div className="col-3.5">
                    <p className={styles.underlyingtextBlue}>View Photo</p>
                  </div>
                  <div className="col-8.5 text-left">
                    <img
                      style={{
                        margin: "-10% 0 0 24%",
                        transform: "rotate(270deg)"
                      }}
                      width="20px"
                      src={viewIcon}
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="row">
              <div className="col-10">
                {/* <p className="dark-gray mb-1">Landmark</p> */}
                <div className={styles.underlyingtextFormat}>
                  {moment(booking.dropPoint.time).format(dateFormat)} |{" "}
                  {moment(booking.dropPoint.time).format(dayFormat)}
                </div>
              </div>{" "}
            </div>
          )}
        </li>
      </ul>
      <hr />
      <Modal
        centered
        visible={isModalVisible}
        footer={null}
        onCancel={handleCancel}
      >
        <div className="row">
          <div className="col-6">
            <p className={styles.modal}>Boarding Point:</p>
          </div>
          <div className="col-6" onClick={mapsSelector}>
            <button
              style={{
                color: "#7B2AFF",
                display: "inline-block",
                background: "white",
                border: "1px solid #7B2AFF",
                borderRadius: "5px"
              }}
            >
              <img
                src={DirectionIcon}
                style={{
                  float: "right",
                  maxWidth: "20px",
                  marginLeft: "0.5em"
                }}
              />
              Navigate
            </button>
          </div>
        </div>
        <p className={styles.modalSecond}>{booking.boardingPoint.address}</p>
        <img
          style={{ width: "-webkit-fill-available" }}
          src={`https://d3bjx0rj820nz0.cloudfront.net/${booking.boardingPoint.stationId}/${booking.boardingPoint.stationImagemain}`}
        />
      </Modal>

      <Modal
        centered
        visible={isDropModalVisible}
        footer={null}
        onCancel={handleDropCancel}
      >
        <div className="row">
          <div className="col-8">
            <p className={styles.modal}>Drop Point:</p>
          </div>
        </div>
        <p className={styles.modalSecond}>{booking.dropPoint.address}</p>
        <img
          style={{ width: "-webkit-fill-available" }}
          src={`https://d3bjx0rj820nz0.cloudfront.net/${booking.dropPoint.stationId}/${booking.dropPoint.stationImagemain}`}
        />
      </Modal>
    </div>
  );
};

export default TripDetails;
