import React, { Component } from "react";
import { Modal, Collapse } from "antd";
import "antd/dist/antd.css";
import Fetch from "../../utils/fetch";
import moment from "moment";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

const { Panel } = Collapse;
const textColor = { color: "#7b2bff" };
class ViewTimelines extends Component {
  state = {
    loading: true,
    selectedRowKeys: [],
    activeKey: "1",
    viewTimelines: false
  };

  inputRef = React.createRef();

  async componentDidMount() {
    try {
      const { task, typeFilter } = this.props;
      let type, timelineDataResponse;
      if (task) {
        if (typeFilter === "Audit") {
          if (task.busId) {
            type = "busId";
            timelineDataResponse = await this.getTimeline(
              task.busId,
              type,
              task.generationDate
            );
          }
          if (task.serviceId) {
            type = "serviceId";
            timelineDataResponse = await this.getTimeline(
              task.serviceId,
              type,
              task.generationDate
            );
          }

          this.setState({
            loading: false,
            timelineData: timelineDataResponse,
            // timelineName: task.service ? task.service.name : null,

            resultdisplayed: [],
            testHashmap: {},
            logHashMap: {}
          });
        } else if (typeFilter === "Task") {
          const taskAuditTrail = await this.getAuditTrail(task._id);
          this.setState({
            loading: false,
            taskAuditTrail: taskAuditTrail
          });
        }
        const jobTrackerLogData = await this.jobTrackerLog(task._id);
        this.getHashMap(jobTrackerLogData, task._id);

        this.setState({ jobTrackerData: jobTrackerLogData });
      } else {
        this.setState({ loading: false });
      }
    } catch (err) {
      this.setState({ loading: false });
      console.log(err);
    }
  }

  getHashMap = async (jobTrackerLogData, _id) => {
    let { testHashmap, logHashMap } = this.state;

    if (testHashmap[_id] === 1) {
      // console.log("hatt Sucess");

      this.setState({
        jobTrackerData: jobTrackerLogData
      });
    } else {
      // console.log("great Sucess");
      if (!jobTrackerLogData) {
        jobTrackerLogData = await this.jobTrackerLog(_id);
      }
      for (let i = 0; i < jobTrackerLogData.length; ++i) {
        let job = jobTrackerLogData[i];
        if (logHashMap[job.jobTrackerId]) {
          logHashMap[job.jobTrackerId].push(job);
        } else {
          logHashMap[job.jobTrackerId] = [job];
        }
      }

      this.setState({
        jobTrackerData: jobTrackerLogData,
        hashmap: logHashMap
      });
      testHashmap[_id] = 1;
    }
  };
  getAuditTrail = async id => {
    try {
      const url = `/bus-tracker/getAuditTrail/${id}`;
      const options = {
        method: "get"
      };

      const response = await Fetch(url, options);
      if (response) {
        return response;
      } else {
        return [];
      }
    } catch (err) {
      console.log(err);

      return [];
    }
  };

  getTimeline = async (id, type, date) => {
    try {
      const url = `/bus-tracker/auditTimeline/${id}/${type}/${date}`;
      const options = {
        method: "get"
      };

      const response = await Fetch(url, options);
      if (response) {
        return response;
      } else {
        return [];
      }
    } catch (err) {
      console.log(err);

      return [];
    }
  };

  jobTrackerLog = async jobTrackerIds => {
    try {
      const url = `/bus-tracker/jobLog`;

      const options = {
        method: "post",
        data: {
          jobTrackerId: [jobTrackerIds]
        }
      };

      const response = await Fetch(url, options);

      if (response) {
        return Promise.resolve(response);
      } else {
        // return [];
        throw new Error(response);
      }
    } catch (err) {
      console.log(err);
      Promise.reject(err);
    }
  };

  handleChange = async (key, value, test) => {
    this.setState({ [key]: value });

    let jobTrackerLogData;

    if (test) {
      this.getHashMap(jobTrackerLogData, test);
    }
  };

  renderMenu = () => {
    let { task } = this.props;

    let { timelineData, taskAuditTrail } = this.state;

    if (timelineData) {
      return (
        <Collapse
          onChange={e => this.handleChange("first", e)}
          defaultActiveKey={
            task.auditId +
            task.generationDate +
            (task.serviceId ? task.serviceId : task.busId)
          }
          ghost
        >
          {this.renderMenuPannel(timelineData)}
        </Collapse>
      );
    }
    if (taskAuditTrail) {
      return <>{this.renderMenuPannel(taskAuditTrail)}</>;
    }
  };

  renderMenuPannel = menuPannel => {
    const { typeFilter } = this.props;
    if (menuPannel) {
      return menuPannel.map(obj => {
        return typeFilter === "Task" ? (
          this.renderTaskTimeline(obj)
        ) : (
          <Panel
            header={
              obj.audit.name +
              " was " +
              (obj.audit.status === "ACTIVE" ? "initiated" : obj.audit.status) +
              " - " +
              moment(obj.generationDate).format("LLL")
            }
            key={
              obj.auditId +
              obj.generationDate +
              (obj.serviceId ? obj.serviceId : obj.busId)
            }
          >
            <div>{this.renderSubMenu(obj.jobTrackers)}</div>
          </Panel>
        );
      });
    }
  };
  renderTaskTimeline = subData => {
    return subData.operationType === "CREATE" ? (
      <div>
        &emsp;
        {"\u25CF"}
        {"  "}
        task :<span style={textColor}> CREATED </span>-{" "}
        {moment(subData.createdOn).format("LLL")} by {subData.user.name}
      </div>
    ) : subData.propertyName === "status" ? (
      <div>
        &emsp;
        {"\u25CF"}
        {"  "}
        Status marked to:
        <span style={textColor}> {subData.propertyNewValue}</span>-{" "}
        {moment(subData.createdOn).format("LLL")} by {subData.user.name}
      </div>
    ) : subData.propertyName === "closeByUserId" ? (
      <div>
        &emsp;
        {"\u25CF"}
        {"  "}
        Status marked to:
        <span style={textColor}> CLOSED</span>-{" "}
        {moment(subData.createdOn).format("LLL")} by {subData.user.name}
      </div>
    ) : subData.propertyName === "assignedUserId" ? (
      <div>
        &emsp;
        {"\u25CF"}
        {"  "}
        assigned to:
        <span style={textColor}> {subData.user.name}</span>- on -
        {moment(subData.createdOn).format("LLL")}
      </div>
    ) : subData.propertyName === "attachment" ? (
      <div>
        &emsp;
        {"\u25CF"}
        {"  "}
        attachments added: - {moment(subData.createdOn).format("LLL")} by{" "}
        {subData.user.name}
      </div>
    ) : subData.propertyName === "comment" ? (
      <div>
        &emsp;
        {"\u25CF"}
        {"  "}
        Comment added: <span style={textColor}>{subData.propertyNewValue}</span>
        - {moment(subData.lastModifiedOn).format("LLL")} by {subData.user.name}
      </div>
    ) : subData.propertyName === "closeComment" ? (
      <div>
        &emsp;
        {"\u25CF"}
        {"  "}
        Closing comment added:{" "}
        <span style={textColor}>{subData.propertyNewValue}</span>-{" "}
        {moment(subData.lastModifiedOn).format("LLL")} by {subData.user.name}
      </div>
    ) : (
      ""
    );
  };

  renderSubMenu = subData => {
    let { task } = this.props;

    if (subData) {
      let { hashmap } = this.state;
      return subData.map((obj, index) => {
        return (
          <Collapse
            onChange={e => this.handleChange("second", e, obj._id)}
            ghost
            key={index}
            defaultActiveKey={task._id}
          >
            <Panel
              header={
                obj.job.name +
                " marked to " +
                (obj.status === "ACTIVE" ? "initiated" : obj.status) +
                " - " +
                moment(obj.generationDate).format("LLL")
              }
              key={obj._id}
            >
              {hashmap && hashmap[obj._id] ? (
                <div>{this.rendersubSubMenu(hashmap[obj._id])}</div>
              ) : (
                <div>&emsp; No update Found</div>
              )}
            </Panel>
          </Collapse>
        );
      });
    }
  };

  rendersubSubMenu = subSubData => {
    if (subSubData) {
      return subSubData.forEach(obj => {
        if (
          obj.update.comments &&
          obj.update.attachments &&
          obj.update.status &&
          obj.update.etaDate
        ) {
          return (
            <>
              <div>
                &emsp;
                {"\u25CF"}
                {"  "}
                Comment added:{" "}
                <span style={textColor}>{obj.update.comments}</span>-{" "}
                {moment(obj.createdOn).format("LLL")} by{" "}
                {obj.createdByUser.name}
              </div>

              <div>
                &emsp;
                {"\u25CF"}
                {"  "}
                {obj.update.attachments.length} attachments added: -{" "}
                {moment(obj.createdOn).format("LLL")} by{" "}
                {obj.createdByUser.name}
              </div>
              <div>
                &emsp;
                {"\u25CF"}
                {"  "}
                Status marked to:
                <span style={textColor}> {obj.update.status}</span>-{" "}
                {moment(obj.createdOn).format("LLL")} by{" "}
                {obj.createdByUser.name}
              </div>
              <div>
                &emsp;
                {"\u25CF"}
                {"  "}
                ETA Submission Date added:
                <span style={textColor}>
                  {moment(obj.update.etaDate).format("LLL")}
                </span>
                - {moment(obj.createdOn).format("LLL")} by{" "}
                {obj.createdByUser.name}
              </div>
            </>
          );
        } else if (
          obj.update.attachments &&
          obj.update.status &&
          obj.update.etaDate
        ) {
          return (
            <>
              <div>
                &emsp;
                {"\u25CF"}
                {"  "}
                {obj.update.attachments.length} attachments added: -{" "}
                {moment(obj.createdOn).format("LLL")} by{" "}
                {obj.createdByUser.name}
              </div>
              <div>
                &emsp;
                {"\u25CF"}
                {"  "}
                Status marked to:
                <span style={textColor}> {obj.update.status}</span>-{" "}
                {moment(obj.createdOn).format("LLL")} by{" "}
                {obj.createdByUser.name}
              </div>
              <div>
                &emsp;
                {"\u25CF"}
                {"  "}
                ETA Submission Date added:
                <span style={textColor}>
                  {moment(obj.update.etaDate).format("LLL")}
                </span>
                - {moment(obj.createdOn).format("LLL")} by{" "}
                {obj.createdByUser.name}
              </div>
            </>
          );
        } else if (
          obj.update.comments &&
          obj.update.status &&
          obj.update.etaDate
        ) {
          return (
            <>
              <div>
                &emsp;
                {"\u25CF"}
                {"  "}
                Comment added:{" "}
                <span style={textColor}>{obj.update.comments}</span>-{" "}
                {moment(obj.createdOn).format("LLL")} by{" "}
                {obj.createdByUser.name}
              </div>

              <div>
                &emsp;
                {"\u25CF"}
                {"  "}
                Status marked to:
                <span style={textColor}> {obj.update.status}</span>-{" "}
                {moment(obj.createdOn).format("LLL")} by{" "}
                {obj.createdByUser.name}
              </div>
              <div>
                &emsp;
                {"\u25CF"}
                {"  "}
                ETA Submission Date added:
                <span style={textColor}>
                  {moment(obj.update.etaDate).format("LLL")}
                </span>
                - {moment(obj.createdOn).format("LLL")} by{" "}
                {obj.createdByUser.name}
              </div>
            </>
          );
        } else if (
          obj.update.comments &&
          obj.update.attachments &&
          obj.update.etaDate
        ) {
          return (
            <>
              <div>
                &emsp;
                {"\u25CF"}
                {"  "}
                Comment added:{" "}
                <span style={textColor}>{obj.update.comments}</span>-{" "}
                {moment(obj.createdOn).format("LLL")} by{" "}
                {obj.createdByUser.name}
              </div>

              <div>
                &emsp;
                {"\u25CF"}
                {"  "}
                {obj.update.attachments.length} attachments added: -{" "}
                {moment(obj.createdOn).format("LLL")} by{" "}
                {obj.createdByUser.name}
              </div>

              <div>
                &emsp;
                {"\u25CF"}
                {"  "}
                ETA Submission Date added:
                <span style={textColor}>
                  {moment(obj.update.etaDate).format("LLL")}
                </span>
                - {moment(obj.createdOn).format("LLL")} by{" "}
                {obj.createdByUser.name}
              </div>
            </>
          );
        } else if (
          obj.update.comments &&
          obj.update.attachments &&
          obj.update.status
        ) {
          return (
            <>
              <div>
                &emsp;
                {"\u25CF"}
                {"  "}
                Comment added:{" "}
                <span style={textColor}>{obj.update.comments}</span>-{" "}
                {moment(obj.createdOn).format("LLL")} by{" "}
                {obj.createdByUser.name}
              </div>

              <div>
                &emsp;
                {"\u25CF"}
                {"  "}
                {obj.update.attachments.length} attachments added: -{" "}
                {moment(obj.createdOn).format("LLL")} by{" "}
                {obj.createdByUser.name}
              </div>
              <div>
                &emsp;
                {"\u25CF"}
                {"  "}
                Status marked to:
                <span style={textColor}> {obj.update.status}</span>-{" "}
                {moment(obj.createdOn).format("LLL")} by{" "}
                {obj.createdByUser.name}
              </div>
            </>
          );
        } else if (obj.update.comments && obj.update.etaDate) {
          return (
            <>
              <div>
                &emsp;
                {"\u25CF"}
                {"  "}
                Comment added:{" "}
                <span style={textColor}>{obj.update.comments}</span>-{" "}
                {moment(obj.createdOn).format("LLL")} by{" "}
                {obj.createdByUser.name}
              </div>
              <div>
                &emsp;
                {"\u25CF"}
                {"  "}
                ETA Submission Date added:
                <span style={textColor}>
                  {moment(obj.update.etaDate).format("LLL")}
                </span>
                - {moment(obj.createdOn).format("LLL")} by{" "}
                {obj.createdByUser.name}
              </div>
            </>
          );
        } else if (obj.update.comments && obj.update.attachments) {
          return (
            <>
              <div>
                &emsp;
                {"\u25CF"}
                {"  "}
                Comment added:{" "}
                <span style={textColor}>{obj.update.comments}</span>-{" "}
                {moment(obj.createdOn).format("LLL")} by{" "}
                {obj.createdByUser.name}
              </div>

              <div>
                &emsp;
                {"\u25CF"}
                {"  "}
                {obj.update.attachments.length} attachments added: -{" "}
                {moment(obj.createdOn).format("LLL")} by{" "}
                {obj.createdByUser.name}
              </div>
            </>
          );
        } else if (obj.update.comments && obj.update.status) {
          return (
            <>
              <div>
                &emsp;
                {"\u25CF"}
                {"  "}
                Comment added:{" "}
                <span style={textColor}>{obj.update.comments}</span>-{" "}
                {moment(obj.createdOn).format("LLL")} by{" "}
                {obj.createdByUser.name}
              </div>
              <div>
                &emsp;
                {"\u25CF"}
                {"  "}
                Status marked to:
                <span style={textColor}> {obj.update.status}</span>-{" "}
                {moment(obj.createdOn).format("LLL")} by{" "}
                {obj.createdByUser.name}
              </div>
            </>
          );
        } else if (obj.update.attachments && obj.update.status) {
          return (
            <>
              <div>
                &emsp;
                {"\u25CF"}
                {"  "}
                {obj.update.attachments.length} attachments added: -{" "}
                {moment(obj.createdOn).format("LLL")} by{" "}
                {obj.createdByUser.name}
              </div>
              <div>
                &emsp;
                {"\u25CF"}
                {"  "}
                Status marked to:
                <span style={textColor}> {obj.update.status}</span>-{" "}
                {moment(obj.createdOn).format("LLL")} by{" "}
                {obj.createdByUser.name}
              </div>
            </>
          );
        } else if (obj.update.attachments && obj.update.etaDate) {
          return (
            <>
              <div>
                &emsp;
                {"\u25CF"}
                {"  "}
                {obj.update.attachments.length} attachments added: -{" "}
                {moment(obj.createdOn).format("LLL")} by{" "}
                {obj.createdByUser.name}
              </div>

              <div>
                &emsp;
                {"\u25CF"}
                {"  "}
                ETA Submission Date added:
                <span style={textColor}>
                  {moment(obj.update.etaDate).format("LLL")}
                </span>
                - {moment(obj.createdOn).format("LLL")} by{" "}
                {obj.createdByUser.name}
              </div>
            </>
          );
        } else if (obj.update.status && obj.update.etaDate) {
          return (
            <>
              <div>
                &emsp;
                {"\u25CF"}
                {"  "}
                Status marked to:
                <span style={textColor}> {obj.update.status}</span>-{" "}
                {moment(obj.createdOn).format("LLL")} by{" "}
                {obj.createdByUser.name}
              </div>
              <div>
                &emsp;
                {"\u25CF"}
                {"  "}
                ETA Submission Date added:
                <span style={textColor}>
                  {moment(obj.update.etaDate).format("LLL")}
                </span>
                - {moment(obj.createdOn).format("LLL")} by{" "}
                {obj.createdByUser.name}
              </div>
            </>
          );
        } else if (obj.update.status) {
          return (
            <div>
              &emsp;
              {"\u25CF"}
              {"  "}
              Status marked to:
              <span style={textColor}> {obj.update.status}</span>-{" "}
              {moment(obj.createdOn).format("LLL")} by {obj.createdByUser.name}
            </div>
          );
        } else if (obj.update.comments) {
          return (
            <div>
              &emsp;
              {"\u25CF"}
              {"  "}
              Comment added:{" "}
              <span style={textColor}>{obj.update.comments}</span>-{" "}
              {moment(obj.createdOn).format("LLL")} by {obj.createdByUser.name}
            </div>
          );
        } else if (obj.update.etaDate) {
          return (
            <div>
              &emsp;
              {"\u25CF"}
              {"  "}
              ETA Submission Date added:
              <span style={textColor}>
                {moment(obj.update.etaDate).format("LLL")}
              </span>
              - {moment(obj.createdOn).format("LLL")} by{" "}
              {obj.createdByUser.name}
            </div>
          );
        } else if (obj.update.attachments) {
          return (
            <div>
              &emsp;
              {"\u25CF"}
              {"  "}
              {obj.update.attachments.length} attachment files added: -{" "}
              {moment(obj.createdOn).format("LLL")} by {obj.createdByUser.name}
            </div>
          );
        }
      });
    }
  };

  render() {
    const { loading, viewTimelines = false } = this.state;

    let name =
      this.props.typeFilter === "Task" ? `Task Timeline` : `Audit Timeline`;

    // if (timelineName !== "undefined") {
    //   name = `Audit Timeline for ${timelineName}  `;
    // }

    if (viewTimelines) {
      this.setState({ jobTrackerData: null });
      this.props.onCancel();
    }

    return (
      <Modal
        title={name}
        visible={true}
        onCancel={this.props.onCancel}
        footer={null}
        header={null}
        width={"50%"}
      >
        {loading ? <h1>loading ...</h1> : <div>{this.renderMenu()}</div>}
      </Modal>
    );
  }
}

export default ViewTimelines;

/*
Status marked to:<span style={textColor}> {obj.update.status}</span>
ETA Submission Date added:<span style={textColor}>{moment(obj.update.etaDate).format("LLL")}</span>
};
*/
