import React, { Component } from "react";
import less from "./login.module.scss";
import { message } from "antd";
import Fetch from "../../utils/fetch";
import Otpinput from "../../components/OtpInput";
import jwt from "jsonwebtoken";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      phone: "",
      login: false,
      showOTP: false,
      loggedIn: false,
      showOtpError: false,
      confirmation: false
    };
  }

  async componentDidMount() {
    const token = localStorage.getItem("idToken");
    const decodedToken = jwt.decode(token);
    if (decodedToken && decodedToken.mobileNo) {
      this.setState({ loggedIn: true, phone: decodedToken.mobileNo });
    }
  }

  handleChange = (key, value) => {
    this.setState({ [key]: value });
  };

  sendOtp = async () => {
    const path = `/user/loginViaOtp`;
    const { phone } = this.state;
    const options = {
      method: "post",
      data: {
        mobileNo: phone
      }
    };

    if (phone.length < 10) {
      return message.error("Mobile Number is not valid");
    }
    const response = await Fetch(path, options);
    if (response) {
      this.setState({ showOTP: true });
    } else {
      message.error("User Not Registered");
    }
  };

  hideOtp = () => {
    this.setState({ showOTP: false });
  };

  verifyOTP = async data => {
    const url = `/user/verifyOtp`;

    const options = {
      method: "post",
      data
    };

    const response = await Fetch(url, options);
    if (response && response.idToken) {
      localStorage.setItem("idToken", response.idToken);
      this.setState({ loggedIn: true });
    } else if (response == "Sorry! You're not allowed") {
      console.log("Already Deleted user");
    } else {
      this.setState({ showOtpError: true });
    }
  };

  resendOtp = async () => {
    const path = `/user/login`;
    const { phone } = this.state;
    const options = {
      method: "post",
      data: {
        mobileNo: phone
      }
    };

    await Fetch(path, options);
  };

  confirmDeletion = async () => {
    const path = `/user/deleteOperatorUser`;
    const { phone } = this.state;
    const options = {
      method: "post",
      data: {
        mobileNo: phone
      }
    };

    const response = await Fetch(path, options);
    if (response == "User and data removed successfully!") {
      this.setState({ confirmation: true });
    } else {
      message.error("Profile not deleted");
    }
  };

  close = () => {
    localStorage.removeItem("idToken");
    window.location.reload();
  };

  render() {
    const { phone, loggedIn, confirmation, showOTP, showOtpError } = this.state;

    return !loggedIn ? (
      <div className={less.container}>
        <div className={less.logo}>
          <img
            src="https://d1flzashw70bti.cloudfront.net/original/images/accountdel/zingbus.svg"
            alt="logo"
          />
        </div>
        <div className={less.loginSection}>
          <p className={less.heading}>Login to delete your account</p>
          {showOTP ? (
            <Otpinput
              isMobile={true}
              number={phone}
              hideOtp={this.hideOtp}
              showOtpError={showOtpError}
              verifyOTP={this.verifyOTP}
            />
          ) : (
            <>
              {" "}
              <p className={less.subHeading}>
                To continue login with your number
              </p>
              <div className={less.mobileNumberInput}>
                <input
                  type="text"
                  name="phoneNumber"
                  id="phoneNumber"
                  inputMode="numeric"
                  placeholder="Enter Mobile Number"
                  autoComplete="off"
                  pattern="\d*"
                  value={phone}
                  onChange={e => {
                    const re = /^[0-9\b]+$/;
                    if (
                      e.target.value.length < 11 &&
                      (e.target.value === "" || re.test(e.target.value))
                    ) {
                      this.handleChange("phone", e.target.value);
                    }
                  }}
                />
              </div>
              <button onClick={this.sendOtp}>Send OTP</button>
            </>
          )}
        </div>
      </div>
    ) : !confirmation ? (
      <div className={less.parentContainer}>
        <div className={less.confirmation}>
          <img
            src="https://d1flzashw70bti.cloudfront.net/original/images/accountdel/delete.svg"
            alt="confirmation"
          />
          <span>Are you sure you want to delete your account? </span>
          <p>
            This action is irreversible. Once deleted, your account and all
            associated data will be permanently removed. If you&apos;re having
            issues, please contact our support team before deleting your
            account.
          </p>
          <button onClick={this.confirmDeletion}>Delete Account</button>
        </div>
      </div>
    ) : (
      <div className={less.parentContainer}>
        <div className={less.success}>
          <img
            src="https://d1flzashw70bti.cloudfront.net/original/images/accountdel/success.svg"
            alt="success"
          />
          <span>Successful</span>
          <p>You zingbus account has been deleted successfully</p>
          <button onClick={this.close}>Okay</button>
        </div>{" "}
      </div>
    );
  }
}

export default Login;
