import React, { Component } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import { CopyOutlined, PlayCircleOutlined } from "@ant-design/icons";
import ZingPrime from "../../components/zingPrime";
import SendMessage from "../gdsMessages";
import SendRefund from "../gdsRefund";
import SendCoupon from "../../components/sendCoupon";

import {
  Input,
  Button,
  DatePicker,
  Select,
  Modal,
  message,
  Table,
  Tag
} from "antd";
import Fetch from "../../utils/fetch";
import EditBooking from "../editBooking";
import swapIcon from "../../images/swap-icon.svg";
import { getSeatNumberFromTripChart } from "../../utils/common";

const { Option } = Select;
const wordBreakStyle = { wordBreak: "breakAll" };

const columns = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    width: "8%",
    render: ({ value, copyCustomerDetails }) => (
      <>
        <span style={wordBreakStyle}>{value}&ensp;</span>
        <CopyOutlined onClick={copyCustomerDetails} />
      </>
    )
  },
  {
    title: "Mobile Number",
    dataIndex: "mobileNo",
    key: "mobileNo",
    width: "8%",
    render: ({ value }) => <span style={wordBreakStyle}>{value}</span>
  },
  {
    title: "PNR",
    dataIndex: "bookingCode",
    width: "8%",
    key: "bookingCode"
  },
  {
    title: "Seats",
    dataIndex: "seatChart",
    width: "8%",
    key: "seatChart"
  },
  {
    title: "From Station",
    dataIndex: "fromStation",
    width: "8%",
    key: "fromStation",
    render: ({ name, date }) => (
      <>
        <span style={wordBreakStyle}>{name}</span>
        <br></br>
        <span>{date}</span>
      </>
    )
  },
  {
    title: "To Station",
    dataIndex: "toStation",
    width: "8%",
    key: "toStation",
    render: ({ name, date }) => (
      <>
        <span style={wordBreakStyle}>{name}</span>
        <br></br>
        <span>{date}</span>
      </>
    )
  },
  {
    title: "Refunds",
    dataIndex: "refunds",
    width: "8%",
    key: "refunds",
    render: ({ data }) =>
      data && data.assuredTripRefundId ? (
        <div>
          <p>Refund Already done</p>
        </div>
      ) : (
        <Tag color="red">No Records</Tag>
      )
  },
  {
    title: "ZingPrime Details",
    dataIndex: "isZingPrimeUser",
    key: "isZingPrimeUser",
    width: "10%",
    render: ({ isZingPrimeUser, showModal }) => {
      if (isZingPrimeUser) {
        return <Button onClick={showModal}>View</Button>;
      } else {
        return;
      }
    }
  },
  {
    title: "Call attempted",
    dataIndex: "callAttempted",
    key: "callAttempted",
    width: "8%"
  },
  {
    title: "Call records",
    dataIndex: "callRecords",
    key: "callRecords",
    width: "8%",
    render: ({ data = [] }) => {
      if (data.length) {
        return data.map((call, index) => (
          <div key={index} className="mb-1">
            {call.recordFile ? (
              <>
                <a
                  href={call.recordFile}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <PlayCircleOutlined />
                </a>
                <br></br>

                {!call.isOutgoing ? (
                  <div>
                    {"I-"}
                    {moment(call.createdOn).format("lll")}
                    <br />
                    {call.totalDuration}
                    {call.isCalltoAgent ? "(A)" : "(M)"}
                  </div>
                ) : (
                  <div>
                    {"O-"}
                    {moment(call.createdOn).format("lll")}
                    <br />
                    {call.totalDuration}
                  </div>
                )}
              </>
            ) : (
              <div>
                <Tag>{call.status}</Tag>
                <br></br>
                <Tag>{call.date}</Tag>
              </div>
            )}
          </div>
        ));
      } else {
        return <Tag color="red">No Call Records</Tag>;
      }
    }
  },
  {
    title: "Invice Action",
    dataIndex: "invoice",
    key: "invoice",
    width: "15%",
    render: ({ downloadEmail, sendEmail, isCoreBooking }) => (
      <div>
        <Button
          disabled={!isCoreBooking}
          className="ant-btn mb-1"
          onClick={sendEmail}
        >
          Whatspp Invoice
        </Button>
        <Button
          className="ant-btn mb-1"
          disabled={!isCoreBooking}
          onClick={downloadEmail}
        >
          Download Invoice
        </Button>
      </div>
    )
  },
  {
    title: "Action",
    dataIndex: "action",
    key: "action",
    width: "10%",
    render: ({ gdsIdTrip, customerTrackingLink, copyCustomerTrackingLink }) => {
      return (
        <>
          <Link to={`/gdstrack/${gdsIdTrip}`} target="_blank">
            <Button className="mb-1">Track Bus</Button>
          </Link>
          <a href={customerTrackingLink} target="_blank" rel="noreferrer">
            <Button className="mb-1">Customer Link</Button>
          </a>
          <Button onClick={copyCustomerTrackingLink}>
            Share Customer Link
          </Button>
        </>
      );
    }
  }
];

class SearchForm extends Component {
  state = {
    showResult: false,
    result: [],
    fromCity: undefined,
    toCity: undefined,
    date: moment(),
    editBooking: false,
    editField: "",
    bookingToEdit: {},
    bookingSearchValue: "",
    bookingLoader: false,
    viewMoreList: [],
    showMoreDetails: false,
    showCrewDetails: false,
    showZingPrimeDetails: false,
    selectedBookings: [],
    bookings: [],
    messageSentReasons: [],
    showMessageSentDetails: false,
    invoiceLoader: false
  };

  inputRef = React.createRef();

  fetchBooking = async () => {
    try {
      this.setState({ bookingLoader: true });
      let { bookingSearchValue } = this.state;
      if (bookingSearchValue) {
        let url = `/gds/booking/getParticularBooking`;
        if (
          bookingSearchValue.slice(0, 2) == "zg" ||
          bookingSearchValue.slice(0, 2) == "ZG"
        ) {
          url += `?bookingCode=${bookingSearchValue}`;
        } else {
          bookingSearchValue = bookingSearchValue.replace(" ", "");
          if (
            bookingSearchValue.length > 10 &&
            bookingSearchValue.slice(0, 2) == "91"
          ) {
            let mobile = bookingSearchValue.substring(2);
            url += `?mobileNo=${mobile}`;
          } else {
            url += `?mobileNo=${bookingSearchValue}`;
          }
        }

        const options = {
          method: "get"
        };
        let response = await Fetch(url, options);
        if (response) {
          const bookingR = [];

          for (let i = 0; i < response.length; ++i) {
            const booking = response[i];
            const isCoreBooking =
              booking.demandSource == "B2CIOS" ||
              booking.demandSource == "B2CWEB" ||
              booking.demandSource == "B2CANDROID";

            const isToStationTimePassed = booking.toStationDate < Date.now();

            let seatsLabel = "";
            if (booking.tripChart && booking.tripChart.length > 0) {
              for (let i = 0; i < booking.tripChart.length; ++i) {
                for (let i = 0; i < booking.tripChart.length; ++i) {
                  const seat = booking.tripChart[i];

                  if (seat.status === "CONFIRMED") {
                    const seatNumber = seat.seatLabel;
                    const seatGender = seat.gender ? seat.gender : "";

                    if (seatsLabel.length === 0) {
                      seatsLabel += `${seatNumber}(${seatGender})`;
                    } else {
                      seatsLabel += `,${seatNumber}(${seatGender})`;
                    }
                  }
                }
                break;
              }
            }

            const bookingObj = {
              key: booking._id,
              name: {
                value: booking.name,
                copyCustomerDetails: () =>
                  this.copyCustomerDetails(
                    booking.bookingCode,
                    booking.mobileNo,
                    seatsLabel,
                    booking.fromCity.name,
                    booking.fromStationName,
                    booking.fromStationDate,
                    booking.toCity.name,
                    booking.toStationAddress,
                    booking.trackingUrl,
                    booking.trip.bus
                  )
              },
              mobileNo: {
                value: booking.mobileNo,
                onChange: () =>
                  this.setState({
                    editField: "mobileNo",
                    editBooking: true,
                    bookingToEdit: booking
                  })
              },
              amountToBeCollected: booking.amountToBeCollected,
              bookingCode: booking.bookingCode,
              seatChart: seatsLabel,
              fromStation: {
                name: `${booking.fromCity.name}, ${booking.fromStationName}`,
                date: moment(booking.fromStationDate).format("lll")
              },
              seats: getSeatNumberFromTripChart(booking.tripChart),
              toStation: {
                name: `${booking.toCity.name}, ${booking.toStationName}`,
                date: moment(booking.toStationDate).format("lll")
              },
              // remark: booking.remark,
              status: booking.status,
              invoice: {
                isCoreBooking: isCoreBooking,
                sendEmail: () =>
                  this.sendEmail(
                    booking.mobileNo,
                    booking._id,
                    booking.fromStationDate
                  ),
                downloadEmail: () =>
                  this.downloadEmail(
                    booking.bookingCode,
                    booking._id,
                    booking.fromStationDate
                  )
              },
              action: {
                gdsIdTrip: booking.gdsIdTrip,
                customerTrackingLink: `http://${booking.trackingUrl}`,
                showMessagesInfo: () => this.showMessageSent(booking),
                copyCustomerTrackingLink: () =>
                  this.copyCustomerTrackingLink(booking.trackingUrl)
              },
              messageSent: booking.messageSent,
              refunds: {
                data: booking.refund ? booking.refund : null
              },
              isCreditCouponSent: booking.isCreditCouponSent,
              creditCouponCode: booking.creditCouponCode,
              isZingPrimeUser: {
                isZingPrimeUser: booking.isZingPrimeUser,
                showModal: () =>
                  this.setState({
                    showZingPrimeDetails: true,
                    selectedPrimeBooking: booking
                  })
              },
              callAttempted: booking.callDetails
                ? booking.callDetails.length
                : 0,

              callRecords: {
                data: booking.callDetails
              },
              trackingUrl: booking.trackingUrl,
              booking,
              actionHistory: booking.actionHistory || "",
              remark: {
                value: booking.remark,
                onChange: () =>
                  this.setState({
                    editField: "remark",
                    editBooking: true,
                    bookingToEdit: booking
                  })
              },
              _id: booking._id
            };

            bookingR.push(bookingObj);
          }
          this.setState({
            showResult: true,
            showBooking: true,
            result: response,
            bookings: bookingR,
            bookingLoader: false
          });
        }
      } else {
        this.setState({ bookingLoader: false });
        const errMsg = `PNR field is blank`;

        message.error(errMsg);
        this.setState({ bookingLoader: false });
      }
    } catch (err) {
      console.log(err);
      this.setState({ bookingLoader: false });
    }
  };

  sendEmail = async (bookingCode, id, date) => {
    if (date < 1694716200000) {
      message.error("Booking should be of after 14th Sept");
      return;
    }
    this.setState({ invoiceLoader: true });

    const url = `/gds/booking/whatsAppInvoice`;

    const options = {
      method: "post",
      data: {
        pnr: bookingCode,
        id
      }
    };
    const response = await Fetch(url, options);

    if (response) {
      message.success("WhatsApp Sent Successfully");
    }
    this.setState({ invoiceLoader: false });
  };

  downloadEmail = async (customerpnr, id, date) => {
    if (date < 1694716200000) {
      message.error("Booking should be of after 14th Sept");
      return;
    }
    this.setState({ invoiceLoader: true });

    const url = `/gds/booking/downloadEmail?pnr=${customerpnr}&id=${id}`;

    const options = {
      method: "get"
    };

    const response = await Fetch(url, options);

    if (response) {
      message.success("Invoice downloaded");
    }
    this.setState({ invoiceLoader: false });

    const linkSource = response;
    const downloadLink = document.createElement("a");
    const fileName = `invoice-${customerpnr}.pdf`;
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  fetchTrip = async () => {
    try {
      const { fromCity, toCity, date } = this.state;
      const data = {
        fromCity,
        toCity,
        date
      };
      this.props.onSearch(data);
    } catch (err) {
      message.error(err);
    }
  };

  showBooking = bookings => {
    return bookings.map(booking => {
      let popConfirmText = "";

      if (booking.isOnboarded) {
        popConfirmText = "Are you sure you want to mark customer not boarded";
      } else {
        popConfirmText = `Are you sure you want to mark customer boarded`;

        if (booking.amountToBeCollected) {
          popConfirmText += `customer has cash collection of ${booking.amountToBeCollected} `;
        }
      }
      return popConfirmText;
    });
  };

  showMessageSent = booking => {
    const { messageSentReasons } = this.state;
    const { messageSent } = booking;
    for (let i = 0; i < messageSent.length; i++) {
      let data = messageSent[i];
      let templateType =
        data.templateType == "delay" ? "Delay" : data.templateType;

      let message = `${templateType} - at ${moment(data.time).format("lll")}`;
      if (templateType == "Delay") {
        messageSentReasons.push(message);
      }
    }
    this.setState({ showMessageSentDetails: true, messageSentReasons });
  };

  sendMessage = async (
    templateType,
    vars,
    smsVariables,
    sendSMSMessage,
    sendWhatsAppMessage
  ) => {
    try {
      const { bookings } = this.state;

      if (bookings.length) {
        const url = `/gds/booking/messages`;

        const options = {
          method: "post",
          data: {
            bookings: bookings.map(b => b.booking),
            templateType,
            smsVariables,
            vars,
            sendSMSMessage,
            sendWhatsAppMessage
          }
        };

        const response = await Fetch(url, options);

        if (response) {
          message.success("Message sent successfully");
        }
      } else {
        message.error("No Booking Selected");
      }
    } catch (err) {
      message.error(err);
    }
  };

  sendMessageNumber = async (
    templateType,
    vars,
    smsVariables,
    sendSMSMessage,
    sendWhatsAppMessage
  ) => {
    try {
      const { selectedBookings } = this.state;

      if (selectedBookings.length) {
        const url = `/gds/booking/messages`;

        const options = {
          method: "post",
          data: {
            bookings: selectedBookings.map(b => b.booking),
            templateType,
            vars,
            smsVariables,
            sendSMSMessage,
            sendWhatsAppMessage
          }
        };

        const response = await Fetch(url, options);

        if (response) {
          message.success("Message sent successfully");
          this.setState({ selectedRowKeys: [] });
        } else {
          message.error("No Booking Selected");
        }
      }
    } catch (err) {
      message.error(err);
    }
  };

  copyCustomerDetails = (
    bookingCode,
    mobileNo,
    seatsLabel,
    fromStation,
    fromCity,
    fromStationDate,
    toStation,
    toCity,
    trackingUrl,
    rc
  ) => {
    let busNumber = "";
    if (rc) {
      busNumber = rc.rc;
    } else {
      busNumber = "No Bus Assigned";
    }
    let time = moment(fromStationDate).format("lll");

    let text = `PNR :${bookingCode} \n Mobile Number: ${mobileNo} \n Seat Number: ${seatsLabel} \n From Station: ${fromStation}, ${fromCity}, ${time} \n To Station: ${toStation}, ${toCity} \n Tracking URL: ${trackingUrl} \n Bus Number: ${busNumber} `;
    // console.log("text", text);

    this.inputRef.current.value = text;
    this.inputRef.current.select();
    document.execCommand("copy");
    message.success("Customer Details copied to clipboard");
  };

  sendRefundviaPnr = async (
    selectedCategory,
    subIssueId,
    issueId,
    categorys,
    issueComment,
    userInput
  ) => {
    try {
      const { bookings } = this.state;
      let booking = [];
      booking = bookings.map(obj => {
        return {
          agentMobileNo: obj.booking.agent ? obj.booking.agent.mobileNo : null,
          mobileNo: obj.mobileNo.value,
          userId: obj.booking.userId,
          bookingCode: obj.bookingCode ? obj.bookingCode : obj.pnr
        };
      });

      let refundID = selectedCategory[0]._id;
      let refundClassification = selectedCategory[0].refundClassification;

      if (bookings) {
        const url = `/bus-tracker/sendRefund`;
        const options = {
          method: "post",
          data: {
            subIssueId: subIssueId,
            issueId: issueId,
            bookingType: "GDS",
            refundRuleId: refundID,
            refundClassification: refundClassification
              ? refundClassification
              : "NORMAL",
            comment: issueComment,
            amount: userInput,
            type: "booking",
            bookings: booking
          }
        };
        const response = await Fetch(url, options);

        if (response) {
          message.success("Refund Initiated");
          this.setState({
            selectedBookings: [],
            selectedRowKeys: [],
            showResult: false,
            bookings: []
          });
        }
      } else {
        message.error("No Booking Selected");
      }
    } catch (err) {
      message.error(err);
    }
  };

  sendRefund = async (
    selectedCategory,
    subIssueId,
    issueId,
    categorys,
    issueComment,
    userInput
  ) => {
    try {
      const { selectedBookings } = this.state;
      let refundID = selectedCategory[0]._id;
      let refundClassification = selectedCategory[0].refundClassification;

      if (selectedBookings.length) {
        const url = `/bus-tracker/sendRefund`;
        let booking = [];
        booking = selectedBookings.map(obj => {
          return {
            agentMobileNo: obj.booking.agent
              ? obj.booking.agent.mobileNo
              : null,
            userId: obj.booking.userId,
            mobileNo: obj.mobileNo.value,
            bookingCode: obj.bookingCode
          };
        });
        const options = {
          method: "post",
          data: {
            subIssueId: subIssueId,
            issueId: issueId,
            type: "booking",
            refundRuleId: refundID,
            refundClassification: refundClassification
              ? refundClassification
              : "NORMAL",
            amount: userInput,
            bookingType: "GDS",
            comment: issueComment,
            bookings: booking
          }
        };
        const response = await Fetch(url, options);

        if (response) {
          message.success("Refund Initiated");
          this.setState({
            selectedBookings: [],
            selectedRowKeys: [],
            showResult: false,
            bookings: []
          });
        }
      } else {
        message.error("No Booking Selected");
      }
    } catch (err) {
      message.error(err);
    }
  };
  copyCustomerTrackingLink = customerTrackingLink => {
    this.inputRef.current.value = customerTrackingLink;
    this.inputRef.current.select();

    document.execCommand("copy");
    message.success("Customer Link copied to clipboard");
  };

  showCrewDetails = () => {
    this.setState({ showCrewDetails: true });
  };

  handleChange = (key, value) => {
    this.setState({ [key]: value, messageSentReasons: [] });
  };

  handleSpecialCharacters = event => {
    const newCheck = event.target.value.replace(/[^\w\s]/gi, "");
    this.setState({ bookingSearchValue: newCheck });
  };

  handleChangeInput = event => {
    const { value, maxLength } = event.target;
    const message = value.slice(0, maxLength);
    this.setState({ bookingSearchValue: message });
  };

  handleSwap = () => {
    const { fromCity, toCity } = this.state;

    this.setState({ fromCity: toCity, toCity: fromCity });
  };

  sendCoupon = async _id => {
    try {
      const { selectedBookings, bookings } = this.state;
      const sbooking =
        selectedBookings && selectedBookings.length > 0
          ? selectedBookings
          : bookings;
      if (sbooking.length) {
        const url = `/booking/sendCoupon`;
        let booking = [];
        if (sbooking.length) {
          booking = sbooking.map(obj => {
            return obj.bookingCode;
          });
        }
        const options = {
          method: "post",
          data: {
            ruleId: _id,
            pnrArr: booking
          }
        };
        const response = await Fetch(url, options);

        if (response == "Credit Coupon sent Successfully") {
          message.success("Credit Coupon sent Successfully");
          this.setState({ selectedRowKeys: [] });
        } else {
          message.error("Coupon not sent");
          this.setState({ selectedRowKeys: [] });
        }
      } else {
        message.error("No Booking Selected");
        this.setState({ selectedRowKeys: [] });
      }
    } catch (err) {
      message.error(err);
    }
  };

  render() {
    const {
      showResult,
      editBooking,
      bookingSearchValue,
      editField,
      bookingToEdit,
      fromCity,
      toCity,
      bookingLoader,
      selectedBookings,
      date,
      bookings,
      selectedPrimeBooking,
      showZingPrimeDetails,
      showMessageSentDetails,
      messageSentReasons,
      invoiceLoader
    } = this.state;

    const { cities } = this.props;
    return (
      <>
        <div className="searchForm">
          <input
            ref={this.inputRef}
            readOnly={true}
            style={{
              position: "absolute",
              left: "-9999px"
            }}
          />
          <div className="pnrSearch">
            <Input
              type="text"
              maxLength="20"
              placeholder="PNR / Mobile Number Search"
              style={{ minWidth: "50px", margin: "0 0.2rem" }}
              value={bookingSearchValue}
              onChange={this.handleSpecialCharacters}
            />

            <Button
              onClick={this.fetchBooking}
              loading={bookingLoader}
              type="primary"
            >
              Search
            </Button>
          </div>
          <div className="tripSearch">
            <div className="citySelect">
              <Select
                showSearch
                style={{ width: 150 }}
                value={fromCity}
                onChange={value => this.setState({ fromCity: value })}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {cities.map(city => (
                  <Option value={city._id} key={city._id}>
                    {city.name}
                  </Option>
                ))}
              </Select>
              <button className="swapButton" onClick={this.handleSwap}>
                <img src={swapIcon} alt="swapicon" />
              </button>
              <Select
                showSearch
                style={{ width: 150 }}
                value={toCity}
                onChange={value => this.setState({ toCity: value })}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {cities.map(city => (
                  <Option value={city._id} key={city._id}>
                    {city.name}
                  </Option>
                ))}
              </Select>
            </div>
            <div className="optionSelect">
              <DatePicker
                style={{ width: 150 }}
                value={date}
                onChange={date => this.setState({ date })}
              />
            </div>
            <div>
              <Button
                onClick={this.fetchTrip}
                style={{ width: 100 }}
                type="primary"
              >
                Search
              </Button>
            </div>
          </div>
        </div>
        <hr />
        <Modal
          title="Search Result"
          className="modalClass"
          visible={showResult}
          onCancel={() => this.setState({ showResult: false })}
          footer={null}
          width="80"
        >
          {bookingSearchValue.slice(0, 2) == "zg" ||
          bookingSearchValue.slice(0, 2) == "ZG" ? (
            <div className="bookingListFooter">
              <div className="bookingListFooterItemOne">
                <SendMessage
                  selectedBookings={bookings}
                  onClick={this.sendMessage}
                />
              </div>
              <SendRefund
                selectedBookings={bookings}
                onClick={this.sendRefundviaPnr}
                selectedCount={bookings.length}
                isSearchForm={true}
              />
              <SendCoupon
                selectedBookings={bookings}
                onClick={this.sendCoupon}
                selectedCount={selectedBookings.length}
                isSearchForm={true}
                isgdsBooking={true}
              />
            </div>
          ) : (
            <div className="bookingListFooter">
              <div className="bookingListFooterItemOne">
                <SendMessage
                  selectedBookings={selectedBookings}
                  onClick={this.sendMessageNumber}
                />
              </div>
              <SendRefund
                selectedBookings={selectedBookings}
                onClick={this.sendRefund}
                isSearchForm={true}
                selectedCount={selectedBookings.length}
              />
              <SendCoupon
                isgdsBooking={true}
                isSearchForm={true}
                selectedBookings={selectedBookings}
                onClick={this.sendCoupon}
                selectedCount={selectedBookings.length}
              />
            </div>
          )}
          <Modal
            centered
            width={"100%"}
            title="Messages Details"
            visible={showMessageSentDetails}
            cancelButtonProps={{ style: { display: "none" } }}
            onCancel={() => this.handleChange("showMessageSentDetails", false)}
            footer={null}
          >
            <ul>
              {messageSentReasons.map(function(data, index) {
                return <li key={index}>{data}</li>;
              })}
            </ul>
          </Modal>
          {bookingSearchValue.slice(0, 2) == "zg" ||
          bookingSearchValue.slice(0, 2) == "ZG" ? (
            <Table
              columns={columns}
              loading={invoiceLoader}
              dataSource={bookings}
              pagination={false}
              scroll={{ y: 400 }}
            />
          ) : (
            <Table
              loading={invoiceLoader}
              columns={columns}
              dataSource={bookings}
              pagination={false}
              rowSelection={{
                type: "radio",
                columnWidth: "4%",
                onChange: (keys, booking) => {
                  this.setState({
                    selectedBookings: booking
                  });
                }
              }}
              scroll={{ y: 300 }}
            />
          )}
          {editBooking ? (
            <EditBooking
              onChange={obj => this.setState(obj)}
              editField={editField}
              booking={bookingToEdit}
              closeParentModal={() => this.setState({ showResult: false })}
            />
          ) : null}
          {showZingPrimeDetails ? (
            <ZingPrime
              selectedBooking={selectedPrimeBooking}
              onCancel={() =>
                this.setState({
                  showZingPrimeDetails: false,
                  selectedPrimeBooking: undefined
                })
              }
            />
          ) : null}
        </Modal>
      </>
    );
  }
}

export default SearchForm;
