import React, { Component } from "react";
import { Select, Table, Button, Modal, message, Input } from "antd";
import jwt from "jsonwebtoken";

import Loader from "../loader";
import Fetch from "../../utils/fetch";
import CallTriggerSearchForm from "../../components/gdsOutboundSearch";
import { getSeatNumberFromTripChart } from "../../utils/common";

import moment from "moment";
import { EditOutlined, MoreOutlined } from "@ant-design/icons";

const { Option } = Select;
const wordBreakStyle = { wordBreak: "breakAll" };
function msToTime(duration) {
  let seconds = Math.floor((duration / 1000) % 60);
  let minutes = Math.floor((duration / (1000 * 60)) % 60);
  let hours = Math.floor((duration / (1000 * 60 * 60)) % 24);
  hours = hours < 10 ? "0" + hours : hours;
  minutes = minutes < 10 ? "0" + minutes : minutes;
  seconds = seconds < 10 ? "0" + seconds : seconds;

  return hours + " hrs :" + minutes + " min :" + seconds + " sec";
}

const serviceNameFetched = [];
const raisedByNameFetched = [];

const columns = [
  {
    title: "Complain Category",
    dataIndex: "complainCategory",
    key: "complainCategory",
    width: "10%"
  },
  {
    title: "PNR",
    dataIndex: "pnr",
    key: "pnr",
    width: "10%",
    sorter: (a, b) => a.pnr.systemAlertCount - b.pnr.systemAlertCount,
    filters: [
      {
        text: "High",
        value: "high"
      },
      {
        text: "Medium",
        value: "medium"
      },
      {
        text: "Low",
        value: "low"
      }
    ],
    onFilter: (value, record) => record.pnr.priority.includes(value),
    render: ({ systemAlertCount, bookingCode, showRelatedComplains }) => {
      return (
        <div style={{ color: systemAlertCount > 3 ? "red" : "black" }}>
          {bookingCode}
          {bookingCode ? (
            <MoreOutlined
              onClick={() =>
                showRelatedComplains({
                  bookingCode
                })
              }
            />
          ) : null}
        </div>
      );
    }
  },
  {
    title: "Service Name",
    dataIndex: "serviceName",
    key: "serviceName",
    filterSearch: true,
    filters: serviceNameFetched,
    onFilter: (value, record) => record.serviceName.includes(value),
    width: "10%"
  },
  {
    title: "Complain Comments",
    dataIndex: "complainComments",
    key: "complainComments",
    width: "10%"
  },
  {
    title: "Call Logs",
    dataIndex: "callLogs",
    key: "callLogs",
    width: "10%",
    render: ({
      showCallLogs,
      recordingUrl,
      bookingCode,
      subIssueName,
      _id
    }) => {
      if (subIssueName == "IVR Call Related" || recordingUrl) {
        return (
          <div>
            <Button onClick={() => window.open(recordingUrl)}>
              Show Recording
            </Button>
          </div>
        );
      } else {
        return (
          <div>
            <Button
              disabled={bookingCode ? false : true}
              onClick={() => showCallLogs({ bookingCode, _id })}
            >
              Show Call Logs
            </Button>
          </div>
        );
      }
    }
  },
  {
    title: "Raised By",
    dataIndex: "raisedBy",
    key: "raisedBy",
    width: "10%",
    filterSearch: true,
    filters: raisedByNameFetched,
    onFilter: (value, record) => record.raisedBy.name.includes(value),
    render: ({ name, date }) => {
      return (
        <div>
          {name}
          <br />
          {date}
        </div>
      );
    }
  },
  {
    title: "Click to call",
    dataIndex: "clickTocall",
    key: "clickTocall",
    width: "15%",
    render: ({ tripId, bookingCode, marshallConnect, _id, userConnect }) => {
      return (
        <div>
          <Button onClick={() => marshallConnect({ tripId, _id })}>
            Connect with Marshal
          </Button>
          <Button
            disabled={bookingCode ? false : true}
            onClick={() => userConnect({ bookingCode, _id })}
          >
            Connect with Passenger
          </Button>
        </div>
      );
    }
  },
  {
    title: "Outbound remark",
    dataIndex: "outboundRemarks",
    key: "outboundRemarks",
    width: "10%",
    render: ({
      value,
      _id,
      raisedBy,
      allOutBoundsRemarks,
      outboundRemarksUpdate
    }) => (
      <>
        <EditOutlined
          onClick={() =>
            outboundRemarksUpdate({ allOutBoundsRemarks, value, _id, raisedBy })
          }
        />
        <span style={wordBreakStyle}>{value}</span>
      </>
    )
  },
  {
    title: "Department",
    dataIndex: "department",
    key: "department",
    width: "10%"
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    width: "10%",
    render: ({ status, raisedBy, _id, updateStatus }) => {
      return (
        <Select
          value={status}
          style={{ width: 120, margin: "0 0.2rem", whiteSpace: "pre-line" }}
          onChange={e =>
            updateStatus({
              status: e,
              raisedBy,
              _id
            })
          }
        >
          <Option value="REJECTED">REJECTED</Option>
          <Option value="CLOSED">CLOSED</Option>
          <Option value="INPROGRESS">INPROGRESS</Option>
          <Option value="CREATED">CREATED</Option>
        </Select>
      );
    }
  }
];

const callDetailsCol = [
  {
    title: "Call Placed by",
    dataIndex: "callPlacedBy",
    key: "callPlacedBy",
    width: "8%"
  },
  {
    title: "Time",
    dataIndex: "time",
    width: "10%",
    key: "time"
  },
  {
    title: "Duration",
    dataIndex: "duration",
    width: "10%",
    key: "duration"
  },
  {
    title: "Status",
    dataIndex: "status",
    width: "10%",
    key: "status"
  },
  {
    title: "Recording",
    dataIndex: "recording",
    width: "10%",
    key: "recording",
    render: ({ recording }) => {
      return (
        <div>
          <audio className="audio_tag" src={recording} controls />
        </div>
      );
    }
  }
];

const pnrDetailedView = [
  {
    title: "Complain Category",
    dataIndex: "complainCategory",
    key: "complainCategory",
    width: "10%"
  },
  {
    title: "Complain Comments",
    dataIndex: "complainComments",
    key: "complainComments",
    width: "10%"
  },
  {
    title: "Mobile No",
    dataIndex: "mobileNo",
    key: "mobileNo",
    width: "10%"
  },
  {
    title: "Raised By",
    dataIndex: "raisedBy",
    key: "raisedBy",
    width: "10%",
    render: ({ name, date }) => {
      return (
        <div>
          {name}
          <br />
          {date}
        </div>
      );
    }
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    width: "10%",
    render: ({ status }) => {
      return <div>{status}</div>;
    }
  }
];

class CallLogsCategory extends Component {
  state = {
    loading: true,
    complainResponse: [],
    outboundRemarkInput: "",
    showCallLogsModal: false,
    showCustomerCall: false,
    showMarshallCall: false,
    bookingLoader: false,
    showOutBoundsEdit: false,
    showRelatedComplains: false,
    showRelatedComplainsList: []
  };

  async componentDidMount() {
    try {
      const complainResponse = await this.fetchComplain();
      this.setState({ complainResponse, loading: false });
    } catch (err) {
      console.log(err);
      this.setState({ loading: false });
    }
  }
  fetchComplain = async () => {
    try {
      const url = `/gds/outbound/`;
      const options = {
        method: "get"
      };
      const response = await Fetch(url, options);
      if (response && Array.isArray(response)) {
        const formattedData = this.formattedData(response);
        return formattedData;
      }
      return null;
    } catch (err) {
      console.log(err);
      message.error(err);
    }
  };

  formattedData = response => {
    try {
      return response.map(complainsObj => {
        let latestValue = complainsObj.outboundRemark
          ? complainsObj.outboundRemark.split("|")
          : "";
        latestValue = latestValue ? latestValue.filter(obj => obj != "") : "";
        const complainFormated = {
          _id: complainsObj._id,
          complainCategory: complainsObj.complaintCategory,
          fromStationDate: complainsObj.fromStationDate,
          complainComments: complainsObj.complaintName,
          serviceName: complainsObj.serviceName,
          pnr: {
            priority:
              complainsObj.systemAlertCount > 3
                ? "high"
                : complainsObj.systemAlertCount > 1
                ? "medium"
                : "low",
            bookingId: complainsObj.bookingId,
            bookingCode: complainsObj.bookingCode,
            systemAlertCount: complainsObj.systemAlertCount
              ? complainsObj.systemAlertCount
              : -1,
            showRelatedComplains: this.showModalRelatedComplains
          },
          callLogs: {
            showCallLogs: this.showCallLogs,
            recordingUrl: complainsObj.recordingUrl,
            _id: complainsObj._id,
            subIssueName: complainsObj.subIssueName,
            bookingCode: complainsObj.bookingCode
          },
          raisedBy: {
            subIssueName: complainsObj.subIssueName,
            name: complainsObj.raisedBy,
            date: moment(complainsObj.createdOn).format("LLL")
          },
          clickTocall: {
            marshallConnect: this.showMarshallDetails,
            userConnect: this.userDetails,
            _id: complainsObj._id,
            tripId: complainsObj.tripId,
            bookingCode: complainsObj.bookingCode
          },
          status: {
            updateStatus: this.updateStatus,
            status: complainsObj.status,
            raisedBy: complainsObj.raisedBy,
            _id: complainsObj._id
          },
          department: complainsObj.department,
          outboundRemarks: {
            _id: complainsObj._id,
            value: latestValue[latestValue.length - 1],
            allOutBoundsRemarks: complainsObj.outboundRemark,
            raisedBy: complainsObj.raisedBy,
            outboundRemarksUpdate: this.outboundRemarksUpdate
          }
        };
        return complainFormated;
      });
    } catch (error) {
      console.log(error);
    }
  };

  showModalRelatedComplains = async obj => {
    const { complainResponse } = this.state;
    let filterPnrData = complainResponse.filter(
      data => data.pnr.bookingCode == obj.bookingCode
    );
    this.setState({
      showRelatedComplains: true,
      showRelatedComplainsList: filterPnrData
    });
  };

  outboundRemarksUpdate = async obj => {
    let allOutBoundsRemarksArray = obj.allOutBoundsRemarks
      ? obj.allOutBoundsRemarks.split("|")
      : "";
    allOutBoundsRemarksArray = allOutBoundsRemarksArray
      ? allOutBoundsRemarksArray.filter(obj => obj != "")
      : "";
    const outboundRemarksUpdateObj = {
      outboundRemark: obj.value,
      allOutBoundsRemarks: allOutBoundsRemarksArray,
      raisedBy: obj.raisedBy,
      _id: obj._id
    };
    this.setState({ showOutBoundsEdit: true, outboundRemarksUpdateObj });
  };

  showCallLogs = async obj => {
    this.setState({ showCallLogsModal: true });
    const url = `/gds/outbound/callRecords?pnr=${obj.bookingCode}`;
    const options = {
      method: "get"
    };
    let response = await Fetch(url, options);
    response.forEach(obj => {
      let callDetailsObj = [];
      if (
        obj.callDetails &&
        Array.isArray(obj.callDetails) &&
        obj.callDetails.length > 0
      ) {
        for (let o = 0; o < obj.callDetails.length; o++) {
          let data = obj.callDetails[o];
          let startTime = new Date(data.start_time).getTime();
          let endTime = new Date(data.end_time).getTime();
          const diff = endTime - startTime;
          let callDuration = msToTime(diff);
          let callData = {
            callPlacedBy: data.callfrom == obj.mobileNo ? "User" : "Marshal",
            time: data.start_time,
            duration: callDuration,
            status: data.status,
            recording: { recording: data.recording, status: data.status }
          };
          callDetailsObj.push(callData);
        }
      }
      this.setState({ callDetailsObj });
    });
  };

  userDetails = async obj => {
    const url = `/gds/booking/getParticularBooking?bookingCode=${obj.bookingCode}`;

    const options = {
      method: "get"
    };
    let response = await Fetch(url, options),
      bookingcomplain;
    if (response) {
      bookingcomplain = {
        systemId: obj._id,
        name: response[0].name,
        bookingCode: response[0].bookingCode,
        number: response[0].mobileNo,
        service: response[0].gdsOperatorService.name,
        TripDate: moment(response[0].tripDate).format("LL"),
        from: response[0].fromStationName,
        to: response[0].toStationName
      };
    }
    this.setState({
      bookingcomplain,
      showCustomerCall: true
    });
  };

  showMarshallDetails = async obj => {
    const url = `/gds/outbound/marshallDetails?_id=${obj.tripId}`;
    const options = {
      method: "get"
    };
    let response = await Fetch(url, options),
      marshalObj;
    if (response) {
      if (!response[0].busAssignmentDetails) {
        message.error("Host Not Assigned Yet");
        return;
      }
      marshalObj = {
        systemId: obj._id,
        name: response[0].busAssignmentDetails.busHostName,
        service: response[0].gdsOperatorService.name,
        number: response[0].busAssignmentDetails.busHostPhoneNumber,
        TripDate: moment(response[0].tripDate).format("LL")
      };
    }
    this.setState({ marshalObj, showMarshallCall: true });
  };

  callUser = async (number, systemId) => {
    const url = `/bus-tracker/outboundCall`;
    const options = {
      method: "post",
      data: {
        number: number,
        systemId: systemId
      }
    };
    let response = await Fetch(url, options);
    if (response == "ok") {
      message.success("You will recieve call shortly");
    } else if (response == "error") {
      message.error("There is some error");
    }
  };

  call = async () => {
    const { bookingcomplain, marshalObj } = this.state;
    const url = `/gds/outbound/call`;
    // const url = `/bus-tracker/outboundCall`;
    const options = {
      method: "post",
      data: {
        number: marshalObj ? marshalObj.number : bookingcomplain.number,
        systemId: marshalObj ? marshalObj.systemId : bookingcomplain.systemId
      }
    };
    let response = await Fetch(url, options);
    if (response == "ok") {
      message.success("You will recieve call shortly");
      this.setState({
        showMarshallCall: false,
        showCustomerCall: false,
        marshalObj: undefined,
        bookingcomplain: []
      });
    } else if (response == "error") {
      message.error("There is some error");
      this.setState({
        showMarshallCall: false,
        showCustomerCall: false,
        marshalObj: undefined,
        bookingcomplain: []
      });
    }
  };

  fetchBooking = async () => {
    try {
      this.setState({ bookingLoader: true });
      let { bookingcomplain } = this.state;
      if (bookingcomplain) {
        let url = `/bus-tracker/booking?pnr=${bookingcomplain.bookingCode}`;
        const options = {
          method: "get"
        };
        let response = await Fetch(url, options);
        if (response) {
          const bookingR = [];
          const stationMap = {};
          let station;
          let booking;
          let stationArray = [];
          let callDuration = "";

          for (let i = 0; i < response.length; ++i) {
            booking = response[i];
            stationArray.push(booking.tripId);
          }

          const urlEta = `/bus-tracker/bulkStations/${stationArray}`;
          let EtaResponse = await Fetch(urlEta, options);

          for (let i = 0; i < EtaResponse.length; ++i) {
            station = EtaResponse[i];
            stationMap[station.stationId] = station;
          }

          for (let i = 0; i < response.length; ++i) {
            const booking = response[i];
            station = stationMap[booking.fromStation._id];
            for (let i = 0; i < booking.callDetails.length; ++i) {
              let calls = booking.callDetails[i];
              if (calls) {
                if (calls.recording) {
                  let startTime = new Date(calls.start_time).getTime();
                  let endTime = new Date(calls.end_time).getTime();
                  const diff = endTime - startTime;
                  callDuration = msToTime(diff);
                } else {
                  callDuration = null;
                }
                booking.callDetails[i]["callDuration"] = callDuration;
              }
            }

            let seatsLabel = "";
            if (booking.tripChart && booking.tripChart.length > 0) {
              for (let i = 0; i < booking.tripChart.length; ++i) {
                for (let i = 0; i < booking.tripChart.length; ++i) {
                  const seat = booking.tripChart[i];

                  if (seat.status === "CONFIRMED") {
                    const seatNumber = seat.seat.seatLabel;
                    const seatGender = seat.gender ? seat.gender : "";

                    if (seatsLabel.length === 0) {
                      seatsLabel += `${seatNumber}(${seatGender})`;
                    } else {
                      seatsLabel += `,${seatNumber}(${seatGender})`;
                    }
                  }
                }
                break;
              }
            }
            if (
              booking.status === "CONFIRMED" ||
              booking.status === "FAILED" ||
              booking.status === "NOT REPORTED" ||
              booking.status === "CANCELLED"
            ) {
              const bookingObj = {
                key: booking._id,
                name: {
                  value: booking.name,
                  copyCustomerDetails: () =>
                    this.copyCustomerDetails(
                      booking.bookingCode,
                      booking.mobileNo,
                      seatsLabel,
                      booking.fromStation.name,
                      booking.fromStation.city.name,
                      booking.fromStationDate,
                      booking.toStation.name,
                      booking.toStation.city.name,
                      booking.trackingUrl,
                      booking.trip.bus
                    )
                },
                email: {
                  value: booking.email,
                  tripStatus: booking.trip.status,
                  onChange: () =>
                    this.setState({
                      editField: "email",
                      editBooking: true,
                      bookingToEdit: booking
                    })
                },
                invoice: {
                  status: booking.status,
                  value: booking.email,
                  bookingcode: booking.bookingCode,
                  tripStatus: booking.trip.status,
                  date: booking.toStationDate,
                  todaysdate: Date.now(),
                  sendEmail: () =>
                    this.sendEmail(booking.bookingCode, booking.email),
                  downloadEmail: () => this.downloadEmail(booking.bookingCode),
                  onChange: () =>
                    this.setState({
                      editField: "email",
                      editBooking: true,
                      bookingToEdit: booking
                    })
                },
                mobileNo: {
                  value: booking.mobileNo,
                  onChange: () =>
                    this.setState({
                      editField: "mobileNo",
                      editBooking: true,
                      bookingToEdit: booking
                    })
                },
                demandSource: ` ${booking.demandSource}(${
                  booking.otaPartner ? booking.otaPartner : ""
                })`,
                bookingCode: booking.bookingCode,
                seatChart: seatsLabel,
                fromStation: {
                  name: `${booking.fromStation.name}, ${booking.fromStation.city.name}`,
                  date: moment(booking.fromStationDate).format("lll"),
                  onChange: () =>
                    this.setState({
                      editField: "fromStation",
                      editBooking: true,
                      bookingToEdit: booking
                    })
                },
                seats: getSeatNumberFromTripChart(booking.tripChart),
                toStation: {
                  name: `${booking.toStation.name}, ${booking.toStation.city.name}`,
                  date: moment(booking.toStationDate).format("lll"),
                  onChange: () =>
                    this.setState({
                      editField: "toStation",
                      editBooking: true,
                      bookingToEdit: booking
                    })
                },
                busNumber: booking.trip.bus ? (
                  <span>{booking.trip.bus.rc}</span>
                ) : (
                  <span>No Bus Assigned</span>
                ),
                crewDetails: {
                  bus: booking.trip.bus,
                  busHost: booking.trip.busHost,
                  busDriver: booking.trip.busDriver
                },
                amountToBeCollected: {
                  value: booking.amountToBeCollected,
                  agentId: booking.agentId ? booking.agentId : null,
                  onChange: () =>
                    this.setState({
                      editField: "amountToBeCollected",
                      editBooking: true,
                      bookingToEdit: booking
                    })
                },
                call: booking.callDetails.length,
                // remark: booking.remark,
                service: booking.trip.service.name,
                status: booking.status,
                refunds: {
                  data: booking.refund ? booking.refund : null,
                  sendRefundMessage: () => this.sendRefundMessage(booking)
                },
                callAttempted: booking.callDetails.length,
                callRecords: {
                  data: booking.callDetails,
                  from: booking.mobileNo
                },

                IncomingcallRecords: booking.incomingCallDetails,
                action: {
                  tripId: booking.tripId,
                  customerTrackingLink: `http://${booking.trackingUrl}`,
                  copyCustomerTrackingLink: () =>
                    this.copyCustomerTrackingLink(booking.trackingUrl),
                  showModal: () => this.showModal(booking)
                },
                trackingUrl: booking.trackingUrl,
                booking,
                actionHistory: booking.actionHistory || "",
                notBoarededReason: booking.notBoardedReason
                  ? booking.notBoardedReason
                  : null,
                remark: {
                  value: booking.remark,
                  onChange: () =>
                    this.setState({
                      editField: "remark",
                      editBooking: true,
                      bookingToEdit: booking
                    })
                },
                _id: booking._id
              };
              bookingR.push(bookingObj);
            }
          }
          this.setState({
            showBookingDetails: true,
            bookings: bookingR,
            bookingLoader: false
          });
        }
      } else {
        this.setState({ bookingLoader: false });
      }
    } catch (err) {
      console.log(err);
      this.setState({ bookingLoader: false });
    }
  };

  updateStatus = async obj => {
    const { complainResponse } = this.state;
    const url = `/gds/outbound/update`;
    const toBeUpdateData = complainResponse.filter(comp => comp._id == obj._id);
    const token = localStorage.getItem("idToken");
    const decodedToken = jwt.decode(token);
    let remark = toBeUpdateData[0].outboundRemarks.allOutBoundsRemarks
      ? `${toBeUpdateData[0].outboundRemarks.allOutBoundsRemarks} `
      : "";

    let updateData = `${remark}${decodedToken.name},${moment().format(
      "LLL"
    )}:moved from ${toBeUpdateData[0].status.status} to ${obj.status}|`;

    if (toBeUpdateData[0].pnr && toBeUpdateData[0].pnr.bookingId) {
      updateData = `${remark}${toBeUpdateData[0].complainCategory}-${
        decodedToken.name
      },${moment().format("LLL")}:moved from ${
        toBeUpdateData[0].status.status
      } to ${obj.status}|`;
    }
    const options = {
      method: "patch",
      data: {
        _id: obj._id,
        raisedBy: obj.raisedBy,
        status: obj.status,
        outboundRemark: updateData,
        bookingId: toBeUpdateData[0].pnr
          ? toBeUpdateData[0].pnr.bookingId
          : undefined
      }
    };

    const response = await Fetch(url, options);
    if (response && Array.isArray(response)) {
      const formattedData = this.formattedData(response);
      let updatedValue = complainResponse.map(
        obj => formattedData.find(o => o._id === obj._id) || obj
      );
      this.setState({
        complainResponse: updatedValue
      });
      message.success("Data is updated");
    }
  };

  updateOutboundRemark = async () => {
    const {
      complainResponse,
      outboundRemarkInput,
      outboundRemarksUpdateObj
    } = this.state;
    if (outboundRemarkInput.trim().length === 0) {
      message.error("please enter something");
      this.setState({
        showOutBoundsEdit: false,
        selectedId: "",
        outboundRemarkInput: ""
      });
      return;
    }
    const url = `/gds/outbound/update`;
    const token = localStorage.getItem("idToken");
    const decodedToken = jwt.decode(token);
    const toBeUpdateData = complainResponse.filter(
      obj => outboundRemarksUpdateObj._id == obj._id
    );
    let remark = toBeUpdateData[0].outboundRemarks.allOutBoundsRemarks
      ? `${toBeUpdateData[0].outboundRemarks.allOutBoundsRemarks}`
      : "";

    let updateData = `${remark}${decodedToken.name},${moment().format(
      "LLL"
    )}: ${outboundRemarkInput}|`;
    if (toBeUpdateData[0].pnr && toBeUpdateData[0].pnr.bookingId) {
      updateData = `${remark}${toBeUpdateData[0].complainCategory}-${
        decodedToken.name
      },${moment().format("LLL")}:${outboundRemarkInput}|`;
    }
    const options = {
      method: "patch",
      data: {
        _id: outboundRemarksUpdateObj._id,
        raisedBy: outboundRemarksUpdateObj.raisedBy,
        outboundRemark: updateData,
        bookingId: toBeUpdateData[0].pnr
          ? toBeUpdateData[0].pnr.bookingId
          : undefined
      }
    };

    const response = await Fetch(url, options);
    if (response && Array.isArray(response)) {
      const formattedData = this.formattedData(response);
      let updatedValue = complainResponse.map(
        obj => formattedData.find(o => o._id === obj._id) || obj
      );
      this.setState({
        showOutBoundsEdit: false,
        selectedId: "",
        outboundRemarkInput: "",
        complainResponse: updatedValue
      });
      message.success("Data is updated");
    } else {
      message.success("Data not updated");
      this.setState({
        showOutBoundsEdit: false,
        selectedId: "",
        outboundRemarkInput: ""
      });
    }
  };

  handleTextChange = (key, value) => {
    this.setState({ [key]: value });
  };

  showLoading = () => {
    this.setState({ contentLoading: true });
  };

  updateData = async data => {
    try {
      if (data && Array.isArray(data)) {
        let formattedData = this.formattedData(data);
        this.setState({
          complainResponse: formattedData,
          contentLoading: false
        });
      } else {
        this.setState({
          complainResponse: [],
          contentLoading: false
        });
      }
    } catch (err) {
      console.log(err);
      this.setState({
        contentLoading: false
      });
      message.error(err);
    }
  };

  handleCancel = () => {
    const audioTag = document.getElementsByClassName("audio_tag");
    if (audioTag && audioTag.length) {
      for (let tag of audioTag) {
        if (tag.duration && tag.duration > 0 && !tag.paused) {
          tag.pause();
        }
      }
    }
    this.setState({
      showCallLogsModal: false,
      callDetailsObj: []
    });
  };

  render() {
    const {
      loading,
      contentLoading,
      complainResponse,
      showCallLogsModal,
      callDetailsObj,
      marshalObj,
      showMarshallCall,
      outboundRemarksUpdateObj,
      showOutBoundsEdit,
      showCustomerCall,
      outboundRemarkInput,
      bookingcomplain,
      showRelatedComplainsList,
      showRelatedComplains
    } = this.state;
    if (loading) {
      return <Loader />;
    } else {
      return (
        <div>
          <div>
            <CallTriggerSearchForm
              showLoader={this.showLoading}
              onSearch={this.updateData}
            />
          </div>
          <div
            style={{
              overflow: "hidden",
              width: "100%",
              height: "100vh"
            }}
          >
            <Table
              scroll={{ y: "70vh" }}
              loading={contentLoading}
              columns={columns}
              pagination={false}
              dataSource={complainResponse}
            />
          </div>
          <Modal
            centered
            width={"100%"}
            title="Call Logs"
            visible={showCallLogsModal}
            footer={null}
            onCancel={this.handleCancel}
          >
            <Table
              columns={callDetailsCol}
              dataSource={callDetailsObj}
              pagination={false}
              scroll={{ y: 300 }}
            />
          </Modal>
          <Modal
            title=" Marshal Details"
            visible={showMarshallCall}
            centered
            onCancel={() =>
              this.setState({
                showMarshallCall: false,
                marshalObj: undefined
              })
            }
            closable={false}
            okButtonProps={{ style: { display: "none" } }}
            cancelButtonProps={{ style: { display: "none" } }}
          >
            <div>
              <p>
                Marshal Name:{" "}
                <b
                  style={{
                    fontSize: "15px"
                  }}
                >
                  {" "}
                  {marshalObj ? marshalObj.name : ""}
                </b>
              </p>
              <p>
                {" "}
                Service:{" "}
                <b
                  style={{
                    fontSize: "15px"
                  }}
                >
                  {" "}
                  {marshalObj ? marshalObj.service : ""}
                </b>
              </p>
              <p>
                Trip Date:{" "}
                <b
                  style={{
                    fontSize: "15px"
                  }}
                >
                  {" "}
                  {marshalObj ? marshalObj.TripDate : ""}
                </b>
              </p>
            </div>
            <b
              style={{
                fontSize: "15px"
              }}
            >
              {" "}
              Are you sure you want to place a Call to the Marshal ?
            </b>
            <div
              style={{
                marginTop: "5px",
                display: "flex",
                justifyContent: "center"
              }}
            >
              <Button
                type="primary"
                onClick={this.call}
                style={{ marginRight: "5px" }}
              >
                Call
              </Button>
              <Button
                onClick={() =>
                  this.setState({
                    showMarshallCall: false,
                    marshalObj: undefined
                  })
                }
                type="ghost"
              >
                Cancel
              </Button>
            </div>
          </Modal>
          <Modal
            title=" Passenger Details"
            visible={showCustomerCall}
            centered
            onCancel={() =>
              this.setState({
                showCustomerCall: false,
                bookingcomplain: []
              })
            }
            closable={false}
            okButtonProps={{ style: { display: "none" } }}
            cancelButtonProps={{ style: { display: "none" } }}
          >
            <div>
              <p>
                Name:{" "}
                <b
                  style={{
                    fontSize: "15px"
                  }}
                >
                  {" "}
                  {bookingcomplain ? bookingcomplain.name : ""}
                </b>
              </p>
              <p>
                {" "}
                Service:{" "}
                <b
                  style={{
                    fontSize: "15px"
                  }}
                >
                  {" "}
                  {bookingcomplain ? bookingcomplain.service : ""}
                </b>
              </p>
              <p>
                Trip Date:{" "}
                <b
                  style={{
                    fontSize: "15px"
                  }}
                >
                  {" "}
                  {bookingcomplain ? bookingcomplain.TripDate : ""}
                </b>
              </p>
              <p>
                From:{" "}
                <b
                  style={{
                    fontSize: "15px"
                  }}
                >
                  {" "}
                  {bookingcomplain ? bookingcomplain.from : ""}
                </b>
              </p>{" "}
              <p>
                To:{" "}
                <b
                  style={{
                    fontSize: "15px"
                  }}
                >
                  {" "}
                  {bookingcomplain ? bookingcomplain.to : ""}
                </b>
              </p>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row-reverse"
              }}
            >
              {/* <Button
                onClick={this.fetchBooking}
                loading={bookingLoader}
                type="primary"
              >
                View More Details
              </Button> */}
            </div>
            <b
              style={{
                fontSize: "15px"
              }}
            >
              {" "}
              Are you sure you want to place a Call to the passenger ?
            </b>
            <div
              style={{
                marginTop: "5px",
                display: "flex",
                justifyContent: "center"
              }}
            >
              <Button
                type="primary"
                onClick={this.call}
                style={{ marginRight: "5px" }}
              >
                Call
              </Button>
              <Button
                onClick={() =>
                  this.setState({
                    showCustomerCall: false,
                    bookingcomplain: []
                  })
                }
                type="ghost"
              >
                Cancel
              </Button>
            </div>
          </Modal>
          <Modal
            title="OutBound Remarks"
            visible={showOutBoundsEdit}
            okText="Update"
            onOk={this.updateOutboundRemark}
            onCancel={() =>
              this.setState({
                outboundRemarkInput: "",
                showOutBoundsEdit: false
              })
            }
          >
            {outboundRemarksUpdateObj &&
            Array.isArray(outboundRemarksUpdateObj.allOutBoundsRemarks) &&
            outboundRemarksUpdateObj.allOutBoundsRemarks.length > 0
              ? outboundRemarksUpdateObj.allOutBoundsRemarks.map(text => (
                  <div key={text}>
                    <p>{text}</p>
                  </div>
                ))
              : null}

            <Input
              value={outboundRemarkInput}
              placeholder="Enter Remark"
              onChange={e =>
                this.handleTextChange("outboundRemarkInput", e.target.value)
              }
            />
          </Modal>
          <Modal
            centered
            width={"100%"}
            title="All Complains"
            visible={showRelatedComplains}
            footer={null}
            onCancel={() =>
              this.setState({
                showRelatedComplains: false,
                showRelatedComplainsList: []
              })
            }
          >
            <Table
              columns={pnrDetailedView}
              dataSource={showRelatedComplainsList}
              pagination={false}
              scroll={{ y: 300 }}
            />
          </Modal>
        </div>
      );
    }
  }
}

export default CallLogsCategory;
