import React from "react";
import { Modal, message } from "antd";
import PhoneImg from "../../images/call.svg";
import Fetch from "../../utils/fetch";
import style from "./style.module.scss";

class CrewDetails extends React.Component {
  call = async number => {
    const url = `/bus-tracker/outboundCall`;
    const options = {
      method: "post",
      data: {
        number
      }
    };
    let response = await Fetch(url, options);
    if (response == "ok") {
      message.success("You will recieve call shortly");
    } else if (response == "error") {
      message.error("There is some error");
    }
  };

  render() {
    const { details = null, onCancel } = this.props;
    const {
      bus,
      busDriver,
      busHost,
      secondaryBusHost,
      secondaryBusDriver
    } = details;

    return (
      <Modal
        title="Crew Details"
        visible={true}
        onCancel={onCancel}
        footer={null}
      >
        {details ? (
          <>
            <div className={style.grid}>
              <span>Bus Number:</span>
              <span>{bus ? bus.rc : null}</span>
            </div>
            <div className={style.grid}>
              <span>Bus Firm:</span>
              <span>{bus ? bus.gpsFirm : null}</span>
            </div>
            <div className={style.grid}>
              <span>Bus IEMI Number:</span>
              <span>{bus ? bus.imei : null}</span>
            </div>
            <div className={style.grid}>
              <span>Bus Host:</span>
              <span>{busHost ? busHost.name : null}</span>
              {busHost && busHost.mobileNo ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px"
                  }}
                >
                  <img
                    src={PhoneImg}
                    alt="call"
                    style={{
                      width: "15px",
                      cursor: "pointer"
                    }}
                    onClick={() => this.call(busHost ? busHost.mobileNo : null)}
                  />
                  <p style={{ margin: "0" }}>
                    {busHost ? busHost.mobileNo : null}
                  </p>
                </div>
              ) : null}
            </div>
            <div className={style.grid}>
              <span>Secondary Bus Host:</span>
              <span>{secondaryBusHost ? secondaryBusHost.name : null}</span>
              {secondaryBusHost && secondaryBusHost.mobileNo ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px"
                  }}
                >
                  <img
                    src={PhoneImg}
                    alt="call"
                    style={{ width: "15px", cursor: "pointer" }}
                    onClick={() =>
                      this.call(
                        secondaryBusHost ? secondaryBusHost.mobileNo : null
                      )
                    }
                  />
                  <p style={{ margin: "0" }}>
                    {secondaryBusHost ? secondaryBusHost.mobileNo : null}
                  </p>
                </div>
              ) : null}
            </div>
            <div className={style.grid}>
              <span>Bus Driver:</span>
              <span>{busDriver ? busDriver.name : null}</span>
              {busDriver && busDriver.mobileNo ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px"
                  }}
                >
                  <img
                    src={PhoneImg}
                    alt="call"
                    style={{ width: "15px", cursor: "pointer" }}
                    onClick={() =>
                      this.call(busDriver ? busDriver.mobileNo : null)
                    }
                  />
                  <p style={{ margin: "0" }}>
                    {busDriver ? busDriver.mobileNo : null}
                  </p>
                </div>
              ) : null}
            </div>
            <div className={style.grid}>
              <span>Secondary Bus Driver:</span>
              <span>{secondaryBusDriver ? secondaryBusDriver.name : null}</span>
              {secondaryBusDriver && secondaryBusDriver.mobileNo ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px"
                  }}
                >
                  <img
                    src={PhoneImg}
                    alt="call"
                    style={{
                      width: "15px",
                      cursor: "pointer"
                    }}
                    onClick={() =>
                      this.call(
                        secondaryBusDriver ? secondaryBusDriver.mobileNo : null
                      )
                    }
                  />
                  <p style={{ margin: "0" }}>
                    {" "}
                    {secondaryBusDriver ? secondaryBusDriver.mobileNo : null}
                  </p>
                </div>
              ) : null}
            </div>
          </>
        ) : (
          "No Crew Details found"
        )}
      </Modal>
    );
  }
}

export default CrewDetails;
