import React, { useState, useRef } from "react";
import styles from "./selfhelp.module.scss";

const MaxImages = () => {
  const [imgSrc, setImgSrc] = useState("");
  const imgRef = useRef(null); // Create a ref to hold the image element

  const openFullscreen = () => {
    if (imgRef.current) {
      if (imgRef.current.requestFullscreen) {
        imgRef.current.requestFullscreen();
      } else if (imgRef.current.mozRequestFullScreen) {
        // Firefox
        imgRef.current.mozRequestFullScreen();
      } else if (imgRef.current.webkitRequestFullscreen) {
        // Chrome, Safari and Opera
        imgRef.current.webkitRequestFullscreen();
      } else if (imgRef.current.msRequestFullscreen) {
        // IE/Edge
        imgRef.current.msRequestFullscreen();
      }
    }
  };

  const handleImageClick = src => {
    setImgSrc(src);
    openFullscreen(); // Call the function to open the image in full screen
  };

  const handleClose = () => {
    setImgSrc(""); // Clear the image source to hide the full-screen image
    // Attempt to exit fullscreen
    try {
      if (document.fullscreenElement) {
        document.exitFullscreen();
      }
    } catch (error) {
      console.error("Error exiting fullscreen:", error);
    }
  };

  return (
    <div className={styles.maxImages}>
      {["1.52", "2.52", "3.52", "5.52"].map(imgSuffix => (
        <img
          key={imgSuffix}
          src={`https://d1flzashw70bti.cloudfront.net/original/images/selfHelp/max/${imgSuffix}`}
          alt="Thumbnail"
          onClick={() =>
            handleImageClick(
              `https://d1flzashw70bti.cloudfront.net/original/images/selfHelp/max/${imgSuffix}`
            )
          }
        />
      ))}

      {imgSrc && (
        <div className={styles.fullscreen} onClick={handleClose}>
          <div
            className={styles.fullscreenContent}
            onClick={e => e.stopPropagation()}
          >
            <img ref={imgRef} src={imgSrc} alt="Full Screen" />
          </div>
        </div>
      )}
    </div>
  );
};

export default MaxImages;
