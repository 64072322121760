import React, { useState } from "react";
import { Link, Redirect } from "react-router-dom";

const WebHeader = props => {
  const { onLogoClick = null } = props;
  const [logout, updateLogoutStatus] = useState(false);
  function refreshPage() {
    window.location.reload(false);
  }
  if (logout) {
    localStorage.removeItem("idToken");
    return (
      <Redirect
        to={{
          pathname: "/login"
        }}
      />
    );
  }

  return (
    <header>
      <div id="webHeader">
        <div className="webHeaderImageContainer">
          {typeof onLogoClick === "function" ? (
            <span onClick={refreshPage} style={{ cursor: "pointer" }}>
              <img
                src="https://d1flzashw70bti.cloudfront.net/original/images/tracer/header/logo.svg"
                alt="zingbus logo"
              />
            </span>
          ) : (
            <Link to="/">
              <img
                src="https://d1flzashw70bti.cloudfront.net/original/images/tracer/header/logo.svg"
                alt="zingbus logo"
              />
            </Link>
          )}
        </div>
        <div className="webHeaderOptionContainer">
          <span type="link" onClick={() => updateLogoutStatus(true)}>
            Logout
          </span>
        </div>
      </div>
    </header>
  );
};

export default WebHeader;
