import React, { Component } from "react";
import { Form, message } from "antd";
import { Redirect } from "react-router-dom";
import Fetch from "../../utils/fetch";
import cx from "classnames";
import "./login.scss";
import { capchaKey } from "../../config";
import indiaFlag from "../../images/india.svg";
import ReCAPTCHA from "react-google-recaptcha";

const Label = Form.Item;

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      phone: "",
      password: "",
      login: false,
      submitButtonDisableState: false
    };
    this.recaptchaRef = React.createRef();
  }
  references = ["phoneNumber", "password", "login"];

  handleChange = (key, value) => {
    this.setState({ [key]: value });
  };
  handleKeyPress = e => {
    if (e.key === "Enter") {
      const name = e.target.name;
      const index = this.references.indexOf(name) + 1;
      document.getElementById(this.references[index]).focus();
    }
  };

  handleSubmit = async () => {
    this.recaptchaRef.current.execute();
  };

  handleCaptchaChange = async value => {
    if (value) {
      this.setState({ captchaValue: value });
    } else {
      message.error("ReCAPTCHA verification failed");
    }
    const path = `/user/login`;
    const { phone, password } = this.state;
    this.setState({ submitButtonDisableState: true });
    const options = {
      method: "post",
      data: {
        mobileNo: phone,
        captchaValue: value,
        password
      }
    };

    if (!password || !phone) {
      return message.error("Password or Mobile Number fields are required");
    }
    const response = await Fetch(path, options);
    if (response) {
      this.setState({ submitButtonDisableState: false });
      localStorage.setItem("idToken", response.idToken);
      this.setState({ login: true });
    } else {
      this.setState({ submitButtonDisableState: false });
    }
  };

  render() {
    const { phone, password, login } = this.state;

    if (login) {
      const { location } = this.props;

      if (location.state) {
        const { from } = location.state;
        return <Redirect to={from} />;
      } else {
        return <Redirect to="/" />;
      }
    }

    return (
      <div className="loginDiv">
        <div className="mainBody">
          <div className="container">
            <div className={cx("row")}>
              <div
                className={cx(
                  "col-md-6",
                  "col-12",
                  "loginBlock",
                  "text-center"
                )}
              >
                <h1 className="mb-5 text-left">Login</h1>
                <div>
                  <Label label="Phone Number">
                    <div className="input">
                      <img src={indiaFlag} alt="india-phone" />
                      <span className="bold">+91</span>
                      <input
                        type="number"
                        name="phoneNumber"
                        id="phoneNumber"
                        placeholder="Phone Number"
                        value={phone}
                        onKeyPress={e => this.handleKeyPress(e)}
                        onChange={e => {
                          if (e.target.value.length < 11) {
                            this.handleChange("phone", e.target.value);
                          }
                        }}
                      />
                    </div>
                  </Label>
                  <Label>
                    <input
                      placeholder="Enter Password"
                      type="password"
                      onKeyPress={e => this.handleKeyPress(e)}
                      name="password"
                      id="password"
                      value={password}
                      onChange={e =>
                        this.handleChange("password", e.target.value)
                      }
                    />
                  </Label>
                  <button
                    className="btn btn-primary w-100 mt-4"
                    type="primary"
                    name="login"
                    id="login"
                    onClick={this.handleSubmit}
                    disabled={this.state.submitButtonDisableState}
                  >
                    Submit
                  </button>
                </div>
              </div>
              <div
                className={cx("col-6", "d-none", "d-md-block", "bgLogin")}
              ></div>
            </div>
          </div>
        </div>
        <ReCAPTCHA
          ref={this.recaptchaRef}
          sitekey={capchaKey}
          onChange={this.handleCaptchaChange}
          size="invisible"
        />
      </div>
    );
  }
}

export default Login;
