import React, { Component } from "react";
import { Button, Input, Select, Modal, Table, message } from "antd";
import Fetch from "../../utils/fetch";
import { assuredRefundId } from "../../config";
import jwt from "jsonwebtoken";

const { TextArea } = Input;
const { Option } = Select;

const columns = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    width: "2%"
  },
  {
    title: "Mobile Number",
    dataIndex: "mobileNo",
    key: "mobileNo",
    width: "2%"
  },
  {
    title: "PNR",
    dataIndex: "pnr",
    key: "pnr",
    width: "2%"
  },
  {
    title: "BookingAmount",
    dataIndex: "amountToBeCollected",
    key: "amountToBeCollected",
    width: "2%"
  },
  {
    title: "Refund Amount",
    dataIndex: "refund",
    key: "refund",
    width: "2%"
  }
];

class SendRefund extends Component {
  state = {
    showRefundOption: false,
    customMessage: "",
    selectedRefundBookings: []
  };

  handleChange = (key, value) => {
    this.setState({ [key]: value });
  };

  subIssue = async issueId => {
    this.setState({
      subIssueId: "",
      issueComment: "",
      categorys: "",
      selectedRefundBookingsAmount: "",
      subIssue: ""
    });
    const subIssueUrl = `/bus-tracker/customerSubIssue/${issueId}`;
    const options = {
      method: "get"
    };
    let subIssueResponse = await Fetch(subIssueUrl, options);
    this.setState({ subIssue: subIssueResponse, issueId: issueId });
  };
  handleChangeInput = event => {
    const { value, maxLength } = event.target;
    const message = value.slice(0, maxLength);
    this.setState({ userInput: parseInt(message) });
    if (value.length <= 0) {
      this.setState({
        selectedRefundBookingsAmount: []
      });
    } else {
      this.updateTable(this.state.selectedCategory[0]._id, value);
    }
  };

  fetchCategoryName = async subIssueId => {
    const categoryUrl = `/bus-tracker/customerRefundCategory/${subIssueId}`;
    const options = {
      method: "get"
    };
    let categoryResponse = await Fetch(categoryUrl, options);

    this.setState({ categorys: categoryResponse, subIssueId: subIssueId });
  };

  updateTable = (categorySelectedId, userInput) => {
    let { selectedBookings, isSearchForm } = this.props;
    let { categorys } = this.state;
    let refundAmount = {},
      selectedRefundBookingsAmount = [];

    if (categorys && categorySelectedId) {
      refundAmount = categorys.filter(obj => obj._id == categorySelectedId);
    }

    for (let i = 0; i < selectedBookings.length; ++i) {
      const booking = selectedBookings[i];
      let paidAmount = booking.booking
        ? booking.booking.paidAmount
        : booking.cargoBooking.paidAmount;
      let refundAlreadyDone = false;
      let assuredTripRefundGiven = false;

      for (let i = 0; i < booking.refunds.data.length; i++) {
        let data = booking.refunds.data[i];
        if (data.status == "COMPLETED") {
          refundAlreadyDone = true;
        }
        if (data.booking.refund && data.booking.refund.assuredTripRefundId) {
          assuredTripRefundGiven = true;
        }
      }
      if (refundAmount[0]._id !== assuredRefundId) {
        assuredTripRefundGiven = false;
      }
      if (isSearchForm) {
        const bookingObj = {
          key: booking._id,
          name: booking.name.value,
          mobileNo: booking.mobileNo.value,
          pnr: booking.bookingCode,
          amountToBeCollected: paidAmount,
          refund:
            (booking.refunds.data.length > 0 && refundAlreadyDone) ||
            assuredTripRefundGiven
              ? "Refund Already Done"
              : refundAmount[0].type == "PERCENTAGE"
              ? Math.abs((paidAmount * refundAmount[0].value) / 100)
              : refundAmount[0].type == "ZINGCASH"
              ? refundAmount[0].value + "   (ZINGCASH)"
              : refundAmount[0].type == "ABSOLUTE"
              ? Math.abs(refundAmount[0].value)
              : refundAmount[0].type == "USERINPUT"
              ? Math.abs(userInput)
              : null
        };
        selectedRefundBookingsAmount.push(bookingObj);
      } else {
        if (booking.status === "CONFIRMED") {
          let paidAmount = booking.booking
            ? booking.booking.paidAmount
            : booking.cargoBooking.paidAmount;

          const bookingObj = {
            key: booking._id,
            name: booking.name ? booking.name : booking.sender_name,
            mobileNo: booking.sender_name
              ? booking.sender_mobileNo.value
              : booking.mobileNo.value,
            pnr: booking.pnr,
            amountToBeCollected: booking.amountToBeCollected.value,
            refund:
              booking.refund && booking.refund.length > 0
                ? "Refund Already Done"
                : refundAmount[0].type == "PERCENTAGE"
                ? Math.abs((paidAmount * refundAmount[0].value) / 100)
                : refundAmount[0].type == "ZINGCASH"
                ? refundAmount[0].value + "   (ZINGCASH)"
                : refundAmount[0].type == "ABSOLUTE"
                ? Math.abs(paidAmount - refundAmount[0].value)
                : refundAmount[0].type == "USERINPUT"
                ? Math.abs(paidAmount - userInput)
                : null
          };

          selectedRefundBookingsAmount.push(bookingObj);
        }
      }
    }
    this.setState({
      loading: false,
      selectedCategory: refundAmount,
      selectedRefundBookingsAmount
    });
  };

  toggleMessageOption = () => {
    let { selectedCount, selectedBookings } = this.props;
    const selectedRefundBookings = [];
    if (!selectedBookings) {
      return message.error("Please select booking");
    }
    if (!selectedCount) {
      selectedCount = selectedBookings.length;
    }

    const { showRefundOption } = this.state;

    if (!showRefundOption && !selectedCount) {
      return message.error("Please select booking or station");
    }
    for (let i = 0; i < selectedBookings.length; ++i) {
      const booking = selectedBookings[i];
      if (booking.status === "CONFIRMED") {
        const bookingObj = {
          key: booking._id,
          name: booking.name,
          mobileNo: booking.sender_name
            ? booking.sender_mobileNo
            : booking.mobileNo.value,
          pnr: booking.pnr ? booking.pnr : booking.pnr,
          amountToBeCollected: booking.amountToBeCollected.value
        };
        selectedRefundBookings.push(bookingObj);
      }
    }
    this.setState({
      loading: false,
      selectedRefundBookings
    });

    this.setState({
      subIssue: "",
      categorys: "",
      selectedRefundBookingsAmount: "",
      showRefundOption: !showRefundOption,
      selectedRefundBookings
    });
  };

  handleSendClick = () => {
    const {
      selectedCategory,
      subIssueId,
      issueId,
      categorys,
      issueComment,
      userInput
    } = this.state;

    const { onClick } = this.props;

    const token = localStorage.getItem("idToken");
    const decodedToken = jwt.decode(token);
    const checkPrivileges =
      decodedToken.privileges.includes("admin") &&
      decodedToken.privileges.includes("experience_sm") &&
      decodedToken.privileges.includes("experience_m");

    if (selectedCategory[0].categoryName == "10X Refund" && !checkPrivileges) {
      message.error("Sorry! You Are Not Authorised");
      return;
    }

    if (selectedCategory[0].type == "USERINPUT" && !userInput) {
      message.error("please enter the amount");
      return;
    }

    if (selectedCategory[0].categoryName == "10X Refund" && userInput > 1000) {
      message.error("Amount cannot be greater than 1000");
      return;
    }

    onClick(
      selectedCategory,
      subIssueId,
      issueId,
      categorys,
      issueComment,
      userInput
    );

    this.setState({
      showRefundOption: false,
      subIssueId: "",
      issueId: "",
      selectedCategory: "",
      issueComment: "",
      userInput: ""
    });
  };

  render() {
    const {
      showRefundOption,
      selectedRefundBookingsAmount,
      subIssue,
      categorys,
      selectedCategory
    } = this.state;
    const { issues } = this.props;

    return (
      <div>
        {showRefundOption ? (
          <Modal
            centered
            width={1500}
            title="Send Refund"
            footer={null}
            visible={true}
            onCancel={() =>
              this.setState({
                showRefundOption: false,
                subIssueId: "",
                issueId: "",
                selectedCategory: "",
                issueComment: "",
                userInput: "",
                subIssue: "",
                categorys: "",
                selectedRefundBookingsAmount: ""
              })
            }
          >
            <div className="row">
              <div className="col-3">Select Issue Name</div>
              <div className="col-6 text-left">
                <Select
                  className="mb-1"
                  style={{ width: "200px" }}
                  defaultValue=" "
                  onChange={key => this.subIssue(key)}
                >
                  {issues.map(issue => (
                    <Option value={issue._id} key={issue._id}>
                      {issue.name}
                    </Option>
                  ))}
                </Select>
              </div>
            </div>
            {Array.isArray(subIssue) && subIssue.length > 0 ? (
              <div className="row">
                <div className="col-3">Select Sub Issue Name</div>
                <div className="col-6 text-left">
                  <Select
                    className="mb-1"
                    style={{ width: "200px" }}
                    defaultValue=" "
                    onChange={value => this.fetchCategoryName(value)}
                  >
                    {subIssue.map(subis => (
                      <Option value={subis._id} key={subis._id}>
                        {subis.name}
                      </Option>
                    ))}
                  </Select>
                </div>
              </div>
            ) : null}
            {Array.isArray(categorys) && categorys.length > 0 ? (
              <div className="row">
                <div className="col-3">Select Category Name</div>
                <div className="col-6 text-left">
                  <Select
                    className="mb-1"
                    style={{ width: "200px" }}
                    defaultValue=" "
                    onChange={value => this.updateTable(value)}
                  >
                    {categorys.map(category => (
                      <Option value={category._id} key={category._id}>
                        {category.categoryName}
                      </Option>
                    ))}
                  </Select>
                </div>
              </div>
            ) : null}

            {Array.isArray(selectedCategory) &&
            selectedCategory.length > 0 &&
            selectedCategory[0].type == "USERINPUT" ? (
              <div className="row">
                <div className="col-3">Enter Amount</div>
                <div className="col-6 text-left">
                  {" "}
                  <Input
                    className="mb-1"
                    type="number"
                    maxLength="5"
                    style={{ width: "200px" }}
                    onChange={this.handleChangeInput}
                  />
                </div>
              </div>
            ) : null}
            {Array.isArray(selectedRefundBookingsAmount) &&
            selectedRefundBookingsAmount.length > 0 ? (
              <>
                {" "}
                <div className="row">
                  <div className="col-3">Comments</div>
                  <div className="col-6 text-left">
                    {" "}
                    <TextArea
                      className="mb-1"
                      autoSize={{ minRows: 1, maxRows: 2 }}
                      // maxLength={30}
                      style={{ width: "100%" }}
                      onChange={e =>
                        this.handleChange("issueComment", e.target.value)
                      }
                    />
                  </div>
                </div>
                <Table
                  columns={columns}
                  dataSource={selectedRefundBookingsAmount}
                  pagination={false}
                />
                <Button
                  type="primary"
                  onClick={this.handleSendClick}
                  style={{ margin: "1% 0" }}
                >
                  Confirm Refund
                </Button>
              </>
            ) : null}
          </Modal>
        ) : (
          <Button type="primary" onClick={this.toggleMessageOption}>
            Send Refund
          </Button>
        )}
      </div>
    );
  }
}

export default SendRefund;
