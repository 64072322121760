import React, { Component } from "react";
import { Tag, Table, Button, Modal, message } from "antd";
import { Link } from "react-router-dom";
import io from "socket.io-client";

import Loader from "../loader";
import Fetch from "../../utils/fetch";
import SearchBar from "../../components/gdsSearch";
import SendMessage from "../gdsMessages";
import SendCoupon from "../../components/sendCoupon";

import { getSeatNumberFromTripChart } from "../../utils/common";
import { socketURL } from "../../config";

import moment from "moment";
import { PlayCircleOutlined } from "@ant-design/icons";
const wordBreakStyle = { wordBreak: "breakAll" };

const columns = [
  {
    title: "Service Name",
    dataIndex: "serviceName",
    key: "serviceName",
    width: "10%",
    defaultSortOrder: "descend",
    render: ({ name, tripId, stationtime, isTripAssigned }) => {
      if (isTripAssigned) {
        return (
          <>
            <Link to={`/gdstrack/${tripId}`} target="_blank">
              {name}
            </Link>
            <br></br>
            {stationtime ? moment(stationtime).format("MMM Do") : null}
            <br />
          </>
        );
      } else {
        return (
          <>
            {name}
            <br></br>
            {stationtime ? moment(stationtime).format("MMM Do") : null}
            <br />
          </>
        );
      }
    }
  },
  {
    title: "Operator Name",
    dataIndex: "operatorName",
    key: "operatorName",
    width: "10%"
  },
  {
    title: "Booking List",
    dataIndex: "bookings",
    width: "10%",
    key: "bookings",
    render: ({ tripId, onClick }) => (
      <Button type={"default"} onClick={() => onClick(tripId)}>
        <span>{`Bookings`}</span>
      </Button>
    )
  },
  {
    title: "Crew Details",
    dataIndex: "crewDetails",
    width: "10%",
    key: "crewDetails",
    render: ({ onClick, isAvailable, name }) => {
      if (!isAvailable) {
        return <Button>Not Assigned</Button>;
      } else {
        return <Button onClick={() => onClick(name)}>Show Details</Button>;
      }
    }
  },
  {
    title: "Last Updated at",
    dataIndex: "lastUpdatedAt",
    key: "lastUpdatedAt",
    width: "10%",
    sorter: (a, b) => a.lastUpdatedAt.lastUpdated - b.lastUpdatedAt.lastUpdated,
    render: ({ lastUpdated }) => {
      if (lastUpdated) {
        return <span>{moment(lastUpdated).calendar()}</span>;
      }
      {
        return <Tag color="red">No Data Found</Tag>;
      }
    }
  }
];

const bookingcolumns = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    render: ({ value }) => <span style={wordBreakStyle}>{value}&ensp;</span>
  },
  {
    title: "Mobile Number",
    dataIndex: "mobileNo",
    key: "mobileNo",
    render: ({ value }) => <span style={wordBreakStyle}>{value}</span>
  },
  {
    title: "PNR",
    dataIndex: "bookingCode",
    key: "bookingCode"
  },
  {
    title: "Seats",
    dataIndex: "seats",
    key: "seats"
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status"
  },
  {
    title: "From Station",
    dataIndex: "fromStation",
    key: "fromStation",
    render: ({ name, date }) => (
      <>
        <span style={wordBreakStyle}>{name}</span>
        <br></br>
        <span>{date}</span>
      </>
    )
  },
  {
    title: "To Station",
    dataIndex: "toStation",
    key: "toStation",
    render: ({ name, date }) => (
      <>
        <span style={wordBreakStyle}>{name}</span>
        <br></br>
        <span>{date}</span>
      </>
    )
  },
  {
    title: "Call attempted",
    dataIndex: "callAttempted",
    key: "callAttempted",
    width: "8%"
  },
  {
    title: "Call records",
    dataIndex: "callRecords",
    key: "callRecords",
    width: "8%",
    render: ({ data = [] }) => {
      if (data.length) {
        return data.map((call, index) => (
          <div key={index} className="mb-1">
            {call.recordFile ? (
              <>
                <a
                  href={call.recordFile}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <PlayCircleOutlined />
                </a>
                <br></br>

                {!call.isOutgoing ? (
                  <div>
                    {"I-"}
                    {moment(call.createdOn).format("lll")}
                    <br />
                    {call.totalDuration}
                    {call.isCalltoAgent ? "(A)" : "(M)"}
                  </div>
                ) : (
                  <div>
                    {"O-"}
                    {moment(call.createdOn).format("lll")}
                    <br />
                    {call.totalDuration}
                  </div>
                )}
              </>
            ) : (
              <div>
                <Tag>{call.status}</Tag>
                <br></br>
                <Tag>{call.date}</Tag>
              </div>
            )}
          </div>
        ));
      } else {
        return <Tag color="red">No Call Records</Tag>;
      }
    }
  },
  {
    title: "Action",
    dataIndex: "action",
    key: "action",
    render: ({ gdsIdTrip, customerTrackingLink, copyCustomerTrackingLink }) => {
      return (
        <>
          <Link to={`/gdstrack/${gdsIdTrip}`} target="_blank">
            <Button className="mb-1">Track Bus</Button>
          </Link>
          <a href={customerTrackingLink} target="_blank" rel="noreferrer">
            <Button className="mb-1">Customer Link</Button>
          </a>
          <Button onClick={copyCustomerTrackingLink}>
            Share Customer Link
          </Button>
        </>
      );
    }
  }
];

class Gds extends Component {
  state = {
    contentLoading: false,
    showCallLogs: false,
    loading: true,
    showCrewModal: false,
    selectedRowKeys: [],
    selectedBookings: [],
    cities: []
  };

  async componentDidMount() {
    try {
      const socket = io(socketURL, {
        withCredentials: true
      });
      const trips = await this.fetchTrips();
      const cities = await this.fetchCities();
      socket.on("LastOnTripData", this.handleLastOnTripData);

      this.setState({ trips, loading: false, cities });
    } catch (err) {
      console.log(err);
      this.setState({ loading: false });
    }
  }

  fetchCities = async () => {
    try {
      const url = `/bus-tracker/fetchCities`;

      const options = {
        method: "get"
      };

      const response = await Fetch(url, options);
      return response;
    } catch (err) {
      message.error(err);
    }
  };

  fetchTrips = async () => {
    try {
      const url = `/gds/trip/`;
      const options = {
        method: "get"
      };
      const response = await Fetch(url, options);

      if (response && Array.isArray(response) && response.length > 0) {
        const formattedData = this.formattedData(response);
        return formattedData;
      }
      return null;
    } catch (err) {
      console.log(err);
      message.error(err);
    }
  };

  fetchParticularTrip = async obj => {
    this.setState({ tripLoading: true });
    const { fromCity, toCity, date } = obj;
    let url = `/gds/trip/getParticularTrip?date=${moment(date).format(
      "YYYY-MM-DD"
    )}`;
    if ((fromCity && !toCity) || (!fromCity && toCity)) {
      message.error("please select both from and to");
      return;
    }
    if (fromCity && toCity) {
      url += `&fromCity=${fromCity}&toCity=${toCity}`;
    }

    const options = {
      method: "get"
    };

    let response = await Fetch(url, options);

    if (Array.isArray(response) && response.length > 0) {
      const trip = this.formattedData(response);
      this.setState({ tripLoading: false, trips: trip });
    } else {
      message.error("No Data found");
      this.setState({ tripLoading: false });
    }
  };

  formattedData = response => {
    try {
      return response.map(trip => {
        const { lastUpdated = 0, isTripAssigned = false } = trip;
        const formatted = {
          _id: trip._id,
          operatorName:
            trip.gdsOperatorService && trip.gdsOperatorService.operatorName
              ? trip.gdsOperatorService.operatorName
              : "",
          serviceName: {
            name:
              trip.gdsOperatorService && trip.gdsOperatorService.name
                ? trip.gdsOperatorService.name
                : "",
            isTripAssigned,
            tripId: trip._id,
            stationtime: trip.gdsOperatorService ? trip.startDate : null
          },
          bookings: {
            tripId: trip._id,
            onClick: this.showBookingModal
          },
          crewDetails: {
            isAvailable: trip.busAssignmentDetails,
            onClick: this.showCrewDetails,
            name: trip.busAssignmentDetails ? trip.busAssignmentDetails : false
          },
          lastUpdatedAt: {
            lastUpdated
          }
        };
        return formatted;
      });
    } catch (error) {
      console.log(error);
    }
  };

  showCrewDetails = data => {
    this.setState({ crewDetailsData: data, showCrewModal: true });
  };

  showBookingModal = async tripId => {
    try {
      let url = `/gds/booking/getParticularBooking?tripId=${tripId}`;
      const options = {
        method: "get"
      };
      let response = await Fetch(url, options);

      if (Array.isArray(response) && response.length > 0) {
        const bookingR = [];

        for (let i = 0; i < response.length; ++i) {
          const booking = response[i];

          let seatsLabel = "";
          for (let i = 0; i < booking.tripChart.length; ++i) {
            for (let i = 0; i < booking.tripChart.length; ++i) {
              const seat = booking.tripChart[i];

              if (seat.status === "CONFIRMED") {
                const seatNumber = seat.seatLabel;
                const seatGender = seat.gender ? seat.gender : "";

                if (seatsLabel.length === 0) {
                  seatsLabel += `${seatNumber}(${seatGender})`;
                } else {
                  seatsLabel += `,${seatNumber}(${seatGender})`;
                }
              }
            }
            break;
          }
          const bookingObj = {
            key: booking._id,
            name: {
              value: booking.name,
              copyCustomerDetails: () =>
                this.copyCustomerDetails(
                  booking.bookingCode,
                  booking.mobileNo,
                  seatsLabel,
                  booking.fromCity.name,
                  booking.fromStationName,
                  booking.fromStationDate,
                  booking.toCity.name,
                  booking.toStationAddress,
                  booking.trackingUrl,
                  booking.trip.bus
                )
            },
            mobileNo: {
              value: booking.mobileNo,
              onChange: () =>
                this.setState({
                  editField: "mobileNo",
                  editBooking: true,
                  bookingToEdit: booking
                })
            },
            bookingCode: booking.bookingCode,
            seatChart: seatsLabel,
            fromStation: {
              name: `${booking.fromCity.name}, ${booking.fromStationName}`,
              date: moment(booking.fromStationDate).format("lll")
            },
            seats: getSeatNumberFromTripChart(booking.tripChart),
            toStation: {
              name: `${booking.toCity.name}, ${booking.toStationAddress}`,
              date: moment(booking.toStationDate).format("lll")
            },
            callAttempted: booking.callDetails ? booking.callDetails.length : 0,
            isCreditCouponSent: booking.isCreditCouponSent,
            creditCouponCode: booking.creditCouponCode,
            callRecords: {
              data: booking.callDetails
            },
            messageSent: booking.messageSent,
            // remark: booking.remark,
            status: booking.status,
            action: {
              gdsIdTrip: booking.gdsOperatorTrip._id,
              customerTrackingLink: `http://${booking.trackingUrl}`,
              copyCustomerTrackingLink: () =>
                this.copyCustomerTrackingLink(booking.trackingUrl)
            },
            trackingUrl: booking.trackingUrl,
            booking,
            actionHistory: booking.actionHistory || "",
            remark: {
              value: booking.remark,
              onChange: () =>
                this.setState({
                  editField: "remark",
                  editBooking: true,
                  bookingToEdit: booking
                })
            },
            _id: booking._id
          };

          bookingR.push(bookingObj);
        }
        this.setState({
          showBookingDetails: true,
          bookings: bookingR
        });
      } else {
        message.error("No Active Booking found");
        return;
      }
    } catch (err) {
      console.log(err);
    }
  };

  handleChange = (key, value) => {
    if (key == "showBookingDetails") {
      this.setState({ bookings: [] });
    }
    if (key == "showAllCustomerCall") {
      this.setState({ afftectedBooking: [] });
    }
    if (key == "showCrewModal") {
      this.setState({ crewDetailsData: undefined, showCrewModal: false });
    }
    this.setState({ [key]: value });
  };

  copyCustomerTrackingLink = customerTrackingLink => {
    navigator.clipboard.writeText(customerTrackingLink);
    message.success("Customer Link copied to clipboard");
  };

  handleLastOnTripData = async data => {
    try {
      const socketDataMap = {};
      for (let i = 0; i < data.length; ++i) {
        const tripRecentData = data[i];

        socketDataMap[tripRecentData.tripId] = tripRecentData;
      }

      const { trips } = this.state;

      const updatedTrips = trips.map(originalTrip => {
        const { tripId } = originalTrip;
        const lastTripData = socketDataMap[tripId];

        if (lastTripData) {
          const { latlngDate } = lastTripData;
          const updatedTrip = {
            ...originalTrip,
            lastUpdatedAt: {
              ...originalTrip.lastUpdatedAt,
              lastUpdated: parseInt(latlngDate)
            }
          };
          return updatedTrip;
        }

        return originalTrip;
      });

      this.setState({ trips: updatedTrips });
    } catch (err) {
      console.log(err);
    }
  };

  showLoading = () => {
    this.setState({ contentLoading: true });
  };

  sendMessage = async (
    templateType,
    vars,
    smsVariables,
    sendSMSMessage,
    sendWhatsAppMessage
  ) => {
    try {
      const { selectedBookings } = this.state;
      if (selectedBookings.length) {
        const url = `/gds/booking/messages`;

        let messageSelectedBooking = selectedBookings.map(b => {
          return {
            mobileNo: b.booking.mobileNo
          };
        });
        const options = {
          method: "post",
          data: {
            bookings: messageSelectedBooking,
            templateType,
            vars,
            smsVariables,
            sendSMSMessage,
            sendWhatsAppMessage
          }
        };

        const response = await Fetch(url, options);

        if (response) {
          message.success("Message sent successfully");
          this.setState({ selectedBookings: [], selectedRowKeys: [] });
        }
      } else {
        message.error("No Booking Selected");
      }
    } catch (err) {
      message.error(err);
    }
  };

  sendCoupon = async _id => {
    try {
      const { selectedBookings } = this.state;

      if (selectedBookings.length) {
        const url = `/booking/sendCoupon`;
        let booking = [];
        if (selectedBookings.length) {
          booking = selectedBookings.map(obj => {
            return obj.bookingCode;
          });
        }
        const options = {
          method: "post",
          data: {
            ruleId: _id,
            pnrArr: booking
          }
        };
        const response = await Fetch(url, options);

        if (response == "Credit Coupon sent Successfully") {
          message.success("Credit Coupon sent Successfully");
          this.setState({ selectedBookings: [], selectedRowKeys: [] });
        } else {
          message.error("Coupon not sent");
          this.setState({ selectedBookings: [], selectedRowKeys: [] });
        }
      } else {
        message.error("No Booking Selected");
      }
    } catch (err) {
      message.error(err);
    }
  };

  render() {
    const {
      loading,
      trips,
      showBookingDetails,
      crewDetailsData,
      showCrewModal,
      bookings,
      tripLoading,
      selectedRowKeys,
      selectedBookings,
      cities
    } = this.state;

    if (loading) {
      return <Loader />;
    } else {
      return (
        <div>
          <div>
            <SearchBar
              showLoader={this.showLoading}
              onSearch={this.fetchParticularTrip}
              cities={cities}
            />
          </div>
          <div
            style={{
              overflow: "hidden",
              width: "100%",
              height: "100vh"
            }}
          >
            <Table
              scroll={{ y: "70vh" }}
              columns={columns}
              loading={tripLoading}
              pagination={false}
              dataSource={trips}
            />
          </div>
          <Modal
            centered
            width={"100%"}
            title="Booking List"
            visible={showBookingDetails}
            cancelButtonProps={{ style: { display: "none" } }}
            onCancel={() => this.handleChange("showBookingDetails", false)}
            footer={null}
          >
            <div className="bookingListFooter">
              <div className="bookingListFooterItemOne">
                <SendMessage
                  selectedBookings={selectedBookings}
                  onClick={this.sendMessage}
                  selectedCount={selectedBookings.length}
                />
              </div>
              <div className="bookingListFooterItemOne">
                <SendCoupon
                  isgdsBooking={true}
                  selectedBookings={selectedBookings}
                  onClick={this.sendCoupon}
                  selectedCount={selectedBookings.length}
                />
              </div>
            </div>
            <Table
              columns={bookingcolumns}
              dataSource={bookings}
              pagination={false}
              scroll={{ y: 300 }}
              rowSelection={{
                selectedRowKeys,
                onChange: (keys, booking) => {
                  this.setState({
                    selectedBookings: booking,
                    selectedRowKeys: keys
                  });
                }
              }}
            />
          </Modal>
          {showCrewModal ? (
            <Modal
              centered
              width={"50%"}
              title="Crew Details"
              visible={showCrewModal}
              cancelButtonProps={{ style: { display: "none" } }}
              onCancel={() => this.handleChange("showCrewModal", false)}
              footer={null}
            >
              <div>
                <h5>Bus Driver</h5>
                <p>Driver Name: {crewDetailsData.driverName}</p>
                <p>Driver Number: {crewDetailsData.driverPhoneNumber}</p>
              </div>
              <div>
                <h5>Bus Host</h5>
                <p>BusHost Name: {crewDetailsData.busHostName}</p>
                <p>BusHost Number: {crewDetailsData.busHostPhoneNumber}</p>
              </div>
              <div>
                <h5>Bus</h5>
                <div>Bus Rc: {crewDetailsData.busRc}</div>
              </div>
            </Modal>
          ) : null}
        </div>
      );
    }
  }
}

export default Gds;
