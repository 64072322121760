import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Modal, Tabs, Table, Button, message, Tag, Select } from "antd";
import { EditOutlined } from "@ant-design/icons";
import moment from "moment";
import Fetch from "../../utils/fetch";
import SendMessage from "../sendMessage";
import SendRefund from "../sendRefund";
import SendCoupon from "../sendCoupon";

import SendSelfHelpMessage from "../SendSelfHelpMessage";
import EditBooking from "../editBooking";
import ShowImages from "../showImages";
import {
  getAllStationBeforeUpcomingStation,
  getHoursAndMinFromMillis
} from "../../utils/common";
import { CSVLink } from "react-csv";

const wordBreakStyle = { wordBreak: "breakAll" };
const { Option } = Select;

const { TabPane } = Tabs;
const fromStationName = [];
const toStationName = [];

const columns = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    width: "20%",
    render: data => <div style={wordBreakStyle}>{data}</div>
  },
  {
    title: "Mobile Number",
    dataIndex: "mobileNo",
    key: "mobileNo",
    width: "20%",
    render: ({ value, onChange }) => (
      <>
        <EditOutlined onClick={onChange} />
        <span style={wordBreakStyle}>{value}</span>
      </>
    )
  },
  {
    title: "PNR",
    dataIndex: "pnr",
    key: "pnr",
    width: "20%"
  },
  {
    title: "Seats",
    dataIndex: "seats",
    key: "seats",
    width: "20%",
    render: data => <div style={wordBreakStyle}>{data}</div>
  },
  {
    title: "From Station",
    dataIndex: "fromStation",
    key: "fromStation",
    width: "20%",
    filters: fromStationName,
    onFilter: (value, record) =>
      record.booking.fromStation.name.includes(value),
    render: ({ name, onChange, date }) => (
      <>
        <EditOutlined onClick={onChange} />{" "}
        <span style={wordBreakStyle}>{name}</span>
        <br />
        <span>{date}</span>
      </>
    )
  },
  {
    title: "To Station",
    dataIndex: "toStation",
    key: "toStation",
    width: "20%",
    filters: toStationName,
    onFilter: (value, record) => record.booking.toStation.name.includes(value),
    render: ({ name, onChange, date }) => (
      <>
        <EditOutlined onClick={onChange} />{" "}
        <span style={wordBreakStyle}>{name}</span>
        <br />
        <span>{date}</span>
      </>
    )
  },
  {
    title: "Demand Source",
    dataIndex: "demandSource",
    key: "demandSource",
    width: "20%"
  },
  {
    title: "Action",
    dataIndex: "action",
    key: "action",
    width: "20%",
    render: ({
      tripId,
      customerTrackingLink,
      copyCustomerTrackingLink,
      showModal
    }) => {
      return (
        <>
          <Link to={`/track/${tripId}`} target="_blank">
            <Button className="mb-1">Track Bus</Button>
          </Link>
          <a href={customerTrackingLink} rel="noreferrer" target="_blank">
            <Button className="mb-1">Customer Link</Button>
          </a>
          <Button onClick={copyCustomerTrackingLink}>
            Share Customer Link
          </Button>
          <Button onClick={showModal}>Show more Details</Button>
        </>
      );
    }
  }
];
const cargoColumns = [
  {
    title: "Sender Name",
    dataIndex: "sender_name",
    key: "sender_name",
    width: "8%",
    render: data => <div style={wordBreakStyle}>{data}</div>
  },
  {
    title: "Sender Mobile Number",
    dataIndex: "sender_mobileNo",
    key: "sender_mobileNo",
    width: "8%",
    render: ({ value, onChange }) => (
      <>
        <EditOutlined onClick={onChange} />
        <span style={wordBreakStyle}>{value}</span>
      </>
    )
  },
  {
    title: "Receiver Name",
    dataIndex: "receiver_name",
    key: "receiver_name",
    width: "8%",
    render: data => <div style={wordBreakStyle}>{data}</div>
  },
  {
    title: "Receiver Mobile Number",
    dataIndex: "receiver_mobileNo",
    key: "receiver_mobileNo",
    width: "8%",
    render: ({ value, onChange }) => (
      <>
        <EditOutlined onClick={onChange} />
        <span style={wordBreakStyle}>{value}</span>
      </>
    )
  },
  {
    title: "PNR",
    dataIndex: "pnr",
    key: "pnr",
    width: "8%"
  },
  {
    title: "Package Count",
    dataIndex: "packageCount",
    key: "packageCount",
    width: "8%",
    render: data => <div style={wordBreakStyle}>{data}</div>
  },
  {
    title: "From Station",
    dataIndex: "fromStation",
    key: "fromStation",
    width: "8%",
    render: ({ name, onChange }) => (
      <>
        <EditOutlined onClick={onChange} />{" "}
        <span style={wordBreakStyle}>{name}</span>
      </>
    )
  },
  {
    title: "To Station",
    dataIndex: "toStation",
    key: "toStation",
    width: "8%",
    render: ({ name, onChange }) => (
      <>
        <EditOutlined onClick={onChange} />{" "}
        <span style={wordBreakStyle}>{name}</span>
      </>
    )
  },
  {
    title: "Cash Collection",
    dataIndex: "amountToBeCollected",
    key: "amountToBeCollected",
    width: "8%",
    render: ({ value, onChange }) => (
      <>
        <EditOutlined onClick={onChange} />{" "}
        <span style={wordBreakStyle}>{value}</span>
      </>
    )
  },

  {
    title: "E waybill Number",
    dataIndex: "ewayBill",
    key: "ewayBill",
    width: "8%"
  },
  {
    title: "Remark",
    dataIndex: "remark",
    key: "remark",
    width: "8%",
    render: ({ value = "", onChange }) => {
      if (!value) {
        value = "";
      }
      value = value.split("|");

      let remarkStr = "";

      for (let i = 0; i < value.length; ++i) {
        remarkStr += value[i];
      }

      return (
        <>
          <EditOutlined onClick={onChange} />
          {remarkStr.length ? (
            <span style={wordBreakStyle}>{remarkStr}</span>
          ) : (
            <span>No Remark</span>
          )}
        </>
      );
    }
  },
  {
    title: "Action History",
    dataIndex: "actionHistory",
    key: "actionHistory",
    width: "8%"
  },
  {
    title: "Action",
    dataIndex: "action",
    key: "action",
    width: "8%",
    render: ({ tripId, customerTrackingLink, copyCustomerTrackingLink }) => {
      return (
        <>
          <Link to={`/track/${tripId}`} target="_blank">
            <Button className="mb-1">Track Bus</Button>
          </Link>
          <a
            href={customerTrackingLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button className="mb-1">Customer Link</Button>
          </a>
          <Button onClick={copyCustomerTrackingLink}>
            Share Customer Link
          </Button>
        </>
      );
    }
  },
  {
    title: "Image",
    dataIndex: "image",
    key: "image",
    width: "8%",
    render: ({ onChange }) => {
      return <Button onClick={onChange}>View Loaded Image</Button>;
    }
  }
];
const moreDetailsColumns = [
  {
    title: "Cash Collection",
    dataIndex: "amountToBeCollected",
    key: "amountToBeCollected",
    width: "8%",
    render: ({ value, agentId, onChange }) =>
      agentId ? (
        <>
          <EditOutlined onClick={onChange} />
          <span style={wordBreakStyle}>{value}</span>
        </>
      ) : (
        <span style={wordBreakStyle}>{value}</span>
      )
  },

  {
    title: "Call attempted",
    dataIndex: "callAttempted",
    key: "callAttempted",
    width: "8%"
  },
  {
    title: "Call records",
    dataIndex: "callRecords",
    key: "callRecords",
    width: "8%",
    render: ({ data = [], from }) => {
      if (data.length) {
        return data.map((call, index) => (
          <div key={index} className="mb-1">
            {call.recording ? (
              <>
                <div>
                  <audio className="audio_tag" src={call.recording} controls />
                </div>
                <br></br>
                {call.callfrom == from ? (
                  <div>
                    {"I-"}
                    {moment(call.date).format("lll")}
                    <br />
                    {call.callDuration}
                  </div>
                ) : (
                  <div>
                    {"O-"}
                    {moment(call.date).format("lll")}
                    <br />
                    {call.callDuration}
                  </div>
                )}
              </>
            ) : (
              <div>
                <Tag>{call.status}</Tag>
                <br></br>
                <Tag>{call.date}</Tag>
              </div>
            )}
          </div>
        ));
      } else {
        return <Tag color="red">No Call Records</Tag>;
      }
    }
  },
  {
    title: "Remark",
    dataIndex: "remark",
    key: "remark",
    width: "8%",
    render: ({ value = "", onChange }) => {
      if (!value) {
        value = "";
      }
      value = value.split("|");

      let remarkStr = "";

      for (let i = 0; i < value.length; ++i) {
        remarkStr += value[i];
      }

      return (
        <>
          <EditOutlined onClick={onChange} />
          {remarkStr.length ? (
            <span style={wordBreakStyle}>{remarkStr}</span>
          ) : (
            <span>No Remark</span>
          )}
        </>
      );
    }
  },
  {
    title: "Not Boarded Reason",
    dataIndex: "notBoarededReason",
    key: "notBoarededReason",
    width: "8%"
  },
  {
    title: "Action History",
    dataIndex: "actionHistory",
    key: "actionHistory",
    width: "8%"
  }
];

function msToTime(duration) {
  let minutes = Math.floor((duration / (1000 * 60)) % 60),
    hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

  hours = hours < 10 ? "0" + hours : hours;
  minutes = minutes < 10 ? "0" + minutes : minutes;
  return hours + " hrs :" + minutes + " min";
}
class BoardingList extends Component {
  state = {
    loading: true,
    bookings: [],
    boardingList: [],
    viewMoreList: [],
    notBoardedList: [],
    allBookingData: [],
    upcomingList: [],
    selectedBookings: [],
    selectedCargos: [],
    selectedRowKeys: [],
    cargoLoadingList: [],
    cargoNotLoadedList: [],
    cargoUpcomingList: [],
    cargoNotUnLoadedList: [],
    cargoUnLoadedList: [],
    activeKey: "1",
    editBooking: false,
    showImages: false,
    bookingToEdit: {},
    bookingEdit: false,
    selectKey: "booking",
    field: "",
    updatedField: "",
    closeBookingList: false,
    trackingWorking: true,
    showMoreDetails: false
  };

  inputRef = React.createRef();

  async componentDidMount() {
    try {
      const { tripId, upcomingStation, busRoute } = this.props;
      const url = `/bus-tracker/tripbookings`;
      const etaURL = `/bus-tracker/stations/${tripId}`;
      const issueUrl = `/bus-tracker/customerIssue`;
      const options = {
        method: "get"
      };
      const bookingOptions = {
        method: "post",
        data: {
          tripId
        }
      };
      fromStationName.length = 0;
      toStationName.length = 0;

      let response = await Fetch(url, bookingOptions);
      let etaResponse = await Fetch(etaURL, options);
      let issueResponse = await Fetch(issueUrl, options);
      const stationMap = {};

      for (let i = 0; i < etaResponse.length; ++i) {
        const station = etaResponse[i];
        stationMap[station.stationId] = station;
      }
      if (response) {
        const boardingList = [],
          notBoardedList = [],
          upcomingList = [],
          allBookingData = [];
        let fromStationHashmap = {},
          toStationHashmap = {};
        const markedStationIdArr = getAllStationBeforeUpcomingStation(
          upcomingStation._id,
          busRoute
        );

        const markedStationMap = {};

        for (let i = 0; i < markedStationIdArr.length; ++i) {
          const stationId = markedStationIdArr[i];
          markedStationMap[stationId] = 1;
        }

        response = response.sort(
          (a, b) => a.fromStationDate - b.fromStationDate
        );

        for (let i = 0; i < response.length; ++i) {
          const booking = response[i];
          if (booking.status === "CONFIRMED") {
            const station = stationMap[booking.fromStation._id];

            fromStationHashmap[booking.fromStation.name] = [];
            toStationHashmap[booking.toStation.name] = [];

            let callTime = "";
            for (let i = 0; i < booking.callDetails.length; ++i) {
              const t = new Date(booking.callDetails[i].date);
              if (station && station.reachedTime) {
                const Kalyera = t.getTime();
                const diff = Math.abs(Kalyera - station.reachedTime);
                callTime = getHoursAndMinFromMillis(diff);
              } else {
                callTime = booking.callDetails[i].date;
              }
              booking.callDetails[i]["callTime"] = callTime;
            }
            let incomingCallDetail = "";
            if (booking.incomingCallDetails) {
              for (let i = 0; i < booking.incomingCallDetails.length; ++i) {
                const t = new Date(booking.incomingCallDetails[i].date);
                if (station && station.reachedTime) {
                  const Kalyera = t.getTime();
                  const diff = Math.abs(Kalyera - station.reachedTime);
                  incomingCallDetail = msToTime(diff);
                } else {
                  incomingCallDetail = booking.incomingCallDetails[i].date;
                }
                booking.incomingCallDetails[i][
                  "incomingCallDetail"
                ] = incomingCallDetail;
              }
            }

            let seatsLabel = "";
            if (booking.tripChart && booking.tripChart.length > 0) {
              for (let i = 0; i < booking.tripChart.length; ++i) {
                const seat = booking.tripChart[i];
                if (seat.status === "CONFIRMED") {
                  const seatNumber = seat.seat.seatLabel;
                  const seatGender = seat.gender ? seat.gender : "";

                  if (seatsLabel.length === 0) {
                    seatsLabel += `${seatNumber}(${seatGender})`;
                  } else {
                    seatsLabel += `,${seatNumber}(${seatGender})`;
                  }
                }
              }
            }

            const bookingObj = {
              key: booking._id,
              name: booking.name,
              mobileNo: {
                value: booking.mobileNo,
                onChange: () =>
                  this.setState({
                    editField: "mobileNo",
                    editBooking: true,
                    editType: "booking",
                    bookingToEdit: booking
                  })
              },
              messageSent: booking.messageSent,
              refunds: {
                data: booking.refund ? booking.refund : null
              },
              demandSource: ` ${booking.demandSource}(${
                booking.otaPartner ? booking.otaPartner : ""
              })`,
              pnr: booking.bookingCode,
              status: booking.status,
              seats: seatsLabel,
              fromStation: {
                name: `${booking.fromStation.name}, ${booking.fromStation.city.name}`,
                date: moment(parseInt(booking.fromStationDate)).format("lll"),
                onChange: () =>
                  this.setState({
                    editField: "fromStation",
                    editBooking: true,
                    editType: "booking",
                    bookingToEdit: booking
                  })
              },
              toStation: {
                name: `${booking.toStation.name}, ${booking.toStation.city.name}`,
                date: moment(parseInt(booking.toStationDate)).format("lll"),
                onChange: () =>
                  this.setState({
                    editField: "toStation",
                    editType: "booking",
                    editBooking: true,
                    bookingToEdit: booking
                  })
              },
              amountToBeCollected: {
                value: booking.amountToBeCollected,
                agentId: booking.agentId ? booking.agentId : null,
                onChange: () =>
                  this.setState({
                    editField: "amountToBeCollected",
                    editType: "booking",
                    editBooking: true,
                    bookingToEdit: booking
                  })
              },
              _id: booking._id,

              callAttempted: booking.callDetails.length,
              callRecords: {
                data: booking.callDetails,
                from: booking.mobileNo
              },

              incomingCallDetails: booking.incomingCallDetails,
              remark: {
                value: booking.remark,
                onChange: () =>
                  this.setState({
                    editField: "remark",
                    editType: "booking",
                    editBooking: true,
                    bookingToEdit: booking
                  })
              },
              action: {
                tripId: booking.tripId,
                customerTrackingLink: `http://${booking.trackingUrl}`,
                copyCustomerTrackingLink: () =>
                  this.copyCustomerTrackingLink(booking.trackingUrl),
                showModal: () => this.showModal(booking)
              },
              isCreditCouponSent: booking.isCreditCouponSent,
              trackingUrl: booking.trackingUrl,
              booking,
              notBoarededReason: booking.notBoardedReason
                ? booking.notBoardedReason
                : null,
              actionHistory: booking.actionHistory || ""
            };
            const csvBookingObj = {
              name: booking.name,
              seats: seatsLabel,
              pnr: booking.bookingCode,
              mobileNo: booking.mobileNo,
              fromStation: `${booking.fromStation.name}, ${
                booking.fromStation.city.name
              }, ${moment(booking.fromStationDate).format("lll")}`,
              toStation: `${booking.toStation.name}, ${
                booking.toStation.city.name
              },${moment(booking.toStationDate).format("lll")}`,
              amountToBeCollected: booking.amountToBeCollected
            };
            allBookingData.push(csvBookingObj);
            if (upcomingStation.status === "COMPLETED") {
              if (booking.isOnboarded) {
                bookingObj.time = moment(booking.boardingDate).format(
                  "Do MMMM YYYY, h:mm:ss a"
                );
                boardingList.push(bookingObj);
              } else {
                notBoardedList.push(bookingObj);
              }
            } else if (booking.isOnboarded) {
              bookingObj.time = moment(booking.boardingDate).format(
                "Do MMMM YYYY, h:mm:ss a"
              );
              boardingList.push(bookingObj);
            }
            // cx will be not boarded only after its boarding time is passed
            else if (
              markedStationMap[booking.fromStationId] &&
              booking.fromStationDate <= Date.now()
            ) {
              notBoardedList.push(bookingObj);
            } else {
              upcomingList.push(bookingObj);
            }
          }
        }

        for (let i in fromStationHashmap) {
          let obj = {
            text: i,
            value: i
          };
          fromStationName.push(obj);
        }
        for (let i in toStationHashmap) {
          let obj = {
            text: i,
            value: i
          };
          toStationName.push(obj);
        }

        this.setState({
          loading: false,
          bookings: response,
          boardingList,
          customerIssue: issueResponse,
          allBookingData,
          notBoardedList,
          upcomingList
        });
      } else {
        this.setState({ loading: false });
      }
    } catch (err) {
      this.setState({ loading: false });
      console.log(err);
    }
  }

  copyCustomerTrackingLink = customerTrackingLink => {
    this.inputRef.current.value = customerTrackingLink;
    this.inputRef.current.select();

    document.execCommand("copy");
    message.success("Customer Link copied to clipboard");
  };

  showModal = async bookings => {
    const url = `/bus-tracker/booking?id=${bookings._id}`;
    const getbooking = await Fetch(url);
    let booking = getbooking[0];
    const viewMoreList = [];
    const bookingObj = {
      refunds: {
        data: booking.refund ? booking.refund : null
      },
      demandSource: booking.demandSource,
      amountToBeCollected: {
        value: booking.amountToBeCollected,
        agentId: booking.agentId ? booking.agentId : null,
        onChange: () =>
          this.setState({
            editField: "amountToBeCollected",
            editType: "booking",
            editBooking: true,
            bookingToEdit: booking
          })
      },
      callAttempted: booking.callDetails.length,
      callRecords: { data: booking.callDetails, from: booking.mobileNo },
      incomingCallDetails: booking.incomingCallDetails,
      remark: {
        value: booking.remark,
        onChange: () =>
          this.setState({
            editField: "remark",
            editType: "booking",
            editBooking: true,
            bookingToEdit: booking
          })
      },
      notBoarededReason: booking.notBoardedReason
        ? booking.notBoardedReason
        : null,
      actionHistory: booking.actionHistory || ""
    };
    viewMoreList.push(bookingObj);
    this.setState({ showMoreDetails: true, viewMoreList });
  };

  handleChange = (key, value) => {
    this.setState({ [key]: value, viewMoreList: [] });
  };

  showBooking = bookings => {
    return bookings.map(booking => (
      <div key={booking._id}>
        <span>{`${booking.name}(${booking.bookingCode})`}</span>
      </div>
    ));
  };

  sendMessage = async (
    templateType,
    vars,
    sendSMSMessage,
    sendWhatsAppMessage
  ) => {
    try {
      const { selectedBookings, selectedCargos } = this.state;
      if (selectedBookings.length || selectedCargos.length) {
        const url = `/bus-tracker/sendmessageandwhatsapp`;

        let messageSelectedBooking =
          selectedBookings.length > 0
            ? selectedBookings.map(b => {
                return {
                  mobileNo: b.booking.mobileNo,
                  _id: b.booking._id,
                  messageSent: b.booking.messageSent,
                  trackingUrl: b.booking.trackingUrl
                };
              })
            : selectedCargos;
        const options = {
          method: "post",
          data: {
            bookings: messageSelectedBooking,
            templateType,
            vars,
            sendSMSMessage,
            sendWhatsAppMessage
          }
        };

        const response = await Fetch(url, options);

        if (response) {
          message.success("Message sent successfully");
          this.setState({ selectedBookings: [], selectedRowKeys: [] });
        }
      } else {
        message.error("No Booking Selected");
      }
    } catch (err) {
      message.error(err);
    }
  };

  sendRefund = async (
    selectedCategory,
    subIssueId,
    issueId,
    categorys,
    issueComment,
    userInput
  ) => {
    try {
      const { selectedBookings, selectedCargos } = this.state;
      let refundID = selectedCategory[0]._id;
      let refundClassification = selectedCategory[0].refundClassification;

      if (selectedBookings.length || selectedCargos.length) {
        const url = `/bus-tracker/sendRefund`;
        let booking = [];
        if (selectedBookings.length) {
          booking = selectedBookings.map(obj => {
            return {
              agentMobileNo: obj.booking.agent
                ? obj.booking.agent.mobileNo
                : null,
              mobileNo: obj.mobileNo.value,
              userId: obj.booking.userId,
              bookingCode: obj.bookingCode ? obj.bookingCode : obj.pnr
            };
          });
        } else {
          booking = selectedCargos.map(obj => {
            return {
              agentMobileNo: obj.cargoBooking.agent
                ? obj.cargoBooking.agent.mobileNo
                : null,
              sender_mobileNo: obj.sender_mobileNo.value,
              receiver_mobileNo: obj.receiver_mobileNo.value,
              bookingCode: obj.bookingCode ? obj.bookingCode : obj.pnr
            };
          });
        }
        const options = {
          method: "post",
          data: {
            subIssueId: subIssueId,
            issueId: issueId,
            type: selectedBookings.length > 0 ? "booking" : "cargoBooking",
            refundRuleId: refundID,
            refundClassification: refundClassification
              ? refundClassification
              : "NORMAL",
            comment: issueComment,
            bookingType: "ZINGBUS",
            amount: userInput,
            bookings: booking
          }
        };
        const response = await Fetch(url, options);

        if (response) {
          message.success("Refund Initiated");
          this.setState({ selectedBookings: [], selectedRowKeys: [] });
        }
      } else {
        message.error("No Booking Selected");
      }
    } catch (err) {
      message.error(err);
    }
  };

  sendCoupon = async _id => {
    try {
      const { selectedBookings } = this.state;

      if (selectedBookings.length) {
        const url = `/booking/sendCoupon`;
        let booking = [];
        if (selectedBookings.length) {
          booking = selectedBookings.map(obj => {
            return obj.pnr;
          });
        }
        const options = {
          method: "post",
          data: {
            ruleId: _id,
            pnrArr: booking
          }
        };
        const response = await Fetch(url, options);

        if (response == "Credit Coupon sent Successfully") {
          message.success("Credit Coupon sent Successfully");
          this.setState({ selectedBookings: [], selectedRowKeys: [] });
        } else {
          message.error("Coupon not sent");
          this.setState({ selectedBookings: [], selectedRowKeys: [] });
        }
      } else {
        message.error("No Booking Selected");
      }
    } catch (err) {
      message.error(err);
    }
  };

  publishNote = async (comment, time) => {
    try {
      const { selectedBookings, selectedCargos } = this.state;

      if (selectedBookings.length || selectedCargos.length) {
        const url = `/bus-tracker/publishSelfHelpNote`;
        let booking = [];
        booking = selectedBookings.map(obj => {
          return {
            bookingId: obj._id ? obj._id : obj.pnr,
            actionHistory: obj.actionHistory
          };
        });
        const options = {
          method: "post",
          data: {
            selfHelpComment: comment,
            selfHelpCommentRelayTime: time,
            bookings: booking
          }
        };
        const response = await Fetch(url, options);

        if (response) {
          message.success("Note Published");
          this.setState({ selectedBookings: [], selectedRowKeys: [] });
        }
      } else {
        message.error("No Booking Selected");
      }
    } catch (err) {
      message.error(err);
    }
  };

  removeNote = async () => {
    try {
      const { selectedBookings } = this.state;

      const url = `/bus-tracker/removeSelfHelpNote`;
      let booking = [];
      booking = selectedBookings.map(obj => {
        return {
          bookingId: obj._id ? obj._id : obj.pnr,
          actionHistory: obj.actionHistory
        };
      });
      const options = {
        method: "post",
        data: {
          bookings: booking
        }
      };
      const response = await Fetch(url, options);

      if (response) {
        message.success("Note Removed");
        this.setState({ selectedBookings: [], selectedRowKeys: [] });
      }
    } catch (err) {
      message.error(err);
    }
  };

  updateBoardingStatus = async () => {
    try {
      const {
        selectedBookings,
        activeKey,
        boardingList,
        notBoardedList,
        upcomingList
      } = this.state;

      const { upcomingStation } = this.props;
      const url = `/bus-tracker/booking/boarding`;

      let bookingToBeUpdated = selectedBookings.map(b => {
        let body = {
          _id: b.booking._id,
          booking: {
            transactionType: b.booking.transactionType,
            _id: b.booking._id,
            agentId: b.booking.agentId,
            bookingAmount: b.booking.bookingAmount,
            advance: b.booking.advance,
            amountToBeCollected: b.booking.amountToBeCollected,
            bookingCode: b.booking.bookingCode
          },
          tripId: b.booking.trip._id,
          actionHistory: b.actionHistory
        };
        return body;
      });
      const options = {
        method: "patch",
        data: {
          bookings: bookingToBeUpdated,
          type: activeKey === "1" ? "MARKUNBOARDED" : "MARKONBOARDED"
        }
      };

      const response = await Fetch(url, options);

      if (response) {
        const bookingMap = {};
        const updateStateObj = { selectedBookings: [], selectedRowKeys: [] };

        for (let i = 0; i < response.length; ++i) {
          const booking = response[i];
          bookingMap[booking._id] = booking;
        }

        if (activeKey === "1") {
          updateStateObj.notBoardedList = [...notBoardedList];
          updateStateObj.upcomingList = [...upcomingList];

          updateStateObj.boardingList = boardingList.filter(b => {
            if (
              bookingMap[b._id] &&
              bookingMap[b._id].fromStationId === upcomingStation._id
            ) {
              updateStateObj.upcomingList.push(b);
            } else {
              updateStateObj.notBoardedList.push(b);
            }

            return !bookingMap[b._id];
          });
        } else if (activeKey === "2") {
          updateStateObj.boardingList = [...boardingList];
          updateStateObj.notBoardedList = [...notBoardedList];

          updateStateObj.upcomingList = upcomingList.filter(b => {
            if (bookingMap[b._id]) {
              updateStateObj.boardingList.push(b);
            }

            return !bookingMap[b._id];
          });
        } else {
          updateStateObj.boardingList = [...boardingList];
          updateStateObj.upcomingList = [...upcomingList];

          updateStateObj.notBoardedList = notBoardedList.filter(b => {
            if (bookingMap[b._id]) {
              updateStateObj.boardingList.push(b);
            }

            return !bookingMap[b._id];
          });
        }

        message.success("Booking boarding status updated");
        this.setState(updateStateObj);
      }
    } catch (err) {
      message.error(err);
    }
  };

  updateCargoLoadingStatus = async () => {
    try {
      const {
        selectedCargos,
        activeKey,
        cargoLoadingList,
        cargoNotLoadedList,
        cargoUpcomingList,
        cargoNotUnLoadedList,
        cargoUnLoadedList
      } = this.state;

      const { upcomingStation } = this.props;
      const url = `/bus-tracker/cargo/boarding`;

      const options = {
        method: "patch",
        data: {
          cargoBookings: selectedCargos,
          type:
            activeKey === "1"
              ? "MarkNotLoaded"
              : activeKey === "4"
              ? "MarkUnloaded"
              : activeKey === "5"
              ? "MarkNotUnloaded"
              : "MarkLoaded"
        }
      };

      const response = await Fetch(url, options);

      if (response) {
        const cargobookingMap = {};
        const updateStateObj = { selectedCargos: [], selectedRowKeys: [] };

        for (let i = 0; i < response.length; ++i) {
          const booking = response[i];
          cargobookingMap[booking._id] = booking;
        }

        if (activeKey === "1") {
          updateStateObj.cargoNotLoadedList = [...cargoNotLoadedList];
          updateStateObj.cargoUpcomingList = [...cargoUpcomingList];

          updateStateObj.cargoLoadingList = cargoLoadingList.filter(b => {
            if (
              cargobookingMap[b._id] &&
              cargobookingMap[b._id].fromStationId === upcomingStation._id
            ) {
              updateStateObj.cargoUpcomingList.push(b);
            } else {
              updateStateObj.cargoNotLoadedList.push(b);
            }

            return !cargobookingMap[b._id];
          });
        } else if (activeKey === "2") {
          updateStateObj.cargoLoadingList = [...cargoLoadingList];
          updateStateObj.cargoNotLoadedList = [...cargoNotLoadedList];

          updateStateObj.cargoUpcomingList = cargoUpcomingList.filter(b => {
            if (cargobookingMap[b._id]) {
              updateStateObj.cargoLoadingList.push(b);
            }

            return !cargobookingMap[b._id];
          });
        } else if (activeKey === "3") {
          updateStateObj.cargoLoadingList = [...cargoLoadingList];
          updateStateObj.cargoUpcomingList = [...cargoUpcomingList];

          updateStateObj.cargoNotLoadedList = cargoNotLoadedList.filter(b => {
            if (cargobookingMap[b._id]) {
              updateStateObj.cargoLoadingList.push(b);
            }

            return !cargobookingMap[b._id];
          });
        } else if (activeKey === "4") {
          updateStateObj.cargoUnLoadedList = [...cargoUnLoadedList];

          updateStateObj.cargoNotUnLoadedList = cargoNotUnLoadedList.filter(
            b => {
              if (cargobookingMap[b._id]) {
                updateStateObj.cargoUnLoadedList.push(b);
              }

              return !cargobookingMap[b._id];
            }
          );
        } else if (activeKey === "5") {
          updateStateObj.cargoNotUnLoadedList = [...cargoNotUnLoadedList];

          updateStateObj.cargoUnLoadedList = cargoUnLoadedList.filter(b => {
            if (cargobookingMap[b._id]) {
              updateStateObj.cargoNotUnLoadedList.push(b);
            }

            return !cargobookingMap[b._id];
          });
        }

        message.success("Cargo boarding status updated");
        this.setState(updateStateObj);
      }
    } catch (err) {
      message.error(err);
    }
  };

  updateCargoUnLoadingStatus = async () => {
    try {
      const {
        selectedCargos,
        activeKey,
        cargoLoadingList,
        cargoUnLoadedList
      } = this.state;

      const url = `/bus-tracker/cargo/boarding`;

      const options = {
        method: "patch",
        data: {
          cargoBookings: selectedCargos,
          type: "MarkUnloaded"
        }
      };

      const response = await Fetch(url, options);

      if (response) {
        const cargobookingMap = {};
        const updateStateObj = { selectedCargos: [], selectedRowKeys: [] };

        for (let i = 0; i < response.length; ++i) {
          const booking = response[i];
          cargobookingMap[booking._id] = booking;
        }

        if (activeKey === "1") {
          updateStateObj.cargoUnLoadedList = [...cargoUnLoadedList];

          updateStateObj.cargoLoadingList = cargoLoadingList.filter(b => {
            if (cargobookingMap[b._id]) {
              updateStateObj.cargoUnLoadedList.push(b);
            }
            return !cargobookingMap[b._id];
          });
        }

        message.success("Cargo boarding status updated");
        this.setState(updateStateObj);
      }
    } catch (err) {
      message.error(err);
    }
  };

  showCargoBooking = async value => {
    const { tripId, upcomingStation, busRoute } = this.props;
    const { cargoBookings } = this.state;
    if (value == "cargo" && !cargoBookings) {
      const cargoUrl = `/bus-tracker/cargoBooking/${tripId}`;
      const issueUrl = `/bus-tracker/customerIssue`;

      const options = {
        method: "get"
      };
      let issueResponse = await Fetch(issueUrl, options);
      let cargoResponse = await Fetch(cargoUrl, options);
      const stationMap = {};
      if (cargoResponse) {
        const cargoLoadingList = [],
          cargoNotLoadedList = [],
          cargoUpcomingList = [],
          cargoNotUnLoadedList = [],
          cargoUnLoadedList = [];

        const markedStationIdArr = getAllStationBeforeUpcomingStation(
          upcomingStation._id,
          busRoute
        );

        const markedStationMap = {};

        for (let i = 0; i < markedStationIdArr.length; ++i) {
          const stationId = markedStationIdArr[i];
          markedStationMap[stationId] = 1;
        }

        cargoResponse = cargoResponse.sort(
          (a, b) => a.fromStationDate - b.fromStationDate
        );
        for (let i = 0; i < cargoResponse.length; ++i) {
          const cargoBooking = cargoResponse[i];
          if (cargoBooking.status === "CONFIRMED") {
            const station = stationMap[cargoBooking.fromStation._id];

            let callTime = "";
            for (let i = 0; i < cargoBooking.callDetails.length; ++i) {
              const t = new Date(cargoBooking.callDetails[i].date);
              if (station && station.reachedTime) {
                const Kalyera = t.getTime();
                const diff = Math.abs(Kalyera - station.reachedTime);
                callTime = getHoursAndMinFromMillis(diff);
              } else {
                callTime = cargoBooking.callDetails[i].date;
              }
              cargoBooking.callDetails[i]["callTime"] = callTime;
            }

            const bookingObj = {
              key: cargoBooking._id,
              sender_name: cargoBooking.sender.name,
              sender_mobileNo: {
                value: cargoBooking.sender.mobileNo,
                onChange: () =>
                  this.setState({
                    editField: "Sender mobileNo",
                    editBooking: true,
                    editType: "cargo",
                    bookingToEdit: cargoBooking
                  })
              },
              receiver_name: cargoBooking.receiver.name,
              receiver_mobileNo: {
                value: cargoBooking.receiver.mobileNo,
                onChange: () =>
                  this.setState({
                    editField: "Receiver mobileNo",
                    editType: "cargo",
                    editBooking: true,
                    bookingToEdit: cargoBooking
                  })
              },
              pnr: cargoBooking.bookingCode,
              refund: cargoBooking.refund,
              status: cargoBooking.status,
              packageCount: cargoBooking.package.length,
              fromStation: {
                name: `${cargoBooking.fromStation.name}, ${cargoBooking.fromCity.name}`,
                onChange: () =>
                  this.setState({
                    editField: "fromStation",
                    editType: "cargo",
                    editBooking: true,
                    bookingToEdit: cargoBooking
                  })
              },
              toStation: {
                name: `${cargoBooking.toStation.name}, ${cargoBooking.toCity.name}`,
                onChange: () =>
                  this.setState({
                    editField: "toStation",
                    editType: "cargo",
                    editBooking: true,
                    bookingToEdit: cargoBooking
                  })
              },
              amountToBeCollected: {
                value: cargoBooking.amountToBeCollected,
                onChange: () =>
                  this.setState({
                    editType: "cargo",
                    editField: "amountToBeCollected",
                    editBooking: true,
                    bookingToEdit: cargoBooking
                  })
              },
              ewayBill: cargoBooking.ewayBill,
              _id: cargoBooking._id,
              remark: {
                value: cargoBooking.remark,
                onChange: () =>
                  this.setState({
                    editField: "remark",
                    editType: "cargo",
                    editBooking: true,
                    bookingToEdit: cargoBooking
                  })
              },
              action: {
                tripId: cargoBooking.tripId,
                customerTrackingLink: cargoBooking.trackingUrl,
                copyCustomerTrackingLink: () =>
                  this.copyCustomerTrackingLink(cargoBooking.trackingUrl)
              },
              image: {
                onChange: () =>
                  this.setState({
                    showImages: true,
                    cargoImageTobeShown: cargoBooking.logisticState.imageURL
                  })
              },
              trackingUrl: cargoBooking.trackingUrl,
              cargoBooking,
              actionHistory: cargoBooking.actionHistory || "",
              logisticState: cargoBooking.logisticState
            };
            if (cargoBooking.logisticState.value === "NOTLOADED") {
              cargoNotLoadedList.push(bookingObj);
            } else if (cargoBooking.logisticState.value === "LOADED") {
              cargoLoadingList.push(bookingObj);
            } else if (cargoBooking.logisticState.value === "UNLOADED") {
              if (
                markedStationMap[cargoBooking.toStationId] &&
                cargoBooking.toStationDate <= Date.now()
              ) {
                cargoNotUnLoadedList.push(bookingObj);
              } else {
                cargoUnLoadedList.push(bookingObj);
              }
            } else if (cargoBooking.logisticState.value === "NOTUNLOADED") {
              cargoNotUnLoadedList.push(bookingObj);
            } else if (cargoBooking.logisticState.value === "READY") {
              cargoUpcomingList.push(bookingObj);
            }
            // cx will be not boarded only after its boarding time is passed
            // else if (
            //   markedStationMap[cargoBooking.fromStationId] &&
            //   cargoBooking.fromStationDate <= Date.now()
            // ) {
            //   cargoNotLoadedList.push(bookingObj);
            // } else {
            //   cargoUpcomingList.push(bookingObj);
            // }
          }
        }
        this.setState({
          loading: false,
          cargoBookings: cargoResponse,
          cargoLoadingList,
          customerIssue: issueResponse,
          cargoNotLoadedList,
          cargoUpcomingList,
          cargoNotUnLoadedList,
          cargoUnLoadedList
        });
      } else {
        this.setState({ loading: false });
      }
    }
    this.setState({
      selectKey: value
    });
  };

  checkProp = () => {
    if (this.state.showMoreDetails == true) {
      this.setState({ showMoreDetails: false });
    } else {
      this.setState({ closeBookingList: true });
    }
  };
  handleCancel = () => {
    const audioTag = document.getElementsByClassName("audio_tag");
    if (audioTag && audioTag.length) {
      for (let tag of audioTag) {
        if (tag.duration && tag.duration > 0 && !tag.paused) {
          tag.pause();
        }
      }
    }
    this.handleChange("showMoreDetails", false);
  };
  render() {
    const {
      loading,
      boardingList,
      customerIssue,
      notBoardedList,
      allBookingData,
      cargoLoadingList,
      cargoNotLoadedList,
      cargoUpcomingList,
      cargoNotUnLoadedList,
      cargoUnLoadedList,
      selectedCargos,
      activeKey,
      editBooking,
      editField,
      showImages,
      cargoImageTobeShown,
      bookingToEdit,
      upcomingList,
      editType,
      selectedBookings,
      selectedRowKeys = [],
      closeBookingList = false,
      selectKey,
      showMoreDetails,
      viewMoreList
    } = this.state;

    if (closeBookingList) {
      this.props.onCancel();
    }

    return (
      <Modal
        title="Booking List"
        visible={true}
        onCancel={this.props.onCancel}
        className="modalClass"
        footer={null}
        width={"100%"}
      >
        {loading ? (
          <h1>loading ...</h1>
        ) : (
          <>
            <Select
              defaultValue={selectKey}
              style={{ width: "fit-content", minWidth: "100px" }}
              onChange={value => this.showCargoBooking(value)}
            >
              <Option value="booking">Passenger List</Option>
              <Option value="cargo">Cargo List</Option>
              {/* <Option value="ALL">All</Option> */}
            </Select>
            {/* input tag is for copying customer link */}
            <input
              ref={this.inputRef}
              readOnly={true}
              style={{ position: "absolute", left: "-9999px" }}
            />
            {editBooking ? (
              <EditBooking
                onChange={obj => this.setState(obj)}
                editType={editType}
                editField={editField}
                booking={bookingToEdit}
                closeParentModal={this.checkProp}
              />
            ) : null}

            {showImages ? (
              <ShowImages
                onChange={obj => this.setState(obj)}
                cargoBooking={cargoImageTobeShown}
                closeParentModal={this.props.onCancel}
              />
            ) : null}

            <br />
            {selectKey == "booking" ? (
              <>
                <div className="bookingListFooter">
                  {selectedBookings.length > 0 ? (
                    <div className="bookingListFooterItemOne">
                      <SendRefund
                        issues={customerIssue}
                        selectedBookings={selectedBookings}
                        onClick={this.sendRefund}
                        selectedCount={selectedBookings.length}
                      />
                    </div>
                  ) : null}
                  {selectedBookings.length > 0 ? (
                    <div className="bookingListFooterItemOne">
                      <SendCoupon
                        selectedBookings={selectedBookings}
                        onClick={this.sendCoupon}
                        selectedCount={selectedBookings.length}
                      />
                    </div>
                  ) : null}
                  {selectedBookings.length > 0 ? (
                    <div className="bookingLisonsttFooterItemOne">
                      <SendSelfHelpMessage
                        selectedBookings={selectedBookings}
                        onClick={this.publishNote}
                        selectedCount={selectedBookings.length}
                      />
                    </div>
                  ) : null}

                  {selectedBookings.length > 0 ? (
                    <Button
                      onClick={this.removeNote}
                      style={{ marginRight: "10px" }}
                      type="danger"
                    >
                      Remove Note
                    </Button>
                  ) : null}
                  <div className="bookingListFooterItemOne">
                    <Button onClick={this.updateBoardingStatus} type="danger">
                      {activeKey === "1" ? "Mark Not Boarded" : "Mark Boarded"}
                    </Button>
                  </div>
                  <div className="bookingListFooterItem2">
                    <SendMessage
                      selectedBookings={selectedBookings}
                      onClick={this.sendMessage}
                      selectedCount={selectedBookings.length}
                    />
                  </div>
                  <Modal
                    centered
                    width={"100%"}
                    title="More Details"
                    visible={showMoreDetails}
                    cancelButtonProps={{ style: { display: "none" } }}
                    onCancel={this.handleCancel}
                    footer={null}
                  >
                    <Table
                      columns={moreDetailsColumns}
                      dataSource={viewMoreList}
                      pagination={false}
                      scroll={{ y: 300 }}
                    />
                  </Modal>
                  <div className="bookingListFooterItem2">
                    <CSVLink
                      filename={"booking.csv"}
                      data={allBookingData}
                      className="ant-btn ant-btn-primary"
                    >
                      Download Report
                    </CSVLink>
                  </div>
                </div>
                <Tabs
                  activeKey={activeKey}
                  onChange={key =>
                    this.setState({ activeKey: key, selectedBookings: [] })
                  }
                >
                  <TabPane
                    tab={"Not Boarded List (" + notBoardedList.length + ")"}
                    key="3"
                  >
                    <Table
                      columns={columns}
                      dataSource={notBoardedList}
                      pagination={false}
                      rowSelection={{
                        columnwidth: "10%",
                        selectedRowKeys,
                        onChange: (keys, booking) => {
                          this.setState({
                            selectedBookings: booking,
                            selectedRowKeys: keys
                          });
                        }
                      }}
                      scroll={{ y: 300 }}
                    />
                  </TabPane>
                  <TabPane
                    tab={"Boarded List (" + boardingList.length + ")"}
                    key="1"
                  >
                    <Table
                      columns={columns}
                      dataSource={boardingList}
                      pagination={false}
                      rowSelection={{
                        columnwidth: "10%",
                        selectedRowKeys,
                        onChange: (keys, booking) => {
                          this.setState({
                            selectedBookings: booking,
                            selectedRowKeys: keys
                          });
                        }
                      }}
                      scroll={{ y: 300 }}
                    />
                  </TabPane>
                  <TabPane
                    tab={"Upcoming Boarding List (" + upcomingList.length + ")"}
                    key="2"
                  >
                    <Table
                      columns={columns}
                      dataSource={upcomingList}
                      pagination={false}
                      rowSelection={{
                        columnwidth: "10%",
                        selectedRowKeys,
                        onChange: (keys, booking) => {
                          this.setState({
                            selectedBookings: booking,
                            selectedRowKeys: keys
                          });
                        }
                      }}
                      scroll={{ y: 300 }}
                    />
                  </TabPane>
                </Tabs>
              </>
            ) : (
              <>
                <div className="bookingListFooter">
                  {selectedCargos.length > 0 ? (
                    <div className="bookingListFooterItemOne">
                      <SendRefund
                        issues={customerIssue}
                        selectedBookings={selectedCargos}
                        onClick={this.sendRefund}
                        selectedCount={selectedCargos.length}
                      />
                    </div>
                  ) : null}
                  <div className="bookingListFooterItemOne">
                    <Button
                      onClick={this.updateCargoLoadingStatus}
                      type="danger"
                    >
                      {activeKey === "1"
                        ? "Mark Not Loaded"
                        : activeKey === "4"
                        ? "Mark Unloaded"
                        : activeKey === "5"
                        ? "Mark Not Unloaded"
                        : "Mark Loaded"}
                    </Button>
                  </div>
                  {activeKey === "1" ? (
                    <div className="bookingListFooterItemOne">
                      <Button
                        onClick={this.updateCargoUnLoadingStatus}
                        type="primary"
                      >
                        Mark Unloaded
                      </Button>
                    </div>
                  ) : null}
                  <div className="bookingListFooterItem2">
                    <SendMessage
                      selectedBookings={selectedCargos}
                      onClick={this.sendMessage}
                      selectedCount={selectedCargos.length}
                    />
                  </div>
                </div>
                <Tabs
                  activeKey={activeKey}
                  onChange={key =>
                    this.setState({ activeKey: key, selectedCargos: [] })
                  }
                >
                  <TabPane
                    tab={"Not Loaded List (" + cargoNotLoadedList.length + ")"}
                    key="3"
                  >
                    <Table
                      columns={cargoColumns}
                      dataSource={cargoNotLoadedList}
                      pagination={false}
                      rowSelection={{
                        columnWidth: "4%",
                        selectedRowKeys,
                        onChange: (keys, cargoBooking) => {
                          this.setState({
                            selectedCargos: cargoBooking,
                            selectedRowKeys: keys
                          });
                        }
                      }}
                      scroll={{ y: 300, x: "200%" }}
                    />
                  </TabPane>
                  <TabPane
                    tab={"Loaded List (" + cargoLoadingList.length + ")"}
                    key="1"
                  >
                    <Table
                      columns={cargoColumns}
                      dataSource={cargoLoadingList}
                      pagination={false}
                      rowSelection={{
                        columnWidth: "2%",
                        selectedRowKeys,
                        onChange: (keys, cargoBooking) => {
                          this.setState({
                            selectedCargos: cargoBooking,
                            selectedRowKeys: keys
                          });
                        }
                      }}
                      scroll={{ y: 300, x: "200%" }}
                    />
                  </TabPane>
                  <TabPane
                    tab={
                      "Upcoming Loaded List (" + cargoUpcomingList.length + ")"
                    }
                    key="2"
                  >
                    <Table
                      columns={cargoColumns}
                      dataSource={cargoUpcomingList}
                      pagination={false}
                      rowSelection={{
                        columnWidth: "2%",
                        selectedRowKeys,
                        onChange: (keys, cargoBooking) => {
                          this.setState({
                            selectedCargos: cargoBooking,
                            selectedRowKeys: keys
                          });
                        }
                      }}
                      scroll={{ y: 300, x: "200%" }}
                    />
                  </TabPane>
                  <TabPane
                    tab={
                      "Not UnLoaded List (" + cargoNotUnLoadedList.length + ")"
                    }
                    key="4"
                  >
                    <Table
                      columns={cargoColumns}
                      dataSource={cargoNotUnLoadedList}
                      pagination={false}
                      rowSelection={{
                        columnWidth: "2%",
                        selectedRowKeys,
                        onChange: (keys, cargoBooking) => {
                          this.setState({
                            selectedCargos: cargoBooking,
                            selectedRowKeys: keys
                          });
                        }
                      }}
                      scroll={{ y: 300, x: "200%" }}
                    />
                  </TabPane>
                  <TabPane
                    tab={"UnLoaded List (" + cargoUnLoadedList.length + ")"}
                    key="5"
                  >
                    <Table
                      columns={cargoColumns}
                      dataSource={cargoUnLoadedList}
                      pagination={false}
                      rowSelection={{
                        columnWidth: "2%",
                        selectedRowKeys,
                        onChange: (keys, cargoBooking) => {
                          this.setState({
                            selectedCargos: cargoBooking,
                            selectedRowKeys: keys
                          });
                        }
                      }}
                      scroll={{ y: 300, x: "200%" }}
                    />
                  </TabPane>
                </Tabs>
              </>
            )}
          </>
        )}
      </Modal>
    );
  }
}

export default BoardingList;
