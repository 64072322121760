import React, { Component } from "react";
import { Table, Modal, Select, DatePicker, Input, message } from "antd";
import Loader from "../../loader";
import Fetch from "../../../utils/fetch";
import Search from "../contractSearch";
import less from "./inter.module.scss";
import jwt from "jsonwebtoken";
import moment from "moment";

const { Option } = Select;

const columns = [
  {
    title: "Operator",
    dataIndex: "operator",
    key: "operator",
    width: "10%"
  },
  {
    title: "Route",
    dataIndex: "route",
    key: "route",
    width: "10%",
    render: ({ fromCity, toCity }) => (
      <div>
        <span style={{ wordBreak: "break-word" }}>
          {fromCity}-{toCity}
        </span>
      </div>
    )
  },
  {
    title: "Start Date",
    dataIndex: "date",
    key: "date",
    width: "10%"
  },
  {
    title: "Expiry Date",
    dataIndex: "Exdate",
    key: "Exdate",
    width: "10%"
  },
  {
    title: "Route Type",
    dataIndex: "routeType",
    key: "routeType",
    width: "10%"
  },
  {
    title: "Asset Type",
    dataIndex: "type",
    key: "type",
    width: "10%"
  },
  {
    title: "Amount",
    dataIndex: "amount",
    key: "amount",
    width: "10%"
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    width: "10%",
    render: ({ value }) => (
      <div className={less.statusDiv}>
        <div
          className={
            value === "ACTIVE" ? less.statusActive : less.statusInActive
          }
        ></div>
        <span>{value}</span>
      </div>
    )
  },
  {
    title: "Actions",
    dataIndex: "actions",
    key: "actions",
    width: "10%",
    render: ({ canEdit, edit, logs, _id }) => (
      <div className={less.editTable}>
        {canEdit ? (
          <div onClick={() => edit(_id)}>
            <span>Edit</span>
          </div>
        ) : null}

        <div onClick={() => logs(_id)}>
          <span>Logs</span>
        </div>
      </div>
    )
  }
];

class OperatorContract extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      contentLoading: true, // Initialize contentLoading state
      editData: {},
      interValue: {
        startDate: moment().startOf("day")
      },
      showLogModal: false,
      cabOperator: null,
      intraValue: {
        startDate: moment().startOf("day")
      },
      showCreateModal: false,
      showEditModal: false
    };
  }

  async componentDidMount() {
    try {
      const token = localStorage.getItem("idToken");
      const decodedToken = jwt.decode(token);
      const { cities } = this.props;
      const response = await this.fetchData();
      const cabOperators = await this.fetchOperators();
      const assetTypeValues = await this.fetchAssetType();
      const activeCities = cities.filter(city => city.status == "ACTIVE");
      this.setState({
        loading: false,
        cities: activeCities,
        contractTableData: response.data,
        contractData: response.allResponse,
        assetTypeValues,
        cabOperators,
        userName: decodedToken.name,
        contentLoading: false
      });
    } catch (err) {
      console.log(err);
      this.setState({ loading: false });
    }
  }

  fetchData = async () => {
    try {
      const url = `/cabOperator/cabOperatorContract`;

      const options = {
        method: "get"
      };
      let allResponse = await Fetch(url, options);
      let data = [];

      if (Array.isArray(allResponse) && allResponse.length > 0) {
        data = this.formatData(allResponse);
      } else {
        allResponse = [];
      }

      return { data, allResponse };
    } catch (err) {
      console.log(err);
      message.error(err);
    }
  };

  sortData = data => {
    const currentTime = Date.now();

    data.sort((a, b) => {
      if (a.status === "ACTIVE" && b.status !== "ACTIVE") {
        return -1;
      }
      if (a.status !== "ACTIVE" && b.status === "ACTIVE") {
        return 1;
      }

      if (a.startDate > currentTime && b.startDate <= currentTime) {
        return -1;
      }
      if (a.startDate <= currentTime && b.startDate > currentTime) {
        return 1;
      }

      return a.startDate - b.startDate;
    });

    return data;
  };

  formatData = values => {
    const sortedData = this.sortData(values);
    let tableData = sortedData.map(value => {
      let canEdit = Date.now() <= value.expiryDate;

      let data = {
        key: value._id,
        route: {
          fromCity: value.fromCity.name,
          toCity: value.toCity.name
        },
        date: moment(value.startDate).format("DD MMM YY"),
        Exdate: moment(value.expiryDate).format("DD MMM YY"),
        routeType: value.isIntraCity ? "Intra" : "Inter",
        type: value.assetType,
        operator: value.cabOperator ? value.cabOperator.name : null,
        status: {
          value: value.expiryDate > Date.now() ? "ACTIVE" : "EXPIRED"
        },
        amount: value.supplyCost,

        actions: {
          canEdit,
          _id: value._id,
          edit: this.editData,
          logs: this.showLogs
        },
        bookings: value.bookings
      };
      return data;
    });
    return tableData;
  };

  editData = async _id => {
    const { contractData } = this.state;
    let stateObj = { showEditModal: true };
    const editData = contractData.filter(data => data._id == _id)[0];
    if (editData.isIntraCity) {
      const stations = await this.fetchStations();
      const intraStations = stations.filter(
        station => station.cityId == editData.fromCityId
      );
      stateObj.stations = stations;
      stateObj.intraStations = intraStations;
    }
    stateObj.editData = editData;
    this.setState(stateObj);
  };

  showLogs = _id => {
    let stateObj = { showLogModal: true };
    const { contractData } = this.state;
    let logs = contractData.filter(data => data._id == _id)[0]?.remark;
    if (logs) {
      const statusParts = logs.split(" ||| ");

      const updateRegex = /(.+?) is Updated from (.+?) to (.+?) by ([\w\s]+) at (.+)/;
      const addRegex = /([a-f0-9]{24}) added by ([\w\s]+) at (.+)/;
      const removeRegex = /([a-f0-9]{24}) removed by ([\w\s]+) at (.+)/;

      let parts = statusParts
        .map(status => {
          let match = status.match(updateRegex);
          if (match) {
            return {
              value: match[1], // Value being updated
              oldValue: match[2], // Old value
              newValue: match[3], // New value
              actor: match[4], // Actor (who made the change)
              time: match[5] // Time of the change
            };
          }

          match = status.match(addRegex);
          if (match) {
            return {
              value: match[1], // Value being added (e.g., asset ID or other identifier)
              oldValue: "", // No old value for "Added" logs
              newValue: "Added", // No new value for "Added" logs
              actor: match[2], // Actor (who made the addition)
              time: match[3] // Time of the addition
            };
          }

          match = status.match(removeRegex);
          if (match) {
            return {
              value: match[1], // Value being removed (e.g., asset ID or other identifier)
              oldValue: "", // No old value for "Removed" logs
              newValue: "Removed", // No new value for "Removed" logs
              actor: match[2], // Actor (who made the removal)
              time: match[3] // Time of the removal
            };
          }

          return null;
        })
        .filter(item => item !== null);

      const logsColoumn = [
        {
          title: "Value",
          dataIndex: "value",
          key: "value"
        },
        {
          title: "Old Value",
          dataIndex: "oldValue",
          key: "oldValue"
        },
        {
          title: "Updated Value",
          dataIndex: "newValue",
          key: "newValue"
        },
        {
          title: "Actor",
          dataIndex: "actor",
          key: "actor"
        },
        {
          title: "Time",
          dataIndex: "time",
          key: "time"
        }
      ];

      stateObj.logsColoumn = logsColoumn;
      stateObj.parts = parts;

      this.setState(stateObj);
    } else {
      message.error("No matching logs found");
    }
  };

  fetchOperators = async () => {
    const url = `/cabOperator/getOperator`;

    const options = {
      method: "get"
    };

    const response = await Fetch(url, options);

    return response;
  };

  fetchAssetType = async () => {
    const url = `/cabOperator/getAssetType`;

    const options = {
      method: "get"
    };
    const response = await Fetch(url, options);
    if (response) {
      return response;
    } else {
      return [];
    }
  };

  fetchStations = async () => {
    try {
      const url = `/bus-tracker/getStationsCache`;

      const options = {
        method: "get"
      };
      const response = await Fetch(url, options);
      if (response) {
        return response;
      } else {
        return [];
      }
    } catch (err) {
      console.log(err);
      return [];
    }
  };

  handleChange = async (key, value, data) => {
    let stations = [];
    if (key === "tripType" && value === "intra") {
      stations = await this.fetchStations();
    }
    if (key === "cabOperatorId") {
      this.setState({ [key]: data.key, cabOperator: value });
    } else this.setState({ [key]: value, stations });
  };

  setInterCityValues = (key, value, data) => {
    let { interValue = {} } = this.state;
    let newInterValue = interValue;

    if (key === "toCityId") {
      if (data.key == interValue.fromCityId) {
        message.error("Both City can't be same");
        return;
      }
      newInterValue = {
        ...interValue,
        toCity: value,
        toCityId: data.key
      };
      this.setState({ interValue: newInterValue });
    } else if (key === "fromCityId") {
      newInterValue = {
        ...interValue,
        fromCity: value,
        fromCityId: data.key
      };
      this.setState({ interValue: newInterValue });
    } else {
      interValue[key] = value;
      this.setState({ interValue });
    }
  };

  setIntraCityValues = (key, value, data) => {
    let { intraValue = {}, stations } = this.state;
    let newIntraValue = intraValue;
    if (key === "city") {
      let cityId = data.key;
      let intraStations = stations.filter(station => station.cityId == cityId);
      newIntraValue = {
        ...newIntraValue,
        cityId,
        fromStationName: undefined,
        toStationName: undefined,
        city: value
      };
      this.setState({ intraValue: newIntraValue, intraStations });
    } else if (key === "fromStationId") {
      newIntraValue = {
        ...intraValue,
        fromStationName: value,
        fromStationId: data.key
      };
      this.setState({ intraValue: newIntraValue });
    } else if (key === "toStationId") {
      newIntraValue = {
        ...newIntraValue,
        toStationName: value,
        toStationId: data.key
      };
      this.setState({ intraValue: newIntraValue });
    } else {
      intraValue[key] = value;

      this.setState({ intraValue });
    }
  };

  triggerCreateModal = () => {
    this.setState({
      assetType: "",
      interValue: {},
      intraValue: {},
      tripType: "inter",
      cabOperator: undefined,
      cabOperatorId: undefined,
      showCreateModal: true,
      showErrowMessage: false,
      supplyCost: undefined
    });
  };

  onOk = value => {
    const { tripType } = this.state;
    const date = new Date(value);
    date.setHours(0, 0, 0, 0);
    const millis = date.getTime();
    if (tripType == "inter") {
      this.setInterCityValues("startDate", millis);
    } else {
      this.setIntraCityValues("startDate", millis);
    }
  };

  expireTimeAccept = value => {
    const { tripType } = this.state;
    const date = new Date(value);
    date.setHours(23, 59, 59, 59);
    const millis = date.getTime();
    if (tripType == "inter") {
      this.setInterCityValues("expiryDate", millis);
    } else {
      this.setIntraCityValues("expiryDate", millis);
    }
  };

  createOperatorContract = async () => {
    const {
      cabOperatorId,
      assetType,
      supplyCost,
      interValue,
      intraValue,
      tripType,
      userName
    } = this.state;
    let details = {
      cabOperatorId,
      assetType,
      supplyCost: parseInt(supplyCost)
    };
    if (tripType == "inter") {
      if (
        !cabOperatorId ||
        !assetType ||
        !supplyCost ||
        !interValue.toCityId ||
        !interValue.fromCityId ||
        !interValue.startDate ||
        !interValue.expiryDate
      ) {
        this.setState({ showErrowMessage: true });
        return;
      }
      details = {
        ...details,
        ...interValue
      };
    } else {
      details = {
        ...details,
        ...intraValue
      };

      details = {
        cabOperatorId: details.cabOperatorId,
        assetType: details.assetType,
        supplyCost: details.supplyCost,
        fromStationId: details.fromStationId,
        toStationId: details.toStationId,
        startDate: details.startDate,
        isIntraCity: true,
        fromCityId: details.cityId,
        toCityId: details.cityId,
        expiryDate: details.expiryDate
      };

      if (
        !cabOperatorId ||
        !supplyCost ||
        !assetType ||
        !details.fromStationId ||
        !details.toStationId ||
        !details.fromCityId ||
        !details.startDate ||
        !details.expiryDate
      ) {
        this.setState({ showErrowMessage: true });
        return;
      }
    }
    const date = new Date(details.startDate);
    const millis = date.getTime();
    details = {
      ...details,
      startDate: millis,
      remark: `Contract is created by ${userName} at ${moment().format("LLL")}`
    };

    const url = `/cabOperator/cabOperatorContract`;

    const options = {
      method: "post",
      data: { details }
    };

    const response = await Fetch(url, options);
    if (Array.isArray(response) && response.length > 0) {
      const response = await this.fetchData();
      this.setState({
        assetType: "",
        supplyCost: "",
        contractTableData: response.data,
        contractData: response.allResponse,
        showErrowMessage: false,
        showCreateModal: false,
        clearFilters: true,
        showConfirmationModal: true
      });
    } else {
      this.setState({
        assetType: "",
        supplyCost: "",
        showErrowMessage: false,
        showCreateModal: false,
        showErrorModal: true
      });
    }
  };

  updateContract = async () => {
    const { newEditValues = {}, editData, contractData, userName } = this.state;
    let bodyData = { remark: "" };

    const {
      edittedStartDate,
      edittedExpiryDate,
      edittedSupplyCost
    } = newEditValues;
    if (Object.keys(newEditValues).length === 0) {
      message.error("Nothing To Update");
      this.setState({
        showEditModal: false,
        newEditValues: {}
      });
      return;
    }

    if (edittedStartDate && edittedStartDate !== editData.startDate) {
      bodyData.remark += `||| startDate is Updated from ${moment(
        editData.startDate
      ).format("DD MMM YY")} to ${moment(edittedStartDate).format(
        "DD MMM YY"
      )} by ${userName} at ${moment().format("LLL")}  `;
      bodyData = { ...bodyData, startDate: edittedStartDate };
    }
    if (edittedExpiryDate && edittedExpiryDate !== editData.expiryDate) {
      bodyData.remark += `||| expiryDate is Updated from ${moment(
        editData.expiryDate
      ).format("DD MMM YY")} to ${moment(edittedExpiryDate).format(
        "DD MMM YY"
      )} by ${userName} at ${moment().format("LLL")}  `;
      bodyData = { ...bodyData, expiryDate: edittedExpiryDate };
    }

    if (edittedSupplyCost && edittedSupplyCost !== editData.supplyCost) {
      bodyData.remark += `||| supplyCost is Updated from ${
        editData.supplyCost
      } to ${edittedSupplyCost} by ${userName} at ${moment().format("LLL")}  `;
      bodyData = { ...bodyData, supplyCost: parseInt(edittedSupplyCost) };
    }
    bodyData.remark = bodyData.remark ? bodyData.remark.slice(4) : "";
    if (Object.keys(bodyData).length === 1) {
      message.error("Nothing To Update");
      this.setState({
        showEditModal: false,
        newEditValues: {}
      });
      return;
    }
    if (editData.isIntraCity) {
      bodyData = {
        ...bodyData,
        isIntraCity: true
      };
    }

    const url = `/cabOperator/updateContract`;

    const options = {
      method: "patch",
      data: { updateBody: bodyData, _id: editData._id }
    };

    const response = await Fetch(url, options);

    if (Array.isArray(response) && response.length > 0) {
      const updatedContractData = contractData.map(contract => {
        const updatedResponse = response.find(
          item => item._id === contract._id
        );

        if (updatedResponse) {
          return { ...contract, ...updatedResponse };
        }
        return contract;
      });
      const data = this.formatData(updatedContractData);
      message.success("Contract Is Updated");
      this.setState({
        contractTableData: data,
        contractData: updatedContractData,
        showEditModal: false,
        newEditValues: {}
      });
    }
  };

  editValue = (key, value, data) => {
    const { newEditValues = {} } = this.state;
    let edittedData = newEditValues;
    if (key === "cabOperatorId") {
      edittedData = {
        ...edittedData,
        edittedCabOperator: value,
        edittedCabOperatorId: data.key
      };
    } else if (key === "startDate") {
      edittedData = {
        ...edittedData,
        edittedStartDate: value
      };
    } else if (key === "expiryDate") {
      edittedData = {
        ...edittedData,
        edittedExpiryDate: value
      };
    } else if (key === "supplyCost") {
      edittedData = {
        ...edittedData,
        edittedSupplyCost: value
      };
    }
    this.setState({ newEditValues: edittedData });
  };

  onEditOk = value => {
    const date = new Date(value);
    const millis = date.getTime();
    this.editValue("startDate", millis);
  };

  onExpireOk = value => {
    const date = new Date(value);
    const millis = date.getTime();
    this.editValue("expiryDate", millis);
  };

  searchData = response => {
    const data = this.formatData(response);
    this.setState({
      contractTableData: data,
      contractData: response
    });
  };

  render() {
    const {
      loading,
      contentLoading,
      interValue = {},
      cabOperator,
      showCreateModal,
      intraStations = [],
      tripType,
      intraValue = {},
      cabOperators = [],
      assetTypeValues = [],
      assetType,
      showLogModal,
      parts,
      logsColoumn,
      supplyCost,
      showConfirmationModal,
      showErrorModal,
      editData = {},
      newEditValues,
      contractTableData,
      showEditModal,
      showErrowMessage = false,
      clearFilters = false
    } = this.state;

    const { cities } = this.props;
    if (loading) {
      return <Loader />;
    } else {
      return (
        <div>
          <div className={less.header}>
            <span>Contract Management</span>
            <div onClick={this.triggerCreateModal}>
              <span>+ Create Contract</span>
            </div>
          </div>
          <Search
            cabOperators={cabOperators}
            assetTypeValues={assetTypeValues}
            cities={cities}
            clearFilters={clearFilters}
            onSearch={this.searchData}
          />
          <hr />

          <Table
            scroll={{ y: "70vh" }}
            loading={contentLoading}
            columns={columns}
            pagination={false}
            dataSource={contractTableData}
          />

          {showCreateModal ? (
            <Modal
              title="Create New Contract"
              visible={showCreateModal}
              okText="Create Contract"
              onCancel={() =>
                this.setState({
                  assetType: "",
                  interValue: {},
                  intraValue: {},
                  tripType: "inter",
                  cabOperator: undefined,
                  cabOperatorId: undefined,
                  showCreateModal: false,
                  showErrowMessage: false,
                  supplyCost: undefined
                })
              }
              onOk={this.createOperatorContract}
            >
              <div className={less.createModal}>
                <div>
                  <span>
                    Connecting Type<span style={{ color: "red" }}>*</span>
                  </span>
                  <Select
                    style={{ width: 450 }}
                    value={tripType}
                    placeholder="Select"
                    onChange={value => this.handleChange("tripType", value)}
                  >
                    <Option value="inter">Inter</Option>
                    <Option value="intra">Intra</Option>
                  </Select>
                </div>
                {tripType == "inter" ? (
                  <div className={less.typeSelector}>
                    <div>
                      <span>
                        From City<span style={{ color: "red" }}>*</span>
                      </span>
                      <Select
                        showSearch
                        style={{ width: 450 }}
                        value={interValue.fromCity}
                        onChange={(value, data) =>
                          this.setInterCityValues("fromCityId", value, data)
                        }
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {cities.map(city => (
                          <Option value={city.name} key={city._id}>
                            {city.name}
                          </Option>
                        ))}
                      </Select>
                    </div>
                    <div>
                      <span>
                        To City<span style={{ color: "red" }}>*</span>
                      </span>
                      <Select
                        showSearch
                        style={{ width: 450 }}
                        value={interValue.toCity ? interValue.toCity : null}
                        onChange={(value, data) =>
                          this.setInterCityValues("toCityId", value, data)
                        }
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {cities.map(city => (
                          <Option value={city.name} key={city._id}>
                            {city.name}
                          </Option>
                        ))}
                      </Select>
                    </div>
                  </div>
                ) : (
                  <div className={less.typeSelector}>
                    <div>
                      <span>
                        City<span style={{ color: "red" }}>*</span>
                      </span>
                      <Select
                        showSearch
                        style={{ width: 450 }}
                        value={intraValue.city}
                        onChange={(value, data) =>
                          this.setIntraCityValues("city", value, data)
                        }
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {cities.map(city => (
                          <Option value={city.name} key={city._id}>
                            {city.name}
                          </Option>
                        ))}
                      </Select>
                    </div>
                    <div>
                      <span>
                        From Station<span style={{ color: "red" }}>*</span>
                      </span>
                      <Select
                        showSearch
                        style={{ width: 450 }}
                        placeholder="Select"
                        value={intraValue.fromStationName}
                        onChange={(value, data) =>
                          this.setIntraCityValues("fromStationId", value, data)
                        }
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {intraStations.map(station => (
                          <Option value={station.name} key={station._id}>
                            {station.name}
                          </Option>
                        ))}
                      </Select>
                    </div>
                    <div>
                      <span>
                        To Station<span style={{ color: "red" }}>*</span>
                      </span>
                      <Select
                        showSearch
                        style={{ width: 450 }}
                        placeholder="Select"
                        value={intraValue.toStationName}
                        onChange={(value, data) =>
                          this.setIntraCityValues("toStationId", value, data)
                        }
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {intraStations.map(station => (
                          <Option value={station.name} key={station._id}>
                            {station.name}
                          </Option>
                        ))}
                      </Select>
                    </div>
                  </div>
                )}

                <div className={less.DatesSelector}>
                  <div>
                    <span>
                      Start Date<span style={{ color: "red" }}>*</span>
                    </span>
                    <DatePicker
                      style={{ width: 200 }}
                      value={
                        tripType == "inter" && interValue.startDate
                          ? moment(interValue.startDate).startOf("day")
                          : tripType == "intra" && intraValue.startDate
                          ? moment(intraValue.startDate).startOf("day")
                          : null
                      }
                      disabledDate={currentDate => {
                        return currentDate.isBefore(
                          moment().startOf("day"),
                          "day"
                        );
                      }}
                      format="DD-MM-YYYY"
                      onChange={this.onOk}
                    />
                  </div>
                  <div>
                    <span>
                      Expiry Date<span style={{ color: "red" }}>*</span>
                    </span>
                    <DatePicker
                      style={{ width: 200 }}
                      value={
                        tripType == "inter" && interValue.expiryDate
                          ? moment(interValue.expiryDate).endOf(`day`)
                          : tripType == "intra" && intraValue.expiryDate
                          ? moment(intraValue.expiryDate).endOf(`day`)
                          : null
                      }
                      format="DD-MM-YYYY"
                      onChange={this.expireTimeAccept}
                    />
                  </div>
                </div>

                <div className={less.DatesSelector}>
                  <div>
                    <span>
                      Asset Type<span style={{ color: "red" }}>*</span>
                    </span>
                    <Select
                      style={{ width: 200 }}
                      value={assetType}
                      placeholder="Select"
                      onChange={value => this.handleChange("assetType", value)}
                    >
                      {assetTypeValues.map(asset => (
                        <Option value={asset.name} key={asset.id}>
                          {asset.name}
                        </Option>
                      ))}
                    </Select>
                  </div>
                  <div>
                    <span>
                      Supply Cost <span style={{ color: "red" }}>*</span>
                    </span>
                    <Input
                      style={{ width: 200 }}
                      type="number"
                      value={supplyCost}
                      placeholder="e.g ₹ 450000 "
                      onChange={e =>
                        this.handleChange("supplyCost", e.target.value)
                      }
                    />
                  </div>
                </div>
                <div>
                  <span>
                    Operator<span style={{ color: "red" }}>*</span>
                  </span>
                  <Select
                    showSearch
                    style={{ width: 450 }}
                    onChange={(value, data) =>
                      this.handleChange("cabOperatorId", value, data)
                    }
                    value={cabOperator}
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {cabOperators.map(operator => (
                      <Option value={operator.value} key={operator._id}>
                        {operator.value}
                      </Option>
                    ))}
                  </Select>
                </div>
                {showErrowMessage ? (
                  <span className={less.error}> All Fields Are mandatory</span>
                ) : null}
              </div>
            </Modal>
          ) : null}
          {showEditModal ? (
            <Modal
              title="Edit Contract"
              closable={false}
              visible={showEditModal}
              header
              onCancel={() =>
                this.setState({
                  newEditValues: {},
                  showEditModal: false,
                  updateBody: {}
                })
              }
              onOk={this.updateContract}
            >
              <div className={less.createModal}>
                <div>
                  <span>
                    Trip Type<span style={{ color: "red" }}>*</span>
                  </span>
                  <Select
                    disabled
                    style={{ width: 450 }}
                    defaultValue={editData.isIntraCity ? "intra" : "inter"}
                    placeholder="Select"
                  >
                    <Option value="inter">Inter</Option>
                    <Option value="intra">Intra</Option>
                  </Select>
                </div>
                {!editData.isIntraCity ? (
                  <div className={less.typeSelector}>
                    <div>
                      <span>
                        From City<span style={{ color: "red" }}>*</span>
                      </span>
                      <Select
                        disabled
                        showSearch
                        style={{ width: 450 }}
                        value={
                          newEditValues && newEditValues.edittedFromCity
                            ? newEditValues.edittedFromCity
                            : editData.fromCity.name
                        }
                      ></Select>
                    </div>
                    <div>
                      <span>
                        To City<span style={{ color: "red" }}>*</span>
                      </span>
                      <Select
                        disabled
                        showSearch
                        style={{ width: 450 }}
                        value={
                          newEditValues && newEditValues.edittedToCity
                            ? newEditValues.edittedToCity
                            : editData.toCity.name
                        }
                      ></Select>
                    </div>
                  </div>
                ) : (
                  <div className={less.typeSelector}>
                    <div>
                      <span>
                        City<span style={{ color: "red" }}>*</span>
                      </span>
                      <Select
                        disabled
                        showSearch
                        style={{ width: 450 }}
                        value={
                          newEditValues && newEditValues.edittedCity
                            ? newEditValues.edittedCity
                            : editData.fromCity.name
                        }
                      ></Select>
                    </div>
                    <div>
                      <span>
                        From Station<span style={{ color: "red" }}>*</span>
                      </span>
                      <Select
                        disabled
                        showSearch
                        style={{ width: 450 }}
                        placeholder="Select"
                        value={
                          newEditValues &&
                          (newEditValues.edittedFromStation ||
                            newEditValues.edittedCity)
                            ? newEditValues.edittedFromStation
                            : editData.fromStation.name
                        }
                      ></Select>
                    </div>
                    <div>
                      <span>
                        To Station<span style={{ color: "red" }}>*</span>
                      </span>
                      <Select
                        disabled
                        showSearch
                        style={{ width: 450 }}
                        placeholder="Select"
                        value={editData.toStation.name}
                      ></Select>
                    </div>
                  </div>
                )}
                <div className={less.DatesSelector}>
                  <div>
                    <span>
                      Start Date <span style={{ color: "red" }}>*</span>
                    </span>
                    <DatePicker
                      disabled={Date.now() >= editData.startDate}
                      style={{ width: 200 }}
                      value={moment(
                        newEditValues && newEditValues.edittedStartDate
                          ? newEditValues.edittedStartDate
                          : editData.startDate
                      )}
                      format="DD-MM-YYYY "
                      onChange={this.onEditOk}
                    />
                  </div>
                  <div>
                    <span>
                      Expiry Date<span style={{ color: "red" }}>*</span>
                    </span>
                    <DatePicker
                      style={{ width: 200 }}
                      value={moment(
                        newEditValues && newEditValues.edittedExpiryDate
                          ? newEditValues.edittedExpiryDate
                          : editData.expiryDate
                      )}
                      format="DD-MM-YYYY"
                      onChange={this.onExpireOk}
                    />
                  </div>
                </div>
                <div className={less.DatesSelector}>
                  <div>
                    <span>
                      Asset Type<span style={{ color: "red" }}>*</span>
                    </span>
                    <Select
                      disabled
                      style={{ width: 200 }}
                      value={editData.assetType}
                    ></Select>
                  </div>
                  <div>
                    <span>
                      Supply Cost<span style={{ color: "red" }}>*</span>
                    </span>
                    <Input
                      style={{ width: 200 }}
                      value={
                        newEditValues &&
                        newEditValues.edittedSupplyCost != null &&
                        newEditValues.edittedSupplyCost !== 0
                          ? newEditValues.edittedSupplyCost
                          : editData.supplyCost
                      }
                      placeholder="e.g ₹ 450000 "
                      type="number"
                      onChange={e =>
                        this.editValue("supplyCost", e.target.value)
                      }
                    />
                  </div>
                </div>

                <div>
                  <span>
                    Operator<span style={{ color: "red" }}>*</span>
                  </span>
                  <Select
                    disabled
                    showSearch
                    style={{ width: 450 }}
                    onChange={(value, data) =>
                      this.editValue("cabOperatorId", value, data)
                    }
                    value={editData.cabOperator.name}
                  ></Select>
                </div>
                {showErrowMessage ? (
                  <span className={less.error}> All Fields Are mandatory</span>
                ) : null}
              </div>
            </Modal>
          ) : null}
          {showLogModal ? (
            <Modal
              width={"70%"}
              title="Logs"
              centered
              visible={showLogModal}
              footer={null}
              onCancel={() => this.setState({ showLogModal: false })}
            >
              <Table
                loading={contentLoading}
                columns={logsColoumn}
                pagination={false}
                dataSource={parts}
                scroll={{ x: "100%", y: "760vh" }}
              />
            </Modal>
          ) : null}
          <Modal
            centered
            visible={showConfirmationModal}
            footer={null}
            onCancel={() => this.setState({ showConfirmationModal: false })}
            // closable={false}
          >
            <div className={less.modalMessages}>
              <img
                src="https://d1flzashw70bti.cloudfront.net/original/images/modal/check.svg"
                alt="ok"
              />
              <div>
                <span>Creation Successful</span>
                <span>Contract has been successfully created</span>
              </div>
              <button
                onClick={() =>
                  this.setState({
                    showConfirmationModal: false
                  })
                }
              >
                <span>OK</span>
              </button>
            </div>
          </Modal>
          <Modal
            visible={showErrorModal}
            centered
            footer={null}
            onCancel={() => this.setState({ showErrorModal: false })}
          >
            <div className={less.modalMessages}>
              <img
                src="https://d1flzashw70bti.cloudfront.net/original/images/modal/error.svg"
                alt="error"
              />
              <div>
                <span>Creation Failed</span>
                <span>Sorry, we couldn&apos;t create the contract</span>
              </div>
              <button onClick={() => this.setState({ showErrorModal: false })}>
                <span>OK</span>
              </button>
            </div>
          </Modal>
        </div>
      );
    }
  }
}

export default OperatorContract;
