import React, { Component } from "react";
import { Select, Table, Button, Modal, message } from "antd";

import Loader from "../loader";
import Fetch from "../../utils/fetch";
import SearchForm from "./search";
import styles from "./home.module.scss";
import moment from "moment";

const { Option } = Select;

const columns = [
  {
    title: "Operator Name",
    dataIndex: "name",
    key: "name",
    width: "10%"
  },
  {
    title: "Service ID",
    dataIndex: "serviceId",
    key: "serviceId",
    width: "10%"
  },
  {
    title: "Total Bookings",
    dataIndex: "bookings",
    key: "bookings",
    width: "10%"
  },
  {
    title: "RC Number",
    dataIndex: "rc",
    key: "rc",
    width: "10%"
  },
  {
    title: "Marshal Number",
    dataIndex: "marshalNumber",
    key: "marshalNumber",
    width: "10%"
  },
  {
    title: "CX Arrival Time ",
    dataIndex: "arrivalTime",
    key: "arrivalTime",
    width: "10%"
  },
  {
    title: "Lounge name",
    dataIndex: "loungeName",
    key: "loungeName",
    width: "10%"
  },
  {
    title: "View Assignment Details",
    dataIndex: "details",
    key: "details",
    width: "15%",
    render: ({ marshalDetailsFunction, busAssignmentDetails }) => {
      return (
        <div>
          <Button onClick={() => marshalDetailsFunction(busAssignmentDetails)}>
            View
          </Button>
        </div>
      );
    }
  },
  {
    title: "View Bookings",
    dataIndex: "bookingsView",
    key: "bookingsView",
    width: "15%",
    render: ({ fetchBookingFunction, bookings }) => {
      return (
        <div>
          <Button onClick={() => fetchBookingFunction(bookings)}>
            View Bookings
          </Button>
        </div>
      );
    }
  }
];

const bookingColumns = [
  {
    title: "PNR",
    dataIndex: "pnr",
    key: "pnr",
    width: "10%"
  },
  {
    title: "GDS PNR",
    dataIndex: "gdsPNR",
    key: "gdsPNR",
    width: "10%"
  },
  {
    title: "Mobile Number",
    dataIndex: "mobileNo",
    key: "mobileNo",
    width: "10%"
  },
  {
    title: "Connection",
    dataIndex: "connection",
    key: "connection",
    width: "10%"
  },
  {
    title: "GDS Station Name",
    dataIndex: "stationName",
    key: "stationName",
    width: "10%"
  },
  {
    title: "CX Arrival Time",
    dataIndex: "arrivalTime",
    key: "arrivalTime",
    width: "10%"
  },
  {
    title: "GDS Pickup Time",
    dataIndex: "pickupTime",
    key: "pickupTime",
    width: "10%"
  },
  {
    title: "Total Passengers",
    dataIndex: "passengers",
    key: "passengers",
    width: "10%"
  },
  {
    title: "Arrived",
    dataIndex: "details",
    key: "details",
    width: "15%",
    render: ({ details, updatedetails, _id, fromStationLoungeId }) => {
      return (
        <Select
          value={details}
          style={{ width: 120, margin: "0 0.2rem", whiteSpace: "pre-line" }}
          onChange={e =>
            updatedetails({
              details: e,
              _id,
              fromStationLoungeId
            })
          }
        >
          <Option value={true}>Yes</Option>
          <Option value={false}>No</Option>
        </Select>
      );
    }
  },
  {
    title: "Boarded",
    dataIndex: "boarded",
    key: "boarded",
    width: "15%",
    render: ({ boarded, updatedetails, _id, fromStationLoungeId }) => {
      return (
        <Select
          value={boarded}
          style={{ width: 120, margin: "0 0.2rem", whiteSpace: "pre-line" }}
          onChange={e =>
            updatedetails({
              boarded: e,
              _id,
              fromStationLoungeId
            })
          }
        >
          <Option value={true}>Yes</Option>
          <Option value={false}>No</Option>
        </Select>
      );
    }
  }
];

class GdsLoungeBookings extends Component {
  state = {
    loading: true,
    showAssignmentDetails: false,
    assignmentDetails: {},
    serviceResponse: [],
    outboundRemarkInput: "",
    showBookingDetails: false,
    bookingDetails: []
  };

  async componentDidMount() {
    try {
      const serviceResponse = await this.fetchServices();
      this.setState({ serviceResponse, loading: false });
    } catch (err) {
      console.log(err);
      this.setState({ loading: false });
    }
  }
  fetchServices = async () => {
    try {
      const url = `/gds/booking/getLoungeBookings`;
      const options = {
        method: "get"
      };
      const response = await Fetch(url, options);
      if (response && Array.isArray(response)) {
        const formattedData = this.formattedData(response);
        return formattedData;
      }
      return null;
    } catch (err) {
      console.log(err);
      message.error(err);
    }
  };

  formattedData = response => {
    try {
      return response.map(complainsObj => {
        const complainFormated = {
          _id: complainsObj[0].gdsIdTrip,
          name:
            complainsObj[0] && complainsObj[0].gdsOperatorService
              ? complainsObj[0].gdsOperatorService.operatorName
              : null,
          serviceId: complainsObj[0].gdsOperatorService.gdsIdService,
          bookings: complainsObj.length,
          rc:
            complainsObj[0].gdsOperatorTrip &&
            complainsObj[0].gdsOperatorTrip.busAssignmentDetails
              ? complainsObj[0].gdsOperatorTrip.busAssignmentDetails.busRc
              : null,
          marshalNumber: complainsObj[0].gdsOperatorTrip.marshallNumbers[0],
          arrivalTime: moment(complainsObj[0].fromStationLoungeDate).format(
            "LLL"
          ),
          loungeName: complainsObj[0].mappedFromStationName,
          loungeId: complainsObj[0].fromStationLoungeId,
          details: {
            marshalDetailsFunction: this.marshalDetails,
            busAssignmentDetails: complainsObj[0].gdsOperatorTrip
              ? complainsObj[0].gdsOperatorTrip.busAssignmentDetails
              : null
          },
          bookingsView: {
            fetchBookingFunction: this.bookingsDetails,
            bookings: complainsObj
          }
        };
        return complainFormated;
      });
    } catch (error) {
      console.log(error);
    }
  };

  bookingsDetails = bookings => {
    try {
      const bookingDetails = this.formattedBooking(bookings);
      this.setState({ showBookingDetails: true, bookingDetails });
    } catch (err) {
      console.log(err);
      this.setState({ bookingLoader: false });
    }
  };

  formattedBooking = bookings => {
    try {
      return bookings.map(bookingObj => {
        const bookingFormated = {
          _id: bookingObj._id,
          pnr: bookingObj.bookingCode,
          gdsPNR: bookingObj.gdsPNR,
          mobileNo: bookingObj.mobileNo,
          connection: `${bookingObj.fromCity.name} - ${bookingObj.toCity.name}`,
          stationName: bookingObj.fromStationName,
          arrivalTime: moment(bookingObj.fromStationLoungeDate).format("LLL"),
          pickupTime: moment(bookingObj.fromStationDate).format("LLL"),
          passengers: bookingObj.tripChart.length,
          details: {
            _id: bookingObj._id,
            details: bookingObj.hasArrived,
            updatedetails: this.updateBookingArrived,
            fromStationLoungeId: bookingObj.fromStationLoungeId
          },
          boarded: {
            _id: bookingObj._id,
            boarded: bookingObj.hasBoarded,
            updatedetails: this.updateBookingBoarding,
            fromStationLoungeId: bookingObj.fromStationLoungeId
          }
        };
        return bookingFormated;
      });
    } catch (error) {
      console.log(error);
    }
  };

  marshalDetails = busAssignmentDetails => {
    this.setState({
      assignmentDetails: busAssignmentDetails,
      showAssignmentDetails: true
    });
  };

  showLoading = () => {
    this.setState({ contentLoading: true });
  };

  updateData = async data => {
    try {
      if (data && Array.isArray(data)) {
        let formattedData = this.formattedData(data);
        this.setState({
          serviceResponse: formattedData,
          contentLoading: false
        });
      } else {
        this.setState({
          serviceResponse: [],
          contentLoading: false
        });
      }
    } catch (err) {
      console.log(err);
      this.setState({
        contentLoading: false
      });
      message.error(err);
    }
  };

  updateBookingArrived = async updateObj => {
    try {
      const url = `/gds/booking/updateLoungeBookings`;

      const options = {
        method: "patch",
        data: {
          _id: updateObj._id,
          hasArrived: updateObj.details
        }
      };

      const response = await Fetch(url, options);
      if (Array.isArray(response) && response.length > 0) {
        message.success("Arrival Updated");

        const { bookingDetails } = this.state;
        const _id = updateObj._id;
        let newBookingDetails = bookingDetails.map(obj => {
          if (obj._id === _id) {
            return {
              ...obj,
              details: { ...obj.details, details: updateObj.details }
            };
          }
          return obj;
        });
        this.setState({ bookingDetails: newBookingDetails });
      }
    } catch (error) {
      console.log(error);
    }
  };

  updateBookingBoarding = async updateObj => {
    try {
      const url = `/gds/booking/updateLoungeBookings`;

      const options = {
        method: "patch",
        data: {
          _id: updateObj._id,
          hasBoarded: updateObj.boarded
        }
      };
      const response = await Fetch(url, options);
      if (Array.isArray(response) && response.length > 0) {
        message.success("Boarding Updated");
        const { bookingDetails } = this.state;
        const _id = updateObj._id;
        let newBookingDetails = bookingDetails.map(obj => {
          if (obj._id === _id) {
            return {
              ...obj,
              boarded: { ...obj.boarded, boarded: updateObj.boarded }
            };
          }
          return obj;
        });
        this.setState({ bookingDetails: newBookingDetails });
      }
    } catch (error) {
      console.log(error);
    }
  };

  render() {
    const {
      loading,
      contentLoading,
      serviceResponse,
      showAssignmentDetails,
      assignmentDetails,
      showBookingDetails,
      bookingDetails
    } = this.state;

    if (loading) {
      return <Loader />;
    } else {
      return (
        <div>
          <div>
            <SearchForm
              showLoader={this.showLoading}
              onSearch={this.updateData}
              serviceResponse={serviceResponse}
            />
          </div>
          <div
            style={{
              overflow: "hidden",
              width: "100%",
              height: "100vh"
            }}
          >
            <Table
              scroll={{ y: "70vh" }}
              loading={contentLoading}
              columns={columns}
              pagination={false}
              dataSource={serviceResponse}
            />
          </div>
          <Modal
            centered
            width={"30%"}
            title="Assignment Details"
            visible={showAssignmentDetails}
            footer={null}
            onCancel={() =>
              this.setState({
                showAssignmentDetails: false,
                assignmentDetails: {}
              })
            }
          >
            <div className={styles.modalHeader}>
              {!assignmentDetails ? (
                <span>Assignment Detail Not Available</span>
              ) : (
                <>
                  <div className={styles.info}>
                    <span>Bus Host Number:</span>
                    <span>{assignmentDetails.busHostPhoneNumber}</span>
                  </div>
                  <div className={styles.info}>
                    <span>Bus Host Name:</span>
                    <span>{assignmentDetails.busHostName}</span>
                  </div>
                  <div className={styles.info}>
                    <span>Bus RC Number:</span>
                    <span>{assignmentDetails.busRc}</span>
                  </div>
                  <div className={styles.info}>
                    <span>Bus Driver Number:</span>
                    <span>{assignmentDetails.driverPhoneNumber}</span>
                  </div>
                  <div className={styles.info}>
                    <span>Bus Host Name:</span>
                    <span>{assignmentDetails.driverName}</span>
                  </div>
                </>
              )}
              <div className={styles.button}>
                <Button
                  type="primary"
                  onClick={() =>
                    this.setState({
                      showAssignmentDetails: false,
                      assignmentDetails: {}
                    })
                  }
                  style={{ marginRight: "5px" }}
                >
                  Close
                </Button>
              </div>
            </div>
          </Modal>
          <Modal
            centered
            width={"100%"}
            title="Booking Details"
            visible={showBookingDetails}
            footer={null}
            onCancel={() =>
              this.setState({
                showBookingDetails: false,
                bookingDetails: []
              })
            }
          >
            <Table
              scroll={{ y: "70vh" }}
              columns={bookingColumns}
              pagination={false}
              dataSource={bookingDetails}
            />
          </Modal>
        </div>
      );
    }
  }
}

export default GdsLoungeBookings;
